import React from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import { IStoreItemReplacementForm } from '../../../reducers/storeItemReplacements/actions'
import InputNumberField from '../../../atoms/InputNumberField'
import DateField from '../../../atoms/DateField'
import JobSelectField from '../forms/JobSelectField'
import validateStoreItemReplacementForm from './validateStoreItemReplacementForm'
import StoreItemSelectField from '../forms/StoreItemSelectField'

type ComponentProps = {
	isCreate: boolean,
	id: number,
}
type Props = InjectedFormProps<IStoreItemReplacementForm, ComponentProps> & ComponentProps

const StoreItemReplacementForm = (props: Props) => {
	const {
		handleSubmit, invalid, pristine, isCreate, id
	} = props

	const { t } = useTranslation('components')

	const storeItemReplacementForm: IStoreItemReplacementForm = useSelector((state) => getFormValues(FORMS.STORE_ITEM_REPLACEMENT_FORM)(state)) as IStoreItemReplacementForm

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<h1>{ !isCreate ? t('pages:Upraviť skladovú položku') : t('pages:Pridať skladovú položku') }</h1>
						<Field
							name={ 'dateOfReplacement' }
							component={ DateField }
							label={ t('components:Date of Replacement') }
							size={ 'large' }
							required
						/>
						<Field
							name={ 'amount' }
							component={ InputNumberField }
							label={ storeItemReplacementForm?.isLiquid ? t('components:Množstvo v (%)') : t('components:Množstvo v (kusy)')}
							size={'large'}
							type={'number'}
							min={1}
							precision={0}
							step={1}
							required
						/>
						<Field
							name={ 'jobID' }
							component={ JobSelectField }
							label={ t('components:Job') }
							size={ 'large' }
							required
						/>
						<Field
							name={ 'storeItemID' }
							component={ StoreItemSelectField }
							label={ t('components:Store Item') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IStoreItemReplacementForm, ComponentProps>({
	form: FORMS.STORE_ITEM_REPLACEMENT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateStoreItemReplacementForm
})(StoreItemReplacementForm)

export default form
