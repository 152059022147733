import React, { useEffect } from 'react'
import cx from 'classnames'
import { Select } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getCategoryDocuments } from '../../../reducers/documentCategories/actions'
import { RootState } from '../../../reducers'

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean
}

const { Option } = Select

const DocumentCategoriesSelectField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		meta: { touched, error },
		type,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		width,
		multiple,
		...rest
	} = props

	const dispatch: any = useDispatch()

	const { t } = useTranslation('pages')

	useEffect(() => {
		dispatch(getCategoryDocuments())
	}, [dispatch])

	const documentCategories = useSelector((state: RootState) => state.documentCategories.documentCategories)

	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<label className={ cx(customLabelClass, { required }) }>{ t('components:Kategória') }</label>
			<div className={ 'input-field min-w-full' }>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={ 'min-w-full' }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					style={ { width } }
					onChange={ onInputChange }
					filterOption={ (input: any, option: any) => option.children.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0 }
					{ ...rest }
				>
					<Option
						key={ 0 }
						value={ 0 }
					>
						{ t('components:Žiadna') }
					</Option>
					{ map(documentCategories.tableData, (option) => (
						<Option
							key={ option.key || 0 }
							value={ option.key || 0 }
						>
							{ option.name }
						</Option>
					)) }
				</Select>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

export default DocumentCategoriesSelectField
