import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { CREATE_STORE_ITEM_REPLACEMENT, DELETE_STORE_ITEM_REPLACEMENT, LOAD_SELECT_STORE_ITEM_REPLACEMENTS, LOAD_STORE_ITEM_REPLACEMENT, UPDATE_STORE_ITEM_REPLACEMENT } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'

export type IStoreItemReplacementActions = IResetStore
	| IGetStoreItemReplacementsActions
	| IGetStoreItemReplacementAction;

export interface IGetStoreItemReplacementsActions {
	type: LOAD_SELECT_STORE_ITEM_REPLACEMENTS;
	payload: IStoreItemReplacementsPayload;
}

export interface IResponsePaginationReplacements extends IResponsePagination{
	amountOfOneItem: number;
}

export interface IStoreItemReplacementsPayload {
	tableData: StoreItemReplacementTableItem[];
	originalData: StoreItemReplacementOriginalItem[];
	pagination: IResponsePaginationReplacements | null;
}

export type StoreItemReplacementTableItem = {
	key: number;
	id: number;
	dateOfReplacement: string;
	dateOfReplacementRaw: string;
	isLiquid: boolean;
	storeItemID: number;
	jobID: number;
	amount: number;
	storeItemName: string;
	jobAddress: string;
	userEmployeeName: string;
	adminName: string;
};

export type StoreItemReplacementOriginalItem = {
	id: number;
	dateOfReplacement: string;
	amount: number;
	isLiquid: boolean;
	storeItem: {
		id: number;
		name: string;
	};
	job: {
		id: number;
		address: string;
	};
	userEmployee: {
		id: number;
		fullName: string;
	};
	admin: {
		id: number;
		fullName: string;
	};
};

export const getStoreItemReplacements = (
	page: number = 1,
	limit: number | null = 20,
	queryParams: any = {}
): ThunkResult<Promise<IStoreItemReplacementsPayload>> => async (dispatch) => {
	let payload = {} as IStoreItemReplacementsPayload
	try {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_REPLACEMENTS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/store-item-replacements', normalizeQueryParams)

		const originalData: StoreItemReplacementOriginalItem[] = get(data, 'replacements', [])
		const tableData: StoreItemReplacementTableItem[] = map(originalData, (item) => ({
			key: item.id,
			id: item.id,
			dateOfReplacement: formatDateWithTime(item.dateOfReplacement),
			dateOfReplacementRaw: item.dateOfReplacement,
			amount: item.amount,
			storeItemName: item.storeItem.name,
			storeItemID: item.storeItem.id,
			isLiquid: item.isLiquid,
			jobAddress: item.job.address,
			jobID: item.job.id,
			userEmployeeName: item.userEmployee.fullName,
			adminName: item.admin.fullName
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_STORE_ITEM_REPLACEMENTS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_REPLACEMENTS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IStoreItemReplacementForm {
	id: number | null;
	dateOfReplacement: string;
	isLiquid: boolean;
	amount: number;
	jobID: number | null;
	storeItemID: number | null;
}

export const createStoreItemReplacement = (
	values: IStoreItemReplacementForm,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<any>> => async (dispatch) => {
	dispatch({
		type: CREATE_STORE_ITEM_REPLACEMENT.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/store-item-replacements', null, normalizeQueryParams)

		dispatch({ type: CREATE_STORE_ITEM_REPLACEMENT.DONE })

		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_STORE_ITEM_REPLACEMENT.FAILED })
		onFailure(error)
		return error
	}
}

export const updateStoreItemReplacement = (
	id: number,
	values: IStoreItemReplacementForm,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_STORE_ITEM_REPLACEMENT.START
	})
	try {
		await putReq(`/api/admin/store-item-replacements/${id}`, null, values)
		dispatch({ type: UPDATE_STORE_ITEM_REPLACEMENT.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_STORE_ITEM_REPLACEMENT.FAILED })
		onFailure(error)
		return error
	}
}

interface IGetStoreItemReplacementAction {
	type: LOAD_STORE_ITEM_REPLACEMENT;
	payload: IStoreItemReplacementPayload;
}

export interface IStoreItemReplacementPayload {
	data: IStoreItemReplacementDetail;
}

export interface IStoreItemReplacementDetail {
	id: number;
	isLiquid: boolean;
	dateOfReplacement: string;
	amount: number;
	storeItem: {
		id: number;
		name: string;
	};
	job: {
		id: number;
		address: string;
	};
	userEmployee: {
		id: number;
		fullName: string;
	};
	admin: {
		id: number;
		fullName: string;
	};
}

export const getStoreItemReplacement = (
	id: number,
	onSuccess: (data: IStoreItemReplacementPayload) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IStoreItemReplacementPayload>> => async (dispatch) => {
	let payload = {} as IStoreItemReplacementPayload
	dispatch({
		type: LOAD_STORE_ITEM_REPLACEMENT.START
	})
	try {
		const { data } = await getReq(`/api/admin/store-item-replacements/${id}`)

		payload = {
			data
		}

		dispatch({
			type: LOAD_STORE_ITEM_REPLACEMENT.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_STORE_ITEM_REPLACEMENT.FAILED
		})
		onFailure(error)
		return error
	}
}

export const deleteStoreItemReplacement = (
	id: number,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_STORE_ITEM_REPLACEMENT.START })
	try {
		await deleteReq(`/api/admin/store-item-replacements/${id}`)

		dispatch({ type: DELETE_STORE_ITEM_REPLACEMENT.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_STORE_ITEM_REPLACEMENT.FAILED })
		onFailure(error)
		return false
	}
}
