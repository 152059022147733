import React from 'react'
import { render } from 'react-dom'
import { ConfigProvider } from 'antd'
// import * as Sentry from '@sentry/browser'

// dayjs
import dayjs from 'dayjs'
import utcPlugin from 'dayjs/plugin/utc'
import timezonePlugin from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
// lokalizacia pre ConfigProvider -> ant
import SK from 'antd/es/locale/sk_SK'
// Lokalizcia pre antd-dayjs-webpack-plugin, ktory overridne Moment -> Dayjs format v komponentoch
import 'dayjs/locale/sk'

import App from './App'
// load theme styles with webpack
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// NOTE: lebo je v gitignore a az pri starte sa vytvori prvy krat a pipelina by padla
// eslint-disable-next-line import/no-unresolved
import './styles/main.css'
import './styles/global.sass'

import ErrorBoundary from './components/ErrorBoundary'

import firebaseAdmin from './utils/firebase'

// dayjs extended plugins
dayjs.extend(isBetween)
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(minMax)
dayjs.locale('sk')

// Sentry.init({
// 	dsn: 'https://2a37f51da68d47339f324dcee270315b@sentry.asene.com/3',
// 	release: process.env.REACT_APP_VERSION,
// 	enabled: process.env.NODE_ENV !== 'development'
// })

firebaseAdmin?.auth()?.onAuthStateChanged((user) => {
	if (user) {
		return user
	}
	return null
})

const app = (
	<ConfigProvider locale={SK}>
		<ErrorBoundary>
			<App />
		</ErrorBoundary>
	</ConfigProvider>
)
render(app, document.getElementById('root'))
