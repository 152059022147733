import React, { FC } from 'react'
import { Modal, Button } from 'antd'
import { ModalProps } from 'antd/lib/modal'

// assets
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../assets/icons/BaseIcons/x-icon.svg'
// eslint-disable-next-line import/no-cycle
import { FORMS } from '../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import FinishQrCodeForm from '../forms/FinishQrCodeForm'
// eslint-disable-next-line import/no-cycle
import { IFinishForm, updateFinish } from '../../../reducers/finishes/actions'

type Props = ModalProps & {
	isVisible: boolean,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: Function,
	classNameBtn?: string
	finishID: number | null
}

const UpdateFinishQrCodeModal: FC<Props> = (props) => {
	const { t } = useTranslation()

	const {
		isVisible,
		onCancel,
		onSuccess,
		width,
		classNameBtn,
		finishID
	} = props

	const dispatch: any = useDispatch()

	const handleCreate = (body: IFinishForm) => {
		dispatch(updateFinish(body, () => onSuccess()))
	}

	const handleSubmit = (values:IFinishForm) => {
		const body: IFinishForm = {
			id: null
		}

		handleCreate({
			...body
		})
	}

	return 	<Modal
		closeIcon={<CloseIcon width={18} height={18}/>}
		title={t('loc:Upraviť ukončenie')}
		centered
		visible={isVisible}
		width={width}
		onCancel={onCancel}
		footer={[
			<Button style={{ width: 118, height: 40 }} key='back' type='text' onClick={onCancel}>
				{ t('loc:Zrušiť') }
			</Button>,
			<Button style={{ width: 118, height: 40 }} className={classNameBtn || ''} key='submit' type='primary' onClick={() => dispatch(submit(FORMS.FINISH_QR_CODE_FORM))}>
				{ t('loc:Upraviť') }
			</Button>
		]}
	>
		<div>
			<FinishQrCodeForm
				onSubmit={ handleSubmit }
				finishID={finishID}
			/>
		</div>
	</Modal>
}

export default UpdateFinishQrCodeModal
