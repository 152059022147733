import { get, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import {
	deleteReq, getReq, postReq, putReq
} from '../../utils/request'
// eslint-disable-next-line import/no-cycle,import/extensions
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'
import {
	CREATE_STORE, LOAD_STORE, REMOVE_STORE, UPDATE_STORE
} from './types'

export type IStoreActions = IResetStore
	| IGetStoreAction

// store
interface IGetStoreAction {
	type: LOAD_STORE
	payload: IStorePayload
}

export interface IStoreForm {
	address: string
	userID: number
	updatedAt: string
	organizationID: number | null | undefined
	userOrganizations: IUserOrganization[]
}

export interface IUserOrganization {
	hasStore: boolean
	clientStoreID: number | null
	clientStoreName: string | null
	organizationID: number
	organizationName: string
	organizationHasItsOwnStoreItems: boolean
	organizationHasItsOwnTasks: boolean
}

export const createStore = (
	values: IStoreForm,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: CREATE_STORE.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/stores', null, normalizeQueryParams)

		dispatch({ type: CREATE_STORE.DONE })
		onSuccess(get(data, 'data.id'))
		return true
	} catch (error) {
		dispatch({ type: CREATE_STORE.FAILED })
		onFailure(error)
		return false
	}
}

export const updateStore = (
	id: number,
	values: IStoreForm,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_STORE.START
	})
	try {
		await putReq(`/api/admin/stores/${id}`, null, values)
		dispatch({ type: UPDATE_STORE.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_STORE.FAILED })
		onFailure(error)
		return false
	}
}

// store
export interface IStorePayload {
	data: IStoreDetail | null
}

export interface IStoreDetail {
	id: number
	address: string

	isFailure: boolean
}

export const getStore = (
	id: number,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<IStorePayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_STORE.START
	})
	let payload = {} as IStorePayload
	try {
		const { data } = await getReq(`/api/admin/stores/${id}`)

		payload = {
			data
		} as IStorePayload
		dispatch({
			type: LOAD_STORE.DONE,
			payload
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_STORE.FAILED
		})
		onFailure(error)
		return error
	}
}

export const removeStore = (
	id: number,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_STORE.START
		})
		const { data } = await deleteReq(`/api/admin/stores/${id}`)

		dispatch({
			type: REMOVE_STORE.DONE
		})

		onSuccess(data)
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_STORE.FAILED
		})
		onFailure(error)
		return false
	}
}

export const removeItemsFromDeletedJob = (
	jobId: number,
	itemId: number,
	onSuccess: (data: any) => void = ((data) => {}),
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	try {
		dispatch({
			type: REMOVE_STORE.START
		})

		const normalizeQueryParams = mapValues({ jobId, itemId }, (query) => query || undefined)

		const { data } = await postReq('api/admin/store-item-transactions/remove-items-from-deleted-store', null, normalizeQueryParams)

		dispatch({
			type: REMOVE_STORE.DONE
		})

		onSuccess(data)
		return true
	} catch (error) {
		dispatch({
			type: REMOVE_STORE.FAILED
		})
		onFailure(error)
		return false
	}
}
