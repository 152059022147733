import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Input } from 'antd'

const TextField = ({
	input, name, label, placeholder, showLabel, customClass, customLabelClass, customInputClass,
	meta: { touched, error }, type, disabled, onInput, onKeyUp, suffix, required, min, onBlur, size
}) => (
	<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
		{ showLabel && <label className={ cx(customLabelClass, { required }) } htmlFor={ name }>{ label }</label> }
		<div className={ 'input-field' }>
			<Input
				{ ...input }
				placeholder={ placeholder }
				type={ type }
				disabled={ disabled }
				className={ cx('input-field', customInputClass, { disabled }) }
				onInput={ onInput }
				onKeyUp={ onKeyUp }
				required={ required }
				min={ min }
				onBlur={ onBlur }
				suffix={ suffix }
				size={ size }
			/>
		</div>
		<div className={ 'tooltip-error' }>
			{ touched ? error : '' }
		</div>
	</div>
)

TextField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.any
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	size: PropTypes.string
}

TextField.defaultProps = {
	name: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	size: 'middle'
}

export default TextField
