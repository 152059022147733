import React, { useEffect, FC } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { Dictionary } from 'lodash'
import { bindActionCreators, compose, Dispatch } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { isLoggedIn } from '../utils/auth'
import { loadUser } from '../reducers/user/userActions'
import { ADMIN_ROLES_WEIGHTS, getRouteAllowedRoles, PAGE } from '../utils/enums'
import { RootState } from '../reducers'

const mapStateToProps = (state: RootState) => ({
	profile: state.profile
	// user: state.user // NOTE: auth Route has to listen on change user state cause of log in action
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
	userActions: bindActionCreators(loadUser, dispatch)
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = RouteProps & PropsFromRedux & WithTranslation & {
	layout: any
	component: any
	translatePathKey?: string
	page?: PAGE
	routeName: string
	routeKey: string
	/** e.g. tabKey or other extra props for page */
	extra?: Dictionary<any>
}

// type Props = RouteProps & WithTranslation & {
// 	layout: any
// 	translatePathKey?: string
// 	component: any
// }

class AdminRoute extends Route<Props> {
	componentDidMount = async () => {
		// await this.props.loadUser()
	}

	render = () => {
		const { t, profile, routeKey } = this.props
		if (!profile.profile.data) {
			return <Redirect to={ t('paths:login|path') } />
		}
		const routeAllowedRoles: any = getRouteAllowedRoles()

		if (ADMIN_ROLES_WEIGHTS[this?.props?.profile?.profile?.data?.role || ADMIN_ROLES_WEIGHTS.ADMIN] > ADMIN_ROLES_WEIGHTS[routeAllowedRoles[routeKey]]) {
			return <Redirect to={ t('paths:base|path') } />
		}

		if (!isLoggedIn()) {
			return <Redirect to={ t('paths:dashboard|path') } />
		}

		const Layout = this.props.layout
		const Component = this.props.component
		if (this.props.layout) {
			return (<>
				<Layout { ...this.props }>
					<Component { ...this.props } />
				</Layout>
			</>)
		}
		return (
			<Component { ...this.props } />
		)
	}
}

// export default compose(connector, withTranslation('paths'))(AdminRoute)

export default withTranslation('paths')(compose(
	connector
)(AdminRoute))
