import React, { useState } from 'react'

// ant
import { Form, Row, Select } from 'antd'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { SwitchProps } from 'antd/lib/switch'
import { useTranslation } from 'react-i18next'
import { IUserOrganization } from '../../../reducers/stores/actions'
import { ReactComponent as ChevronIcon } from '../../../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import CreateVirtualStoreModal from './CreateVirtualStoreModal'

type Props = SwitchProps & FormItemLabelProps& {
	organizationID: number,
	organization: IUserOrganization | null,
	onSelectedStore: (value: IUserOrganization) => void
}

export interface ChooseVirtualStoreProps {
	isShowed: boolean,
	organizationStoreName: string
	organizationID: number
}

function getSingleItem(
	organizationID: number,
	organization: IUserOrganization | null,
	onChange: (value: IUserOrganization) => void
) {
	const { t } = useTranslation('components')

	const [visibility, setVisibilityModal] = useState<ChooseVirtualStoreProps>({
		isShowed: false,
		organizationStoreName: '',
		organizationID: 0
	})

	return <>
		{
			<div key={organization?.organizationName} className={'p-1 w-full'}>
				<Row justify={'space-between'} align={'middle'} className={'h-10 w-full'} key={organizationID}>
					<Select
						onBlur={() => {}}
						className={'dropDown w-60'}
						size={'middle'}
						value={organization?.clientStoreName || ''}
						options={[]}
						showAction={['click']}
						open={false}
						onClick={() => {
							setVisibilityModal({
								isShowed: true,
								organizationStoreName: organization?.organizationName || '',
								organizationID
							})
						}}
						placeholder={t('loc:Select procedure')}
						suffixIcon={<ChevronIcon width={20} height={20}/>}
					/>
				</Row>
			</div>
		}
		<CreateVirtualStoreModal
			isVisible={visibility.isShowed}
			organizationStoreName={visibility.organizationStoreName}
			organizationID={visibility.organizationID}
			onSuccess={(selectedStore) => {
				const result = {
					...organization,
					clientStoreID: selectedStore.id,
					clientStoreName: selectedStore.name,
					organizationID: selectedStore.organizationID
				} as IUserOrganization
				setVisibilityModal({
					...visibility,
					isShowed: false
				})
				onChange(result)
			}}
			onCancel={() => {
				setVisibilityModal({
					...visibility,
					isShowed: false
				})
			}}
		/>
	</>
}

const VirtualStorePicker = (props: Props) => {
	const {
		organizationID,
		organization,
		onSelectedStore
	} = props

	return (
		<div>
			{getSingleItem(
				organizationID,
				organization,
				onSelectedStore
			)}
		</div>
	)
}

export default VirtualStorePicker
