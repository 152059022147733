import React, {
	useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Table, Popconfirm, Button, Empty, Input, Form, Select
} from 'antd'
import {
	DeleteOutlined,
	QuestionCircleOutlined,
	CloseCircleOutlined, PlusCircleOutlined
} from '@ant-design/icons'
import i18next from 'i18next'
import { debounce, get, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { history } from '../../utils/history'
import { deleteUser, getUsers, UsersTableItem } from '../../reducers/usersU/actions'
import { translateRoleToSk, USER_ROLES } from '../../utils/enums'
import { RootState } from '../../reducers'

const { Option } = Select

const PAGE_SIZE = 20

type Columns = ColumnsType<UsersTableItem>

const Users = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')
	const [filter, setFilter] = useState<any | null>({})

	const columns: Columns = [
		{
			title: i18next.t('pages:Priezvisko'),
			dataIndex: 'lastName',
			key: 'lastName',
			sorter: true,
			ellipsis: true,
			render: (value) => (value || '-')
		},
		{
			title: i18next.t('pages:Meno'),
			dataIndex: 'firstName',
			key: 'firstName',
			sorter: true,
			ellipsis: true,
			render: (value) => (value || '-')
		},
		{
			title: i18next.t('pages:Kontaktný mail'),
			dataIndex: 'email',
			key: 'email',
			ellipsis: true,
			render: (value) => (value || '-')
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render(text, record) {
				return (
					<Popconfirm
						title={ i18next.t('pages:Skutočne chcete vymazať záznam?') }
						icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
						cancelText={ i18next.t('pages:Zrušiť') }
						okText={ i18next.t('pages:Vymazať') }
						onConfirm={ (e) => {
							e?.stopPropagation()
							dispatch(deleteUser(record.id, () => {
								dispatch(getUsers(1, PAGE_SIZE, { ...filter, page: 1 }))
							}))
						} }
						onCancel={ (e) => e?.stopPropagation() }
						okButtonProps={ {
							size: 'small',
							type: 'primary'
						} }
						cancelButtonProps={ {
							size: 'small',
							type: 'ghost'
						} }
					>
						<Button
							icon={ <DeleteOutlined /> }
							type={ 'primary' }
							onClick={ (e) => e.stopPropagation() }
						/>
					</Popconfirm>
				)
			}
		}
	]

	const users = useSelector((state: RootState) => state.users.users)
	const user = useSelector((state: RootState) => state.users.detail)

	useEffect(() => {
		dispatch(getUsers(1,
			PAGE_SIZE,
			{ ...filter }))
	}, [filter])

	const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<UsersTableItem> | SorterResult<UsersTableItem>[], extra: TableCurrentDataSource<UsersTableItem>) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setFilter({ ...filter, search: searchTerm }), 300), [filter])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const resetFilter = () => {
		setFilter({})
	}

	return (
		<div className={ 'page-wrapper' }>
			<div className={ 'flex justify-end' }>
				<Button
					icon={ <PlusCircleOutlined /> }
					href={ t('paths:userCreate|path') }
					type={ 'primary' }
					className={'mt-6'}
				>
					{ t('pages:Pridať Používateľa') }
				</Button>
			</div>
			<div className={ 'flex justify-between' }>
				<Form.Item>
					<Input.Search onChange={ handleOnChange } style={ { width: 300 } } allowClear />
				</Form.Item>
			</div>
			<div className={ 'flex' } style={ { flexWrap: 'wrap' } }>
				<Form.Item>
					<Select
						style={ { width: 200, marginRight: 20 } }
						onChange={ (value) => setFilter({ ...filter, role: value, page: 1 }) }
						placeholder={ t('pages:Roles') }
						value={ filter?.role }
						mode={ 'multiple' }
						filterOption={ (input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
					>
						{ map(USER_ROLES, (role, index) => (
							<Option
								key={ `role-${index}` }
								value={ role }
							>
								{ translateRoleToSk(role) }
							</Option>
						)) }
					</Select>
				</Form.Item>
				<Form.Item>
					<Button
						style={ { marginBottom: 20 } }
						icon={ <CloseCircleOutlined /> }
						onClick={ resetFilter }
					>
						{ t('pages:Zrušiť filter') }
					</Button>
				</Form.Item>
			</div>
			<Table
				className={ 'general-table' }
				columns={ columns }
				dataSource={ users.tableData }
				onChange={ handleTableChange }
				showSorterTooltip={ false }
				rowKey={ 'id' }
				style={ { marginTop: -20 } }
				pagination={ {
					pageSize: PAGE_SIZE,
					total: users.pagination?.totalCount,
					current: users.pagination?.page,
					showSizeChanger: false
				} }
				loading={ user.isLoading || users.isLoading }
				onRow={ (record) => ({
					onClick: () => history.push(`${t('paths:userDetail|path')}/${get(record, 'id')}`)
				}) }
				locale={ {
					emptyText: (
						<Empty description={ t('pages:Žiadne dáta') } />
					)
				} }
				size={ 'small' }
			/>
		</div>
	)
}

Users.propTypes = {}

export default Users
