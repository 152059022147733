import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get } from 'lodash'
import { notification, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMS, PRICE_OFFER_STATUS } from '../../utils/enums'
import { history } from '../../utils/history'
import PriceOfferForm from './forms/PriceOfferForm'
import { IComputedMatch } from '../../types/interfaces'
import { createPriceOffers, getPriceOffer, IPriceOfferForm, sendPriceOffer, sendTransferPriceOfferToJob, updatePriceOffer } from '../../reducers/priceOffers/actions'
import { RootState } from '../../reducers'
import { FREQUENCY } from '../../utils/translate'

type Props = {
	computedMatch: IComputedMatch<{ id: number }>
}

const DetailPriceOffer = (props: Props) => {
	const { computedMatch } = props
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()
	const detail = useSelector((state: RootState) => state.priceOffer.detail)

	const isLoading = get(detail, 'isLoading')

	const { id } = computedMatch.params

	const [isModalVisible, setIsModalVisible] = React.useState(false)
	const [selectedFrequencies, setSelectedFrequencies] = React.useState<FREQUENCY[]>([])

	const initEmptyDetailForm = () => {
		const initValues = {
			id: '',
			name: '',
			status: PRICE_OFFER_STATUS.NOT_ACCEPTED
		}

		dispatch(initialize(FORMS.PRICE_OFFER_FORM, initValues))
	}

	const initDetailForm = (data: IPriceOfferForm) => {
		dispatch(initialize(FORMS.PRICE_OFFER_FORM, data))
	}

	const loadDetail = () => {
		dispatch(getPriceOffer(id, (data) => {
			const initValues: IPriceOfferForm = {
				id: data.data.id,
				priceOfferHeader: data.data.priceOfferHeader,
				priceOfferFirmHeaderID: data.data.priceOfferFirmHeaderID,
				address: data.data.address,
				jobId: data.data.jobId,
				nameOfHousingAssociation: data.data.nameOfHousingAssociation,
				floors: data.data.floors,
				flats: data.data.flats,
				nameOfTrustee: data.data.nameOfTrustee,
				phoneOfTrustee: data.data.phoneOfTrustee,
				emailOfTrustee: data.data.emailOfTrustee,
				nameOfFirmWhichCleanedUpToNow: data.data.nameOfFirmWhichCleanedUpToNow,
				pricePaidForCleaningUpToNow: data.data.pricePaidForCleaningUpToNow,
				isThereRoomForCleaningService: data.data.isThereRoomForCleaningService,
				placeWithWater: data.data.placeWithWater,
				whereCanWaterBePoured: data.data.whereCanWaterBePoured,
				whenToCall: data.data.whenToCall,
				dateOfHouseAssociationMeeting: data.data.dateOfHouseAssociationMeeting,
				status: data.data.status,
				price: data.data.price,
				updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',

				mondayTasks: data.data.mondayTasks,
				tuesdayTasks: data.data.tuesdayTasks,
				wednesdayTasks: data.data.wednesdayTasks,
				thursdayTasks: data.data.thursdayTasks,
				fridayTasks: data.data.fridayTasks,
				saturdayTasks: data.data.saturdayTasks,
				sundayTasks: data.data.sundayTasks,
				monthTasks: data.data.monthTasks,
				quarterYearTasks: data.data.quarterYearTasks,
				halfYearTasks: data.data.halfYearTasks,
				yearTasks: data.data.yearTasks,

				isUznesenieIsPartOfPriceOffer: data.data.isUznesenieIsPartOfPriceOffer,
				additionalTexts: data.data.additionalTexts
			}

			initDetailForm(initValues)
		}))
	}

	useEffect(() => {
		if (id) {
			loadDetail()
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [computedMatch])

	const handleCreate = (body: IPriceOfferForm) => {
		dispatch(createPriceOffers(body, (PriceOfferId) => history.replace(`${t('paths:priceOfferDetail|path')}/${PriceOfferId}`)))
	}

	const handleSendPriceOffer = (priceOfferId: number, additionalEmails: string[]) => {
		dispatch(sendPriceOffer(priceOfferId, additionalEmails, () => notification.success({
			message: 'Cenová ponuka bola úspešne odoslaná'
		})))
	}

	const handleSendTransferPriceOfferToJob = (priceOfferId: number) => {
		dispatch(sendTransferPriceOfferToJob(priceOfferId, () => {
			notification.success({
				message: 'Cenová ponuka bola úspešne prevedená na zákazku'
			})
			loadDetail()
		}))
	}

	const handleShowPdfPriceOffer = (priceOfferId: number) => {
		if (priceOfferId !== null) {
			const domain = process.env.REACT_APP_API_BASE_URL || 'https://api.umyjeme.top' || undefined
			const pdfURL = `${domain}/api/admin/price-offers/serve/offer/${id}`
			window.open(pdfURL, '_blank')
		}
	}

	const handleUpdate = (body: IPriceOfferForm) => {
		dispatch(updatePriceOffer(id, body, () => {
			dispatch(getPriceOffer(id, (data) => {
				const initData: IPriceOfferForm = {
					id: null,
					updatedAt: null,
					jobId: data.data.jobId,
					address: data.data.address,
					priceOfferHeader: data.data.priceOfferHeader,
					priceOfferFirmHeaderID: data.data.priceOfferFirmHeaderID,
					nameOfHousingAssociation: data.data.nameOfHousingAssociation,
					floors: data.data.floors,
					flats: data.data.flats,
					nameOfTrustee: data.data.nameOfTrustee,
					phoneOfTrustee: data.data.phoneOfTrustee,
					emailOfTrustee: data.data.emailOfTrustee,
					nameOfFirmWhichCleanedUpToNow: data.data.nameOfFirmWhichCleanedUpToNow,
					pricePaidForCleaningUpToNow: data.data.pricePaidForCleaningUpToNow,
					isThereRoomForCleaningService: data.data.isThereRoomForCleaningService,
					placeWithWater: data.data.placeWithWater,
					whereCanWaterBePoured: data.data.whereCanWaterBePoured,
					whenToCall: data.data.whenToCall,
					dateOfHouseAssociationMeeting: data.data.dateOfHouseAssociationMeeting,
					status: data.data.status,
					price: data.data.price,

					mondayTasks: data.data.mondayTasks,
					tuesdayTasks: data.data.tuesdayTasks,
					wednesdayTasks: data.data.wednesdayTasks,
					thursdayTasks: data.data.thursdayTasks,
					fridayTasks: data.data.fridayTasks,
					saturdayTasks: data.data.saturdayTasks,
					sundayTasks: data.data.sundayTasks,
					monthTasks: data.data.monthTasks,
					quarterYearTasks: data.data.quarterYearTasks,
					halfYearTasks: data.data.halfYearTasks,
					yearTasks: data.data.yearTasks,

					isUznesenieIsPartOfPriceOffer: data.data.isUznesenieIsPartOfPriceOffer,
					additionalTexts: data.data.additionalTexts
				}
				initDetailForm(initData)
			}))
		}))
	}

	const handleSubmit = (values: IPriceOfferForm) => {
		if (id) {
			handleUpdate(values)
		} else {
			handleCreate(values)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PriceOfferForm
					onSubmit={handleSubmit}
					isCreate={!id}
					id={id}
					sendPriceOffer={(id, additionalEmails) => {
						handleSendPriceOffer(id, additionalEmails)
					}}
					showPdfPriceOffer={(id) => {
						handleShowPdfPriceOffer(id)
					}}
					transferOfferToJob={(id) => {
						handleSendTransferPriceOfferToJob(id)
					}}
					onRemove={() => {

					}}
				/>
			</Spin>
		</div>
	)
}

export default DetailPriceOffer
