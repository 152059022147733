// https://dev.to/emeka/securing-your-express-node-js-api-with-firebase-auth-4b5f

import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

if (!firebase.apps.length) {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_KEY,
		authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
		projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
		storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
		appId: process.env.REACT_APP_FIREBASE_APP_ID
	})
}

export const { auth } = firebase

const firebaseAdmin = firebase
export default firebaseAdmin
