import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Spin } from 'antd'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import { getPriceOffersSentPerMonthStats, IPriceOffersSentPerMonthPayload } from '../../../reducers/statistics/actions'

const PriceOffersSentPerMonth = () => {
	const dispatch = useDispatch()
	const [chartData, setChartData] = useState<IPriceOffersSentPerMonthPayload['priceOffersSentPerMonth']>([])

	useEffect(() => {
		dispatch(getPriceOffersSentPerMonthStats(
			null,
			(data: IPriceOffersSentPerMonthPayload) => {
				// onSuccess method: update local state with the fetched data
				setChartData(data.priceOffersSentPerMonth)
				console.log('Fetched data successfully:', data)
			},
			(error) => {
				// Handle failure if needed
				console.error('Failed to fetch data:', error)
			}
		))
	}, [dispatch])

	return (
		<Card hoverable className={'my-2'}>
			<Spin spinning={false}>
				<div className={'font-bold mb-2'}>Price Offers Sent Per Month</div>
				<BarChart width={500} height={300} data={chartData}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="date" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="count" fill="#1890ff" />
				</BarChart>
			</Spin>
		</Card>
	)
}

export default PriceOffersSentPerMonth
