import React, { useState } from 'react'
import { WrappedFieldProps } from 'redux-form'

// ant
import {
	Form, notification, Row, Select, Switch
} from 'antd'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { SwitchProps } from 'antd/lib/switch'
import { EventWithDataHandler } from 'redux-form/lib/Field'
import { useTranslation } from 'react-i18next'
import { IUserOrganization } from '../../../reducers/stores/actions'
import { ReactComponent as ChevronIcon } from '../../../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import CreateVirtualStoreModal from './CreateVirtualStoreModal'

const { Item } = Form

type Props = WrappedFieldProps & SwitchProps & FormItemLabelProps& {
	itemDisabled: (iUserOrganization: IUserOrganization) => boolean,
}

export interface ChooseVirtualStoreProps {
	isShowed: boolean,
	organizationStoreName: string
	organizationID: number
}

function getSwitchList(
	inputUserOrganizations: IUserOrganization[],
	onChange: EventWithDataHandler<IUserOrganization[]>,
	itemDisabled: (iUserOrganization: IUserOrganization) => boolean
) {
	const { t } = useTranslation('components')

	const [visibility, setVisibilityModal] = useState<ChooseVirtualStoreProps>({
		isShowed: false,
		organizationStoreName: '',
		organizationID: 0
	})

	return <>
		{(inputUserOrganizations as IUserOrganization[] || []).map((userOrganization: IUserOrganization) => (
			<div key={userOrganization.organizationName} className={'p-1 w-full pr-8'}>
				<Row justify={'space-between'} align={'middle'} className={'mb-1 h-10'} key={userOrganization.organizationID}>
					<div>{userOrganization.organizationName}</div>
					<Select
						onBlur={() => {}}
						className={'dropDown w-60'}
						size={'middle'}
						value={userOrganization.clientStoreName || ''}
						options={[]}
						showAction={['click']}
						open={false}
						onClick={() => {
							if (!itemDisabled(userOrganization)) {
								setVisibilityModal({
									isShowed: true,
									organizationStoreName: userOrganization.organizationName,
									organizationID: userOrganization.organizationID
								})
							}
						}}
						placeholder={t('loc:Select procedure')}
						disabled={itemDisabled(userOrganization)}
						suffixIcon={<ChevronIcon width={20} height={20}/>}
					/>
				</Row>
				<div className='h-px bg-gray-400' />
			</div>
		))}
		<CreateVirtualStoreModal
			isVisible={visibility.isShowed}
			organizationStoreName={visibility.organizationStoreName}
			organizationID={visibility.organizationID}
			onSuccess={(selectedStore) => {
				const userOrganizationInput = inputUserOrganizations as IUserOrganization[]
				const userOrganizations = [...userOrganizationInput]
				const result = userOrganizations.map((uo: IUserOrganization) => {
					if (uo.organizationID === selectedStore.organizationID) {
						return {
							...uo,
							clientStoreID: selectedStore.id,
							clientStoreName: selectedStore.name,
							organizationID: selectedStore.organizationID
						} as IUserOrganization
					}
					return {
						...uo
					}
				})
				setVisibilityModal({
					...visibility,
					isShowed: false
				})
				onChange(result)
			}}
			onCancel={() => {
				setVisibilityModal({
					...visibility,
					isShowed: false
				})
			}}
		/>
	</>
}

const TableVirtualStorePickerField = (props: Props) => {
	const {
		input,
		label,
		itemDisabled,
		meta: {
			error,
			touched
		},
		style,
		className
	} = props

	return (
		<Item
			label={label}
			help={touched && error}
			validateStatus={(error && touched) ? 'error' : undefined}
			style={style}
			className={className}
		>
			<div>
				{getSwitchList(
					input.value,
					input.onChange,
					itemDisabled
				)}
			</div>
		</Item>
	)
}

export default TableVirtualStorePickerField
