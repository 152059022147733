import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	Empty,
	Form,
	Input,
	Popconfirm, Table, Tag
} from 'antd'
import i18next from 'i18next'
import { debounce, get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getFormInitialValues, initialize } from 'redux-form'
import {
	NumberParam, StringParam, useQueryParams, withDefault
} from 'use-query-params'
import { deletePriceOffer, getPriceOffers, PriceOfferTableItem } from '../../reducers/priceOffers/actions'
import { history } from '../../utils/history'
import { RootState } from '../../reducers'
import { FORM, PRICE_OFFER_STATUS, translatePriceOfferStatusColor, translatePriceOfferStatusToSk } from '../../utils/enums'
import { colorHash } from '../storeItems/StoreItems'

const PAGE_SIZE = 30

type Columns = ColumnsType<PriceOfferTableItem>

const PriceOffers = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const dataSource = useSelector((state: RootState) => state.priceOffer.priceOffers)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.PRICE_OFFERS_FILTER)(state))

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: NumberParam,
		page: withDefault(NumberParam, 1),
		orderBy: withDefault(StringParam, 'address'),
		orderDirection: withDefault(StringParam, null)
	})

	useEffect(() => {
		dispatch(getPriceOffers(query.page, PAGE_SIZE, {
			search: query.search,
			orderBy: query.orderBy,
			orderDirection: query.orderDirection
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderBy, query.orderDirection])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.PRICE_OFFERS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const columns: Columns = [
		{
			title: i18next.t('pages:Adresa'),
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			sorter: true,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Status'),
			dataIndex: 'status',
			key: 'status',
			fixed: 'right',
			width: 150,
			render(status, record) {
				return (
					<span>
						<Tag color={translatePriceOfferStatusColor(status)}>{ translatePriceOfferStatusToSk(status) }</Tag>
					</span>
				)
			}
		},
		{
			key: 'operation',
			fixed: 'right',
			width: 50,
			render(status, record) {
				return (
					<Popconfirm
						title={ i18next.t('pages:Skutočne chcete vymazať záznam?') }
						icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
						cancelText={ i18next.t('pages:Zrušiť') }
						okText={ i18next.t('pages:Vymazať') }
						onConfirm={ (e) => {
							e?.stopPropagation()
							dispatch(deletePriceOffer(record.id, () => {
								dispatch(getPriceOffers(1, PAGE_SIZE))
							}))
						} }
						onCancel={ (e) => e?.stopPropagation() }
						okButtonProps={ {
							size: 'small',
							type: 'primary'
						} }
						cancelButtonProps={ {
							size: 'small',
							type: 'ghost'
						} }
					>
						<Button
							icon={ <DeleteOutlined /> }
							type={ 'primary' }
							onClick={ (e) => e.stopPropagation() }
						/>
					</Popconfirm>
				)
			}
		}
	]

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string,
			FilterValue | null>,
		sorter: SorterResult<PriceOfferTableItem> | SorterResult<PriceOfferTableItem>[],
		extra: TableCurrentDataSource<PriceOfferTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			limit: PAGE_SIZE,
			...query,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm }), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	return (
		<div className={ 'page-wrapper' }>
			<div className={ 'flex justify-between' }>
				<Form.Item>
					<Input.Search onChange={ handleOnChange } style={ { width: 300 } } allowClear />
				</Form.Item>
				<Button
					icon={ <PlusCircleOutlined /> }
					href={ t('paths:priceOfferCreate|path') }
					type={ 'primary' }
				>
					{ t('pages:Vytvoriť cenovú ponuku') }
				</Button>
			</div>
			<Table
				className={ 'general-table' }
				columns={ columns }
				dataSource={ dataSource.tableData }
				onChange={ handleTableChange }
				style={ { marginTop: -20 } }
				showSorterTooltip={ false }
				pagination={ {
					pageSize: PAGE_SIZE,
					total: dataSource.pagination?.totalCount,
					current: dataSource.pagination?.page,
					showSizeChanger: false
				} }
				loading={ dataSource.isLoading }
				onRow={ (record) => ({
					onClick: () => history.push(`${t('paths:priceOfferDetail|path')}/${get(record, 'id')}`)
				}) }
				locale={ {
					emptyText: (
						<Empty description={ t('pages:Žiadne dáta') } />
					)
				} }
				size={ 'small' }
			/>
		</div>
	)
}

export default PriceOffers
