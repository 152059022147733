export enum FREQUENCY {
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY',
	SUNDAY = 'SUNDAY',
	MONTH = 'MONTH',
	WEEK = 'WEEK',
	QUARTERYEAR = 'QUARTERYEAR',
	HALFYEAR = 'HALFYEAR',
	YEAR = 'YEAR'
}

export const translateFrequencyToSk = (frequency: string) => {
	switch (frequency) {
		case FREQUENCY.MONDAY: return 'Pondelok'
		case FREQUENCY.TUESDAY: return 'Utorok'
		case FREQUENCY.WEDNESDAY: return 'Streda'
		case FREQUENCY.THURSDAY: return 'Štvrtok'
		case FREQUENCY.FRIDAY: return 'Piatok'
		case FREQUENCY.SATURDAY: return 'Sobota'
		case FREQUENCY.SUNDAY: return 'Nedeľa'
		case FREQUENCY.MONTH: return 'Mesiac'
		case FREQUENCY.WEEK: return 'Týždeň'
		case FREQUENCY.HALFYEAR: return 'Pol-rok'
		case FREQUENCY.QUARTERYEAR: return 'Štvrť rok'
		case FREQUENCY.YEAR: return 'Rok'
		default: return frequency
	}
}

export const translateToTableNameFrequency = (frequency: string) => {
	switch (frequency) {
		case FREQUENCY.MONDAY: return 'monday'
		case FREQUENCY.TUESDAY: return 'tuesday'
		case FREQUENCY.WEDNESDAY: return 'wednesday'
		case FREQUENCY.THURSDAY: return 'thursday'
		case FREQUENCY.FRIDAY: return 'friday'
		case FREQUENCY.SATURDAY: return 'saturday'
		case FREQUENCY.SUNDAY: return 'sunday'
		case FREQUENCY.MONTH: return 'month'
		case FREQUENCY.WEEK: return 'week'
		case FREQUENCY.HALFYEAR: return 'halfYear'
		case FREQUENCY.QUARTERYEAR: return 'quarterYear'
		case FREQUENCY.YEAR: return 'year'
		default: return frequency
	}
}

export const toSkImportantStatus = (frequency: string) => {
	const toSkImportantStatus = (frequency: string) => {
		switch (frequency) {
			case FREQUENCY.MONDAY: return 'orientačná'
			case FREQUENCY.TUESDAY: return 'orientačná'
			case FREQUENCY.WEDNESDAY: return 'orientačná'
			case FREQUENCY.THURSDAY: return 'orientačná'
			case FREQUENCY.FRIDAY: return 'orientačná'
			case FREQUENCY.SATURDAY: return 'orientačná'
			case FREQUENCY.SUNDAY: return 'orientačná'
			case FREQUENCY.MONTH: return 'závezná'
			case FREQUENCY.WEEK: return 'závezná'
			case FREQUENCY.HALFYEAR: return 'závezná'
			case FREQUENCY.QUARTERYEAR: return 'závezná'
			case FREQUENCY.YEAR: return 'závezná'
			default: return frequency
		}
	}

	return `(${toSkImportantStatus(frequency)} frequentácia)`
}

export const translateFrequencyToSkPriceOffer = (frequency: string) => {
	switch (frequency) {
		case FREQUENCY.MONDAY: return 'Pondelok'
		case FREQUENCY.TUESDAY: return 'Utorok'
		case FREQUENCY.WEDNESDAY: return 'Streda'
		case FREQUENCY.THURSDAY: return 'Štvrtok'
		case FREQUENCY.FRIDAY: return 'Piatok'
		case FREQUENCY.SATURDAY: return 'Sobota'
		case FREQUENCY.SUNDAY: return 'Nedeľa'
		case FREQUENCY.MONTH: return 'Mesačné'
		case FREQUENCY.WEEK: return 'Týždenné'
		case FREQUENCY.HALFYEAR: return 'Polročné'
		case FREQUENCY.QUARTERYEAR: return 'Štvrťročné'
		case FREQUENCY.YEAR: return 'Ročné'
		default: return frequency
	}
}

export const isWeekFrequency = (frequency: string) => frequency === FREQUENCY.MONDAY
	|| frequency === FREQUENCY.TUESDAY
	|| frequency === FREQUENCY.WEDNESDAY
	|| frequency === FREQUENCY.THURSDAY
	|| frequency === FREQUENCY.FRIDAY
	|| frequency === FREQUENCY.SATURDAY
	|| frequency === FREQUENCY.SUNDAY
