import React, { useCallback } from 'react'
import { Form, InputNumber, InputNumberProps } from 'antd'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'

const { Item } = Form

type Props = WrappedFieldProps &
	InputNumberProps &
	InputProps &
	FormItemLabelProps & {
	search?: boolean // NOTE: only for search inputs fields -> renders diffrent styles
	customOnBlur?: (value: string | null) => any
	hideHelp?: boolean
}

const InputNumberField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		step,
		precision,
		max,
		min,
		formatter,
		type,
		prefix,
		disabled,
		style,
		search,
		customOnBlur,
		meta: {
			error,
			touched
		},
		hideHelp,
		maxLength,
		suffix
	} = props

	// const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
	// 	const val = e.target.valueAsNumber ? e.target.valueAsNumber : 0
	// 	input.onChange(val)
	// }, [input])
	const onChange = (value: number | string) => {
		input.onChange(value)
	}

	const onBlur = useCallback(async (e) => {
		// NOTE: prevent to have "" empty string as empty value
		const val = e.target.value ? e.target.value : null

		// NOTE: wait until redux-form "BLUR" action is finished
		await input.onBlur(val)

		if (customOnBlur) {
			customOnBlur(val)
		}
	}, [input, customOnBlur])

	const onFocus = useCallback(async (e) => {
		// NOTE: prevent to have "" empty string as empty value
		const val = e.target.value ? e.target.value : null
		if (input.onFocus) {
			input.onFocus(val)
		}
	}, [input])

	return (
		<Item
			label={<span className={'font-semibold'}>{label}</span>}
			required={required}
			style={style}
			help={hideHelp ? undefined : (touched && error)}
			validateStatus={(error && touched) ? 'error' : undefined}
		>
			<InputNumber
				{...input}
				className={cx({ search })}
				onChange={onChange}
				onBlur={onBlur}
				size={size || 'middle'}
				min={min}
				precision={precision}
				step={step}
				max={max}
				prefix={prefix}
				formatter={formatter}
				style={{ width: '100%' }}
				onFocus={onFocus}
				value={input.value}
				placeholder={placeholder}
				disabled={disabled}
				maxLength={maxLength}
			/>
		</Item>
	)
}

export default React.memo(InputNumberField)
