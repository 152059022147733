import {
	LOGOUT_USER, LOAD_PROFILE, SWITCH_PROFILE
} from './userTypes'
// eslint-disable-next-line import/no-cycle
import { IUserProfilePayload, UserActions } from './userActions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	profile: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IUserProfilePayload & ILoadingAndFailure,
	switchUser: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IUserProfilePayload & ILoadingAndFailure
}

export default (state = initState, action: UserActions) => {
	switch (action.type) {
		case LOAD_PROFILE.START:
			return {
				...state,
				profile: {
					...state.profile,
					isLoading: true,
					isFailure: false
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case LOAD_PROFILE.DONE:
			return {
				...state,
				profile: {
					...initState.profile,
					data: action.payload.data
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case LOAD_PROFILE.FAILED:
			return {
				...state,
				profile: {
					...initState.profile,
					isFailure: true
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case SWITCH_PROFILE.START:
			return {
				...state,
				switchUser: {
					...state.switchUser,
					isLoading: true,
					isFailure: false
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case SWITCH_PROFILE.DONE:
			return {
				...state,
				switchUser: {
					...initState.switchUser,
					data: action.payload.data
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case SWITCH_PROFILE.FAILED:
			return {
				...state,
				switchUser: {
					...initState.switchUser,
					isFailure: true
				} as IUserProfilePayload & ILoadingAndFailure
			}
		case LOGOUT_USER.LOGOUT:
			return initState
		default:
			return state
	}
}
