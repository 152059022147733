// TODO: why does the dev build not pick this up automatically?
// https://github.com/microsoft/TypeScript-React-Starter/issues/12#issuecomment-369113072
// eslint-disable-next-line @typescript-eslint/triple-slash-reference

import * as React from 'react'
// eslint-disable-next-line import/no-cycle

type Props = {};

type State = {
	checked: boolean,
};

export class TableToPrint extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { checked: false }
	}

	private canvasEl!: HTMLCanvasElement;

	// eslint-disable-next-line no-return-assign
	private setRef = (ref: HTMLCanvasElement) => this.canvasEl = ref;

	public render() {
		const { children } = this.props

		return (
			<div >
				<style type="text/css" media="print">
				</style>
				<table >
					<thead>
						{children}
					</thead>
					<tbody>
						<tr>
							<td>
								<canvas height="250" id="myCanvas" ref={this.setRef} width="250">
								Your browser does not support the HTML5 canvas tag.
								</canvas>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default TableToPrint
