export enum LOAD_SELECT_JOBS {
	START= 'LOAD_SELECT_JOBS_START',
	DONE= 'LOAD_SELECT_JOBS_DONE',
	FAILED= 'LOAD_SELECT_JOBS_FAILED'
}

export enum LOAD_JOB {
	START= 'LOAD_JOB_START',
	DONE= 'LOAD_JOB_DONE',
	FAILED= 'LOAD_JOB_FAILED'
}

export enum CREATE_JOBS {
	START= 'CREATE_JOBS_START',
	DONE= 'CREATE_JOBS_DONE',
	FAILED= 'CREATE_JOBS_FAILED'
}

export enum UPDATE_JOBS {
	START= 'UPDATE_JOBS_START',
	DONE= 'UPDATE_JOBS_DONE',
	FAILED= 'UPDATE_JOBS_FAILED'
}

export enum REMOVE_JOB {
	START= 'REMOVE_JOB_START',
	DONE= 'REMOVE_JOB_DONE',
	FAILED= 'REMOVE_JOB_FAILED'
}

export enum CHANGE_FORM_JOB_SEND_EMAIL {
	CHANGE= 'CHANGE_FORM_JOB_SEND_EMAIL_CHANGE'
}
