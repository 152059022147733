// TODO: why does the dev build not pick this up automatically?
// https://github.com/microsoft/TypeScript-React-Starter/issues/12#issuecomment-369113072
// eslint-disable-next-line @typescript-eslint/triple-slash-reference

import * as React from 'react'
// eslint-disable-next-line import/no-cycle
import { chunk } from 'lodash'
import QrCode, { QrIdentifier } from './QrCode'

// interface QrRecord {
// 	jobID: number,
// 	jobName: string,
// }

type Props = {
	list: QrIdentifier[],
};

type State = {
	checked: boolean,
};

export class ComponentToPrint extends React.PureComponent<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = { checked: false }
	}

	private canvasEl!: HTMLCanvasElement;

	// eslint-disable-next-line no-return-assign
	private setRef = (ref: HTMLCanvasElement) => this.canvasEl = ref;

	public render() {
		const {
			list
		} = this.props

		return (
			<div >
				<style type="text/css" media="print">
				</style>
				<table >
					<thead>
						{chunk<QrIdentifier>(list, 3).map((data) => (<tr key={`iterator-qr-${data.length}`}>
							{data.map((qrRecord) => (<th key={qrRecord.name}>
								<QrCode
									name={qrRecord.name}
									data={qrRecord.data}
								/>
							</th>))}
						</tr>))}
					</thead>
					<tbody>
						<tr>
							<td>
								<canvas height="250" id="myCanvas" ref={this.setRef} width="250">
			Your browser does not support the HTML5 canvas tag.
								</canvas>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export const FunctionalComponentToPrint = React.forwardRef<ComponentToPrint | null, Props>((props, ref) => <ComponentToPrint ref={ref} list={props.list} />)
