import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
// eslint-disable-next-line no-unused-vars
import { Button, Modal, Select, Tooltip } from 'antd'
import { CloseOutlined, EditFilled, MailOutlined, PlusOutlined } from '@ant-design/icons'
import Styled from 'styled-components'
import i18next from 'i18next'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, initialize, reset } from 'redux-form'
import UserForm from '../../pages/users/forms/UserForm'
import { createUser } from '../../reducers/usersU/actions'
import { FORMS, USER_ROLE } from '../../utils/enums'

export function ShowButtonHover2({
	showLabel,
	customLabelClass,
	label
}) {
	const [style, setStyle] = useState({ display: 'none' })

	return (
		<div
			style={ {
				display: 'flex',
				justifyContent: 'space-between'
			} }
			onMouseEnter={ (e) => {
				setStyle({
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				})
			} }
			onMouseLeave={ (e) => {
				setStyle({ display: 'none' })
			} }
		>
			{ showLabel && <label className={ cx(customLabelClass) }>{ label }</label> }
			<EditFilled style={ style }/>
		</div>
	)
}

const Wrapper = Styled.div`
	margin: 6px;
	padding: 6px;
	backgroundColor: #e6f7ff;
	border: 1px solid #91d5ff;
	display: flex;
`

function tagRender(props, input) {
	const {
		value,
		onClose
	} = props

	let data

	try {
		data = JSON.parse(value)
	} catch (e) {
		data = value
	}
	const { sendEmail } = data

	return (
		<Wrapper style={{ minWidth: '260px', display: 'flex', justifyContent: 'space-between' }}>
			<div>
				<div>
					<b>{ data?.name || '' }</b>
				</div>
				<div>
					{ data?.email || '' }
				</div>
				<div>
					{ data?.phone || ''}
				</div>
			</div>
			<div>
				<Tooltip placement="topLeft" title={ sendEmail ? <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} <b style={{ color: 'green' }}>{ i18next.t('pages:Aktívne') }</b> / { i18next.t('pages:Neaktívne') }</div> : <div>{i18next.t('pages:Odosielať potvrdzujúci email: ')} { i18next.t('pages:Aktívne') } / <b style={{ color: 'red' }}>{ i18next.t('pages:Neaktívne') }</b></div> }>
					<MailOutlined style={ {
						marginRight: 6,
						color: sendEmail ? 'green' : 'red'
					} } onClick={ () => {
						const copyArray = [...input.value]
						const updatedArrayValue = copyArray.map((item) => {
							try {
								let itemObject
								// when loading data from backend, here can get object as value, so this is check if it is value already
								if (item.sendEmail) {
									itemObject = item
								} else {
									itemObject = JSON.parse(item)
								}
								if (itemObject.id === data?.id) {
									const updated = {
										...itemObject,
										sendEmail: !itemObject.sendEmail
									}
									return JSON.stringify(updated)
								}
								return item
							} catch (e) {
								return item
							}
						})
						input.onChange(updatedArrayValue)
					}
					}/>
				</Tooltip>
				<CloseOutlined onClick={ onClose }/>
			</div>
		</Wrapper>
	)
}

const CustomOptionsSelectField = ({
	input,
	label,
	placeholder,
	showLabel,
	customClass,
	customLabelClass,
	customInputClass,
	meta: {
		touched,
		error
	},
	type,
	onSearch,
	disabled,
	onInput,
	onKeyUp,
	suffix,
	required,
	min,
	onBlur,
	options,
	width,
	multiple,
	...rest
}) => {
	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const dispatch = useDispatch()
	const [search, setSearch] = useState('')
	const [isModalVisible, setIsModalVisible] = useState(false)

	const jobForm = useSelector((state) => getFormValues(FORMS.JOB_FORM)(state))

	const initEmptyDetailForm = () => {
		const initValues = {
			role: USER_ROLE.JOB_TRUSTEE
		}

		dispatch(initialize(FORMS.USER_FORM, initValues))
	}

	useEffect(() => {
		if (isModalVisible) {
			initEmptyDetailForm()
		}
	}, [isModalVisible])

	const showModal = () => {
		setIsModalVisible(true)
	}

	const handleOk = () => {
		setIsModalVisible(false)
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const onInputChange = (value) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	const onInputSearch = (value) => {
		onSearch(value)
		setSearch(value)
	}

	const handleCreate = (body) => {
		dispatch(createUser(body, (userID) => {
			setIsModalVisible(false)
			// {"id":10,"name":"Martin Malobický - test","sendEmail":false,"phone":"+421949810793","email":"malobicky.m@gmail.com"}
			const newTrustee = {
				id: userID,
				name: body.name,
				sendEmail: body.sendEmail || false,
				phone: body.phone,
				email: body.email
			}
			onInputChange((jobForm?.trustees || []).concat([JSON.stringify(newTrustee)]))
			dispatch(reset(FORMS.USER_FORM))
		}, (userID) => {
			setIsModalVisible(false)
		}))
	}

	const handleSubmit = (values) => {
		const body = {
			firstName: get(values, 'firstName'),
			lastName: get(values, 'lastName'),
			email: get(values, 'email'),
			phone: get(values, 'phone'),
			role: get(values, 'role')
		}

		handleCreate({
			...body,
			password: get(values, 'password')
		})
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<ShowButtonHover2 showLabel={ showLabel } customLabelClass={ customLabelClass } label={ label }/>

			<div className={ 'input-field' }>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					mode={ 'multiple' }
					disabled={ disabled }
					tagRender={ (data) => tagRender(data, input) }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					style={ { width } }
					onSearch={ onInputSearch }
					notFoundContent={ <Button onClick={ showModal } style={ { padding: 0, width: '100%' } } type="text"><div><PlusOutlined/> { i18next.t('pages:Vytvoriť správcu s mailom') } <b style={ { color: 'black' } }>{ search }</b></div></Button> }
					onChange={ onInputChange }
					{ ...rest }
				>
					{ options }
				</Select>
				<Modal title={ i18next.t('pages:Vytvoriť klienta') } visible={isModalVisible} okText={i18next.t('pages:Vytvoriť')} onOk={handleOk} onCancel={handleCancel} footer={null} >
					<UserForm
						onSubmit={ handleSubmit }
						isCreate={ false }
						onRefreshPage={ () => {} }
						trusteePopup={true}/>
				</Modal>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

CustomOptionsSelectField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.any
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	width: PropTypes.string,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({}))
}

CustomOptionsSelectField.defaultProps = {
	name: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	width: '100%',
	multiple: false,
	options: []
}

export default CustomOptionsSelectField
