import i18next from 'i18next'

export default (values: any) => {
	const errors: any = {}

	if (!values.name) {
		errors.name = i18next.t('state:Toto pole je povinné')
	}

	if (!values.dateOfReplacement) {
		errors.dateOfReplacement = 'Required'
	}

	if (!values.amount || values.amount <= 0) {
		errors.amount = 'Must be greater than zero'
	}

	if (!values.jobID) {
		errors.jobID = 'Required'
	}

	if (!values.storeItemID) {
		errors.storeItemID = 'Required'
	}

	return errors
}
