/* eslint-disable import/no-cycle */
import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { message as antMessage, notification } from 'antd'
import { forEach, get, has, isEmpty, lowerCase } from 'lodash'
import i18next from 'i18next'
import qs from 'qs'
import { getAccessToken, isLoggedInAsync } from './auth'
import { MSG_TYPE, NOTIFICATION_TYPE } from './enums'
import { translateMessageType } from './helper'
// types
import { IErrorMessage } from '../types/interfaces'
import configureStore from './configureStore'
import rootReducer from '../reducers'

export const axios = Axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL || 'https://api.umyjeme.top' || undefined
})

const { store } = configureStore(rootReducer)

export const showNotifications = (messages: IErrorMessage[], typeNotification: NOTIFICATION_TYPE) => {
	if (!isEmpty(messages)) {
		if (typeNotification === NOTIFICATION_TYPE.NOTIFICATION) {
			forEach(messages, (message) => {
				let notif: any
				switch (lowerCase(message.type)) {
					case 'info': notif = notification.info
						break
					case 'warning': notif = notification.warning
						break
					case 'success': notif = notification.success
						break
					case 'error': notif = notification.error
						break
					default:
						notif = notification.info
						break
				}
				notif({
					message: translateMessageType(message.type),
					description: message.message
				})
			})
		} else if (typeNotification === NOTIFICATION_TYPE.MODAL) {
			// TODO: doriesit modal notification
		}
	}
}

export const showErrorNotifications = (error: AxiosError | Error, typeNotification = NOTIFICATION_TYPE.NOTIFICATION) => {
	let messages = get(error, 'response.data.messages')

	if (get(error, 'response.status') === 401) {
		// if (isLoggedIn()) {
		// 	messages = [{
		// 		type: MSG_TYPE.INFO,
		// 		message: i18next.t('loc:Boli ste automaticky odhlásený')
		// 	}]
		// }
		// showNotifications(messages, typeNotification)
		// logOutUser()(store.dispatch, store.getState, undefined)
		// history.push(i18next.t('paths:login|path'))
	} else if (get(error, 'response.status') === 504 || get(error, 'response') === undefined || get(error, 'message') === 'Network Error') {
		messages = [{
			type: MSG_TYPE.ERROR,
			message: i18next.t('loc:Chyba pripojenia k serveru')
		}]
		showNotifications(messages, typeNotification)
	} else {
		// if BE do not send message set general error message
		messages = (isEmpty(messages)) ? [{ type: MSG_TYPE.ERROR, message: i18next.t('loc:Ups niečo sa pokazilo') }] : messages
		showNotifications(messages, typeNotification)
	}
}

interface IHeader {
	[key: string]: string | boolean | undefined
}

interface ICustomConfig extends AxiosRequestConfig {
	messages?: IErrorMessage[]
}

async function buildHeaders(items: any = null) {
	const headers: IHeader = {
		'content-type': 'application/json',
		Accept: 'application/json',
		'Access-Control-Allow-Credentials': true,
		'Cache-Control': 'no-cache, no-store',
		Pragma: 'no-cache',
		Language: 'SK'
	}
	// eslint-disable-next-line no-console
	console.log('await isLoggedInAsync()')
	const x = await isLoggedInAsync()
	// eslint-disable-next-line no-console
	console.log(x)
	if (await isLoggedInAsync()) {
		const bearerToken = getAccessToken()
		headers.Authorization = `Bearer ${bearerToken}`
	}

	return {
		...headers,
		...items || {}
	}
}

// const buildHeaders = () => {
// 	const headers: IHeader = {
// 		'Content-Type': 'application/json',
// 		Accept: 'application/json',
// 		'Access-Control-Allow-Credentials': true,
// 		'Cache-Control': 'no-cache, no-store',
// 		Pragma: 'no-cache',
// 		'X-Version': process.env.REACT_APP_VERSION
// 	}
// 	if (isLoggedIn()) {
// 		headers.Authorization = `Bearer ${getAccessToken()}`
// 	}
//
// 	return headers
// }

/**
 * @param url url endpoint
 * @param params Object object
 * @param customConfig overwrite defaultConfig with custom one
 * @param typeNotification Enum notification type
 * @return Promise response
 * @param showLoading loading status
 *
 */
export const getReq = async (url: string, params?: any, customConfig: ICustomConfig = {}, typeNotification?: NOTIFICATION_TYPE, showLoading: boolean = false) => {
	let hide
	if (showLoading) {
		hide = antMessage.loading('Načitavajú sa dáta...', 0)
	}
	const config: AxiosRequestConfig = {
		paramsSerializer: qs.stringify,
		...customConfig,
		headers: {
			...await buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}

	if (params) {
		config.params = params
	}

	try {
		const res = await axios.get(url, config)
		if (typeNotification) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, typeNotification)
			} else if (has(res, 'data.messages')) {
				showNotifications(get(res, 'data.messages'), typeNotification)
			}
		}
		if (hide) {
			hide()
		}

		return res
	} catch (e) {
		showErrorNotifications(e, typeNotification)
		if (hide) {
			hide()
		}
		return Promise.reject(e)
	}
}

/**
 * @param url url endpoint
 * @param params Object params object
 * @param data Object data object
 * @param customConfig overwrite defaultConfig with custom one
 * @param typeNotification Enum notification type
 * @param showLoading loading indicator
 *
 * Performs put request to url and returns callback with result
 */
export const putReq = async (url: string, params: any, data: any = {}, customConfig: ICustomConfig = {}, typeNotification?: NOTIFICATION_TYPE, showLoading: boolean = false) => {
	let hide
	if (showLoading) {
		hide = antMessage.loading('Operácia sa vykonáva...', 0)
	}
	const config = {
		...customConfig,
		headers: {
			...await buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}

	if (params) {
		config.params = params
	}

	try {
		const res = await axios.put(url, data, config)
		if (typeNotification) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, typeNotification)
			} else if (has(res, 'data.messages')) {
				showNotifications(get(res, 'data.messages'), typeNotification)
			}
		}

		if (hide) {
			hide()
		}
		return res
	} catch (e) {
		if (hide) {
			hide()
		}
		showErrorNotifications(e, typeNotification)

		return Promise.reject(e)
	}
}

/**
 * @param url url endpoint
 * @param params Object params object
 * @param data Object data object
 * @param customConfig overwrite defaultConfig with custom one
 * @param typeNotification Enum notification type
 * @param showLoading Boolean show loading
 * @return Promise response
 * Performs post request to url and returns callback with result
 */
export const postReq = async (url: string, params: any = undefined, data: any = {}, customConfig: ICustomConfig = {}, typeNotification?: NOTIFICATION_TYPE, showLoading: boolean = false) => {
	let hide
	if (showLoading) {
		hide = antMessage.loading('Operácia sa vykonáva...', 0)
	}
	const config = {
		...customConfig,
		headers: {
			...await buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}

	if (params) {
		config.params = params
	}

	try {
		const res = await axios.post(url, data, config)
		if (typeNotification) {
			if (customConfig && customConfig.messages) {
				showNotifications(customConfig.messages, typeNotification)
			} else if (has(res, 'data.messages')) {
				showNotifications(get(res, 'data.messages'), typeNotification)
			}
		}

		if (hide) {
			hide()
		}
		return res
	} catch (e) {
		if (hide) {
			hide()
		}
		showErrorNotifications(e, typeNotification)

		return Promise.reject(e)
	}
}

/**
 * @param url url endpoint
 * @param params Object params object
 * @param data Object data object
 * @param customConfig overwrite defaultConfig with custom one
 * @param typeNotification Enum notification type
 * @param showLoading loading status
 *
 * Performs put request to url and returns callback with result
 */
export const patchReq = async (url: string, params: any, data: any = {}, customConfig: ICustomConfig = {}, typeNotification?: NOTIFICATION_TYPE, showLoading: boolean = false) => {
	let hide
	if (showLoading) {
		hide = antMessage.loading('Operácia sa vykonáva...', 0)
	}
	const config = {
		...customConfig,
		headers: {
			...await buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}

	if (params) {
		config.params = params
	}
	try {
		const res = await axios.patch(url, data, config)
		if (typeNotification && customConfig && customConfig.messages) {
			showNotifications(customConfig.messages, typeNotification)
		} else if (typeNotification && has(res, 'data.messages')) {
			showNotifications(get(res, 'data.messages'), typeNotification)
		}
		if (hide) {
			hide()
		}
		return res
	} catch (e) {
		if (hide) {
			hide()
		}
		showErrorNotifications(e, typeNotification)
		return Promise.reject(e)
	}
}

/**
 * @param url url endpoint
 * @param params Object params object
 * @param customConfig overwrite defaultConfig with custom one
 * @param typeNotification Enum notification type
 * @param showLoading Boolean show loading
 *
 * Performs delete request to url and returns with result
 */
export const deleteReq = async (url: string, params: any = {}, customConfig: ICustomConfig = {}, typeNotification?: NOTIFICATION_TYPE, showLoading: boolean = false) => {
	let hide
	if (showLoading) {
		hide = antMessage.loading('Operácia sa vykonáva...', 0)
	}

	const config = {
		...customConfig,
		headers: {
			...await buildHeaders(),
			...get(customConfig, 'headers', {})
		}
	}

	if (params) {
		config.params = params
	}

	try {
		const res = await axios.delete(url, config)

		if (typeNotification && customConfig && customConfig.messages) {
			showNotifications(customConfig.messages, typeNotification)
		} else if (typeNotification && has(res, 'data.messages')) {
			showNotifications(get(res, 'data.messages'), typeNotification)
		}

		if (hide) {
			hide()
		}

		return res
	} catch (e) {
		if (hide) {
			hide()
		}

		showErrorNotifications(e, typeNotification)
		return Promise.reject(e)
	}
}
