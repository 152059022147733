import { LOAD_DASHBOARD } from './types'
// eslint-disable-next-line import/no-cycle
import { IDashboardActions, IDashboardPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IDashboardPayload & ILoadingAndFailure
}

export default (state = initState, action: IDashboardActions) => {
	switch (action.type) {
		case LOAD_DASHBOARD.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IDashboardPayload & ILoadingAndFailure
			}
		case LOAD_DASHBOARD.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IDashboardPayload & ILoadingAndFailure
			}
		case LOAD_DASHBOARD.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IDashboardPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
