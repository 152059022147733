import {
	LOAD_JOB, LOAD_SELECT_JOBS
} from './types'
// eslint-disable-next-line import/no-cycle
import { IJobActions, IJobPayload, IJobsPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	jobs: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IJobsPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IJobPayload & ILoadingAndFailure
}

export default (state = initState, action: IJobActions) => {
	switch (action.type) {
		case LOAD_SELECT_JOBS.START:
			return {
				...state,
				jobs: {
					...state.jobs,
					isFailure: false,
					isLoading: true
				} as IJobsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_JOBS.DONE:
			return {
				...state,
				jobs: {
					...initState.jobs,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IJobsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_JOBS.FAILED:
			return {
				...state,
				jobs: {
					...initState.jobs,
					isFailure: true
				} as IJobsPayload & ILoadingAndFailure
			}
		// case CREATE_JOBS.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_JOBS.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_JOBS.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case UPDATE_JOBS.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_JOBS.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_JOBS.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		case LOAD_JOB.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IJobPayload & ILoadingAndFailure
			}
		case LOAD_JOB.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IJobPayload & ILoadingAndFailure
			}
		case LOAD_JOB.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IJobPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
