import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { RootState } from '../reducers'
import { getStoreItems } from '../reducers/storeItems/actions'
import { createSlug } from '../utils/helper'

const { Item } = Form
const { Option } = Select

type Props = {
	onValueChange: (value: ISelectedStoreItemOption) => void,
	storeItemValue: number | undefined
}

export interface ISelectedStoreItemOption {
	value: number,
	label: string
}

const SelectStoreItemField = (props: Props) => {
	const { onValueChange, storeItemValue } = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [storeItemSelected, setStoreItemSelected] = useState<number | undefined>(undefined)

	function onChange(value: number, option: any) {
		onValueChange({
			value: option?.value,
			label: option?.children
		})
		setStoreItemSelected(option?.value)
	}

	useEffect(() => {
		dispatch(getStoreItems(
			1,
			10000,
			{}
		))
	}, [dispatch])

	useEffect(() => {
		setStoreItemSelected(storeItemValue)
	}, [storeItemValue])

	const storeItems = useSelector((state: RootState) => state.storeItems.storeItems.tableData)
		.map((storeItem: any) => ({
			value: storeItem.id,
			label: storeItem.name
		}))

	return (
		<Item>
			<Select
				onBlur={() => {}}
				className={'dropDown'}
				size={'middle'}
				onChange={onChange}
				suffixIcon={<ChevronIcon width={20} height={20} />}
				showSearch
				allowClear={true}
				value={storeItemSelected}
				style={{ width: 200 }}
				placeholder="Vyber položku skladu"
				optionFilterProp="children"
				filterOption={(input: any, option: any) => createSlug(option?.children).indexOf(createSlug(input)) >= 0}
			>
				{map(storeItems, (option) => (
					<Option
						key={option.value}
						value={option.value}
					>
						{option.label}
					</Option>
				))}
			</Select>
		</Item>
	)
}

export default React.memo(SelectStoreItemField)
