import React, { useState } from 'react'
import cx from 'classnames'
import { filter, map } from 'lodash'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, Empty, Modal, Popconfirm, Radio, Row, Select, Tooltip } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import i18next from 'i18next'
import { CheckCircleTwoTone, QuestionCircleOutlined, StopTwoTone, SwapOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from '../../components/DeleteIcon'
import JobsModal from '../../components/JobsModal'
import { createFinish } from '../../reducers/finishes/actions'
import { ITaskDetail } from '../../reducers/tasks/actions'
import { FREQUENCIES, FrequencyI } from '../../utils/enums'

const { Option } = Select

export interface MoveOptionI {
	name: string,
	key: string,
}

export enum MoveOptions {
	move = 'move',
	copy = 'copy'
}

export const MOVE_OPTIONS: MoveOptionI[] = [
	{
		name: 'Presunúť',
		key: MoveOptions.move.toLowerCase()
	},
	{
		name: 'Skopírovať',
		key: MoveOptions.copy.toLowerCase()
	}
]

type Props = WrappedFieldProps & {
	label?: string,
	showLabel?: string,
	customClass?: string,
	customLabelClass?: string,
	options: Task[],
	frequency: string,
	jobId: number,
	userId: number,
	extraData: boolean,
	meta: Meta,
	setModalText: any
	handleRefresh: Function
	onMoveTasks: (fromFrequency: FrequencyI, toFrequency: FrequencyI, moveOption: MoveOptionI) => void
}

type Meta = {
	touched: boolean,
	error: string
}

type TaskFrequency = {
	id?: string | null,
	frequency: string,
	order: number,
	task: Task
}

type Task = {
	id: number,
	name: string
}

const grid = 8

const frequencyToIsoDay = (day: string): number => {
	const normalizeDay = day?.toLowerCase()
	switch (normalizeDay) {
		case 'monday':
			return 1
		case 'tuesday':
			return 2
		case 'wednesday':
			return 3
		case 'thursday':
			return 4
		case 'friday':
			return 5
		case 'saturday':
			return 6
		case 'sunday':
			return 7
		default:
			return -1
	}
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const getListStyle = (isDraggingOver: any) => ({
	background: isDraggingOver ? 'lightgrey' : 'lightgrey',
	padding: grid,
	width: '100%'
})

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	// change background colour if dragging
	background: isDragging ? '#f7931d' : 'grey',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',

	// styles we need to apply on draggables
	...draggableStyle
})

const DragCustom = (
	{ onChange, value, onDelete }: DragCustomProps
) => {
	const onDragEnd = (result: any) => {
		// dropped outside the list
		if (!result.destination) {
			return
		}

		const items = reorder(
			value,
			result.source.index,
			result.destination.index
		)
		onChange(items)
	}

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{map(value, (item, index) => (
							<Draggable key={item.task.id} draggableId={`${item.task.id}`} index={index}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style
										)}
									>
										<Row justify={'space-between'}>
											<div style={{ color: 'white' }}>{item.task.name}</div>
										</Row>
										<DeleteIcon onClick={() => onDelete(item)}/>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

const DragAndDropField = ({
	input,
	label,
	customClass,
	options,
	frequency,
	meta: { touched, error },
	extraData,
	jobId,
	userId,
	handleRefresh,
	onMoveTasks
}: Props) => {
	const dispatch = useDispatch()

	const { t } = useTranslation()

	const [task, setModalTask] = useState<ITaskDetail | null>(null)
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [selectedAction, setSelectedAction] = useState(MOVE_OPTIONS[0])
	const [selectedFrequency, setSelectedFrequency] = useState<string | undefined>(undefined)

	const optionsSetKey = map(input.value, (x) => (
		x?.task?.id
	))
	const optionsSet = map(filter(options, (v) => !optionsSetKey.includes(v.id)), (x) => (
		<Option key={x.id} value={x.id}>{x.name}</Option>
	))

	function onChangeSelect(value: any, options: any) {
		const newValue: TaskFrequency = {
			id: null,
			frequency,
			order: input.value.length,
			task: {
				id: options.value,
				name: options.children
			}
		}

		input.onChange(
			typeof input.value === 'string' && input.value.length === 0
				? [newValue] : input.value.concat(newValue)
		)
	}

	const onDelete = (value: any) => {
		input.onChange(input.value.filter((field: { task: { id: any; }; }) => field.task.id !== value.id))
	}

	const openModal = (value: any) => {
		setModalTask(value.task)
	}

	const closeModal = () => {
		setModalTask(null)
	}

	const closeSwapModal = () => {
		setIsModalVisible(false)
	}

	const handleOk = () => {
		onDelete(task)
		closeModal()
	}

	const handleSwapModalOk = () => {
		setIsModalVisible(false)
		onMoveTasks(
			FREQUENCIES.find((option: FrequencyI) => option.key.toLowerCase() === frequency.toLowerCase()) as FrequencyI,
			FREQUENCIES.find((option: FrequencyI) => option.key.toLowerCase() === selectedFrequency?.toLowerCase()) as FrequencyI,
			selectedAction
		)
	}

	const handleActionChange = (e: any) => {
		setSelectedAction(MOVE_OPTIONS.find((option: MoveOptionI) => option.key === e.target.value) as MoveOptionI)
	}

	const handleFrequencyChange = (value: string) => {
		setSelectedFrequency(value)
	}

	const dropList = input.value.length === 0
		? <Empty style={{ margin: '20px' }} description='Žiadne úlohy'/>
		: <DragCustom
			onChange={(value: TaskFrequency[]) => {
				input.onChange(value.map((value: TaskFrequency, index: Number) => ({
					...value,
					order: index
				})))
			}}
			onDelete={openModal}
			value={input.value}
		/>

	const userNotDefined = userId === undefined || userId === null
	return (
		<div className={cx('input-wrapper', { error: touched && error }, customClass)}>
			<div className={'input-field'}>
				<Row justify={'space-between'}>
					<h3>{label}</h3>
					<span>
						{
							input.value.length !== 0 && <Tooltip placement="bottomLeft" title={<span>{i18next.t('pages:Presunúť úlohy do inej frekvencie')}</span>}>
								<Button
									icon={<SwapOutlined twoToneColor="#f64747"/>}
									type={'link'}
									onClick={(e) => {
										setIsModalVisible(true)
										e.stopPropagation()
									}
									}
								/>
							</Tooltip>
						}
						{/* eslint-disable-next-line no-nested-ternary */}
						{jobId && frequency !== 'XXX' ? !extraData ? <Popconfirm
							title={i18next.t('pages:Označiť tento deň za ukončený?')}
							icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
							cancelText={i18next.t('pages:Zrušiť')}
							disabled={userNotDefined}
							okText={i18next.t('pages:Pridať ukončenie')}
							onConfirm={(e) => {
								e?.stopPropagation()
								dispatch(createFinish({
									jobId,
									userId,
									executionForFrequency: frequency,
									executionForDayDate: dayjs().day((frequencyToIsoDay(frequency))).toISOString()
								}, () => {
									console.log('ukoncene uspesne')
									handleRefresh()
								}, () => {
									console.log('ukoncene neuspesne')
								}))
							}}
							onCancel={(e) => e?.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Tooltip placement="bottomLeft" title={<span>{i18next.t('pages:Ukončiť úlohu')}</span>}>
								<Button
									icon={<StopTwoTone twoToneColor="#f64747"/>}
									type={'link'}
									disabled={userNotDefined}
									onClick={(e) => e.stopPropagation()}
								/>
							</Tooltip>
						</Popconfirm> : <Tooltip placement="top" title={<span>{i18next.t('pages:Úloha je ukončená')}</span>}>
							<Button
								icon={<CheckCircleTwoTone twoToneColor="#52c41a"/>}
								type={'link'}
								onClick={(e) => {
									// here show dialog
									e.stopPropagation()
								}}
							/>
						</Tooltip> : <div/>
						}
					</span>
				</Row>
				<Select
					showSearch
					style={{ width: '100%' }}
					placeholder="Vyber osobu"
					optionFilterProp="children"
					onChange={onChangeSelect}
					value='select value'
					// onFocus={onFocus}
					// onBlur={onBlur}
					// onSearch={onSearch}
					filterOption={(input, option) => option?.children?.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
					}
				>
					{optionsSet}
				</Select>
				{dropList}
				<JobsModal task={task} handleCancel={closeModal} loading={false} handleOk={handleOk}/>
			</div>
			<div className={'tooltip-error'}>
				{touched ? error : ''}
			</div>
			<Modal
				title={t('pages:Presunúť/skopírovať úlohy do inej frekvencie')}
				visible={isModalVisible}
				onOk={handleSwapModalOk}
				onCancel={closeSwapModal}
				okText={t('pages:Presunúť')}
				cancelText={t('pages:Zrušiť')}
			>
				<Radio.Group onChange={handleActionChange} value={selectedAction.key}>
					{MOVE_OPTIONS.map((option: MoveOptionI) => (
						<Radio key={option.key} value={option.key}>
							{option.name}
						</Radio>
					))}
				</Radio.Group>
				<Select
					style={{ width: '100%', marginTop: '20px' }}
					placeholder={t('pages:Vyber frekvenciu')}
					onChange={handleFrequencyChange}
					value={selectedFrequency}
				>
					{FREQUENCIES.filter((option: FrequencyI) => option.key?.toLowerCase() !== frequency?.toLowerCase()).map((option) => (
						<Select.Option key={option.key} value={option.key}>
							{option.name}
						</Select.Option>
					))}
				</Select>
			</Modal>
		</div>
	)
}

type DragCustomProps = {
	onChange: (value: TaskFrequency[]) => void,
	value: TaskFrequency[],
	onDelete: (value: any) => void
}

export default DragAndDropField
