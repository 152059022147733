import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button, Empty, Form, Input, Popconfirm, Select, Table
} from 'antd'
import { CloseCircleOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { debounce, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getFormInitialValues } from 'redux-form'
import {
	NumberParam, StringParam, useQueryParam, useQueryParams, withDefault
} from 'use-query-params'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ArrayParam } from 'serialize-query-params/lib/params'
import { deleteFinish, FinishTableItem, getFinishes } from '../../reducers/finishes/actions'
import { FREQUENCY, translateFrequencyToSk } from '../../utils/translate'
import { RootState } from '../../reducers'
import { FORM } from '../../utils/enums'
import SelectUserField from '../../components/SelectUserField'
import { createSlug } from '../../utils/helper'
// eslint-disable-next-line import/no-extraneous-dependencies

const PAGE_SIZE = 20
const { Option } = Select

type Columns = ColumnsType<FinishTableItem>

const Finishes = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const finishes = useSelector((state:RootState) => state.finishes.finishes)
	const isLoadingFinishes = finishes.isLoading

	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.FINISHES_FILTER)(state))

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		frequencies: withDefault(ArrayParam, []),
		limit: NumberParam,
		userID: withDefault(NumberParam, undefined),
		page: withDefault(NumberParam, 1),
		orderBy: withDefault(StringParam, 'realDateOfExecution'),
		orderDirection: withDefault(StringParam, 'desc')
	})

	const resetFilter = () => {
		setQuery({
			...query,
			search: '',
			frequencies: [],
			limit: PAGE_SIZE,
			userID: undefined,
			page: 1,
			orderBy: 'realDateOfExecution',
			orderDirection: 'desc'
		})
	}

	useEffect(() => {
		dispatch(getFinishes({
			limit: PAGE_SIZE,
			...query
		}))
	}, [dispatch, query])

	const columns: Columns = [
		{
			title: i18next.t('pages:Zákazka'),
			dataIndex: 'address',
			key: 'address',
			render: (address) => address || '-'
		},
		{
			title: i18next.t('pages:Vykonal'),
			dataIndex: 'userName',
			key: 'user',
			render: (userName) => userName
		},
		{
			title: i18next.t('pages:Čas ukončenia'),
			dataIndex: 'realDateOfExecution',
			key: 'realDateOfExecution',
			sorter: true,
			ellipsis: true,
			render(realDateOfExecution) {
				return <span>{realDateOfExecution}</span>
			}
		},
		{
			title: i18next.t('pages:Frekvencia'),
			dataIndex: 'frequency',
			key: 'frequency',
			sorter: true,
			ellipsis: true,
			render: (frequency) => frequency
		},
		{
			title: i18next.t('pages:Hodnotenie'),
			dataIndex: 'rating',
			key: 'rating',
			ellipsis: true,
			render: (value) => (value || '-')
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render(text, record) {
				return (
					<Popconfirm
						title={i18next.t('pages:Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
						cancelText={i18next.t('pages:Zrušiť')}
						okText={i18next.t('pages:Vymazať')}
						onConfirm={(e) => {
							e?.stopPropagation()
							dispatch(deleteFinish(record.id, () => {
								dispatch(getFinishes({
									limit: PAGE_SIZE,
									page: 1
								}))
							}))
						}}
						onCancel={(e) => e?.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary'
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button
							icon={<DeleteOutlined/>}
							type={'primary'}
							onClick={(e) => e.stopPropagation()}
						/>
					</Popconfirm>
				)
			}
		}
	]

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string,
			FilterValue | null>,
		sorter: SorterResult<FinishTableItem> | SorterResult<FinishTableItem>[],
		extra: TableCurrentDataSource<FinishTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			...query,
			...order,
			page: pagination.current
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm }), 300), [query])

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	}

	const frequencies = [FREQUENCY.MONDAY, FREQUENCY.TUESDAY, FREQUENCY.WEDNESDAY, FREQUENCY.THURSDAY, FREQUENCY.FRIDAY, FREQUENCY.MONTH, FREQUENCY.YEAR]

	const [textTemplateParam, setTextTemplateParam] = useQueryParam('textTemplate', NumberParam)

	const onChangeTextTemplate = useCallback((templateID) => {
		setTextTemplateParam(templateID)
	}, [setTextTemplateParam])

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'}>
				<Form.Item>
					<Input.Search onChange={handleOnChange} style={{ width: 300 }} allowClear/>
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Select
						style={{ width: 200, marginRight: 20 }}
						onChange={(value: any) => setQuery({ ...query, frequencies: value, page: 1 })}
						placeholder={t('pages:Frekvencia')}
						value={query.frequencies as any}
						mode={'multiple'}
						filterOption={(input:any, option:any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{map(frequencies, (frequency, index) => (
							<Option
								key={`frequency-filter-${index}`}
								value={frequency.toString()}
							>
								{translateFrequencyToSk(frequency)}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<SelectUserField
						onValueChange={(data) => {
							setQuery({
								...query,
								userID: data.value
							})
						}}
						userValue={query.userID}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						className={'mb-4'}
						style={{ marginLeft: 10 }}
						icon={<CloseCircleOutlined/>}
						onClick={resetFilter}
					>
						{t('pages:Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={finishes.tableData}
				onChange={handleTableChange}
				showSorterTooltip={false}
				rowKey={'id'}
				style={{ marginTop: -20 }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: finishes.pagination?.totalCount,
					current: finishes.pagination?.page,
					showSizeChanger: false
				}}
				loading={isLoadingFinishes}
				onRow={(record) => ({
					// onClick: () => history.push(`${ t('paths:user.path') }/${ get(record, 'id') }`)
				})}
				locale={{
					emptyText: (
						<Empty description={t('pages:Žiadne dáta')}/>
					)
				}}
				size={'small'}
			/>
		</div>
	)
}

Finishes.propTypes = {}

export default Finishes
