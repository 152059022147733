import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'
import { CREATE_USER, DELETE_USER, LOAD_DETAIL_USER, LOAD_USER_JOBS, LOAD_USERS, UPDATE_USER } from './types'
import { IResetStore } from '../generalTypes'
import { IResponsePagination } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IUserForm } from '../../pages/users/forms/UserForm'
import { formatDateWithTime } from '../../utils/helper'
import { FREQUENCY } from '../../utils/translate'
// eslint-disable-next-line import/no-cycle
import { StoreItemOriginalItem, StoreItemTableItem } from '../storeItems/actions'

export type IUserActions = IResetStore
	| IGetUsersActions
	| IGetUserAction
	| IGetUserJobsAction

// users
interface IGetUsersActions {
	type: LOAD_USERS
	payload: IUsersPayload
}

type UsersOriginalItem = {
	email: string
	id: number
	firstName: string
	lastName: string
	key: number
	role: string
	jobs: UsersOriginalItemJob
}

type UsersOriginalItemJob = {
	address: string
	id: number
}

export type UsersTableItem = {
	key: number
	id: number
	firstName: string
	lastName: string
	lastLoginAtDate: string
	lastLoginAtHours: string
	email: string
	role: string
}

export interface IUsersPayload {
	tableData: UsersTableItem[]
	originalData: UsersOriginalItem[]
	pagination: IResponsePagination | null
}

export const getUsers = (
	page: number = 1,
	limit: number|null = 20,
	queryParams = {}
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	let payload = {} as IUsersPayload
	try {
		dispatch({ type: LOAD_USERS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/users', normalizeQueryParams)

		const originalData = get(data, 'users', [])
		const tableData: any[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			firstName: column.firstName,
			lastName: column.lastName,
			email: column.email,
			lastLoginAtDate: formatDateWithTime(column.email)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_USERS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_USERS.FAILED })
		Promise.reject(error)
		return error
	}
}

// user
interface IGetUserAction {
	type: LOAD_DETAIL_USER
	payload: IUserPayload
}

export interface IUserPayload {
	data: IUser | null
}

export interface IUserPermission {
	organizationID: number
	name: string
	userIsActiveInOrganization: boolean
	adminCanAddUserToOrganization: boolean
}

export interface IUser {
	email: string
	firstName: string
	lastName: string
	phone: string
	role: string
	id: number
	userStoreId: number | null
	jobs: IUserJob[]
	ratings: IUserRating[]
	storeItems: {
		context: {
			count: number,
			jobAddress: string,
			jobID: number,
		},
		storeItems: StoreItemOriginalItem[]
	},
	tableData: StoreItemTableItem[] | null,
	isFailure: boolean,
	userPermissions: IUserPermission[] | null
}

export interface IUserJobs {
	jobs: IUserJob[],
	allMoneyEarned: number,
	allTimeWorkedThisMonth: string,
	allTimeWorkedThisMonthFormatted: string,
}

export interface IUserJob {
	id: number
	address: string
	sumDuration: number
	priceForCleaning: number
	priceForCleaningSum: number
	finishes: IUserJobFinish[]
	executedSomebodyElse: string
	hasJobTasks: boolean
	role: string
}

export interface IUserJobFinish {
	id: number
	date: string
	frequency: FREQUENCY
	qrScans: {
		duration: string | null,
		from: string | null,
		to: string | null
	}
}

export interface IUserRating {
	comment: string
	dateOfExecution: string
	createdAt: number
	updatedAt: number
	finish: IUserFinish
	id: number
	rating: number
	taskRatings: IUserTaskRating[]
}

export interface IUserFinish {
	id: number
}

export interface IUserTaskRating {
	id: number
	name: string
	reaction: 'DISLIKE' | 'LIKE'
}

export const getUser = (
	id: number,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUserPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_DETAIL_USER.START
	})
	let payload = {} as IUserPayload
	try {
		const { data } = await getReq(`/api/admin/users/${id}`)

		const originalData: StoreItemOriginalItem[] = get(data, 'storeItems.storeItems', [])
		const tableData: StoreItemTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			name: column.name,
			idealStockPercentage: column.idealStockPercentage,
			isInVirtualStore: column.isInVirtualStore,
			idealStockCount: column.idealStockCount,
			isCountOkeyCompareToIdealStock: column.isCountOkeyCompareToIdealStock,
			organizationName: column.organizationName,
			count: column.count,
			createdAt: formatDateWithTime(column.createdAt),
			updatedAt: formatDateWithTime(column.updatedAt)
		}))

		payload = {
			data: {
				...data,
				tableData
			}
		}

		dispatch({
			type: LOAD_DETAIL_USER.DONE,
			payload
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_DETAIL_USER.FAILED
		})
		onFailure(error)
		return error
	}
}

// user
interface IGetUserJobsAction {
	type: LOAD_USER_JOBS
	payload: IUserJobsPayload
}
export interface IUserJobsPayload {
	data: IUserJobs | null
}

export interface IUserJobsParams {
	month: string | undefined | null
}

export const getUserJob = (
	id: number,
	params: IUserJobsParams = {
		month: null
	},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_USER_JOBS.START
	})
	let payload = {} as IUserPayload

	const normalizeQueryParams = mapValues(params, (query) => query || undefined)

	try {
		const { data } = await getReq(`/api/admin/users/${id}/jobs`, normalizeQueryParams)

		payload = {
			data
		}

		dispatch({
			type: LOAD_USER_JOBS.DONE,
			payload
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_USER_JOBS.FAILED
		})
		onFailure(error)
		return error
	}
}

export interface IUsersJobsTimeWorkedPayload {
	data: IUsersJobsTimeWorked | null
}

export interface IUsersJobsTimeWorked {
	allTimeWorkedThisMonth: string,
	allTimeWorkedThisMonthFormatted: string,
}

export const getUsersJobsTimeWorked = (
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: IUsersJobsTimeWorkedPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	let payload = {} as IUsersJobsTimeWorkedPayload

	try {
		const { data } = await getReq('/api/admin/users/jobs/work-time')

		payload = {
			data
		}

		onSuccess(payload)
		return data
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const deleteUser = (
	id: number,
	onSuccess: () => void,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: DELETE_USER.START
	})
	try {
		await deleteReq(`/api/admin/users/${id}`)

		dispatch({ type: DELETE_USER.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_USER.FAILED })
		onFailure(error)
		return false
	}
}

export interface IUserUpdateParams extends IUserForm{
}

export const updateUser = (
	id: number,
	values: IUserForm,
	onSuccess: () => void = (() => {}),
	onFailure: (error: any) => void = (() => {})
) : ThunkResult<Promise<Boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_USER.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		await patchReq(`/api/admin/users/${id}`, null, normalizeQueryParams)

		dispatch({ type: UPDATE_USER.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_USER.FAILED })
		onFailure(error)
		return false
	}
}

export interface IUserCreateParams extends IUserForm{
}

export const createUser = (
	values: IUserForm,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_USER.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/users', null, normalizeQueryParams)

		dispatch({ type: CREATE_USER.DONE })
		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_USER.FAILED })
		onFailure(error)
		return error
	}
}

export const addAccessForOrganization = (
	userId: number,
	organizationId: number,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_USER.START
	})
	try {
		const { data } = await postReq(`/api/admin/users/${userId}/add-access/organization/${organizationId}`, null, null)
		onSuccess()
		return data
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const removeAccessForOrganization = (
	userId: number,
	organizationId: number,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_USER.START
	})
	try {
		const { data } = await postReq(`/api/admin/users/${userId}/remove-access/organization/${organizationId}`, null, null)
		onSuccess()
		return data
	} catch (error) {
		onFailure(error)
		return error
	}
}
