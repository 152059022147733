import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button, List, Select, Space, Spin, Tooltip
} from 'antd'
import PropTypes from 'prop-types'
import { ClearOutlined, SaveOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import i18next from 'i18next'
import { Collection, getCollections, updateCollections } from '../reducers/collections/actions'
import { getJobs, JobTableItem } from '../reducers/jobs/actions'
import ConfirmationModal from './ConfirmationModal'
import { DeleteIcon } from './DeleteIcon'
import { RootState } from '../reducers'

const { Option } = Select
const PAGE_SIZE = 10000

const ListItem = styled.div`
	display: flex;
    justify-content: space-between;
    width: 100%;
`

const ActionWrapper = styled.div`
	margin-left: auto;
`

interface JobGroupsI {
	jobID: number,
	cleaningSetLocatedOnJob: boolean | null,
	address: string,
}

const filterObjectArray = (arr: any, filterArr: any) => arr.filter((objFromA: any) => !filterArr?.find((objFromB: any) => objFromA.id === objFromB.jobID))

// @ts-ignore
const Collections = ({ id }) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()
	const [temporaryJobGroups, setJobGroups] = useState<JobGroupsI[]>([])
	const [job, setModalJob] = useState(false)

	const detail = useSelector((state:RootState) => state.userGroup.detail)
	const { isLoading } = detail
	const dataSource = useSelector((state:RootState) => state.jobs.jobs.tableData)

	const initCollectionFrom = (data: Collection) => {
		const jobGroups: JobGroupsI[] = data.jobGroups.map((data) => ({
			jobID: data.jobID,
			address: data.address,
			cleaningSetLocatedOnJob: data.cleaningSetLocatedOnJob
		}))
		setJobGroups(jobGroups)
	}

	useEffect(() => {
		dispatch(getCollections(id, (data) => {
			// eslint-disable-next-line no-use-before-define
			initCollectionFrom(data)
		}))
	}, [])

	useEffect(() => {
		dispatch(getJobs({
			limit: PAGE_SIZE,
			page: 1,
			queryParams: {}
		}))
	}, [])

	const onChangeSelect = (value: any) => {
		const newValue: JobTableItem|undefined = dataSource && dataSource.find((data: any) => data.id === value)
		if (newValue) {
			const newValueJobGroup: JobGroupsI = {
				jobID: newValue.id,
				address: newValue.address,
				cleaningSetLocatedOnJob: false
			}
			setJobGroups([...temporaryJobGroups, newValueJobGroup])
		}
	}

	const saveJobGroups = () => {
		const data = { jobGroups: temporaryJobGroups.map((job) => ({ jobID: job.jobID })) }
		// @ts-ignore
		dispatch(updateCollections(id, data, (data) => console.log(`success: ${data}`), (data) => console.log(`failure: ${data}`)))
	}

	const onDelete = (value: any) => {
		// @ts-ignore
		const newValues = temporaryJobGroups && temporaryJobGroups.filter((data) => data.jobID !== value.jobID)
		// @ts-ignore
		setJobGroups(newValues)
	}
	const openModal = (value: any) => {
		setModalJob(value)
	}

	const closeModal = () => {
		setModalJob(false)
	}

	const handleOk = () => {
		onDelete(job)
		closeModal()
	}

	const selectOptions = dataSource && filterObjectArray(dataSource, temporaryJobGroups).map((job: any) => <Option key={job.key} value={ job.id }>{job.address}</Option>)

	// @ts-ignore
	const Header = () => (
		<div className={'mt-6'}>
			<p style={ { fontWeight: 'bold' } }>{ t('pages:Komplety') }</p>
			<Select
				showSearch
				style={ { width: '100%' } }
				className={'mt-6'}
				placeholder={ 'Search to Select' }
				value={ 'select value' }
				optionFilterProp={ 'children' }
				filterOption={ (input:any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
				}
				filterSort={ (optionA: any, optionB: any) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()) }
				onChange={ onChangeSelect }
			>
				{selectOptions}
			</Select>
		</div>
	)

	return (
		<>
			<Spin spinning={ isLoading }>
				<List
					header={ <Header /> }
					footer={ (
						<Button
							icon={ <SaveOutlined /> }
							onClick={ saveJobGroups }
							type={ 'primary' }
							style={ { margin: '20px' } }
						>
							{ t('pages:Uložiť') }
						</Button>
					) }
					bordered
					dataSource={ temporaryJobGroups }
					renderItem={ (item: any) => (
						<List.Item>
							<ListItem>
								<span>{item.address}</span>
								<ActionWrapper>
									<Space direction={ 'horizontal' }>
										{item.cleaningSetLocatedOnJob && <Tooltip key={item.key} placement="topLeft" title={ <div>{ i18next.t('pages:Komplet na upratovanie sa nachádza na tejto pobočke') }</div> }><ClearOutlined style={ { color: '#d4b106' } } /></Tooltip>}
										<DeleteIcon onClick={ () => openModal(item) } />
									</Space>
								</ActionWrapper>
							</ListItem>
						</List.Item>
					) }
				/>
			</Spin>
			<ConfirmationModal item={ job } handleCancel={ closeModal } loading={ false } handleOk={ handleOk } />
		</>
	)
}

Collections.propTypes = {
	id: PropTypes.number
}

export default Collections
