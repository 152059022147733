import React from 'react'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

interface Props {
	text: string | null,
	handleCancel: (e: React.MouseEvent<HTMLElement>) => void,
	handleOk: (e: React.MouseEvent<HTMLElement>) => void,
}

const PriceDeleteModal = ({
	text, handleCancel, handleOk
} : Props) => {
	const { t } = useTranslation('pages')
	return (
		<>
			<Modal
				visible={!!text}
				title={t('pages:Naozaj zmazať')}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						{t('pages:Späť')}
					</Button>,
					<Button key="submit" type="primary" onClick={handleOk}>
						{t('pages:Zmazať')}
					</Button>
				]}
			>
				<div dangerouslySetInnerHTML={{ __html: text || '' }} />
			</Modal>
		</>
	)
}

export default PriceDeleteModal
