import {
	LOAD_FINISHES_PER_MONTH
} from './types'
// eslint-disable-next-line import/namespace,import/no-cycle
import { IStatisticsActions, IFinishesPerMonthPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	statistics: {
		finishesPerMonth: [],
		isLoading: false,
		isFailure: false
	} as IFinishesPerMonthPayload & ILoadingAndFailure
}

export default (state = initState, action: IStatisticsActions) => {
	switch (action.type) {
		case LOAD_FINISHES_PER_MONTH.START:
			return {
				...state,
				statistics: {
					...state.statistics,
					isFailure: false,
					isLoading: true
				} as IFinishesPerMonthPayload & ILoadingAndFailure
			}
		case LOAD_FINISHES_PER_MONTH.DONE:
			return {
				...state,
				statistics: {
					...initState.statistics,
					finishesPerMonth: action.payload.finishesPerMonth
				} as IFinishesPerMonthPayload & ILoadingAndFailure
			}
		case LOAD_FINISHES_PER_MONTH.FAILED:
			return {
				...state,
				statistics: {
					...initState.statistics,
					isFailure: true
				} as IFinishesPerMonthPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
