export enum TEMPLATES {
	TEMPLATES_LOAD_START = 'TEMPLATES_LOAD_START',
	TEMPLATES_LOAD_DONE = 'TEMPLATES_LOAD_DONE',
	TEMPLATES_LOAD_FAIL = 'TEMPLATES_LOAD_FAIL'
}

export enum TEMPLATE {
	TEMPLATE_LOAD_START = 'TEMPLATE_LOAD_START',
	TEMPLATE_LOAD_DONE = 'TEMPLATE_LOAD_DONE',
	TEMPLATE_LOAD_FAIL = 'TEMPLATE_LOAD_FAIL'
}

export enum DUPLICATE_TEMPLATE {
	TEMPLATE_LOAD_START = 'TEMPLATE_LOAD_START',
	TEMPLATE_LOAD_DONE = 'TEMPLATE_LOAD_DONE',
	TEMPLATE_LOAD_FAIL = 'TEMPLATE_LOAD_FAIL'
}
