import React from 'react'
import { Button, Row } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { LeftOutlined } from '@ant-design/icons'
import ProfileDropdown from './ProfileDropdown'
import { history } from '../utils/history'

interface Props {
	routeKey: string,
	computedMatch: {
		path: string
	}
}

const HeaderContent = ({ routeKey, computedMatch }: Props) => {
	const { t } = useTranslation('paths')

	const path = `paths:${routeKey}|path`
	const justify = computedMatch.path === t(path) || computedMatch.path === '/' ? 'end' : 'space-between'

	const display: any = {}
	if ((computedMatch.path === t(path) || computedMatch.path === '/')) {
		display.display = 'none'
	}

	return (
		<Row justify={ justify } align={ 'middle' } className={'mr-6'}>
			<Button
				icon={ <LeftOutlined /> }
				type={ 'default' }
				style={ { margin: '20px', ...display } }
				onClick={
					history.goBack
				}
			>
				{ t('pages:Späť') }
			</Button>
			<ProfileDropdown />
		</Row>
	)
}

HeaderContent.propTypes = {
	routeKey: PropTypes.string,
	computedMatch: PropTypes.shape({
		path: PropTypes.string
	})
}

HeaderContent.defaultProps = {
	routeKey: null,
	computedMatch: {
		path: null
	}
}

export default HeaderContent
