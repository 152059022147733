import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { LOAD_TRUSTEES } from './types'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload } from '../usersU/actions'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'
import { IResponsePagination } from '../../types/interfaces'

export type ITrusteeActions = IResetStore
	| IGetTrusteeActions

// users
interface IGetTrusteeActions {
	type: LOAD_TRUSTEES
	payload: ITrusteePayload
}

export interface ITrusteePayload {
	tableData: TrusteeTableItem[]
	originalData: TrusteeOriginalItem[]
	pagination: IResponsePagination | null
}

type TrusteeOriginalItem = {
	id: number
	name: string
	email: string
	phone: number
}

type TrusteeTableItem = {
	key: string
	id: number
	name: string
	email: string
	phone: number
}

export interface TrusteeParams {
}

export const getTrustees = (params: TrusteeParams): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	let payload = {} as ITrusteePayload
	try {
		dispatch({ type: LOAD_TRUSTEES.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/users/trustee', normalizeQueryParams)

		const originalData = get(data, 'trustees', [])
		const tableData: any[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			name: column.name,
			email: column.email,
			phone: column.phone
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_TRUSTEES.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_TRUSTEES.FAILED })
		Promise.reject(error)
		return error
	}
}
