import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get } from 'lodash'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import DocumentForm from './forms/DocumentForm'
import {
	createDocuments, getDocument, IDocumentForm
} from '../../reducers/documents/actions'
import { IComputedMatch } from '../../types/interfaces'

type Props = {
    computedMatch: IComputedMatch<{ id: number }>
}

const DetailDocument = (props: Props) => {
	const { computedMatch } = props
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state) => get(state, 'documents.detail'))

	const isLoading = get(detail, 'isLoading')

	const { id } = computedMatch.params

	const initEmptyDetailForm = () => {
		const initValues = {
			id: null,
			name: ''
		} as IDocumentForm

		dispatch(initialize(FORMS.DOCUMENT_FORM, initValues))
	}

	const initDetailForm = (data: IDocumentForm) => {
		dispatch(initialize(FORMS.DOCUMENT_FORM, data))
	}

	useEffect(() => {
		if (id) {
			dispatch(getDocument(id, (data) => {
				// eslint-disable-next-line no-use-before-define
				initDetailForm(data)
			}))
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [computedMatch])

	const handleCreate = (body: IDocumentForm) => {
		dispatch(createDocuments(body, (documentId) => history.replace(`${t('paths:documents|path')}`)))
	}

	const handleSubmit = (values:IDocumentForm) => {
		const body: IDocumentForm = {
			id: null,
			name: values.name,
			fileId: values.fileId
		}

		handleCreate({
			...body
		})
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<DocumentForm
					onSubmit={ handleSubmit }
					isCreate={ !id }
				/>
			</Spin>
		</div>
	)
}

export default DetailDocument
