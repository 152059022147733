import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	debounce, get, isEmpty, map
} from 'lodash'
import {
	Button, Empty, Form, Input, Popconfirm, Rate, Select, Table, Tooltip
} from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import {
	CloseCircleOutlined,
	CommentOutlined, DeleteOutlined, QuestionCircleOutlined
} from '@ant-design/icons'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getFormInitialValues, initialize } from 'redux-form'
import {
	NumberParam, StringParam, useQueryParams, NumericArrayParam, withDefault
} from 'use-query-params'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
	getRatings, postInvisibleRating, RatingTableItem, RatingTaskDetail
// eslint-disable-next-line import/namespace
} from '../../reducers/ratings/actions'
import { rateColor } from '../../components/Ratings'
import { RootState } from '../../reducers'
import { FORM } from '../../utils/enums'
import SelectUsersField from '../../components/SelectUsersField'

const PAGE_SIZE = 20

const ACTION_COLOR = '#FFD700'
const ERROR_COLOR = '#F92316'

type Columns = ColumnsType<RatingTableItem>

const renderReaction = (task: RatingTaskDetail) => {
	const isLikeColor = task?.reaction === 'LIKE'
	if (isLikeColor) {
		return <Tooltip placement="top" title={ <span>{ task?.name }</span> }>
			<ThumbUpAltIcon style={ {
				color: ACTION_COLOR,
				marginLeft: 'auto'
			} }/>
		</Tooltip>
	}
	return <Tooltip placement="top" title={ <span>{ task?.name }</span> }><ThumbDownAltIcon style={ {
		color: ERROR_COLOR,
		marginLeft: 'auto'
	} }/></Tooltip>
}

const Ratings = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const dataSource = useSelector((state: RootState) => state.rating.ratings)
	const isLoadingList = dataSource.isLoading

	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.TASKS_FILTER)(state))

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: NumberParam,
		users: withDefault(NumericArrayParam, []),
		page: withDefault(NumberParam, 1),
		orderBy: withDefault(StringParam, 'createdAt'),
		orderDirection: withDefault(StringParam, 'desc')
	})

	const resetFilter = () => {
		setQuery({
			...query,
			search: null,
			limit: PAGE_SIZE,
			users: [],
			page: 1,
			orderBy: 'createdAt',
			orderDirection: 'desc'
		})
	}

	useEffect(() => {
		dispatch(getRatings({
			limit: PAGE_SIZE,
			...query
		}))
	}, [dispatch, query])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.TASKS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const columns: Columns = [
		{
			title: i18next.t('pages:Zamestnanec'),
			dataIndex: 'userName',
			key: 'userName',
			ellipsis: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Hodnotenie'),
			sorter: true,
			dataIndex: 'rating',
			key: 'rating',
			ellipsis: true,
			width: 150,
			render(value: number) {
				return <Rate style={ {
					color: rateColor(value),
					margin: '0px 12px',
					minWidth: 132
				} } value={ value } disabled/> || '-'
			}
		},
		{
			title: i18next.t('pages:Reakcie'),
			sorter: false,
			dataIndex: 'taskRatings',
			key: 'taskRatings',
			ellipsis: true,
			width: 130,
			render(value: RatingTaskDetail[]) {
				return (<div style={{ whiteSpace: 'break-spaces' }}>
					{map(value, (data) => renderReaction(data))}
				</div>)
			}
		},
		{
			title: i18next.t('pages:Komentár'),
			sorter: false,
			dataIndex: 'comment',
			key: 'comment',
			ellipsis: true,
			width: 80,
			render(value: string) {
				return (value?.length > 0 ? <Tooltip placement="top" title={ <span>{ value }</span> }>
					<Button
						icon={ <CommentOutlined twoToneColor="#52c41a"/> }
						type={ 'link' }
					/>
				</Tooltip> : <div/>)
			}
		},
		{
			title: i18next.t('pages:Zákazka'),
			dataIndex: 'jobName',
			key: 'jobName',
			ellipsis: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Dátum Realizácie'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: 210,
			sorter: true,
			render(createdAt) {
				return <span>{createdAt}</span>
			}
		},		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render(text, record) {
				return (
					<Popconfirm
						title={i18next.t('pages:Skutočne chcete skryť hodnotenie?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
						cancelText={i18next.t('pages:Zrušiť')}
						okText={i18next.t('pages:Vymazať')}
						onConfirm={(e) => {
							e?.stopPropagation()
							dispatch(postInvisibleRating(record.id, () => {
								dispatch(getRatings({
									...query
								}))
							}))
						}}
						onCancel={(e) => e?.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary'
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button
							icon={<DeleteOutlined/>}
							type={'primary'}
							onClick={(e) => e.stopPropagation()}
						/>
					</Popconfirm>
				)
			}
		}
	]

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string,
			FilterValue | null>,
		sorter: SorterResult<RatingTableItem> | SorterResult<RatingTableItem>[],
		extra: TableCurrentDataSource<RatingTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			limit: PAGE_SIZE,
			...query,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({
		...query,
		search: searchTerm
	}), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	return (
		<div className={ 'page-wrapper' }>
			<div className={ 'flex justify-between' }>
				<Form.Item>
					<Input.Search onChange={ handleOnChange } style={ { width: 300 } } allowClear/>
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<SelectUsersField
						onValueChange={(data) => {
							setQuery({
								...query,
								users: data.value.map((item) => (item.value))
							})
						}}
						userValues={query.users}
					/>
				</Form.Item>

				<Form.Item>
					<Button
						className={'mb-4'}
						style={{ marginLeft: 10 }}
						icon={<CloseCircleOutlined/>}
						onClick={resetFilter}
					>
						{t('pages:Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Table
				className={ 'general-table' }
				columns={ columns }
				dataSource={ dataSource.tableData }
				onChange={ handleTableChange }
				style={ { marginTop: 0 } }
				showSorterTooltip={ false }
				pagination={ {
					pageSize: PAGE_SIZE,
					total: dataSource.pagination?.totalCount,
					current: dataSource.pagination?.page,
					showSizeChanger: false
				} }
				loading={ isLoadingList }
				onRow={ (record) => ({
					// onClick: () => history.push(`${ t('paths:jobDetail.path') }/${ get(record, 'id') }`)
				}) }
				locale={ {
					emptyText: (
						<Empty description={ t('pages:Žiadne hodnotenia') }/>
					)
				} }
				size={ 'small' }
			/>
		</div>
	)
}

export default Ratings
