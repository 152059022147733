import React, { useEffect } from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Popconfirm, Row, Select, Tag } from 'antd'
import { ArrowRightOutlined, DeleteOutlined, FilePdfOutlined, PlusCircleOutlined, QuestionCircleOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import i18next from 'i18next'
import { FORMS, PRICE_OFFER_STATUS, translatePriceOfferStatusColor, translatePriceOfferStatusToSk } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validatePriceOfferForm'
import { deletePriceOffer, getPriceOfferHeaderOptions, IFirmHeader, IPriceOfferForm } from '../../../reducers/priceOffers/actions'
import InputField from '../../../atoms/InputField'
import SwitchField from '../../../atoms/SwitchField'
import DateField from '../../../atoms/DateField'
import SelectField from '../../../atoms/SelectField'
import { RootState } from '../../../reducers'
import { getTasks } from '../../../reducers/tasks/actions'
import { FREQUENCY } from '../../../utils/translate'
import InputNumberField from '../../../atoms/InputNumberField'
import ConfirmationModal from './FrequencyPickerModal'
import FrequencyJobsField from './FrequencyJobsField'
import DragAndDropSelectedTextField from './DragAndDropSelectedTextsField'
import StyledLink from '../../../components/NavLink'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import EmailPickerModal from './EmailPickerModal'
import { history } from '../../../utils/history'

const { Option } = Select

type ComponentProps = {
	isCreate: boolean,
	id: number | null,
	sendPriceOffer: (id: number, additionalEmails: string[]) => void
	showPdfPriceOffer: (id: number) => void
	transferOfferToJob: (id: number) => void
	onRemove: (id: number) => void,
}

enum PlaceWithWaterOption {
	ROOM_FOR_CLEANING_SERVICE = 'Miestnosť pre upratovačku',
	HYDRANT = 'Hydrant',
}
enum PlaceToSpillWaterOption {
	ROOM_FOR_CLEANING_SERVICE = 'Miestnosť pre upratovačku',
	OUTSIDE = 'Vonku',
}

type Props = InjectedFormProps<IPriceOfferForm, ComponentProps> & ComponentProps

export const getPriceOfferTag = (status: PRICE_OFFER_STATUS) => <Tag
	color={translatePriceOfferStatusColor(status)}
>
	{translatePriceOfferStatusToSk(status)}
</Tag>

const PriceOfferForm = (props: Props) => {
	const dispatch = useDispatch()
	const {
		handleSubmit,
		invalid,
		pristine,
		isCreate,
		id,
		transferOfferToJob,
		sendPriceOffer,
		showPdfPriceOffer,
		onRemove
	} = props

	const priceOfferStatusSelected: PRICE_OFFER_STATUS | null = (useSelector((state: RootState) => getFormValues(FORMS.PRICE_OFFER_FORM)(state)) as any)?.status
	const priceForm = (useSelector((state: RootState) => getFormValues(FORMS.PRICE_OFFER_FORM)(state)) as any)

	const detail = useSelector((state: RootState) => state.priceOffer.detail)

	const [selectedFrequencies, setSelectedFrequencies] = React.useState<FREQUENCY[]>([])
	const [additionalPriceOfferEmails, setAdditionalPriceOfferEmails] = React.useState<string[]>([])

	const [isModalVisible, setIsModalVisible] = React.useState(false)

	const [isModalEmailPickerVisible, setModalEmailPickerVisible] = React.useState(false)

	const [headerOptions, setHeaderOptions] = React.useState<IFirmHeader[]>([])

	const priceFormFrequenciesWithTasks = () => {
		const freqencies = []
		if (priceForm?.mondayTasks?.length > 0) { freqencies.push(FREQUENCY.MONDAY) }
		if (priceForm?.tuesdayTasks?.length > 0) { freqencies.push(FREQUENCY.TUESDAY) }
		if (priceForm?.wednesdayTasks?.length > 0) { freqencies.push(FREQUENCY.WEDNESDAY) }
		if (priceForm?.thursdayTasks?.length > 0) { freqencies.push(FREQUENCY.THURSDAY) }
		if (priceForm?.fridayTasks?.length > 0) { freqencies.push(FREQUENCY.FRIDAY) }
		if (priceForm?.saturdayTasks?.length > 0) { freqencies.push(FREQUENCY.SATURDAY) }
		if (priceForm?.sundayTasks?.length > 0) { freqencies.push(FREQUENCY.SUNDAY) }
		if (priceForm?.monthTasks?.length > 0) { freqencies.push(FREQUENCY.MONTH) }
		if (priceForm?.quarterYearTasks?.length > 0) { freqencies.push(FREQUENCY.QUARTERYEAR) }
		if (priceForm?.halfYearTasks?.length > 0) { freqencies.push(FREQUENCY.HALFYEAR) }
		if (priceForm?.yearTasks?.length > 0) { freqencies.push(FREQUENCY.YEAR) }
		return freqencies
	}

	const { t } = useTranslation('components')

	useEffect(() => {
		dispatch(getTasks(
			1,
			10000
		))
	}, [])

	useEffect(() => {
		dispatch(getPriceOfferHeaderOptions(
			(data) => {
				setHeaderOptions(data.headers)
			}
		))
	}, [])

	useEffect(() => {
		if (selectedFrequencies.length === 0) {
			setSelectedFrequencies(Array.from(new Set(priceFormFrequenciesWithTasks())))
		}
	}, [priceForm])

	const priceOfferStatus = [
		{
			label: getPriceOfferTag(PRICE_OFFER_STATUS.NOT_ACCEPTED),
			value: PRICE_OFFER_STATUS.NOT_ACCEPTED
		},
		{
			label: getPriceOfferTag(PRICE_OFFER_STATUS.DECLINED),
			value: PRICE_OFFER_STATUS.DECLINED
		},
		{
			label: getPriceOfferTag(PRICE_OFFER_STATUS.FINISHED),
			value: PRICE_OFFER_STATUS.FINISHED,
			disabled: priceOfferStatusSelected !== PRICE_OFFER_STATUS.FINISHED // finished is not allowed to be set manually
		}
	]

	const placeWithWaterOptions = [
		{
			label: PlaceWithWaterOption.ROOM_FOR_CLEANING_SERVICE,
			value: PlaceWithWaterOption.ROOM_FOR_CLEANING_SERVICE
		},
		{
			label: PlaceWithWaterOption.HYDRANT,
			value: PlaceWithWaterOption.HYDRANT
		}
	]

	const placesToSpillWaterOptions = [
		{
			label: PlaceToSpillWaterOption.ROOM_FOR_CLEANING_SERVICE,
			value: PlaceToSpillWaterOption.ROOM_FOR_CLEANING_SERVICE
		},
		{
			label: PlaceToSpillWaterOption.OUTSIDE,
			value: PlaceToSpillWaterOption.OUTSIDE
		}
	]

	const priceOfferOptions = (headerOptions)?.map((option: IFirmHeader) => ({
		label: option.firmName,
		value: option.id
	})) || []

	// @ts-ignore
	return (
		<>
			<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
				<Row justify={'end'} gutter={16}>
					<Col span={18} className={'grid'}>
						<div className={'flex direction-col justify-start sidebar-content'}>
							<Field
								name={'status'}
								component={SelectField}
								options={priceOfferStatus}
								label={t('components:Status')}
							/>
							{(priceOfferStatusSelected !== PRICE_OFFER_STATUS.FINISHED && id != null) && <div className={'mt-2 mb-2 flex direction-col justify-center'}>
								<Button
									icon={<SendOutlined />}
									onClick={() => { setModalEmailPickerVisible(true) }}
									disabled={!(pristine || invalid) || detail?.data?.emailOfTrustee === null}
									type={'primary'}
									style={{ margin: '0px' }}
								>
									{detail?.data?.emailOfTrustee
										? <span>{t('pages:Odoslať cenovú ponuku (presunúť do stavu "Ukončená")')} + <b>{`na  ${detail?.data?.emailOfTrustee}`}</b></span>
										: <span>{t('pages:Odoslať cenovú ponuku (presunúť do stavu "Ukončená")')}</span>}
								</Button>
							</div>
							}
							{(priceOfferStatusSelected === PRICE_OFFER_STATUS.FINISHED && id != null) && <div className={'mt-2 mb-2 flex direction-col justify-center'}>
								<Button
									icon={<SendOutlined />}
									onClick={() => { setModalEmailPickerVisible(true) }}
									disabled={!(pristine || invalid)}
									type={'primary'}
									style={{ margin: '0px' }}
								>
									{`${t('pages:Odoslať opäť cenovú ponuku na email')}`} <b>{`  ${detail?.data?.emailOfTrustee}`}</b>
								</Button>
							</div>
							}
							{(id != null) && <div className={'mt-2 mb-2 flex direction-col justify-center'}>
								<Button
									icon={<FilePdfOutlined />}
									onClick={() => {
										if (id !== null) {
											showPdfPriceOffer(id)
										}
									}}
									disabled={!(pristine || invalid)}
									type={'primary'}
									style={{ margin: '0px' }}
								>
									{t('pages:Zobraziť ukážku PDF pred odoslaním cenovej ponuky')}
								</Button>
							</div>
							}
						</div>
					</Col>
					<Col span={6} className={'grid'}>
						<div className={'flex direction-col justify-between sidebar-content'}>
							<div>
								{!isCreate && (
									<Field
										name={'updatedAt'}
										component={TextField}
										disabled
										label={t('components:Dátum poslednej úpravy')}
									/>
								)}

								<div className={'flex direction-col justify-center'}>
									<Button
										icon={<SaveOutlined />}
										onClick={handleSubmit}
										disabled={pristine || invalid}
										type={'primary'}
										style={{ margin: '0px' }}
									>
										{t('pages:Uložiť')}
									</Button>
								</div>
								<div className={'flex direction-col justify-center'}>
									{
										id && <Popconfirm
											title={i18next.t('pages:Skutočne chcete vymazať záznam?')}
											icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
											cancelText={i18next.t('pages:Zrušiť')}
											okText={i18next.t('pages:Vymazať')}
											onConfirm={(e) => {
												e?.stopPropagation()
												if (id !== null) {
													dispatch(deletePriceOffer(id, () => {
														history.push(t('paths:priceOffers|path'))
													}))
												}
											}}
											onCancel={(e) => e?.stopPropagation()}
											okButtonProps={{
												size: 'small',
												type: 'primary'
											}}
											cancelButtonProps={{
												size: 'small',
												type: 'ghost'
											}}
										>
											<Button
												icon={<DeleteOutlined />}
												style={{ marginLeft: '0px', marginTop: '8px' }}
												type={'primary'}
												onClick={(e) => e.stopPropagation()}
											>
												{t('components:Odstrániť cenovú ponuku')}
											</Button>
										</Popconfirm>
									}

								</div>
							</div>
							<div className={'flex direction-col justify-center'}>
								{priceForm?.jobId == null
									? <Button
										icon={<ArrowRightOutlined />}
										onClick={
											() => {
												if (id !== null) {
													transferOfferToJob(id)
												}
											}
										}
										disabled={ id == null }
										type={'primary'}
										style={{ margin: '0px' }}
									>
										{t('pages:Previesť na Zákazku')}
									</Button> : <StyledLink to={`${i18next.t('paths:jobDetail|path')}/${priceForm?.jobId}`}
									>{ i18next.t('components:Detail zákazky') } <b>{priceForm?.address}</b></StyledLink>
								}
							</div>
						</div>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={18} className={'mt-4'}>
						<Col span={24} className={'grid'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Cenová ponuka')}</h1>
								<Field
									name={ 'priceOfferFirmHeaderID' }
									component={ CustomOptionsSelectField }
									label={t('loc:Hlavička firmy')}
									placeholder={t('loc:Vyber hlavičku firmy')}
									options={ map(priceOfferOptions, (option) => (
										<Option
											key={ option.value }
											value={ option.value }
										>
											{ option.label }
										</Option>
									)) }
									size={ 'large' }
									allowClear
									showSearch
									filterOption={ (input: any, option: any) => option.children.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0 }
								/>
							</div>
							<div className={'flex direction-col justify-start main-content mt-4'}>
								<h1>{t('pages:Charakteristiky objektu')}</h1>
								<Field
									component={InputField}
									label={t('components:Ulica')}
									placeholder={t('components:Ulica')}
									name={'address'}
									size={'large'}
									type={'text'}
									required
								/>
								<Field
									component={InputField}
									label={t('components:Názov bytového družstva')}
									placeholder={t('components:Názov bytového družstva')}
									name={'nameOfHousingAssociation'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={InputField}
									label={t('components:Počet poschodí')}
									placeholder={t('components:Počet poschodí')}
									name={'floors'}
									size={'large'}
									type={'number'}
								/>
								<Field
									component={InputField}
									label={t('components:Počet bytov')}
									placeholder={t('components:Počet bytov')}
									name={'flats'}
									size={'large'}
									type={'number'}
								/>
							</div>
						</Col>
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Klient')}</h1>
								<Field
									component={InputField}
									label={t('components:Meno klienta')}
									placeholder={t('components:Meno klienta')}
									name={'nameOfTrustee'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={InputField}
									label={t('components:Telefónne číslo')}
									placeholder={t('components:Telefónne číslo')}
									name={'phoneOfTrustee'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={InputField}
									label={t('components:Email na klienta')}
									placeholder={t('components:Email na klienta')}
									name={'emailOfTrustee'}
									size={'large'}
									type={'text'}
								/>
							</div>
						</Col>
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Iná firma')}</h1>
								<Field
									component={InputField}
									label={t('components:Aká firma upratovala doteraz')}
									placeholder={t('components:Aká firma upratovala doteraz')}
									name={'nameOfFirmWhichCleanedUpToNow'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={InputField}
									label={t('components:Akú ste mali cenu doteraz ? (eur) ')}
									placeholder={t('components:Akú ste mali cenu doteraz ? (eur) ')}
									name={'pricePaidForCleaningUpToNow'}
									size={'large'}
									type={'number'}
								/>
							</div>
						</Col>
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Charekteristiky zákazky')}</h1>
								<Field
									component={SwitchField}
									label={t('components:Miestnosť pre upratovačky')}
									placeholder={t('components:Miestnosť pre upratovačky')}
									name={'isThereRoomForCleaningService'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={SelectField}
									label={t('components:Miestnosť kde je voda')}
									placeholder={t('components:Miestnosť kde je voda')}
									name={'placeWithWater'}
									options={placeWithWaterOptions}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={SelectField}
									label={t('components:Vodu možno vylievať')}
									placeholder={t('components:Vodu možno vylievať')}
									name={'whereCanWaterBePoured'}
									size={'large'}
									type={'text'}
									options={placesToSpillWaterOptions}
								/>
							</div>
						</Col>
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Kontaktná firma')}</h1>
								<Field
									component={DateField}
									label={t('components:Kedy sa ozvať')}
									placeholder={t('components:Kedy sa ozvať')}
									name={'whenToCall'}
									size={'large'}
									type={'text'}
								/>
								<Field
									component={DateField}
									label={t('components:Kedy bude schôdza')}
									placeholder={t('components:Kedy bude schôdza')}
									name={'dateOfHouseAssociationMeeting'}
									size={'large'}
									type={'text'}
								/>
							</div>
						</Col>
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<h1>{t('pages:Naša ponuka')}</h1>
								<Field
									placeholder={t('components:Cena za upratovanie (eur)')}
									name={'price'}
									component={InputNumberField}
									label={t('components:Cena za jedno upratovanie (€)')}
									size={'large'}
									type={'number'}
								/>
								<Field
									placeholder={t('components:Uznesenie je súčasťou ponuky')}
									name={'isUznesenieIsPartOfPriceOffer'}
									component={SwitchField}
									label={t('components:Uznesenie je súčasťou ponuky')}
									size={'large'}
									type={'text'}
								/>
								<Field
									placeholder={t('components:Doplňujúce texty')}
									name={'additionalTexts'}
									component={DragAndDropSelectedTextField}
									label={t('components:Doplňujúce texty')}
									size={'large'}
									type={'text'}
								/>
							</div>
						</Col>
					</Col>
					<Col span={6}>
						{map(selectedFrequencies, (frequency) => (
							<FrequencyJobsField
								title={t('components:Zazmlúvnené práce')}
								frequency={frequency}
							/>
						))}
						<Col span={24} className={'grid mt-4'}>
							<div className={'flex direction-col justify-start main-content'}>
								<Row justify={'center'}>
									<Col >
										<h3 className="text-center">{t('components:Pridať frequenciu')}</h3>
										<h5 className="text-center">{t('components:napr. (mesačná, štvrťročná, polročná, ročná)')}</h5>
										<div className={'w-full flex justify-center items-center'}>
											<Button
												type="primary"
												shape="circle"
												size={'large'}
												onClick={() => setIsModalVisible(true)}
											>
												<PlusCircleOutlined />
											</Button>
										</div>
									</Col>
								</Row>
							</div>
						</Col>
					</Col>
				</Row>
			</Form>
			<ConfirmationModal
				filterUsedFrequency={selectedFrequencies}
				visible={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				handleOk={(frequency) => {
					setSelectedFrequencies(Array.from(new Set([...selectedFrequencies, frequency])))
					setIsModalVisible(false)
				}}
			/>
			<EmailPickerModal
				initEmail={detail.data?.emailOfTrustee ? [detail.data?.emailOfTrustee] : []}
				visible={isModalEmailPickerVisible}
				onCancel={() => setModalEmailPickerVisible(false)}
				handleOk={(emails) => {
					if (id !== null) { sendPriceOffer(id, emails) }
					setModalEmailPickerVisible(false)
				}}
			/>
		</>
	)
}

const form = reduxForm<IPriceOfferForm, ComponentProps>({
	form: FORMS.PRICE_OFFER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(PriceOfferForm)

export default form
