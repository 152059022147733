/* eslint-disable import/no-cycle */
import { persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storageSession from 'redux-persist/es/storage/session'
import localStorage from 'redux-persist/es/storage'
import { reducer as formReducer } from 'redux-form'
import { ThunkAction } from 'redux-thunk'

// reducers
import usersReducer from './users/userReducer'
import authReducer from './authorization/authReducer'
import formDocsReducer from './formDocs/formDocsReducer'
import templatesReducer from './templates/templateReducer'
import formTemplateReducer from './formTemplate/formTemplateReducer'

// new reducers
import userReducer from './user/userReducers'
import ratingReducer from './ratings/reducers'
import priceOfferReducer from './priceOffers/reducers'
import dashboardReducer from './dashboard/reducers'
import userUReducer from './usersU/reducers'
import jobsReducer from './jobs/reducers'
import storesReducer from './stores/reducers'
import storeItemOrdersReducer from './storeItemOrders/reducers'
import userGroupReducer from './collections/reducers'
import usersTrusteeReducers from './userTrustees/reducers'
import tasksReducers from './tasks/reducers'
import settingsReducers from './settings/reducers'
import finishesReducers from './finishes/reducers'
import storeItemsReducers from './storeItems/reducers'
import storeItemsReplacementsReducers from './storeItemReplacements/reducers'
import statisticsReducers from './statistics/reducers'
import documentsReducers from './documents/reducers'
import documentCategoriesReducers from './documentCategories/reducers'

export const REDUCER_KEYS = {
	FORMS: 'FORMS',
	FORM_DOCS: 'FORM_DOCS',
	TEMPLATES: 'TEMPLATES',
	LOGIN: 'LOGIN',
	FORM_TEMPLATE: 'FORM_TEMPLATE',
	// new reducer keys
	USER_GROUP: 'USER_GROUP',
	USER: 'USER',
	USERS: 'USERS',
	RATING: 'RATING',
	JOBS: 'JOBS',
	STORES: 'STORES',
	STORE_ITEM_ORDERS: 'STORE_ITEM_ORDERS',
	TRUSTEES: 'TRUSTEES',
	TASKS: 'TASKS',
	SETTINGS: 'SETTINGS',
	DASHBOARD: 'DASHBOARD'
}

const rootReducer = combineReducers({
	auth: persistReducer({
		key: REDUCER_KEYS.LOGIN,
		storage: localStorage
	}, authReducer),
	formDoc: persistReducer({
		key: REDUCER_KEYS.FORM_DOCS,
		storage: storageSession
	}, formDocsReducer),
	template: persistReducer({
		key: REDUCER_KEYS.TEMPLATES,
		storage: storageSession
	}, templatesReducer),
	user: persistReducer({
		key: REDUCER_KEYS.FORM_DOCS,
		storage: storageSession
	}, usersReducer),
	form: persistReducer({
		key: REDUCER_KEYS.FORMS,
		storage: storageSession
	}, formReducer),
	formTemplate: persistReducer({
		key: REDUCER_KEYS.FORM_TEMPLATE,
		storage: storageSession
	}, formTemplateReducer),
	// new
	profile: persistReducer({
		key: REDUCER_KEYS.USER,
		storage: storageSession
	}, userReducer),
	rating: persistReducer({
		key: REDUCER_KEYS.RATING,
		storage: storageSession
	}, ratingReducer),
	dashboard: persistReducer({
		key: REDUCER_KEYS.DASHBOARD,
		storage: storageSession
	}, dashboardReducer),
	users: persistReducer({
		key: REDUCER_KEYS.USERS,
		storage: storageSession
	}, userUReducer),
	jobs: persistReducer({
		key: REDUCER_KEYS.JOBS,
		storage: storageSession
	}, jobsReducer),
	stores: persistReducer({
		key: REDUCER_KEYS.STORES,
		storage: storageSession
	}, storesReducer),
	storeItemOrders: persistReducer({
		key: REDUCER_KEYS.STORE_ITEM_ORDERS,
		storage: storageSession
	}, storeItemOrdersReducer),
	userGroup: persistReducer({
		key: REDUCER_KEYS.USER_GROUP,
		storage: storageSession
	}, userGroupReducer),
	tasks: persistReducer({
		key: REDUCER_KEYS.TASKS,
		storage: storageSession
	}, tasksReducers),
	settings: persistReducer({
		key: REDUCER_KEYS.SETTINGS,
		storage: storageSession
	}, settingsReducers),
	finishes: finishesReducers,
	storeItems: storeItemsReducers,
	storeItemReplacements: storeItemsReplacementsReducers,
	statistics: statisticsReducers,
	documents: documentsReducers,
	documentCategories: documentCategoriesReducers,
	userTrustees: usersTrusteeReducers,
	priceOffer: priceOfferReducer
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, any>;
