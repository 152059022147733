import React, { useEffect } from 'react'
import { Button, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { initialize, reset, submit } from 'redux-form'
import { IStoreItemReplacementForm } from '../../../reducers/storeItemReplacements/actions'
import StoreItemReplacementsForm from './StoreItemReplacementsForm'
import { FORMS } from '../../../utils/enums'

type StoreItemReplacementModalProps = {
	visible: boolean
	onCancel: () => void
	onSubmit: (values: IStoreItemReplacementForm) => void
	initialValues: IStoreItemReplacementForm | undefined | null
	isCreate?: boolean
	id?: number
}

const StoreItemReplacementModal: React.FC<StoreItemReplacementModalProps> = ({
	visible, onCancel, onSubmit, initialValues, isCreate = true, id
}) => {
	const dispatch = useDispatch()

	useEffect(() => {
		if (visible && initialValues) {
			dispatch(initialize(FORMS.STORE_ITEM_REPLACEMENT_FORM, initialValues))
		} else {
			dispatch(reset(FORMS.STORE_ITEM_REPLACEMENT_FORM))
		}
	}, [visible, initialValues, dispatch])

	const handleOk = () => {
		onCancel()
		dispatch(submit(FORMS.STORE_ITEM_REPLACEMENT_FORM))
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleOk}>
					Save
				</Button>
			]}
		>
			<StoreItemReplacementsForm
				onSubmit={onSubmit}
				isCreate={isCreate}
				id={id || 0}
			/>
		</Modal>
	)
}

export default StoreItemReplacementModal
