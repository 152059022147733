import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { List, Rate, Spin } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import i18next from 'i18next'
import { ButtonBase } from '@material-ui/core'
import moment from 'moment'
import { getDashboardRatings } from '../reducers/ratings/actions'
import StyledLink from './NavLink'
import { history } from '../utils/history'
import { RootState } from '../reducers'

interface UserI {
	id: number,
	email: string,
	firstName: string,
	lastName: string,
}

interface JobI {
	jobID: number,
	address: string,
}

interface RatingI {
	id: number,
	rating: number,
	comment: string,
	createdAt: string,
	createdAtTimeStamp: number,
	user: UserI,
	job: JobI
}

const DateWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const ListItem = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
`

const ActionWrapper = styled.div`
	margin-left: auto;
`

const Comment = styled.span`
	max-width: 45%;
`

export const rateColor = (rating: number) => (rating < 3 ? '#ff4d4f' : '#ffc53d')

// @ts-ignore
const Collections = () => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()
	const [temporaryJobGroups, setJobGroups] = useState<RatingI[]>([])

	const detail = useSelector((state: RootState) => state.rating.detail)
	const isLoading = detail.isLoading || false

	const initCollectionFrom = (data: any) => {
		const jobGroups: RatingI[] = get(data, 'ratings')
		setJobGroups(jobGroups)
	}

	useEffect(() => {
		dispatch(getDashboardRatings((data: any) => {
			// eslint-disable-next-line no-use-before-define
			initCollectionFrom(data)
		}))
	}, [dispatch])

	const Header = () => <>
		<p style={{ fontWeight: 'bold' }}>{t('pages:Hodnotenia dnes')}</p>
	</>

	return (
		<>
			<Spin spinning={isLoading}>
				<List
					header={<Header/>}
					bordered
					dataSource={temporaryJobGroups}
					renderItem={(item: RatingI) => <List.Item>
						<ListItem>
							<DateWrapper>{moment(item?.createdAtTimeStamp).format('D. MMM YYYY HH:mm')}
								<StyledLink to={`${i18next.t('paths:jobDetail|path')}/${get(item, 'job.id')}`} style={{ minWidth: '20%' }}>{item?.job?.address} </StyledLink>
							</DateWrapper>
							<Rate style={{ color: rateColor(item?.rating), margin: '0px 12px', minWidth: 132 }} value={item?.rating} disabled/>
							<Comment>{item?.comment}</Comment>
							<ActionWrapper>
								<StyledLink to={`${i18next.t('paths:user|path')}/${get(item, 'user.id')}`}
								>{item?.user?.firstName} {item?.user?.lastName}</StyledLink>
							</ActionWrapper>
						</ListItem></List.Item>}
				/>
			</Spin>
			<div style={{ marginLeft: 'auto', marginRight: 'auto', width: '8em' }}>
				<ButtonBase style={{ fontWeight: 'bold', fontSize: '1.5em', padding: '1em' }} onClick={() => history.push(t('paths:ratings|path'))
				}>{t('pages:Všetky hodnotenia')}</ButtonBase>
			</div>
		</>
	)
}

Collections.propTypes = {
	id: PropTypes.number
}

export default Collections
