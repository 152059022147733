import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

// assets
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
// eslint-disable-next-line import/no-cycle
import { createSlug } from '../utils/helper'
// eslint-disable-next-line import/no-cycle
import { getJobs, JobTableItem } from '../reducers/jobs/actions'
// eslint-disable-next-line import/no-cycle
import { STORE_TYPE } from '../pages/stores/Stores'

const { Item } = Form

const { Option } = Select

type Props = {
	initWithVirtualStore: boolean,
	onValueChange: (value: ISelectedStoreOption) => void,
	storeValue: number | undefined
}

export interface ISelectedStoreOption {
	value: number,
	label: string
}

const SelectStoreField = (props: Props) => {
	const {
		initWithVirtualStore,
		onValueChange,
		storeValue
	} = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [userSelected, setUserSelected] = useState<number | undefined>(undefined)

	const [storesAll, setStoresAll] = useState<JobTableItem[]>([])
	const [virtualStoresAll, setVirtualStoresAll] = useState<JobTableItem[]>([])

	function onChange(value: number, option: any) {
		onValueChange({
			value: option?.value,
			label: option?.children
		})
		setUserSelected(option?.value)
	}

	useEffect(() => {
		dispatch(getJobs(
			{
				limit: 100000,
				page: 1,
				queryParams: {
					search: '',
					storeType: STORE_TYPE.JOB_STORE,
					orderBy: 'address',
					orderDirection: null
				}
			},
			((data) => {
				setStoresAll(data?.tableData)
			})
		))
	}, [])

	useEffect(() => {
		dispatch(getJobs(
			{
				limit: 100000,
				page: 1,
				queryParams: {
					search: '',
					storeType: STORE_TYPE.VIRTUAL_STORE,
					orderBy: 'address',
					orderDirection: null
				}
			},
			((data) => {
				setVirtualStoresAll(data?.tableData)

				if (initWithVirtualStore && data?.tableData?.length > 0 && storeValue === undefined) {
					onChange(data?.tableData[0]?.id, {
						value: data?.tableData[0]?.id,
						children: data?.tableData[0]?.address
					})
				}
			})
		))
	}, [])

	useEffect(() => {
		setUserSelected(storeValue)
	}, [storeValue])

	const jobStores = virtualStoresAll.concat(storesAll)
		.map((job: JobTableItem) => {
			const userName = job.address
			return {
				value: job.id,
				label: userName
			}
		})

	return (
		<Item>
			<Select
				onBlur={() => {
				}}
				className={'dropDown'}
				size={'middle'}
				onChange={onChange}
				suffixIcon={<ChevronIcon width={20} height={20} />}
				showSearch
				allowClear={true}
				value={userSelected}
				style={{ width: 400 }}
				placeholder={t('pages:Vyber pobočku')}
				optionFilterProp='children'
				filterOption={(input: any, option: any) => createSlug(option?.children).indexOf(createSlug(input)) >= 0}
			>

				<Option
					key='no-selection'
					value=''
				>
					{t('pages:Všetky pobočky (bez skladov)')}
				</Option>
				options={map(jobStores, (option) => (
					<Option
						key={option.value}
						value={option.value}
					>
						{option.label}
					</Option>
				))}
			</Select>
		</Item>
	)
}

export default React.memo(SelectStoreField)
