// eslint-disable-next-line import/no-cycle
import { getReq, putReq } from '../../utils/request'
import { LOAD_SETTINGS, UPDATE_SETTINGS } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'

export type ISettingsActions = IResetStore
	| IGetSettingsAction
	| IPutSettingsAction

// Settings
interface IPutSettingsAction {
	type: UPDATE_SETTINGS
	payload: ISettingsPayload
}

export const updateSettings = (
	values: ISettingsDetail,
	onSuccess: () => void = (() => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_SETTINGS.START
	})
	try {
		await putReq('/api/admin/settings', null, values)
		dispatch({ type: UPDATE_SETTINGS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_SETTINGS.FAILED })
		onFailure(error)
		return error
	}
}

// Settings
interface IGetSettingsAction {
	type: LOAD_SETTINGS
	payload: ISettingsPayload
}

export interface ISettingsPayload {
	data: ISettingsDetail
}

export interface ISettingsHeader {
	id: number
	firmName: string
	firmAddress: string
	firmICO: string
	firmDIC: string
	firmImageStampUrl: string
	webPage: string
	phone: string
	email: string
}

export interface ISettingsDetail {
	name: string
	headers: ISettingsHeader[]
}

export const getSettings = (
	onSuccess: (data: ISettingsPayload) => void = ((data) => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<ISettingsPayload>> => async (dispatch) => {
	let payload = {} as ISettingsPayload
	dispatch({
		type: LOAD_SETTINGS.START
	})
	try {
		const { data } = await getReq('/api/admin/settings')

		payload = {
			data
		}

		dispatch({
			type: LOAD_SETTINGS.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_SETTINGS.FAILED
		})
		onFailure(error)
		return error
	}
}
