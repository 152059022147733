import React, { FC } from 'react'
import { Modal, Button } from 'antd'
import { ModalProps } from 'antd/lib/modal'

// assets
import { submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { ReactComponent as CloseIcon } from '../../assets/icons/BaseIcons/x-icon.svg'
import DocumentCategoryForm from '../../pages/documents/forms/DocumentCategoryForm'
import { FORMS } from '../../utils/enums'
import { createCategoryDocument, IDocumentCategoryForm } from '../../reducers/documentCategories/actions'

type Props = ModalProps & {
	isVisible: boolean,
	title: string,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: Function,
	onConfirmBtnTitle: string,
	classNameBtn?: string
}

const AddDocumentCategoryModal: FC<Props> = (props) => {
	const {
		isVisible,
		title,
		onCancel,
		onSuccess,
		width,
		onConfirmBtnTitle,
		cancelText,
		classNameBtn
	} = props

	const dispatch: any = useDispatch()

	const handleCreate = (body: IDocumentCategoryForm) => {
		dispatch(createCategoryDocument(body, () => onSuccess()))
	}

	const handleSubmit = (values:IDocumentCategoryForm) => {
		const body: IDocumentCategoryForm = {
			id: null,
			name: values.name
		}

		handleCreate({
			...body
		})
	}

	return 	<Modal
		closeIcon={<CloseIcon width={18} height={18}/>}
		title={title}
		centered
		visible={isVisible}
		width={width}
		onCancel={onCancel}
		footer={[
			<Button style={{ width: 118, height: 40 }} key='back' type='text' onClick={onCancel}>
				{ cancelText }
			</Button>,
			<Button style={{ width: 118, height: 40 }} className={classNameBtn || ''} key='submit' type='primary' onClick={() => dispatch(submit(FORMS.DOCUMENT_CATEGORY_FORM))}>
				{ onConfirmBtnTitle }
			</Button>
		]}
	>
		<div>
			<DocumentCategoryForm
				onSubmit={ handleSubmit }
				isCreate={ false }
			/>
		</div>
	</Modal>
}

export default AddDocumentCategoryModal
