import { LOAD_STORE } from './types'
// eslint-disable-next-line import/no-cycle
import { IStoreActions, IStorePayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IStorePayload & ILoadingAndFailure
}

export default (state = initState, action: IStoreActions) => {
	switch (action.type) {
		// case CREATE_STORES.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_STORES.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_STORES.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case UPDATE_STORES.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_STORES.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_STORES.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		case LOAD_STORE.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IStorePayload & ILoadingAndFailure
			}
		case LOAD_STORE.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IStorePayload & ILoadingAndFailure
			}
		case LOAD_STORE.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IStorePayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
