import React from 'react'
import { WrappedFieldProps } from 'redux-form'

// ant
import { Form, Row, Switch } from 'antd'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { SwitchProps } from 'antd/lib/switch'

const { Item } = Form

type Props = WrappedFieldProps & SwitchProps & FormItemLabelProps

const SwitchField = (props: Props) => {
	const {
		input,
		label,
		disabled,
		meta: {
			error,
			touched
		},
		style,
		size,
		onClick,
		checked,
		className
	} = props
	return (
		<Item
			label={<span className={'font-semibold'}>{label}</span>}
			help={touched && error}
			validateStatus={(error && touched) ? 'error' : undefined}
			style={style}
			className={className}
		>
			<Row>
				<Switch
					onChange={input.onChange}
					checked={(input.value === 'true' || input.value === true) || checked}
					disabled={disabled}
					size={size}
					onClick={onClick}
				/>
			</Row>
		</Item>
	)
}

export default SwitchField
