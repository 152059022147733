// eslint-disable-next-line import/no-cycle
import { IStoreItemReplacementActions, IStoreItemReplacementPayload, IStoreItemReplacementsPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { LOAD_SELECT_STORE_ITEM_REPLACEMENTS, LOAD_STORE_ITEM_REPLACEMENT } from './types'

export const initState = {
	storeItemReplacements: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IStoreItemReplacementsPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IStoreItemReplacementPayload & ILoadingAndFailure
}

export default (state = initState, action: IStoreItemReplacementActions) => {
	switch (action.type) {
		case LOAD_SELECT_STORE_ITEM_REPLACEMENTS.START:
			return {
				...state,
				storeItemReplacements: {
					...state.storeItemReplacements,
					isFailure: false,
					isLoading: true
				} as IStoreItemReplacementsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEM_REPLACEMENTS.DONE:
			return {
				...state,
				storeItemReplacements: {
					...initState.storeItemReplacements,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IStoreItemReplacementsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEM_REPLACEMENTS.FAILED:
			return {
				...state,
				storeItemReplacements: {
					...initState.storeItemReplacements,
					isFailure: true
				} as IStoreItemReplacementsPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_REPLACEMENT.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IStoreItemReplacementPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_REPLACEMENT.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IStoreItemReplacementPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_REPLACEMENT.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IStoreItemReplacementPayload & ILoadingAndFailure
			}
		// case CREATE_STORE_ITEM_REPLACEMENT.START:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_STORE_ITEM_REPLACEMENT.DONE:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_STORE_ITEM_REPLACEMENT.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case UPDATE_STORE_ITEM_REPLACEMENT.START:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_STORE_ITEM_REPLACEMENT.DONE:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_STORE_ITEM_REPLACEMENT.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case DELETE_STORE_ITEM_REPLACEMENT.START:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case DELETE_STORE_ITEM_REPLACEMENT.DONE:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case DELETE_STORE_ITEM_REPLACEMENT.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		default:
			return state
	}
}
