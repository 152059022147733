import React from 'react'
import { Card } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import LoginForm from './forms/LoginForm'
import { history } from '../../utils/history'
import { loginUser } from '../../reducers/user/userActions'

const LoginPage = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation('components')
	const handleSubmit = async (values: any) => {
		await dispatch(loginUser(values.email, values.password, () => history.push(t('paths:dashboard|path'))))
	}

	return (
		<div className={ 'flex-container primary' }>
			<Card className={ 'secondary' } style={ { width: '400px', border: 'none' } }>
				<div className={ 'login-logo' } />
				<LoginForm onSubmit={ handleSubmit } />
			</Card>
		</div>
	)
}

LoginPage.propTypes = {}

export default LoginPage
