export enum LOAD_FINISHES {
	START= 'LOAD_FINISHES_START',
	DONE= 'LOAD_FINISHES_DONE',
	FAILED= 'LOAD_FINISHES_FAILED'
}

export enum LOAD_DETAIL_FINISH {
	START= 'LOAD_DETAIL_FINISH_START',
	DONE= 'LOAD_DETAIL_FINISH_DONE',
	FAILED= 'LOAD_DETAIL_FINISH_FAILED'
}

export enum DELETE_FINISH {
	START= 'DELETE_FINISH_START',
	DONE= 'DELETE_FINISH_DONE',
	FAILED= 'DELETE_FINISH_FAILED'
}

export enum CREATE_FINISH {
	START= 'CREATE_FINISH_START',
	DONE= 'CREATE_FINISH_DONE',
	FAILED= 'CREATE_FINISH_FAILED'
}

export enum UPDATE_FINISH {
	START= 'UPDATE_FINISH_START',
	DONE= 'UPDATE_FINISH_DONE',
	FAILED= 'UPDATE_FINISH_FAILED'
}
