import React, { useState, useEffect, ReactNode } from 'react'
import { Layout, BackTop } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SideMenu from '../components/SideMenu'
import HeaderContent from '../components/HeaderContent'

const {
	Header, Content, Footer, Sider
} = Layout

interface Props {
	children: ReactNode,
	routeKey: string,
	computedMatch: {
		path: string
	}
}

function AdminLayout({ routeKey, children, computedMatch } : Props) {
	const [collapsed, setCollapsed] = useState(false)
	const [version, setVersion] = useState(null)

	const { t } = useTranslation(['pages', 'paths'])

	useEffect(() => {
		(async () => {
			try {
				// todo implement later
				// const { data } = await getReq('/api/v1/version')
				// setVersion(data.version)
			} catch (error) {
				Promise.reject(error)
			}
		})()
	}, [])

	return (
		<Layout style={ { minHeight: '100vh' } }>
			<Sider collapsible collapsed={ collapsed } onCollapse={ () => setCollapsed(!collapsed) }>
				<Link to={ t('paths:dashboard|path').toString()}>
					<div className={ 'app-logo' } />
				</Link>
				<SideMenu routeKey={ routeKey } />
			</Sider>
			<Layout>
				<Header className={ 'site-layout-background' } style={ { padding: 0 } }>
					<HeaderContent routeKey={ routeKey } computedMatch={ computedMatch } />
				</Header>
				<Content style={ { margin: '0 16px' } }>
					<div className={ 'content-box' }>
						{ children }
					</div>
					<BackTop />
				</Content>
				<Footer style={ { textAlign: 'center' } }>
					<div className={ 'copyrights' }>
						{ `Copyright © 2020 - (Version: ${process.env.REACT_APP_VERSION} API: ${version})` }
					</div>
				</Footer>
			</Layout>
		</Layout>
	)
}

AdminLayout.propTypes = {
	routeKey: PropTypes.string,
	computedMatch: PropTypes.shape({})
}

AdminLayout.defaultProps = {
	routeKey: '',
	computedMatch: null
}

export default AdminLayout
