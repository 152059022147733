import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Badge, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CheckOutlined, DribbbleOutlined, DropboxOutlined, HomeOutlined, PhoneOutlined, QuestionOutlined, ReadOutlined, SettingOutlined, UserOutlined, UserSwitchOutlined, VideoCameraOutlined } from '@ant-design/icons'

import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_ROLES_WEIGHTS, getRouteAllowedRoles } from '../utils/enums'
import { Profile } from '../reducers/user/userActions'
import { RootState } from '../reducers'
import { getStoreItemOrders } from '../reducers/storeItemOrders/actions'

interface Props {
	routeKey: string
}

const SideMenu = ({ routeKey }: Props) => {
	const dispatch = useDispatch()

	const { t } = useTranslation(['pages', 'paths'])

	const profile = useSelector((state: RootState) => state.profile.profile.data)
	const orders = useSelector((state: RootState) => state.storeItemOrders.list.notExecutedOrders)
	const routeAllowedRoles: any = getRouteAllowedRoles()
	const isAllowed = (userProfile: Profile | null, key: string) => ADMIN_ROLES_WEIGHTS[userProfile?.role || Number.MAX_VALUE] <= ADMIN_ROLES_WEIGHTS[routeAllowedRoles[key]]
	const hasStoreItemsFeature = (userProfile: Profile | null) => userProfile?.hasStoreItemsFeature || false
	const hasSuperAdminFeature = (userProfile: Profile | null) => userProfile?.hasSuperAdminFeature || false
	const hasDocumentsFeature = (userProfile: Profile | null) => userProfile?.hasDocumentsFeature || false
	const hasPermissionToCreatePriceOfferFeature = (userProfile: Profile | null) => userProfile?.hasPermissionToCreatePriceOffer || false

	// reset orders
	useEffect(() => {
		dispatch(getStoreItemOrders({
			limit: 1,
			page: 1,
			queryParams: {}
		}))
	}, [])

	return (
		<Menu theme={'dark'} selectedKeys={[routeKey]} mode={'inline'}>
			{isAllowed(profile, t('paths:appSuperAdmin|key')) && hasSuperAdminFeature(profile) && (
				<Menu.Item key={t('paths:appSuperAdmin|key').toString()} icon={<UserSwitchOutlined />}>
					<Link to={t('paths:appSuperAdmin|path').toString()}>
						{t('paths:appSuperAdmin|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:home|key')) && (
				<Menu.Item key={t('paths:home|key').toString()} icon={<HomeOutlined />}>
					<Link to={t('paths:home|path').toString()}>
						{t('paths:home|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:users|key')) && (
				<Menu.Item key={t('paths:users|key').toString()} icon={<UserOutlined />}>
					<Link to={t('paths:users|path').toString()}>
						{t('paths:users|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:jobs|key')) && (
				<Menu.Item key={t('paths:jobs|key').toString()} icon={<VideoCameraOutlined />}>
					<Link to={t('paths:jobs|path').toString()}>
						{t('paths:jobs|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:tasks|key')) && (
				<Menu.Item key={t('paths:tasks|key').toString()} icon={<DribbbleOutlined />}>
					<Link to={t('paths:tasks|path').toString()}>
						{t('paths:tasks|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:finishes|key')) && (
				<Menu.Item key={t('paths:finishes|key').toString()} icon={<CheckOutlined />}>
					<Link to={t('paths:finishes|path').toString()}>
						{t('paths:finishes|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:ratings|key')) && (
				<Menu.Item key={t('paths:ratings|key').toString()} icon={<QuestionOutlined />}>
					<Link to={t('paths:ratings|path').toString()}>
						{t('paths:ratings|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:documents|key')) && hasDocumentsFeature(profile) && (
				<Menu.Item key={t('paths:documents|key').toString()} icon={<ReadOutlined />}>
					<Link to={t('paths:documents|path').toString()}>
						{t('paths:documents|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:stores|key')) && hasStoreItemsFeature(profile) && (
				<Menu.SubMenu
					icon={<DropboxOutlined />} title={
						<span className={'inline-block'}>
							{t('paths:stores|title')}
							<Badge
								count={orders}
								className='site-badge-count-109 ml-3'
								style={{ backgroundColor: '#eb9936' }}
							/>
						</span>
					}
				>
					{isAllowed(profile, t('paths:stores|key')) && hasStoreItemsFeature(profile) && (
						<Menu.Item key={t('paths:stores|key').toString()} icon={<DropboxOutlined />}>
							<Link to={t('paths:stores|path').toString()}>
								{t('paths:stores|title')}
							</Link>
						</Menu.Item>
					)}
					<Menu.SubMenu
						icon={<DropboxOutlined />} title={
							<span className={'inline-block'}>
								{t('paths:storeItems|titleShort')}
								<Badge
									count={orders}
									className='site-badge-count-109 ml-3'
									style={{ backgroundColor: '#eb9936' }}
								/>
							</span>
						}
					>
						{isAllowed(profile, t('paths:storeItems|key')) && hasStoreItemsFeature(profile) && (
							<Menu.Item key={t('paths:storeItems|key').toString()} icon={<DropboxOutlined />}>
								<Link to={t('paths:storeItems|path').toString()}>
									{t('paths:storeItems|title')}
								</Link>
							</Menu.Item>
						)}
						{isAllowed(profile, t('paths:storeItemsAmounts|key')) && hasStoreItemsFeature(profile) && (
							<Menu.Item key={t('paths:storeItemsAmounts|key').toString()} icon={<DropboxOutlined />}>
								<Link to={t('paths:storeItemsAmounts|path').toString()}>
									{t('paths:storeItemsAmounts|title')}
								</Link>
							</Menu.Item>
						)}
					</Menu.SubMenu>
					{isAllowed(profile, t('paths:storeOrders|key')) && hasStoreItemsFeature(profile) && (
						<Menu.Item key={t('paths:storeOrders|key').toString()} icon={<DropboxOutlined />}>
							<Link to={t('paths:storeOrders|path').toString()}>
								{t('paths:storeOrders|title')}
								<span>  </span>
								<Badge
									count={orders}
									className='site-badge-count-109'
									style={{ backgroundColor: '#eb9936' }}
								/>
							</Link>
						</Menu.Item>
					)}
				</Menu.SubMenu>
			)}
			{isAllowed(profile, t('paths:priceOffers|key')) && hasPermissionToCreatePriceOfferFeature(profile) && (
				<Menu.Item key={t('paths:priceOffers|key').toString()} icon={<PhoneOutlined />}>
					<Link to={t('paths:priceOffers|path').toString()}>
						{t('paths:priceOffers|title')}
					</Link>
				</Menu.Item>
			)}
			{isAllowed(profile, t('paths:appSettings|key')) && (
				<Menu.Item key={t('paths:appSettings|key').toString()} icon={<SettingOutlined />}>
					<Link to={t('paths:appSettings|path').toString()}>
						{t('paths:appSettings|title')}
					</Link>
				</Menu.Item>
			)}
		</Menu>
	)
}

SideMenu.propTypes = {
	routeKey: PropTypes.string
}

SideMenu.defaultProps = {
	routeKey: ''
}

export default SideMenu
