import React from 'react'
import { WrappedFieldProps } from 'redux-form'

// ant
import { Form, Row, Switch } from 'antd'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { SwitchProps } from 'antd/lib/switch'
import { EventWithDataHandler } from 'redux-form/lib/Field'
import { IUserOrganization } from '../reducers/stores/actions'

const { Item } = Form

type Props = WrappedFieldProps & SwitchProps & FormItemLabelProps& {
	itemChecked: (iUserOrganization: IUserOrganization) => boolean,
	itemDisabled: (iUserOrganization: IUserOrganization) => boolean,
	onItemChange: (iUserOrganization: IUserOrganization) => IUserOrganization,
}

function getSwitchList(
	inputUserOrganizations: IUserOrganization[],
	onChange: EventWithDataHandler<IUserOrganization[]>,
	checked: (iUserOrganization: IUserOrganization) => boolean,
	itemDisabled: (iUserOrganization: IUserOrganization) => boolean,
	onItemChange: (iUserOrganization: IUserOrganization) => IUserOrganization,
	size: 'small' | 'default' | undefined
) {
	return <>
		{(inputUserOrganizations as IUserOrganization[] || []).map((userOrganization: IUserOrganization) => (
			<div key={userOrganization.organizationName} className={'p-1 w-60'}>
				<Row justify={'space-between'} align={'middle'} className={'mb-1  h-10'} key={userOrganization.organizationID}>
					<div>{userOrganization.organizationName}</div>
					<Switch
						checked={checked(userOrganization)}
						disabled={itemDisabled(userOrganization)}
						size={size}
						onChange={() => {
							const userOrganizationInput = inputUserOrganizations as IUserOrganization[]
							const userOrganizations = [...userOrganizationInput]
							const result = userOrganizations.map((uo: IUserOrganization) => {
								if (uo.organizationID === userOrganization.organizationID) {
									return onItemChange(uo)
								}
								return {
									...uo
								}
							})
							onChange(result)
						}}
					/>
				</Row>
				<div className='h-px bg-gray-400' />
			</div>
		))}
	</>
}

const TableSwitchField = (props: Props) => {
	const {
		input,
		itemChecked,
		onItemChange,
		label,
		itemDisabled,
		meta: {
			error,
			touched
		},
		style,
		size,
		className
	} = props
	return (
		<Item
			label={label}
			help={touched && error}
			validateStatus={(error && touched) ? 'error' : undefined}
			style={style}
			className={className}
		>
			<div>
				{getSwitchList(
					input.value,
					input.onChange,
					itemChecked,
					itemDisabled,
					onItemChange,
					size
				)}
			</div>
		</Item>
	)
}

export default TableSwitchField
