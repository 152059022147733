// eslint-disable-next-line import/no-cycle
import { ISettingsActions, ISettingsPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { LOAD_SETTINGS, UPDATE_SETTINGS } from './types'

export const initState = {
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as ISettingsPayload & ILoadingAndFailure
}

export default (state = initState, action: ISettingsActions) => {
	switch (action.type) {
		case LOAD_SETTINGS.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as ISettingsPayload & ILoadingAndFailure
			}
		case LOAD_SETTINGS.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as ISettingsPayload & ILoadingAndFailure
			}
		case LOAD_SETTINGS.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as ISettingsPayload & ILoadingAndFailure
			}
		case UPDATE_SETTINGS.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case UPDATE_SETTINGS.DONE:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: false
				}
			}
		case UPDATE_SETTINGS.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}

		default:
			return state
	}
}
