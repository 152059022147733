import { get } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { getReq, putReq } from '../../utils/request'
import { LOAD_COLLECTIONS, UPDATE_COLLECTIONS } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IFormTemplatePayload } from '../formTemplate/formTemplateActions'

export type ICollectionActions = IResetStore | IGetCollections | IUpdateCollections

// get collection
interface IGetCollections {
	type: LOAD_COLLECTIONS
	payload: ICollectionPayload
}

export interface ICollectionPayload {
	data: Collection
}

export type Collection = {
	jobGroups: IJobGroupOriginalTableItem[]
}

export type IJobGroupOriginalTableItem = {
	jobID: number,
	address: string,
	cleaningSetLocatedOnJob: boolean
}

// update collection
export interface IUpdateCollections {
	type: UPDATE_COLLECTIONS
	payload: ICollectionUpdatePayload
}

interface ICollectionUpdatePayload {
	data: Collection | null
	isLoading: boolean
	isFailure: boolean
}

export const getCollections = (
	id: number,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: Collection) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<ICollectionPayload>> => async (dispatch) => {
	try {
		dispatch({ type: LOAD_COLLECTIONS.START })
		const { data } = await getReq(`/api/admin/jobs/${id}/job-group`)

		const payload: ICollectionPayload = {
			data
		}

		dispatch({ type: LOAD_COLLECTIONS.DONE, payload })
		onSuccess(payload.data)
		return payload.data
	} catch (e) {
		dispatch({ type: LOAD_COLLECTIONS.FAILED })
		onFailure(e)
		return e
	}
}

interface IRequestPayload {
	jobGroups: IUpdateRequestJobPayload[]
}

interface IUpdateRequestJobPayload {
	jobID: number
}

export const updateCollections = (
	id: number,
	values: IRequestPayload,
	onSuccess: () => void,
	onFailure: (error: any) => void
): ThunkResult<Promise<IFormTemplatePayload>> => async (dispatch) => {
	dispatch({
		type: UPDATE_COLLECTIONS.START
	})
	try {
		await putReq(`/api/admin/jobs/${id}/job-group`, null, values)
		dispatch({ type: UPDATE_COLLECTIONS.DONE })

		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_COLLECTIONS.FAILED })
		onFailure(error)
		return error
	}
}
