import React, { useCallback, useEffect, useState } from 'react'
import { change, Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Modal, Row, Select } from 'antd'
import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, get, map } from 'lodash'
import i18next from 'i18next'
import { FORMS, FREQUENCIES, FrequencyI, USER_ROLE } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validateJobForm'
import { createUser, getUsers, IUserCreateParams, IUserUpdateParams } from '../../../reducers/usersU/actions'
import { getTasks } from '../../../reducers/tasks/actions'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import { UsePicker } from '../../../components/useFrequenciesPicker'
import OptionUserSelectField from '../../../atoms/form/OptionUserSelectField'
import { getTrustees } from '../../../reducers/userTrustees/actions'
import DragAndDropField, { MoveOptionI, MoveOptions } from '../../../atoms/form/DragAndDropField'
import Collections from '../../../components/Collections'
import { divideArrayFromAnother } from '../../../utils/helper'
import { RootState } from '../../../reducers'
import { IJobDetail, IJobForm, IPriceOfferItem, IUserJobTask } from '../../../reducers/jobs/actions'
import StoreItems from '../../storeItems/StoreItems'
import InputField from '../../../atoms/InputField'
import InputNumberField from '../../../atoms/InputNumberField'
import StyledLink from '../../../components/NavLink'
import UserForm from '../../users/forms/UserForm'
import SwitchField from '../../../atoms/SwitchField'
import { getPriceOfferTag } from '../../priceOffers/forms/PriceOfferForm'

const { Option } = Select
const PAGE_SIZE = 200

type ComponentProps = {
	onRemove: (id: number) => void,
	onRefresh: () => void,
	isCreate: boolean,
	id: number,
	transferJobToOffer: (id: number) => void
}

type Props = InjectedFormProps<IJobForm, ComponentProps> & ComponentProps

const PriceOfferList = (jobDetail: IJobDetail) => jobDetail?.priceOffers?.map((priceOffer: IPriceOfferItem) => (
	<StyledLink
		to={`${i18next.t('paths:priceOfferDetail|path')}/${priceOffer?.id}`}
		key={priceOffer?.id}
		className={'w-max'}
		style={ {
			marginTop: '10px',
			display: 'flex',
			justifyContent: 'space-between'
		} }
	>
		<span className={'mr-5'}>{ getPriceOfferTag(priceOffer.status)}</span>
		<span>{ priceOffer?.createdAt }</span>
	</StyledLink>
))

function getTasksInTargetFrequencies(jobFormValues: IJobForm, fromFrequency: FrequencyI, toFrequency: FrequencyI): IUserJobTask[] {
	const tasks = get(jobFormValues, `${fromFrequency.key}Tasks`, [])
	const tasksInTargetFrequencies = get(jobFormValues, `${toFrequency.key}Tasks`, [])
	const combinedTasks = tasksInTargetFrequencies.concat(tasks)

	return combinedTasks.reduce((acc: IUserJobTask[], currentTask: IUserJobTask) => {
		if (!acc.find((task) => task.task.id === currentTask.task.id)) {
			acc.push({
				...currentTask,
				frequency: toFrequency.key.toUpperCase()
			})
		}
		return acc
	}, [])
}

const JobForm = ({
	handleSubmit,
	onRemove,
	onRefresh,
	invalid,
	pristine,
	isCreate,
	id,
	transferJobToOffer
}: Props) => {
	const dispatch = useDispatch()

	const [isModalCreateTrusteeVisible, setIsModalCreateTrusteeVisible] = useState(false)

	const jobFormValues: IJobForm = useSelector((state: RootState) => getFormValues(FORMS.JOB_FORM)(state)) as IJobForm
	const [isVisiblePriceOfferModal, setIsVisiblePriceOfferModal] = useState(false)

	const handleOk = () => {
		setIsModalCreateTrusteeVisible(false)
	}

	const handleCreatePriceOffer = () => {
		transferJobToOffer(id)
		setIsVisiblePriceOfferModal(false)
	}

	const handleCancel = () => {
		setIsModalCreateTrusteeVisible(false)
	}

	const handleCreate = (body: any) => {
		dispatch(createUser(body, (userID) => {
			setIsModalCreateTrusteeVisible(false)
			// {"id":10,"name":"Martin Malobický - test","sendEmail":false,"phone":"+421949810793","email":"malobicky.m@gmail.com"}
			const newTrustee = {
				id: userID,
				name: body.name,
				sendEmail: body.sendEmail || false,
				phone: body.phone,
				email: body.email
			}
			dispatch(change(FORMS.JOB_FORM, 'trustees', (jobFormValues?.trustees || []).concat([JSON.stringify(newTrustee) as any])))
		}, (userID) => {
			setIsModalCreateTrusteeVisible(false)
		}))
	}

	const handleCreateTrusteeSubmit = (values: IUserCreateParams | IUserUpdateParams) => {
		const body = {
			firstName: get(values, 'firstName'),
			lastName: get(values, 'lastName'),
			email: get(values, 'email'),
			phone: get(values, 'phone'),
			role: get(values, 'role')
		}

		handleCreate({
			...body,
			password: get(values, 'password')
		})
	}

	const dataSource = useSelector((state:RootState) => state.tasks.tasks.tableData)

	const hasPriceForCleaningFeature = useSelector((state: RootState) => state.profile.profile.data?.hasPriceForCleaningFeature)

	const detail = useSelector((state:RootState) => state.jobs.detail)
	const trustees = useSelector((state:RootState) => state.userTrustees.trustees.tableData)

	const [trusteeFilter, setTrusteeFilter] = useState({})

	const users = useSelector((state:RootState) => state.users.users.tableData)
		.map((user: any) => {
			const userName = user.lastName ? `${user.lastName} ${user.firstName}` : user.email
			return {
				value: user.id,
				label: userName
			}
		})
	const {
		filter,
		Picker
	} = UsePicker()
	// const [filter, setFilter] = useState({})

	useEffect(() => {
		dispatch(getTasks(
			1,
			PAGE_SIZE
		))
	}, [])

	useEffect(() => {
		dispatch(getUsers(
			1,
			10000,
			{
				role: [USER_ROLE.USER, USER_ROLE.ADMIN, USER_ROLE.SUPERADMIN]
			}
		))
	}, [])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...trusteeFilter
		}
		dispatch(getTrustees(body))
	}, [trusteeFilter, dispatch])

	const { t } = useTranslation('components')
	const filteredItems: any[] = divideArrayFromAnother(FREQUENCIES, filter)
	const items = filteredItems.map((value: any) => (
		<div
			key={value?.key}
			className={'mt-6'}
			style={ {
				justifySelf: 'start',
				width: '100%',
				marginBottom: 40
			} }
		>
			<Field
				name={ `${value.key}Tasks` }
				extraData={ detail?.data?.extras?.[value?.key?.toUpperCase()] }
				options={ dataSource }
				handleRefresh={ onRefresh }
				frequency={ value.key.toUpperCase() }
				jobId={ id }
				userId={ jobFormValues?.userID }
				component={ DragAndDropField }
				onMoveTasks={(fromFrequency: FrequencyI, toFrequency: FrequencyI, moveOption: MoveOptionI) => {
					switch (moveOption.key.toLowerCase()) {
						case MoveOptions.copy.toLowerCase(): {
							const tasksInTargetFrequencies = getTasksInTargetFrequencies(jobFormValues, fromFrequency, toFrequency)
							dispatch(change(FORMS.JOB_FORM, `${toFrequency.key}Tasks`, tasksInTargetFrequencies))
							break
						}
						case MoveOptions.move.toLowerCase(): {
							const tasksInTargetFrequencies = getTasksInTargetFrequencies(jobFormValues, fromFrequency, toFrequency)
							dispatch(change(FORMS.JOB_FORM, `${fromFrequency.key}Tasks`, []))
							dispatch(change(FORMS.JOB_FORM, `${toFrequency.key}Tasks`, tasksInTargetFrequencies))
							break
						}
						default: {
							const tasksInTargetFrequencies = getTasksInTargetFrequencies(jobFormValues, fromFrequency, toFrequency)
							dispatch(change(FORMS.JOB_FORM, `${toFrequency.key}Tasks`, tasksInTargetFrequencies))
						}
					}
				}
				}
				label={ value.name }
				required
			/>
		</div>
	))

	const debounced = useCallback(debounce((searchTerm: string) => {
		setTrusteeFilter({ search: searchTerm, page: 1 })
	}, 300), [trusteeFilter])

	const handleOnChange = (searchTerm: string) => {
		debounced(searchTerm)
	}

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
						<h3>{ t('pages:Pridať úlohu') }</h3>
						<Row gutter={ 8 } justify={ 'start' }>
							<Col className={ 'gutter-row' } span={ 6 }>
								<Field
									name={ 'userID' }
									component={ CustomOptionsSelectField }
									label={ t('components:Pracovník') }
									options={ map(users, (option) => (
										<Option
											key={ option.value }
											value={ option.value }
										>
											{ option.label }
										</Option>
									)) }
									size={ 'large' }
									allowClear
									showSearch
									filterOption={ (input: any, option: any) => option.children.toLowerCase()
										.indexOf(input.toLowerCase()) >= 0 }
								/>

							</Col>
							<Col className={ 'gutter-row' } span={ 6 }>
								<Field
									name={ 'address' }
									component={ InputField }
									label={ t('components:Adresa') }
									size={ 'large' }
									required
								/>
							</Col>
							{hasPriceForCleaningFeature && <Col className={'gutter-row'} span={6}>
								<Field
									name={'priceForCleaning'}
									component={InputNumberField}
									label={t('components:Cena za jedno upratovanie (€)')}
									size={'large'}
									type={'number'}
								/>
							</Col>}
							<Col className={ 'gutter-row' } span={ 24 }>
								<Field
									name={ 'trustees' }
									component={ OptionUserSelectField }
									label={
										<span className={'mt-1 mb-2'}>
											<span className={''}>{t('components:Zodpovedné osoby')}</span>
											{
												jobFormValues?.priceOfferTrustee && (
													<Button
														icon={<SaveOutlined />}
														onClick={() => {
															const initValues = {
																firstName: jobFormValues?.priceOfferTrustee?.name,
																role: USER_ROLE.JOB_TRUSTEE,
																lastName: '',
																contactEmail: jobFormValues?.priceOfferTrustee?.emailOfTrustee,
																email: jobFormValues?.priceOfferTrustee?.emailOfTrustee,
																phone: jobFormValues?.priceOfferTrustee?.phoneOfTrustee,
																isConfirmed: true
															}
															dispatch(initialize(FORMS.USER_FORM, initValues))
															setIsModalCreateTrusteeVisible(true)
														}}
														disabled={false}
														type={'primary'}
														className={'ml-2'}
													>
														<span>{t('pages:Vytvoriť klienta ')}</span>
														<span className={'font-semibold'}>&nbsp;{jobFormValues?.priceOfferTrustee?.name}&nbsp;{jobFormValues?.priceOfferTrustee?.emailOfTrustee}&nbsp;</span>
														<span>{t('pages: z cenovej ponuky')}</span>
													</Button>
												)
											}
										</span>
									}
									options={map(trustees.filter((data: any) => {
										const trustees = jobFormValues?.trustees
										const found = trustees?.find((trustee: any) => {
											try {
												return JSON.parse(trustee).id === data?.id
											} catch (e) {
												return false
											}
										})
										return !found
									}), (option) => (
										<Option
											key={ option.id }
											value={ JSON.stringify({
												id: option.id,
												name: option.name,
												sendEmail: false,
												phone: option.phone,
												email: option.email
											}) }
											title={ option.name }
										>
											{ option.name }
										</Option>
									)) }
									onFocus={(data: any) => {
										setTrusteeFilter({ search: '', page: 1 })
									}}
									size={ 'large' }
									allowClear
									showSearch
									onSearch={(searchTerm: any) => {
										handleOnChange(searchTerm.toLowerCase())
									}}
								/>
								<Field
									name={ 'note' }
									component={ TextField }
									rows={2}
									label={ t('components:Poznámka pre zamestnanca') }
									size={ 'large' }
									maxLength={500}
								/>
							</Col>
						</Row>
					</div>
				</Col>
				<Col span={ 6 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-between sidebar-content' }>
						<div className={'flex direction-col justify-center'}>
							{!isCreate && (
								<Field
									name={'updatedAt'}
									component={TextField}
									disabled
									label={t('components:Dátum poslednej úpravy')}
								/>
							)}
							<Button
								icon={<SaveOutlined />}
								onClick={handleSubmit}
								disabled={pristine || invalid}
								type={'primary'}
								style={{ margin: '0px' }}
							>
								{t('pages:Uložiť')}
							</Button>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between'
								}}
								className={'mt-5 font-bold'}
							>
								{i18next.t('components:Cenové ponuky')}
								<PlusOutlined
									onClick={() => {
										setIsVisiblePriceOfferModal(true)
									}}
								/>
							</div>
							{detail?.data ? PriceOfferList(detail.data) : <div>{i18next.t('components:Žiadne cenové ponuky')}</div>}
							<div className={'mt-5'}></div>
						</div>
						<Button
							icon={<DeleteOutlined />}
							style={{ marginLeft: '0px' }}
							type={'primary'}
							onClick={() => onRemove(id)}
						>
							{t('components:Odstrániť zákazku')}
						</Button>
					</div>
				</Col>
			</Row>
			<Row gutter={16} className={'mt-5'}>
				<Col span={ 24 } className={ 'grid' }>
					<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
						<h3>{ t('pages:Obchodné informácie') }</h3>
						<Row gutter={0} justify={'start'} className={'fields-container'}>
							<div className={'gutter-row'}>
								<Field
									name={'flats'}
									component={InputField}
									label={t('components:Počet vchodov')}
									size={'large'}
									type={'number'}
									min={0}
									precision={0}
									step={1}
								/>
							</div>
							<div className={'gutter-row'}>
								<Field
									name={'floors'}
									component={InputField}
									label={t('components:Počet poschodí')}
									size={'large'}
									type={'number'}
									min={0}
									precision={0}
									step={1}
								/>
							</div>
							<div className={'gutter-row'}>
								<Field
									name={'nameOfHousingAssociation'}
									component={InputField}
									label={t('components:Bytové družstvo')}
									size={'large'}
								/>
							</div>
						</Row>
						<Row gutter={0} justify={'start'} className={'fields-container'}>
							<div className={''}>
								<Field
									name={'inflationPartAccepted'}
									component={SwitchField}
									label={t('components:Akceptovaná inflačná doložka')}
									size={'large'}
								/>
							</div>
						</Row>
					</div>
				</Col>
			</Row>

			<div className={'jobs-container'}>
				{id && <Collections id={id} />}
			</div>
			<Picker />
			<div className={'jobs-container'}>
				{items}
			</div>
			{!isCreate && (
				<Row >
					<Col className={'grid'}>
						<h3 className={'ml-2'}>{t('pages:Skladové položky')}</h3>
						<StoreItems
							tableData={ null }
							initFilter={
								{ jobID: id, jobToID: null }
							}
							tableMode={true}
							addRemoveItemFeature={true}
						/>
					</Col>
				</Row>
			) }
			<Modal title={ i18next.t('pages:Vytvoriť klienta') } visible={isModalCreateTrusteeVisible} okText={i18next.t('pages:Vytvoriť')} onOk={handleOk} onCancel={handleCancel} footer={null} >
				<UserForm
					onSubmit={ handleCreateTrusteeSubmit }
					isCreate={ true }
					trusteePopup={true}
					userID={null}
					setShowPasswordChangeModal={() => {}}
					onRefreshPage={() => {}}
				/>
			</Modal>
			<Modal title={ i18next.t('pages:Vytvoriť cenovú ponuku') } visible={isVisiblePriceOfferModal} okText={i18next.t('pages:Vytvoriť')} onOk={handleCreatePriceOffer} onCancel={ () => setIsVisiblePriceOfferModal(false)} >
				{ i18next.t('pages:Vytvoriť cenovú ponuku z aktualnej zákazky') }
			</Modal>
		</Form>
	)
}

const form = reduxForm<IJobForm, ComponentProps>({
	form: FORMS.JOB_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(JobForm)

export default form
