import React from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row, Select } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import InputField from '../../../atoms/InputField'
import validateSettingsForm from './validateSettingsForm'
import { ISettingsDetail } from '../../../reducers/settings/actions'

const { Option } = Select

type ComponentProps = {
}

// "id": 1,
// 	"firmName": "UMYJEME TOP ŽA 2, s.r.o.",
// 	"firmAddress": "Festivalová 2303/17, 010 01 Žilina",
// 	"firmICO": "55117988",
// 	"firmDIC": "2121872379",
// 	"firmImageStampUrl": "https://firebasestorage.googleapis.com/v0/b/we-clean-top.appspot.com/o/price_offer_parts%2FPE%C4%8CIATKA%20-%20UMYJEME%20TOP%20ZA%202%20FOJTIK.png?alt=media&token=07b409a3-90c0-47f9-bc9c-46661bee27d3",
// 	"webPage": "www.umyjeme.top",
// 	"phone": "+421 948 444 443",
// 	"email": "zilina@umyjeme.top"

type Props = InjectedFormProps<ISettingsDetail, ComponentProps> & ComponentProps

const SettingsForm = (props: Props) => {
	const {
		handleSubmit, invalid, pristine
	} = props

	const dispatch = useDispatch()

	const { t } = useTranslation('components')

	const settingsDetail: ISettingsDetail = useSelector((state) => getFormValues(FORMS.SETTINGS_FORM)(state)) as ISettingsDetail

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<h1>{ t('pages:Nastavenia aplikácie') }</h1>
						<Field
							name={ 'name' }
							component={ InputField }
							label={ t('components:Názov organizácie') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content' }>
						<Field
							name={ 'updatedAt' }
							component={ TextField }
							disabled
							label={ t('components:Dátum poslednej úpravy') }
						/>

						<div className={ 'flex direction-col justify-center' }>
							<Button
								icon={ <SaveOutlined /> }
								onClick={ handleSubmit }
								disabled={ pristine || invalid }
								type={ 'primary' }
								className={ 'mt-4'}
							>
								{ t('pages:Uložiť') }
							</Button>
						</div>
					</div>
				</Col>
			</Row>
			{settingsDetail?.headers?.map((header, index) => (
				<Row key={index} gutter={16} className={'mt-5'}>
					<Col span={ 24 } className={ 'grid' }>
						<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
							<h3>{header?.firmName}</h3>
							<Field
								name={ `headers.[${index}].firmName` }
								component={ InputField }
								label={ t('components:Email organizácie') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].firmAddress` }
								component={ InputField }
								label={ t('components:Adresa organizácie') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].firmICO` }
								component={ InputField }
								label={ t('components:ICO') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].firmDIC` }
								component={ InputField }
								label={ t('components:DIC') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].firmImageStampUrl` }
								component={ InputField }
								label={ t('components:URL obrazku') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].webPage` }
								component={ InputField }
								label={ t('components:Web stránka') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].phone` }
								component={ InputField }
								label={ t('components:Telefón') }
								size={ 'large' }
							/>
							<Field
								name={ `headers.[${index}].email` }
								component={ InputField }
								label={ t('components:Email') }
								size={ 'large' }
							/>
						</div>
					</Col>
				</Row>))
			}
		</Form>
	)
}

const form = reduxForm<ISettingsDetail, ComponentProps>({
	form: FORMS.SETTINGS_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateSettingsForm
})(SettingsForm)

export default form
