import React from 'react'
import {
	Field, reduxForm, InjectedFormProps
} from 'redux-form'
import {
	Button, Col, Form, Row
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validateTaskForm'
import { ITaskForm } from '../../../reducers/tasks/actions'

type ComponentProps = {
	isCreate: boolean
}

type Props = InjectedFormProps<ITaskForm, ComponentProps> & ComponentProps

const TaskForm = (props: Props) => {
	const {
		handleSubmit, invalid, pristine, isCreate
	} = props

	const { t } = useTranslation('components')

	return (
		<Form onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<h1>{ t('pages:Pridať úlohu') }</h1>
						<Field
							name={ 'name' }
							component={ TextField }
							label={ t('components:Názov úlohy') }
							size={ 'large' }
							required
						/>

					</div>
				</Col>
				<Col span={ 6 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start sidebar-content' }>
						{ !isCreate && (
							<Field
								name={ 'updatedAt' }
								component={ TextField }
								disabled
								label={ t('components:Dátum poslednej úpravy') }
							/>
						) }

						<div className={ 'flex direction-col justify-center' }>
							<Button
								icon={ <SaveOutlined /> }
								onClick={ handleSubmit }
								disabled={ pristine || invalid }
								type={ 'primary' }
								style={ { margin: '20px' } }
							>
								{ t('pages:Uložiť') }
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<ITaskForm, ComponentProps>({
	form: FORMS.TASK_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(TaskForm)

export default form
