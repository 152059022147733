import React from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { ITaskDetail } from '../reducers/tasks/actions'

interface Props {
	task: ITaskDetail | null,
	loading: boolean,
	handleCancel: (e: React.MouseEvent<HTMLElement>) => void,
	handleOk: (e: React.MouseEvent<HTMLElement>) => void,
}

const JobsModal = ({
	task, loading, handleCancel, handleOk
} : Props) => {
	const { t } = useTranslation('pages')
	return (
		<>
			<Modal
				visible={!!task}
				title={t('pages:Naozaj zmazať')}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						{t('pages:Späť')}
					</Button>,
					<Button key="submit" type="primary" loading={loading} onClick={handleOk}>
						{t('pages:Zmazať')}
					</Button>
				]}
			>
				{task?.name}
			</Modal>
		</>
	)
}

export default JobsModal
