export enum LOAD_COLLECTIONS {
	START = 'LOAD_COLLECTIONS_START',
	DONE = 'LOAD_COLLECTIONS_DONE',
	FAILED = 'LOAD_COLLECTIONS_FAILED'
}

export enum UPDATE_COLLECTIONS {
	START = 'UPDATE_COLLECTIONS_START',
	DONE = 'UPDATE_COLLECTIONS_DONE',
	FAILED = 'UPDATE_COLLECTIONS_FAILED'
}
