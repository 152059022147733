import { LOAD_COLLECTIONS } from './types'
// eslint-disable-next-line import/no-cycle
import { ICollectionActions, ICollectionPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as ICollectionPayload & ILoadingAndFailure
}

export default (state = initState, action: ICollectionActions) => {
	switch (action.type) {
		case LOAD_COLLECTIONS.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as ICollectionPayload & ILoadingAndFailure
			}
		case LOAD_COLLECTIONS.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as ICollectionPayload & ILoadingAndFailure
			}
		case LOAD_COLLECTIONS.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as ICollectionPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
