import React from 'react'
import {
	Field, reduxForm, getFormValues, InjectedFormProps
} from 'redux-form'
import {
	Col, Form, Row
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateDocumentCategoryForm from './validateDocumentCategoryForm'
import { IEditDocumentForm } from '../../../reducers/documents/actions'
import DocumentCategoriesSelectField from './DocumentCategoriesSelectField'

type ComponentProps = {
	isCreate: boolean
}

type Props = InjectedFormProps<IEditDocumentForm, ComponentProps> & ComponentProps

const EditDocumentForm = (props: Props) => {
	const { t } = useTranslation('components')

	const {
		handleSubmit
	} = props

	return (
		<Form onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 24 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<Field
							name={ 'name' }
							component={ TextField }
							label={ t('components:Názov Dokumentu') }
							size={ 'large' }
							required
						/>
						<Field
							name={ 'categoryID' }
							component={ DocumentCategoriesSelectField }
							size={ 'large' }
							allowClear
							showSearch
						/>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IEditDocumentForm, ComponentProps>({
	form: FORMS.EDIT_DOCUMENT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateDocumentCategoryForm
})(EditDocumentForm)

export default form
