import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Form, Select } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'

const { Item } = Form

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean,
	options: any[]
}

const CustomOptionsSelectField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		customInputClass,
		meta: { touched, error },
		type,
		style,
		search,
		hideHelp,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		options,
		width,
		multiple,
		...rest
	} = props
	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<Item
				label={<span className={'font-semibold'}>{label}</span>}
				required={required}
				style={style}
				help={hideHelp ? undefined : (touched && error)}
				validateStatus={(error && touched) ? 'error' : undefined}
			>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={cx({ search })}
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					style={ { width } }
					onChange={ onInputChange }
					{ ...rest }
				>
					{ options }
				</Select>
			</Item>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

CustomOptionsSelectField.propTypes = {
	name: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.any
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	width: PropTypes.string,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({}))
}

CustomOptionsSelectField.defaultProps = {
	name: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	width: '100%',
	multiple: false,
	options: []
}

export default CustomOptionsSelectField
