import {
	LOAD_USERS, LOAD_DETAIL_USER, LOAD_USER_JOBS
} from './types'
// eslint-disable-next-line import/no-cycle
import { IUserActions, IUserJobsPayload, IUserPayload, IUsersPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	users: {
		originalData: [],
		tableData: [],
		pagination: null,
		usersOptions: [],
		userPermissions: [],
		isLoading: false,
		isFailure: false
	} as IUsersPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IUserPayload & ILoadingAndFailure,
	jobs: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IUserJobsPayload & ILoadingAndFailure
}

export default (state = initState, action: IUserActions) => {
	switch (action.type) {
		case LOAD_USERS.START:
			return {
				...state,
				users: {
					...state.users,
					isFailure: false,
					isLoading: true
				}
			}
		case LOAD_USERS.DONE:
			return {
				...state,
				users: {
					...initState.users,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				}
			}
		case LOAD_USERS.FAILED:
			return {
				...state,
				users: {
					...initState.users,
					isFailure: true
				}
			}
		case LOAD_DETAIL_USER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IUserPayload & ILoadingAndFailure
			}
		case LOAD_DETAIL_USER.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IUserPayload & ILoadingAndFailure
			}
		case LOAD_DETAIL_USER.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IUserPayload & ILoadingAndFailure
			}
		case LOAD_USER_JOBS.START:
			return {
				...initState,
				...state,
				jobs: {
					...state.jobs,
					isLoading: true,
					isFailure: false
				} as IUserJobsPayload & ILoadingAndFailure
			}
		case LOAD_USER_JOBS.DONE:
			return {
				...initState,
				...state,
				jobs: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IUserJobsPayload & ILoadingAndFailure
			}
		case LOAD_USER_JOBS.FAILED:
			return {
				...state,
				jobs: {
					...state.jobs,
					isLoading: false,
					isFailure: true
				} as IUserJobsPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
