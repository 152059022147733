export enum FORM_TEMPLATE {
	FORM_TEMPLATE_LOAD_START = 'FORM_TEMPLATE_LOAD_START',
	FORM_TEMPLATE_LOAD_DONE = 'FORM_TEMPLATE_LOAD_DONE',
	FORM_TEMPLATE_LOAD_FAIL = 'FORM_TEMPLATE_LOAD_FAIL',
	FORM_TEMPLATE_CHANGE_BACKUP_STATUS ='FORM_TEMPLATE_CHANGE_BACKUP_STATUS'
}

export enum FORM_TEMPLATE_QUEUES {
	FORM_TEMPLATE_QUEUES_LOAD_START = 'FORM_TEMPLATE_QUEUES_LOAD_START',
	FORM_TEMPLATE_QUEUES_LOAD_DONE = 'FORM_TEMPLATE_QUEUES_LOAD_DONE',
	FORM_TEMPLATE_QUEUES_LOAD_FAIL = 'FORM_TEMPLATE_QUEUES_LOAD_FAIL'
}

export const FORM_EDIT_CHANGE = 'FORM_EDIT_CHANGE'
export const FORM_EDITED = 'FORM_EDITED'
export const RESET_FORM_TEMPLATE_STORE = 'RESET_FORM_TEMPLATE_STORE'
