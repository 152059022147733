import {
	LOAD_PRICE_OFFER, LOAD_SELECT_PRICE_OFFERS
} from './types'
// eslint-disable-next-line import/no-cycle
import { IPriceOfferActions, IPriceOfferPayload, IPriceOffersPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	priceOffers: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IPriceOffersPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IPriceOfferPayload & ILoadingAndFailure
}

export default (state = initState, action: IPriceOfferActions) => {
	switch (action.type) {
		case LOAD_SELECT_PRICE_OFFERS.START:
			return {
				...state,
				priceOffers: {
					...state.priceOffers,
					isFailure: false,
					isLoading: true
				} as IPriceOffersPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_PRICE_OFFERS.DONE:
			return {
				...state,
				priceOffers: {
					...initState.priceOffers,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IPriceOffersPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_PRICE_OFFERS.FAILED:
			return {
				...state,
				priceOffers: {
					...initState.priceOffers,
					isFailure: true
				} as IPriceOffersPayload & ILoadingAndFailure
			}
		case LOAD_PRICE_OFFER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IPriceOfferPayload & ILoadingAndFailure
			}
		case LOAD_PRICE_OFFER.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IPriceOfferPayload & ILoadingAndFailure
			}
		case LOAD_PRICE_OFFER.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IPriceOfferPayload & ILoadingAndFailure
			}
			// case CREATE_PRICE_OFFERS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_PRICE_OFFERS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_PRICE_OFFERS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}
			// case UPDATE_PRICE_OFFERS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_PRICE_OFFERS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_PRICE_OFFERS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}

		default:
			return state
	}
}
