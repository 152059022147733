import {
	LOAD_TRUSTEES
} from './types'
// eslint-disable-next-line import/no-cycle
import { ITrusteeActions, ITrusteePayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	trustees: {
		originalData: [],
		tableData: [],
		pagination: null,
		usersOptions: [],
		userPermissions: [],
		isLoading: false,
		isFailure: false
	} as ITrusteePayload & ILoadingAndFailure
}

export default (state = initState, action: ITrusteeActions) => {
	switch (action.type) {
		case LOAD_TRUSTEES.START:
			return {
				...state,
				trustees: {
					...state.trustees,
					isFailure: false,
					isLoading: true
				} as ITrusteePayload & ILoadingAndFailure
			}
		case LOAD_TRUSTEES.DONE:
			return {
				...state,
				trustees: {
					...initState.trustees,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as ITrusteePayload & ILoadingAndFailure
			}
		case LOAD_TRUSTEES.FAILED:
			return {
				...state,
				trustees: {
					...initState.trustees,
					isFailure: true
				} as ITrusteePayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
