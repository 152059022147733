import React, { useEffect } from 'react'
import cx from 'classnames'
import { Select, Tag } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { useDispatch, useSelector } from 'react-redux'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { RootState } from '../../../reducers'
import { getSharedStores, ISharedStore } from '../../../reducers/storeItemOrders/actions'
import { colorHash } from '../../storeItems/StoreItems'

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean
} & {
	supplySharedStore: ISharedStore | null,
	isSupplierCallingTheRequest: boolean | null,
}

const { Option } = Select

export function SharedStoreOption(option: ISharedStore, t: TFunction) {
	return <div className={'flex justify-between'}>
		<div>
			<span>{ option.supplyStoreName } {'   '}<Tag color={colorHash(option?.supplyOrganizationName || '')}>{ option.supplyOrganizationName }</Tag></span>
		</div>
		<span><b>{t('components:do')}</b></span>
		<div>
			<span>{ option.clientStoreName } <Tag color={colorHash(option?.clientOrganizationName || '')}>{ option.clientOrganizationName }</Tag></span>
		</div>
	</div>
}

const SharedStoreField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		meta: { touched, error },
		type,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		width,
		multiple,
		supplySharedStore,
		isSupplierCallingTheRequest,
		...rest
	} = props

	const dispatch: any = useDispatch()

	const { t } = useTranslation('pages')

	useEffect(() => {
		dispatch(getSharedStores((data) => {
			if (data.data.availableStores.length === 1) {
				input.onChange(data.data.availableStores[0].supplyStoreID)
			}
		}))
	}, [])

	const sharedStores = useSelector((state: RootState) => state.storeItemOrders.sharedStores)

	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<label className={ cx(customLabelClass, { required }) }>{label || t('components:Názov')}</label>
			<div className={ 'input-field min-w-full' }>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={ 'min-w-full' }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					filterOption={false}
					style={ { width } }
					onChange={ onInputChange }
					{ ...rest }
				>
					{
						(isSupplierCallingTheRequest && supplySharedStore)
							? <Option
								key={supplySharedStore.supplyStoreID || 0}
								value={supplySharedStore.supplyStoreID || 0}
							>
								{SharedStoreOption(supplySharedStore, t)}
							</Option>
							: sharedStores?.data?.availableStores?.length !== 0
							&& map(sharedStores.data?.availableStores, (option) => (
								<Option
									key={option.supplyStoreID || 0}
									value={option.supplyStoreID || 0}
								>
									{SharedStoreOption(option, t)}
								</Option>
							))
					}
				</Select>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

export default SharedStoreField
