import React, { FC, useEffect, useState } from 'react'
import { Button, Divider, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { useDispatch, useSelector } from 'react-redux'
// assets
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { history } from '../../../utils/history'
import { ReactComponent as CloseIcon } from '../../../assets/icons/BaseIcons/x-icon.svg'
import { getJobUnconsumedStocks, IUnconsumedStockResponse, postStoreItemsConsumeAllFromJob, postStoreItemsReturnToVirtualStoreFromJob, removeJob } from '../../../reducers/jobs/actions'
import VirtualStorePicker from '../../storeItems/components/VirtualStorePicker'
import { RootState } from '../../../reducers'
import { IUserOrganization } from '../../../reducers/stores/actions'

interface IStoreName {
	id: number,
	organizationID: number,
	name: string
}

type Props = ModalProps & {
	jobID: number,
	isVisible: boolean,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: (storeName: IStoreName) => void,
}

const DeleteJobModal: FC<Props> = (props) => {
	const {
		jobID,
		isVisible,
		onCancel,
		onSuccess
	} = props

	const { t } = useTranslation('pages')

	const dispatch: any = useDispatch()

	const profile = useSelector((state: RootState) => state.profile.profile.data)

	const [unconsumedStores, setUnconsumedStores] = useState({
		data: []
	} as IUnconsumedStockResponse)

	const [selectedStore, setSelectedStore] = useState({} as IUserOrganization | null)

	const [consumeAllFromJob, setConsumeAllFromJob] = useState(false)

	const hasUnconsumedStocks = unconsumedStores.data.length > 0

	useEffect(() => {
		if (isVisible) {
			dispatch(getJobUnconsumedStocks(jobID, (data) => {
				setUnconsumedStores(data)
			}))
		}
	}, [dispatch, isVisible, consumeAllFromJob])

	return <Modal
		closeIcon={<CloseIcon width={18} height={18} />}
		title={t('loc:Vymazať zákazku')}
		centered
		visible={isVisible}
		onCancel={onCancel}
		footer={[
			<Button
				style={{
					width: 118,
					height: 40
				}}
				key='back'
				type='text'
				onClick={(e) => {
					onCancel(e)
				}}
			>
				{t('loc:Zrušiť')}
			</Button>,
			<Button
				key='submit'
				type='primary'
				disabled={hasUnconsumedStocks}
				onClick={() => {
					Modal.confirm({
						title: t('pages:Skutočne si prajete odstrániť zákazku?'),
						icon: <DeleteOutlined />,
						okText: t('pages:Odstrániť'),
						cancelText: t('pages:Zrušiť'),
						okType: 'danger',
						onOk: async () => {
							await dispatch(removeJob(jobID, (data) => {
								history.push(t('paths:jobs|path'))
							}))
						}
					})
				}}
			>
				{t('loc:Vymazať zákazku')}
			</Button>
		]}
	>
		{ hasUnconsumedStocks && <div>
			<div>{t('loc:Na zákazke sa nachádza')}</div>
			{
				unconsumedStores.data.map((store) => <div className={'flex justify-between mt-2'} key={store.id}>
					<span className={'font-semibold'}>{store.name}</span>
					<span className={'font-semibold'}>{store.count}</span>
				</div>)
			}
			<div className={'flex justify-start mt-4'}>
				<Button
					style={{
						width: 150,
						height: 40
					}}
					key='submit'
					type='primary'
					onClick={() => {
						dispatch(postStoreItemsConsumeAllFromJob(jobID, () => {
							setConsumeAllFromJob(true)
						}))
					}}
				>
					{t('loc:Vyradiť položky')}
				</Button>
			</div>
			<span className={'w-full flex justify-center m-2 font-semibold'}>{t('loc:alebo')}</span>
			<div className={'flex justify-between  mt-2'}>
				<Button
					style={{
						width: 150,
						height: 40
					}}
					key='submit'
					type='primary'
					disabled={selectedStore?.clientStoreID === undefined || selectedStore.clientStoreID == null}
					onClick={() => {
						if (selectedStore?.clientStoreID !== undefined && selectedStore.clientStoreID != null) {
							dispatch(postStoreItemsReturnToVirtualStoreFromJob(jobID, selectedStore.clientStoreID, () => {
								setConsumeAllFromJob(true)
							}))
						}
					}}
				>
					{t('loc:Presunúť položky do')}
				</Button>
				{
					profile?.currentOrganization.id && <VirtualStorePicker
						organizationID={profile?.currentOrganization.id}
						organization={selectedStore}
						onSelectedStore={(value) => {
							setSelectedStore(value)
						}}
					/>
				}
			</div>
			<Divider />
		</div>
		}
	</Modal>
}

export default DeleteJobModal
