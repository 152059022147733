import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq } from '../../utils/request'
import { CREATE_FINISH, DELETE_FINISH, LOAD_FINISHES } from './types'
// eslint-disable-next-line import/no-cycle
import { ITaskPayload } from '../tasks/actions'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'
import { translateFrequencyToSk } from '../../utils/translate'
// eslint-disable-next-line import/no-cycle
import { IUser, IUserJob, IUserRating, IUsersJobsTimeWorkedPayload, IUsersPayload } from '../usersU/actions'

export type IFinishActions = IResetStore
	| IGetFinishesAction

// tasks
export interface IGetFinishesAction {
	type: LOAD_FINISHES
	payload: IFinishesPayload
}

export interface IFinishesPayload {
	tableData: FinishTableItem[]
	originalData: FinishOriginalItem[]
	pagination: IResponsePagination | null
}

export type FinishTableItem = {
	key: number
	id: number
	executionForDayDate: string
	realDateOfExecution: string
	frequency: string
	address: string
	rating: number
	userName: string
}

export type FinishOriginalItem = {
	key: number
	id: number
	executionForDayDate: string
	realDateOfExecution: string
	frequency: string
	job: FinishJob
	rating: any
	user: FinishUser
}

export type FinishJob = {
	id: number
	address: string
	finish: any
	hasJobTasks: boolean
	isJobDone: boolean
	objectTrusteeEmail: string
	ownerEmail: string
	rating: number
}

export type FinishUser = {
	id: number
	email: string
	firstName: string
	lastName: string
	phone: string
	isConfirmed: boolean
	jobs: any
	role: string
}

export const getFinishes = (params: any): ThunkResult<Promise<ITaskPayload>> => async (dispatch) => {
	let payload = {} as IFinishesPayload
	try {
		dispatch({ type: LOAD_FINISHES.START })

		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/finishes', normalizeQueryParams)

		const originalData: FinishOriginalItem[] = get(data, 'finishes', [])
		const tableData: FinishTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			executionForDayDate: formatDateWithTime(column.executionForDayDate) || '-',
			realDateOfExecution: formatDateWithTime(column.realDateOfExecution) || '-',
			frequency: translateFrequencyToSk(column.frequency),
			address: column?.job?.address,
			rating: column?.rating?.rating,
			userName: `${column.user.firstName} ${column.user.lastName}`
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_FINISHES.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_FINISHES.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IFinishPayload {
	data: IFinish | null
}

export interface IFinish {
	id: number
	address: string
	finish: any
	hasJobTasks: boolean
	isJobDone: boolean
	objectTrusteeEmail: string
	ownerEmail: string
	rating: number
}

export const getFinishDetail = (
	id: number,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: IFinishPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	let payload = {} as IFinishPayload

	try {
		const { data } = await getReq(`/api/admin/finishes/${id}`)

		payload = {
			data
		}

		onSuccess(payload)
		return data
	} catch (error) {
		onFailure(error)
		return error
	}
}

export const deleteFinish = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: DELETE_FINISH.START
	})
	try {
		await deleteReq(`/api/admin/finishes/${id}`)

		dispatch({ type: DELETE_FINISH.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_FINISH.FAILED })
		onFailure(error)
		return error
	}
}

export interface ICreateFinishParams {
	executionForFrequency: string;
	executionForDayDate: string;
	userId: number;
	jobId: number;
}

export const createFinish = (
	params: ICreateFinishParams,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onSuccess: (data: ITaskPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<ITaskPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_FINISH.START
	})
	try {
		const { data } = await postReq('/api/admin/finishes/', undefined, params)

		dispatch({ type: CREATE_FINISH.DONE })
		onSuccess(get(data, 'data.id'))
		return data
	} catch (error) {
		dispatch({ type: CREATE_FINISH.FAILED })
		onFailure(error)
		return error
	}
}

export interface IFinishForm {
	id: number| null
}

export const updateFinish = (
	values: IFinishForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/document-category', null, normalizeQueryParams)

		onSuccess(get(data, 'data.id'))
		return true
	} catch (error) {
		onFailure(error)
		return false
	}
}
