import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Radio } from 'antd'

const RadioSelectField = ({
	input,
	label,
	showLabel,
	customClass,
	customLabelClass,
	meta: {
		touched,
		error
	},
	defaultValueKey,
	required,
	options
}) => {
	const handleChange = (e) => {
		input.onChange(e.target.value || '')
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			{ showLabel && <label className={ cx(customLabelClass, { required }) }>{ label }</label> }
			<div className={ 'input-field' }>
				<Radio.Group value={ input.value || defaultValueKey } onChange={ handleChange }>
					{ options }
				</Radio.Group>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

RadioSelectField.propTypes = {
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({
		value: PropTypes.any
	}).isRequired,
	required: PropTypes.bool,
	type: PropTypes.string,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	customInputClass: PropTypes.string,
	disabled: PropTypes.bool,
	suffix: PropTypes.string,
	onInput: PropTypes.func,
	onKeyUp: PropTypes.func,
	onBlur: PropTypes.func,
	min: PropTypes.number,
	width: PropTypes.string,
	multiple: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.shape({}))
}

RadioSelectField.defaultProps = {
	name: null,
	defaultValue: null,
	showLabel: true,
	type: 'text',
	placeholder: '',
	suffix: null,
	label: '',
	required: false,
	disabled: false,
	customClass: null,
	customLabelClass: null,
	customInputClass: null,
	onInput: null,
	onBlur: null,
	onKeyUp: null,
	min: 0,
	width: '100%',
	multiple: false,
	options: []
}

export default RadioSelectField
