import i18next from 'i18next'
import { emailRegEx } from '../../../utils/regex'

export default (values: any) => {
	const errors: any = {}

	if (values.firstName && !(values.firstName.length >= 2 && values.firstName.length <= 20)) {
		errors.firstName = i18next.t('pages:Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (values.lastName && !(values.lastName.length >= 2 && values.lastName.length <= 20)) {
		errors.lastName = i18next.t('pages:Meno musí mať min 2 znaky a max 20 znakov')
	}

	if (values.email && !emailRegEx.test(values.email)) {
		errors.email = i18next.t('pages:Email nie je platný')
	}

	return errors
}
