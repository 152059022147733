import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Spin } from 'antd'
import PropTypes from 'prop-types'
import {
	Bar,
	BarChart,
	CartesianGrid, Tooltip, XAxis, YAxis
} from 'recharts'
import { getFinishesPerMonthStats } from '../../reducers/statistics/actions'
import { RootState } from '../../reducers'
import { SECONDARY_COLOR } from '../useFrequenciesPicker'

export const rateColor = (rating: number) => (rating < 3 ? '#ff4d4f' : '#ffc53d')

// https://recharts.org/en-US/examples/SimpleBarChart

// @ts-ignore
const FinishesPerMonth = () => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const statistics = useSelector((state: RootState) => state.statistics.statistics)
	const data = statistics.finishesPerMonth
	const isLoading = statistics.isLoading || false

	useEffect(() => {
		dispatch(getFinishesPerMonthStats())
	}, [dispatch])

	return (
		<>
			<Card
				hoverable
				style={{ inlineSize: 'min-content' }}
				className={'my-2'}
			>
				<Spin spinning={isLoading}>
					<div className={'font-bold mb-2'}>{t('pages:Počet Uprataní')}</div>
					<BarChart
						width={500}
						height={200}
						data={data}
						margin={{
							top: 5, right: 0, left: 0, bottom: 25
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="date" />
						<YAxis orientation={'right'}/>
						<Tooltip />
						<Bar type="monotone" name={t('pages:Počet Uprataní')} dataKey="count" stroke={SECONDARY_COLOR} fill={SECONDARY_COLOR} yAxisId={0} />
					</BarChart>
				</Spin>
			</Card>
		</>
	)
}

FinishesPerMonth.propTypes = {
	id: PropTypes.number
}

export default FinishesPerMonth
