import React, { FC } from 'react'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalProps } from 'antd/lib/modal'
import { Button, Modal, Table, Tag } from 'antd'
// eslint-disable-next-line import/no-cycle
import { addAccessForOrganization, IUserPermission, removeAccessForOrganization } from '../../../reducers/usersU/actions'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../reducers'
import { history } from '../../../utils/history'

type Props = ModalProps & {
	userID: number,
	userPermissions: IUserPermission[] | null | undefined,
	onRefresh: () => void
}

const UserPermissions: FC<Props> = (props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const profile = useSelector((state: RootState) => state.profile.profile.data)

	const [isAddAccessModalVisible, setAddAccessModalVisible] = React.useState(false)
	const [isRemoveAccessModalVisible, setRemoveAccessModalVisible] = React.useState(false)

	const [selectedOrganization, setSelectedOrganization] = React.useState<number | null>(null)

	const {
		userPermissions,
		userID,
		onRefresh
	} = props

	// @ts-ignore
	const columns: ColumnsType<IUserPermission> = [
		{
			title: i18next.t('pages:Address'),
			dataIndex: 'name',
			key: 'name',
			render(value: any) {
				return <b>{value}</b>
			}
		},
		{
			title: i18next.t('pages:Prístup'),
			dataIndex: 'userIsActiveInOrganization',
			key: 'userIsActiveInOrganization',
			render(value: any) {
				return <b>{value ? <Tag color='green'>{t('pages:Áno')}</Tag> : <Tag color='red'>{t('pages:Nie')}</Tag>}</b>
			}
		},
		{
			title: '',
			dataIndex: 'adminCanAddUserToOrganization',
			key: 'adminCanAddUserToOrganization',
			render(value: any, data) {
				if (value) {
					return <div>{data?.userIsActiveInOrganization
						? <Button
							onClick={() => { setRemoveAccessModalVisible(true); setSelectedOrganization(data.organizationID) }}
						>{t('pages:Odobrať prístup')}</Button>
						: <Button
							onClick={() => { setAddAccessModalVisible(true); setSelectedOrganization(data.organizationID) }}
						>{t('pages:Priradiť prístup')}</Button>}</div>
				}
				return <div></div>
			}
		}
	]

	return (
		userPermissions ? <div>
			<h1>{t('pages:Práva & prístupy')}</h1>
			{<Table
				className={'general-table mt-6'}
				pagination={false}
				dataSource={userPermissions}
				columns={columns}
			/>}
			<Modal
				title={`${t('pages:Priradiť prístup ')} ${t('pages:do organizácie ')} ${userPermissions.find((permission) => permission.organizationID === selectedOrganization)?.name || ''}`}
				visible={isAddAccessModalVisible}
				onOk={() => {
					if (selectedOrganization !== null) {
						dispatch(addAccessForOrganization(
							userID,
							selectedOrganization,
							() => { onRefresh(); setAddAccessModalVisible(false) },
							((error) => { console.log(error); setAddAccessModalVisible(false) })
						))
					}
				}}
				onCancel={() => {
					setAddAccessModalVisible(false)
				}}
			/>
			<Modal
				title={`${t('pages:Odobrať prístup ')} ${t('pages:z organizácie ')} ${userPermissions.find((permission) => permission.organizationID === selectedOrganization)?.name || ''}`}
				visible={isRemoveAccessModalVisible}
				onOk={() => {
					if (selectedOrganization !== null) {
						dispatch(removeAccessForOrganization(
							userID,
							selectedOrganization,
							() => {
								onRefresh()
								setRemoveAccessModalVisible(false)
								if (profile?.currentOrganization?.id === selectedOrganization) { history.push(t('paths:users|path')) }
							},
							((error) => { console.log(error); setRemoveAccessModalVisible(false) })
						))
					}
				}}
				onCancel={() => { setRemoveAccessModalVisible(false) }}
			/>
		</div> : <div/>
	)
}

UserPermissions.propTypes = {}

export default UserPermissions
