import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import {
	deleteReq, getReq, postReq, putReq
} from '../../utils/request'
import {
	CREATE_STORE_ITEM_TRANSACTIONS, LOAD_STORE_ITEM_TRANSACTION, LOAD_SELECT_STORE_ITEM_TRANSACTIONS, UPDATE_STORE_ITEM_TRANSACTIONS, DELETE_STORE_ITEM_TRANSACTION
} from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload } from '../usersU/actions'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'
import { STORE_ITEM_TRANSACTION_TYPE } from '../../utils/enums'

export type IStoreItemTransactionActions = IResetStore
	| IGetStoreItemTransactionsActions
	| IGetStoreItemTransactionAction

// storeItemTransactions
export interface IGetStoreItemTransactionsActions {
	type: LOAD_SELECT_STORE_ITEM_TRANSACTIONS
	payload: IStoreItemTransactionsPayload
}

export interface IStoreItemTransactionsPayload {
	tableData: StoreItemTransactionTableItem[]
	originalData: StoreItemTransactionOriginalItem[]
	pagination: IResponsePagination | null
}

export type StoreItemTransactionTableItem = {
	key: number
	id: number
	createdAt: string
	name: string
	updatedAt: string
}

export type StoreItemTransactionOriginalItem = {
	id: number
	createdAt: string
	name: string
	updatedAt: string
}

export const getStoreItemTransactions = (
	page: number = 1,
	limit: number|null = 20,
	queryParams: any = {}
): ThunkResult<Promise<IStoreItemTransactionsPayload>> => async (dispatch) => {
	let payload = {} as IStoreItemTransactionsPayload
	try {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_TRANSACTIONS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/storeItemTransactions', normalizeQueryParams)

		const originalData: StoreItemTransactionOriginalItem[] = get(data, 'storeItemTransactions', [])
		const tableData: StoreItemTransactionTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			name: column.name,
			createdAt: formatDateWithTime(column.createdAt),
			updatedAt: formatDateWithTime(column.updatedAt)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_STORE_ITEM_TRANSACTIONS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_TRANSACTIONS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface ICreateStoreItemTransactionForm {
	amount: number,
	jobFromID: number,
	storeItemID: number,
	jobToID: number,
	type: STORE_ITEM_TRANSACTION_TYPE,
}

export const createStoreItemTransactions = (
	values: ICreateStoreItemTransactionForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_STORE_ITEM_TRANSACTIONS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/store-item-transactions', null, normalizeQueryParams)

		dispatch({ type: CREATE_STORE_ITEM_TRANSACTIONS.DONE })

		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_STORE_ITEM_TRANSACTIONS.FAILED })
		onFailure(error)
		return error
	}
}

// storeItemTransaction
interface IGetStoreItemTransactionAction {
	type: LOAD_STORE_ITEM_TRANSACTION
	payload: IStoreItemTransactionPayload
}

export interface IStoreItemTransactionPayload {
	data: IStoreItemTransactionDetail
}

export interface IStoreItemTransactionDetail {
	id: number
	name: string
	nameSlug: string
	createdAt: string
	updatedAt: string
	organizationID: number
}

export const getStoreItemTransaction = (
	id: number,
	onSuccess: (data: IStoreItemTransactionPayload) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<IStoreItemTransactionPayload>> => async (dispatch) => {
	let payload = {} as IStoreItemTransactionPayload
	dispatch({
		type: LOAD_STORE_ITEM_TRANSACTION.START
	})
	try {
		const { data } = await getReq(`/api/admin/storeItemTransactions/${id}`)

		payload = {
			data
		}

		dispatch({
			type: LOAD_STORE_ITEM_TRANSACTION.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_STORE_ITEM_TRANSACTION.FAILED
		})
		onFailure(error)
		return error
	}
}

export const deleteStoreItemTransaction = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
) : ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_STORE_ITEM_TRANSACTION.START })
	try {
		await deleteReq(`/api/admin/storeItemTransactions/${id}`)

		dispatch({ type: DELETE_STORE_ITEM_TRANSACTION.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_STORE_ITEM_TRANSACTION.FAILED })
		onFailure(error)
		return false
	}
}
