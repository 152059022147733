import React from 'react'
import PropTypes from 'prop-types'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import {
	Form, Button, Row, Col
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { green } from '@material-ui/core/colors'
import { BASE_MENU_TAB_PATHS, FORMS } from '../../../utils/enums'
import validateLoginForm from './validateLoginForm'
import TextField from '../../../atoms/form/TextField'
import { history } from '../../../utils/history'
import InputField from '../../../atoms/InputField'
import InputPasswordField from '../../../atoms/InputPasswordField'

interface ITextDescriptionForm {
}

type ComponentProps = {
	page?: BASE_MENU_TAB_PATHS,
}

type Props = InjectedFormProps<ITextDescriptionForm, ComponentProps> & ComponentProps

const StyledTextField = (props: any) => <TextField {...props} customInputClass={'loginTextField'}/>

const LoginForm = ({ invalid, pristine, handleSubmit }: Props) => {
	const { t } = useTranslation('components')

	const user = useSelector((state) => get(state, 'user'))

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row justify={'center'} className={'mt-2'} style={{ width: '100%' }}>
				<Col span={24}>
					<Field
						component={ InputField }
						label={<label style={{ color: 'white' }}> { t('components:Email') } </label> }
						placeholder={ t('components:Email') }
						name={ 'email' }
						type={ 'text' }
						value={'malobicky.m@gmail.com'}
						required
					/>
					<Field
						name={ 'password' }
						label={<label style={{ color: 'white' }}> {t('components:Heslo')} </label> }
						type={ 'password' }
						value={'umyjeme'}
						placeholder={ t('components:Heslo') }
						component={ InputPasswordField }
						labelCol={ { span: 4 } }
						wrapperCol={ { span: 20 } }
						style={{ color: green }}
						required
					/>
					<Form.Item>
						<Button type="link"
							style={{
								fontWeight: 'bold',
								padding: '0px'
							}}
							onClick={() => {
								history.push(`${t('paths:passwordReset|path')}`)
							}}
						>{ t('components:Zabudli ste heslo ?') }</Button>
						<p/>
						<Button
							type={ 'primary' }
							htmlType={ 'submit' }
							loading={ get(user, 'isLoading') }
							disabled={ invalid || pristine }
						>
							{ t('components:Prihlásiť sa') }
						</Button>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	)
}

LoginForm.propTypes = {
	invalid: PropTypes.bool.isRequired,
	pristine: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired
}

export default reduxForm({
	form: FORMS.LOGIN_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateLoginForm
})(LoginForm)
