export enum LOAD_SELECT_PRICE_OFFERS {
	START= 'LOAD_SELECT_PRICE_OFFERS_START',
	DONE= 'LOAD_SELECT_PRICE_OFFERS_DONE',
	FAILED= 'LOAD_SELECT_PRICE_OFFERS_FAILED'
}

export enum LOAD_PRICE_OFFER {
	START= 'LOAD_PRICE_OFFER_START',
	DONE= 'LOAD_PRICE_OFFER_DONE',
	FAILED= 'LOAD_PRICE_OFFER_FAILED'
}

export enum CREATE_PRICE_OFFERS {
	START= 'CREATE_PRICE_OFFERS_START',
	DONE= 'CREATE_PRICE_OFFERS_DONE',
	FAILED= 'CREATE_PRICE_OFFERS_FAILED'
}

export enum UPDATE_PRICE_OFFERS {
	START= 'UPDATE_PRICE_OFFERS_START',
	DONE= 'UPDATE_PRICE_OFFERS_DONE',
	FAILED= 'UPDATE_PRICE_OFFERS_FAILED'
}

export enum DELETE_PRICE_OFFER {
	START= 'DELETE_PRICE_OFFER_START',
	DONE= 'DELETE_PRICE_OFFER_DONE',
	FAILED= 'DELETE_PRICE_OFFER_FAILED'
}
