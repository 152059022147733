import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import { Popover, Select, Tag } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../reducers'
import { getStoreItems } from '../../../reducers/storeItems/actions'

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean
} & {
	filterJobID?: number,
}

const { Option } = Select

const StoreItemSelectField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		meta: { touched, error },
		type,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		width,
		multiple,
		filterJobID,
		...rest
	} = props

	const dispatch: any = useDispatch()

	const { t } = useTranslation('pages')

	const [query, setQuery] = useState({
		search: '',
		orderBy: 'name',
		orderDirection: 'asc'
	})

	useEffect(() => {
		if (Number.isInteger(filterJobID)) {
			dispatch(getStoreItems(1, 100, {
				search: query.search,
				jobID: filterJobID,
				orderBy: query.orderBy,
				orderDirection: query.orderDirection
			}))
		}
	}, [dispatch,
		query.search, query.orderBy, query.orderDirection])

	const storeItems = useSelector((state: RootState) => state.storeItems.storeItems)

	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({
		...query,
		search: searchTerm
	}), 300), [query])

	const onSearch = (searchTerm: string) => {
		debounced(searchTerm)
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<label className={ cx(customLabelClass, { required }) }>{label || t('components:Názov')}</label>
			<div className={ 'input-field min-w-full' }>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={ 'min-w-full' }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					filterOption={false}
					style={ { width } }
					onChange={ onInputChange }
					onSearch={ onSearch }
					allowClear
					showSearch
					{ ...rest }
				>
					{storeItems?.tableData?.length !== 0
					&& map(storeItems?.tableData, (option) => (
						<Option
							key={ option.key || 0 }
							value={ option.key || 0 }
						>
							<div className={'flex justify-between'}>
								<div>{ option.name }</div>
								<span>
									<Popover content={<span>
										{t('components:Na sklade v ')}
										<b>{ option.organizationName }</b>
										{t('components: je ')}
										<b>{ option.count }</b>
										{t('components: kusov ')}
									</span>}>
										<Tag color='gold'>{t('components:Na sklade ')}{ option.count }</Tag>
									</Popover>
								</span>
							</div>
						</Option>
					))
					}
				</Select>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

export default StoreItemSelectField
