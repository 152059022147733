export enum LOAD_TRUSTEES {
	START= 'LOAD_TRUSTEES_START',
	DONE= 'LOAD_TRUSTEES_DONE',
	FAILED= 'LOAD_TRUSTEES_FAILED'
}

export enum LOAD_DETAIL_TRUSTEE {
	START= 'LOAD_DETAIL_TRUSTEE_START',
	DONE= 'LOAD_DETAIL_TRUSTEE_DONE',
	FAILED= 'LOAD_DETAIL_TRUSTEE_FAILED'
}

export enum DELETE_TRUSTEE {
	START= 'DELETE_TRUSTEE_START',
	DONE= 'DELETE_TRUSTEE_DONE',
	FAILED= 'DELETE_TRUSTEE_FAILED'
}

export enum CREATE_TRUSTEE {
	START= 'CREATE_TRUSTEE_START',
	DONE= 'CREATE_TRUSTEE_DONE',
	FAILED= 'CREATE_TRUSTEE_FAILED'
}

export enum UPDATE_TRUSTEE {
	START= 'UPDATE_TRUSTEE_START',
	DONE= 'UPDATE_TRUSTEE_DONE',
	FAILED= 'UPDATE_TRUSTEE_FAILED'
}
