import React from 'react'
import { Avatar } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import styled from 'styled-components'

const ClickArea = styled.span`
	cursor: pointer;
`

// eslint-disable-next-line import/prefer-default-export
export const DeleteIcon = ({ onClick }) => (
	<ClickArea onClick={onClick}>
		<Avatar icon={<DeleteFilled style={{ color: '#5c0011' }} />} />
	</ClickArea>
)
