import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import StoreItemForm from './forms/StoreItemForm'
import { createStoreItems, getStoreItem, IStoreItemForm, updateStoreItem } from '../../reducers/storeItems/actions'
import { IComputedMatch } from '../../types/interfaces'
import { RootState } from '../../reducers'

type Props = {
    computedMatch: IComputedMatch<{ id: number }>
}

const StoreItemDetail = (props: Props) => {
	const { computedMatch } = props
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state: RootState) => state.storeItems.storeItems)

	const isLoading = detail.isLoading || false

	const [id, setID] = useState<number | null>(computedMatch.params.id)

	const initEmptyDetailForm = () => {
		const initValues: IStoreItemForm = {
			id: 0,
			name: '',
			nameSlug: '',
			isLiquid: false,
			isReplaceable: false,
			idealStockPercentage: 0,
			activeJobs: 0,
			allAvailableStock: 0,
			updatedAt: '-',
			amountOfStockInAddresses: []
		}

		dispatch(initialize(FORMS.STORE_ITEM_FORM, initValues))
	}

	const initDetailForm = (data: IStoreItemForm) => {
		dispatch(initialize(FORMS.STORE_ITEM_FORM, data))
	}

	useEffect(() => {
		if (id) {
			dispatch(
				getStoreItem(id, (data) => {
					const initValues: IStoreItemForm = {
						id: data.data.id,
						isLiquid: data.data.isLiquid,
						isReplaceable: data.data.isReplaceable,
						updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',
						allAvailableStock: data.data.allAvailableStock,
						activeJobs: data.data.activeJobs,
						idealStockPercentage: data.data.idealStockPercentage,
						amountOfStockInAddresses: data.data.amountOfStockInAddresses,
						nameSlug: data.data.nameSlug,
						name: data.data.name
					}

					initDetailForm(initValues)
				})
			)
		} else {
			initEmptyDetailForm()
		}
	}, [dispatch, id])

	const handleCreate = (body: IStoreItemForm) => {
		dispatch(
			createStoreItems(body, (StoreItemId) => {
				history.replace(`${t('paths:storeItemDetail|path')}/${StoreItemId}`)
				setID(StoreItemId)
				window.location.reload()
			})
		)
	}

	const handleUpdate = (body: IStoreItemForm) => {
		dispatch(
			updateStoreItem(id!, body, () => {
				dispatch(
					getStoreItem(id!, (data) => {
						const initData: IStoreItemForm = {
							id: data.data.id,
							updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',
							name: data.data.name,
							isLiquid: data.data.isLiquid,
							isReplaceable: data.data.isReplaceable,
							activeJobs: data.data.activeJobs,
							idealStockPercentage: data.data.idealStockPercentage,
							allAvailableStock: data.data.allAvailableStock,
							amountOfStockInAddresses: data.data.amountOfStockInAddresses,
							nameSlug: data.data.nameSlug
						}
						initDetailForm(initData)
					})
				)
			})
		)
	}

	const handleSubmit = (values: IStoreItemForm) => {
		if (id) {
			handleUpdate(values)
		} else {
			handleCreate(values)
		}
	}

	return (
		<div className='page-wrapper'>
			<Spin spinning={ isLoading }>
				<StoreItemForm
					onSubmit={handleSubmit}
					isCreate={!id}
					id={id!} />
			</Spin>
		</div>
	)
}

export default StoreItemDetail
