import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import {
	getReq, postReq
} from '../../utils/request'
import {
	LOAD_DOCUMENT_CATEGORIES
} from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResponsePagination } from '../../types/interfaces'
import { CREATE_DOCUMENTS } from '../documents/types'

export type IDocumentCategoryActions = IResetStore
	| IGetDocumentCategoriesAction

// documents
export interface IGetDocumentCategoriesAction {
	type: LOAD_DOCUMENT_CATEGORIES
	payload: IDocumentCategoryPayload
}

export interface IDocumentCategoryPayload {
	tableData: CategoryDocumentTableItem[]
	originalData: DocumentOriginItem[]
	pagination: IResponsePagination | null
}

export type CategoryDocumentTableItem = {
	key: number | null
	id: number | null
	name: string
}

export type DocumentOriginItem = {
	id: number
	name: string
}

export const getCategoryDocuments = (
	params: any = {}
): ThunkResult<Promise<IDocumentCategoryPayload>> => async (dispatch) => {
	let payload = {} as IDocumentCategoryPayload
	try {
		dispatch({ type: LOAD_DOCUMENT_CATEGORIES.START })

		const queries = {
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/document-category', normalizeQueryParams)

		const originalData: DocumentOriginItem[] = get(data, 'documentCategories', [])
		const tableData: CategoryDocumentTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			name: column.name
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_DOCUMENT_CATEGORIES.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_DOCUMENT_CATEGORIES.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IDocumentCategoryForm {
	id: number| null
	name: string
}

export const createCategoryDocument = (
	values: IDocumentCategoryForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: CREATE_DOCUMENTS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/document-category', null, normalizeQueryParams)

		dispatch({ type: CREATE_DOCUMENTS.DONE })
		onSuccess(get(data, 'data.id'))
		return true
	} catch (error) {
		dispatch({ type: CREATE_DOCUMENTS.FAILED })
		onFailure(error)
		return false
	}
}
