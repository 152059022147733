import React, { FC, useEffect } from 'react'
import { Button, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal'

import { initialize, submit } from 'redux-form'
import { useDispatch } from 'react-redux'
// assets
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../assets/icons/BaseIcons/x-icon.svg'
import { FORMS, STORE_ITEM_TRANSACTION_TYPE, translateStoreItemTitleTransactionTypeToSk } from '../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import CreateStoreItemTransactionForm from '../forms/CreateStoreItemTransactionForm'
// eslint-disable-next-line import/no-cycle
import { createStoreItemTransactions, ICreateStoreItemTransactionForm } from '../../../reducers/storeItemTransactions/actions'

// eslint-disable-next-line import/no-cycle

export interface CreateTransactionProps {
	amount: number,
	type: STORE_ITEM_TRANSACTION_TYPE,
	storeItemName: string,
	storeItemID: number,
	jobToID: number | null | undefined,
}

type Props = ModalProps & {
	isVisible: boolean,
	transaction: CreateTransactionProps | null,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: Function,
	classNameBtn?: string
}

const CreateStoreItemTransactionModal: FC<Props> = (props) => {
	const {
		isVisible,
		onCancel,
		onSuccess,
		transaction,
		width,
		classNameBtn
	} = props

	const { t } = useTranslation('pages')
	const [transactionType, setTransactionType] = React.useState<STORE_ITEM_TRANSACTION_TYPE>(STORE_ITEM_TRANSACTION_TYPE.REGULAR)

	const dispatch: any = useDispatch()

	useEffect(() => {
		dispatch(initialize(FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM, {
			...transaction
		}))
	}, [dispatch, isVisible])

	const handleCreate = (body: ICreateStoreItemTransactionForm) => {
		dispatch(createStoreItemTransactions(body, () => {
			onSuccess()
			dispatch(initialize(FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM, {
				...transaction
			}))
		}))
	}

	const handleSubmit = (values:ICreateStoreItemTransactionForm) => {
		const body: ICreateStoreItemTransactionForm = {
			amount: values?.amount,
			type: values.type,
			storeItemID: values.storeItemID,
			jobFromID: values.jobFromID,
			jobToID: values.jobToID
		}

		handleCreate({
			...body
		})
	}

	return 	<Modal
		closeIcon={<CloseIcon width={18} height={18}/>}
		title={
			<span>{translateStoreItemTitleTransactionTypeToSk(transactionType)} : {transaction?.storeItemName}   <span style={{ fontSize: '0.5rem' }}>({transaction?.storeItemID})</span></span>
		}
		centered
		visible={isVisible}
		width={width}
		onCancel={onCancel}
		footer={[
			<Button style={{ width: 118, height: 40 }} key='back' type='text' onClick={onCancel}>
				{t('loc:Zrušiť')}
			</Button>,
			<Button style={{ width: 118, height: 40 }} className={classNameBtn || ''} key='submit' type='primary' onClick={() => dispatch(submit(FORMS.CREATE_STORE_ITEM_TRANSACTION_FORM))}>
				{t('loc:Vytvoriť')}
			</Button>
		]}
	>
		<div>
			<CreateStoreItemTransactionForm
				onSubmit={ handleSubmit }
				isCreate={ false }
				onTransactionTypeChanged={ (type) => { setTransactionType(type) } }
			/>
		</div>
	</Modal>
}

export default CreateStoreItemTransactionModal
