import {
	LOAD_SELECT_DOCUMENTS
} from './types'
import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IDocumentActions, IDocumentsPayload } from './actions'

export const initState = {
	documents: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IDocumentsPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: IDocumentActions) => {
	switch (action.type) {
		case LOAD_SELECT_DOCUMENTS.START:
			return {
				...state,
				documents: {
					...state.documents,
					isFailure: false,
					isLoading: true
				} as IDocumentsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_DOCUMENTS.DONE:
			return {
				...state,
				documents: {
					...initState.documents,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IDocumentsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_DOCUMENTS.FAILED:
			return {
				...state,
				documents: {
					...initState.documents,
					isFailure: true
				} as IDocumentsPayload & ILoadingAndFailure
			}
		// case CREATE_DOCUMENTS.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_DOCUMENTS.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case CREATE_DOCUMENTS.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case UPDATE_DOCUMENTS.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_DOCUMENTS.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case UPDATE_DOCUMENTS.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		// case LOAD_DOCUMENT.START:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: true,
		// 			isFailure: false
		// 		}
		// 	}
		// case LOAD_DOCUMENT.DONE:
		// 	return {
		// 		...initState,
		// 		...state,
		// 		detail: {
		// 			data: action.payload,
		// 			isLoading: false,
		// 			isFailure: false
		// 		}
		// 	}
		// case LOAD_DOCUMENT.FAILED:
		// 	return {
		// 		...state,
		// 		detail: {
		// 			...state.detail,
		// 			isLoading: false,
		// 			isFailure: true
		// 		}
		// 	}
		default:
			return state
	}
}
