import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/**
 * @return {null}
 */

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`

function TableHeaderComponent(props) {
	const { left, right, children } = props
	if (!left || !right) {
		return null
	}
	return (
		<Container>
			<Header>
				{left && <span>{left}</span>}
				{right && <span>{right}</span>}
			</Header>
			<Content>
				{children}
			</Content>
		</Container>
	)
}

// eslint-disable-next-line import/prefer-default-export
export const TableHeader = TableHeaderComponent

TableHeader.defaultProps = {}

TableHeader.propTypes = {
	left: PropTypes.string,
	right: PropTypes.string,
	children: PropTypes.node
}
