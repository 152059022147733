import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { CREATE_STORE_ITEM_ORDERS, DELETE_STORE_ITEM_ORDER, LOAD_SELECT_STORE_ITEM_ORDERS, LOAD_SHARED_STORES_ORDER, LOAD_STORE_ITEM_ORDER, UPDATE_STORE_ITEM_ORDERS } from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
// eslint-disable-next-line import/no-cycle
import { IUsersPayload } from '../usersU/actions'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'

export type IStoreItemOrderActions = IResetStore
	| IGetStoreItemOrdersActions
	| IGetStoreItemOrderAction
	| IGetSharedStoreAction

// storeItemOrders
export interface IGetStoreItemOrdersActions {
	type: LOAD_SELECT_STORE_ITEM_ORDERS
	payload: IStoreItemOrdersPayload
}

export interface IStoreItemOrdersPayload {
	tableData: StoreItemOrderTableItem[]
	notExecutedOrders: number | null
	originalData: StoreItemOrderOriginalItemOrder[]
	pagination: IResponsePagination | null
}

export type StoreItemOrderTableItem = {
	key: number
	id: number
	jobFrom: StoreItemOrderJob
	jobTo: StoreItemOrderJob
	amount: number
	storeItemName: string
	orderExecuted: boolean
	orderExecutedDate: string
	orderExecutedTransactionID: number
	createdAt: string
}

export type StoreItemOrderJob = {
	id: number,
	name: string,
}

export type StoreItemOrderOriginalItemOrder = {
	id: number
	jobFrom: StoreItemOrderJob
	jobTo: StoreItemOrderJob
	amount: number
	storeItemName: string
	orderExecuted: boolean
	orderExecutedDate: string
	orderExecutedTransactionID: number
	createdAt: string
}

export interface IGetStoreItemOrderParams{
	limit: number | null
	page: number | null
	queryParams: any
}

export const getStoreItemOrders = (
	params: IGetStoreItemOrderParams
): ThunkResult<Promise<IStoreItemOrdersPayload>> => async (dispatch) => {
	const {
		limit,
		page,
		queryParams
	} = params

	let payload = {} as IStoreItemOrdersPayload
	try {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_ORDERS.START })

		const queries = {
			limit,
			page,
			...queryParams
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/store-item-orders', normalizeQueryParams)

		const originalData: StoreItemOrderOriginalItemOrder[] = get(data, 'storeItemOrders', [])
		const tableData: StoreItemOrderTableItem[] = map(originalData, (column) => ({
			key: column.id,
			id: column.id,
			jobFrom: column.jobFrom,
			jobTo: column.jobTo,
			amount: column.amount,
			storeItemName: column.storeItemName,
			orderExecuted: column.orderExecuted,
			orderExecutedTransactionID: column.orderExecutedTransactionID,
			orderExecutedDate: formatDateWithTime(column.orderExecutedDate),
			createdAt: formatDateWithTime(column.createdAt)
		}))

		payload = {
			tableData,
			originalData,
			notExecutedOrders: get(data, 'context.notExecutedOrders'),
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_STORE_ITEM_ORDERS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_STORE_ITEM_ORDERS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface IStoreItemOrder {
	amount: number | null
	orderExecuted: boolean | null
	comment: string | null
	storeItemID: number | null
}

export interface IStoreItemOrderForm {
	supplyStoreID: number | null,
	supplySharedStore: ISharedStore | null,
	isSupplierCallingTheRequest: boolean | null,
	orders: IStoreItemOrder[]
}

export const createStoreItemOrders = (
	values: IStoreItemOrderForm,
	onSuccess: (data: any) => void = ((data) => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<IUsersPayload>> => async (dispatch) => {
	dispatch({
		type: CREATE_STORE_ITEM_ORDERS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/store-item-orders', null, normalizeQueryParams)

		dispatch({ type: CREATE_STORE_ITEM_ORDERS.DONE })

		onSuccess(get(data, 'data.id'))
		return get(data, 'data.id')
	} catch (error) {
		dispatch({ type: CREATE_STORE_ITEM_ORDERS.FAILED })
		onFailure(error)
		return error
	}
}

export const updateStoreItemOrder = (
	id: number,
	values: IStoreItemOrderForm,
	onSuccess: () => void = (() => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_STORE_ITEM_ORDERS.START
	})
	try {
		await putReq(`/api/admin/store-item-orders/${id}`, null, values)
		dispatch({ type: UPDATE_STORE_ITEM_ORDERS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_STORE_ITEM_ORDERS.FAILED })
		onFailure(error)
		return error
	}
}

// storeItemOrder
interface IGetStoreItemOrderAction {
	type: LOAD_STORE_ITEM_ORDER
	payload: IStoreItemOrderPayload
}

interface IGetSharedStoreAction {
	type: LOAD_SHARED_STORES_ORDER
	payload: ISharedStorePayload
}

export interface IStoreItemOrderPayload {
	data: IStoreItemOrderDetail
}

export interface IStoreItemOrderDetail {
	supplyStoreID: number | null,
	orders: IStoreItemOrder[],
	supplySharedStore: ISharedStore | null,
	isSupplierCallingTheRequest: boolean | null,
}

export const getStoreItemOrder = (
	id: number,
	onSuccess: (data: IStoreItemOrderPayload) => void = ((data) => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<IStoreItemOrderPayload>> => async (dispatch) => {
	let payload = {} as IStoreItemOrderPayload
	dispatch({
		type: LOAD_STORE_ITEM_ORDER.START
	})
	try {
		const { data } = await getReq(`/api/admin/store-item-orders/${id}`)

		payload = {
			data
		}

		dispatch({
			type: LOAD_STORE_ITEM_ORDER.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_STORE_ITEM_ORDER.FAILED
		})
		onFailure(error)
		return error
	}
}

export interface ISharedStoreDetail {
	availableStores: ISharedStore[]
}

export interface ISharedStorePayload {
	data: ISharedStoreDetail
}

export interface ISharedStore {
	supplyStoreID: number,
	supplyStoreName: string,
	supplyOrganizationName: string,
	clientStoreID: number,
	clientStoreName: string
	clientOrganizationName: string
}

export const getSharedStores = (
	onSuccess: (data: ISharedStorePayload) => void = ((data) => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<ISharedStorePayload>> => async (dispatch) => {
	let payload = {} as ISharedStorePayload
	dispatch({
		type: LOAD_SHARED_STORES_ORDER.START
	})
	try {
		const { data } = await getReq('/api/admin/store-item-orders/shared-stores')

		payload = {
			data
		}

		dispatch({
			type: LOAD_SHARED_STORES_ORDER.DONE,
			payload
		})

		onSuccess(payload)
		return payload
	} catch (error) {
		dispatch({
			type: LOAD_SHARED_STORES_ORDER.FAILED
		})
		onFailure(error)
		return error
	}
}

export const deleteStoreItemOrder = (
	id: number,
	onSuccess: () => void = (() => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_STORE_ITEM_ORDER.START })
	try {
		await deleteReq(`/api/admin/store-item-orders/${id}`)

		dispatch({ type: DELETE_STORE_ITEM_ORDER.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_STORE_ITEM_ORDER.FAILED })
		onFailure(error)
		return false
	}
}

export const postExecuteStoreItemOrder = (
	id: number,
	onSuccess: () => void = (() => {
	}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {
	})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({ type: DELETE_STORE_ITEM_ORDER.START })
	try {
		await postReq(`/api/admin/store-item-orders/${id}/execute`)

		dispatch({ type: DELETE_STORE_ITEM_ORDER.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_STORE_ITEM_ORDER.FAILED })
		onFailure(error)
		return false
	}
}
