import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get } from 'lodash'
import { Modal, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { history } from '../../utils/history'

import { IComputedMatch } from '../../types/interfaces'
import { RootState } from '../../reducers'
import {
	createStoreItemOrders, deleteStoreItemOrder, getStoreItemOrder, IStoreItemOrderForm, postExecuteStoreItemOrder, updateStoreItemOrder
} from '../../reducers/storeItemOrders/actions'
import { FORMS } from '../../utils/enums'
import StoreItemOrderForm from './forms/StoreItemOrderForm'

type Props = {
	computedMatch: IComputedMatch<{ id: number }>
}

const StoreItemOrderDetail = (props: Props) => {
	const { computedMatch } = props
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state: RootState) => state.storeItemOrders.detail)

	const isLoading = detail.isLoading || false

	const { id } = computedMatch?.params

	const initEmptyDetailForm = () => {
		const initValues = {
			orders: [
				{
					amount: 0,
					comment: '',
					storeItemID: null
				}
			],
			supplyStoreID: null
		} as IStoreItemOrderForm

		dispatch(initialize(FORMS.STORE_ITEM_ORDER_FORM, initValues))
	}

	const initDetailForm = (data: IStoreItemOrderForm) => {
		dispatch(initialize(FORMS.STORE_ITEM_ORDER_FORM, data))
	}

	useEffect(() => {
		if (id) {
			dispatch(getStoreItemOrder(id, (data) => {
				const initValues: IStoreItemOrderForm = {
					orders: get(data.data, 'orders'),
					supplyStoreID: get(data.data, 'supplyStoreID'),
					isSupplierCallingTheRequest: get(data.data, 'isSupplierCallingTheRequest'),
					supplySharedStore: get(data.data, 'supplySharedStore')
				}
				initDetailForm(initValues)
			}))
		} else {
			initEmptyDetailForm()
		}
	}, [computedMatch])

	console.log(`proper${props.computedMatch.params.id}`)
	const handleCreate = (body: IStoreItemOrderForm) => {
		dispatch(createStoreItemOrders(body, (StoreId) => history.replace(`${t('paths:storeOrderDetail|path')}/${StoreId}`)))
	}

	const handleUpdate = (body: IStoreItemOrderForm) => {
		dispatch(updateStoreItemOrder(id, body, () => {
			dispatch(getStoreItemOrder(id, (data) => {
				// initDetailForm(data) todo
			}))
		}))
	}

	const handleRefresh = () => {
		dispatch(getStoreItemOrder(id, (data) => {
			// initDetailForm(data) todo
		}))
	}

	const handleRemove = (detailID: number) => {
		Modal.confirm({
			title: t('pages:Skutočne si prajete odstrániť virtuálny sklad?'),
			icon: <DeleteOutlined />,
			okText: t('pages:Odstrániť'),
			cancelText: t('pages:Zrušiť'),
			okType: 'danger',
			onOk: async () => {
				await dispatch(deleteStoreItemOrder(detailID, () => {
					history.push(t('paths:storeOrders|path'))
				}))
			}
		})
	}

	const handleAcceptOrder = (detailID: number) => {
		Modal.confirm({
			title: t('pages:Prajete si realizovať objednávku?'),
			icon: <DeleteOutlined />,
			okText: t('pages:Potvrdiť'),
			cancelText: t('pages:Zrušiť'),
			okType: 'danger',
			onOk: async () => {
				await dispatch(postExecuteStoreItemOrder(detailID, () => {
					history.push(t('paths:storeOrders|path'))
				}))
			}
		})
	}

	const handleSubmit = (values: IStoreItemOrderForm) => {
		const body = {
			orders: values.orders || [],
			supplyStoreID: values.supplyStoreID
		} as IStoreItemOrderForm

		if (id) {
			handleUpdate({
				...body
			})
		} else {
			handleCreate({
				...body
			})
		}
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<StoreItemOrderForm
					onSubmit={ handleSubmit }
					onRefresh={ handleRefresh }
					onRemove={ handleRemove }
					onAcceptOrder={ handleAcceptOrder }
					isCreate={ !id }
					id={id}
				/>
			</Spin>
		</div>
	)
}

export default StoreItemOrderDetail
