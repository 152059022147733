/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure } from '../../types/interfaces'
import {
	FORM_DOCS, FORM_DOC
} from './formDocsTypes'
import {
	FormDocsActions, IFormDocBranchPayload, IFormDocsPayload, IFormDocBranchesByFormDocIDPayload, IFormDocPayload, IFormDocBranchesPayload
} from './formDocsActions'

export const initState = {
	formDocs: {
		originalData: null,
		tableData: [],
		formDocsOptions: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IFormDocsPayload & ILoadingAndFailure,
	formDoc: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IFormDocPayload & ILoadingAndFailure,
	formDocBranch: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IFormDocBranchPayload & ILoadingAndFailure,
	formDocBranchesByFormDocID: {
		originalData: null,
		tableData: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IFormDocBranchesByFormDocIDPayload & ILoadingAndFailure,
	formDocBranches: {
		originalData: null,
		branchesOptions: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IFormDocBranchesPayload & ILoadingAndFailure
}

export default (state = initState, action: FormDocsActions) => {
	switch (action.type) {
		case FORM_DOCS.FORM_DOCS_LOAD_START:
			return {
				...state,
				formDocs: {
					...state.formDocs,
					isFailure: false,
					isLoading: true
				}
			}
		case FORM_DOCS.FORM_DOCS_LOAD_FAIL:
			return {
				...state,
				formDocs: {
					...initState.formDocs,
					isFailure: true
				}
			}
		case FORM_DOCS.FORM_DOCS_LOAD_DONE:
			return {
				...state,
				formDocs: {
					...initState.formDocs,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination,
					formDocsOptions: action.payload.formDocsOptions
				}
			}
		case FORM_DOC.FORM_DOC_LOAD_START:
			return {
				...state,
				formDoc: {
					...state.formDoc,
					isFailure: false,
					isLoading: true
				}
			}
		case FORM_DOC.FORM_DOC_LOAD_FAIL:
			return {
				...state,
				formDoc: {
					...initState.formDoc,
					isFailure: true
				}
			}
		case FORM_DOC.FORM_DOC_LOAD_DONE:
			return {
				...state,
				formDoc: {
					...initState.formDoc,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
