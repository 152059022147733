import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-cycle
import { FREQUENCIES, FrequencyI } from '../utils/enums'
// eslint-disable-next-line import/no-cycle
import { getFrequenciesStorage, setFrequenciesStorage } from '../utils/helper'

const PRIMARY_COLOR = '#6A6A6A'
export const SECONDARY_COLOR = '#f7931d'

const Container = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	border-left: none;
	align-items: flex-end;
`

interface BoxProps {
	readonly isActive: boolean;
}

const Box = styled.div<BoxProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	min-width: 40px;
	padding: 0 6px;
	height: 40px;
	border-left: 1px solid #d9d9d9;
	transition: background-color linear 0.2s, opacity linear 0.2s;
	background-color: ${(props) => (props.isActive ? SECONDARY_COLOR : PRIMARY_COLOR)};
	opacity: 0.8;
	color: #ffffff;
	&:hover {
		opacity: 1;
	}
	&:first-of-type {
		border-radius: 8px 0 0 8px;
	}
	&:last-of-type {
		border-radius: 0 8px 8px 0;
	}
`

const AllBox = styled(Box)<BoxProps>`
	width: auto;
	padding: 6px;
	border: none;
	&:only-child {
		border-radius: 8px;
	}
	margin-right: 6px;
`

const createInitState = (frequencies: any, value: boolean) => {
	const state = {}
	// @ts-ignore
	frequencies.forEach((frequency:FrequencyI) => { state[frequency.name] = value })
	setFrequenciesStorage(state)
	return state
}

const PickerComponent = () => {
	const { t } = useTranslation('components')

	const [state, setState] = useState(getFrequenciesStorage() || createInitState(FREQUENCIES, false))
	const isAllOn = Object.values(state).every((v) => v === true)
	const getFilter = useCallback(() => FREQUENCIES.map((frequency:FrequencyI) => state[frequency.name] && frequency.key).filter(Boolean), [state])
	const [filter, setFilter] = useState(getFilter())

	const debouncedSetFilter = useCallback(debounce((filter: any) => setFilter(filter), 1000), [filter])

	useEffect(() => {
		const filter = getFilter()
		debouncedSetFilter(filter)
	}, [state])

	const toggleCurrentBox = (name:string) => {
		const newState = { ...state }
		newState[name] = !newState[name]
		setFrequenciesStorage(newState)
		setState(newState)
	}

	const handleClick = (name:string) => {
		toggleCurrentBox(name)
	}

	const toggleAll = () => {
		const allOn = createInitState(FREQUENCIES, true)
		setFrequenciesStorage(allOn)
		setState(allOn)
	}

	return {
		filter,
		Picker: React.useCallback(() => <Container className={'mt-6'}>
			<span>
				<AllBox onClick={toggleAll} isActive={isAllOn}>{t('pages:Všetky')}</AllBox>
			</span>
			{FREQUENCIES.map(
				(frequency:FrequencyI) => <Box key={frequency.key} onClick={() => handleClick(frequency.name)} isActive={state[frequency.name]}>
					{frequency?.abbreviation}
				</Box>
			)
			}
		</Container>, [handleClick, isAllOn, state, t])
	}
}

// eslint-disable-next-line import/prefer-default-export
export const UsePicker = PickerComponent
