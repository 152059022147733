// eslint-disable-next-line import/no-cycle
import { ISharedStorePayload, IStoreItemOrderActions, IStoreItemOrderPayload, IStoreItemOrdersPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { LOAD_SELECT_STORE_ITEM_ORDERS, LOAD_SHARED_STORES_ORDER, LOAD_STORE_ITEM_ORDER } from './types'

export const initState = {
	list: {
		originalData: [],
		tableData: [],
		notExecutedOrders: 0,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IStoreItemOrdersPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IStoreItemOrderPayload & ILoadingAndFailure,
	sharedStores: {
		data: {},
		isLoading: false,
		isFailure: false
	} as ISharedStorePayload & ILoadingAndFailure
}

export default (state = initState, action: IStoreItemOrderActions) => {
	switch (action.type) {
		case LOAD_SELECT_STORE_ITEM_ORDERS.START:
			return {
				...state,
				list: {
					...state.list,
					isFailure: false,
					isLoading: true
				} as IStoreItemOrdersPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEM_ORDERS.DONE:
			return {
				...state,
				list: {
					...initState.list,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination,
					notExecutedOrders: action.payload.notExecutedOrders
				} as IStoreItemOrdersPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEM_ORDERS.FAILED:
			return {
				...state,
				list: {
					...initState.list,
					isFailure: true
				} as IStoreItemOrdersPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_ORDER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IStoreItemOrderPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_ORDER.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IStoreItemOrderPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM_ORDER.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IStoreItemOrderPayload & ILoadingAndFailure
			}
		case LOAD_SHARED_STORES_ORDER.START:
			return {
				...initState,
				...state,
				sharedStores: {
					...state.sharedStores,
					isLoading: true,
					isFailure: false
				} as ISharedStorePayload & ILoadingAndFailure
			}
		case LOAD_SHARED_STORES_ORDER.DONE:
			return {
				...initState,
				...state,
				sharedStores: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as ISharedStorePayload & ILoadingAndFailure
			}
		case LOAD_SHARED_STORES_ORDER.FAILED:
			return {
				...state,
				sharedStores: {
					...state.sharedStores,
					isLoading: false,
					isFailure: true
				} as ISharedStorePayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
