import React, { useEffect } from 'react'
import { Button, Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-cycle
import { ITextPair } from './DragAndDropSelectedTextsField'

interface Props {
	textDetail: ITextPair | null,
	handleCancel: (e: React.MouseEvent<HTMLElement>) => void,
	handleOk: (string: string) => void,
}

function countMatchingStrings(str?: string): number {
	if (str === undefined) {
		return 0
	}
	const searchString = '{REPLACE_HERE}'
	let count = 0
	let index = str.indexOf(searchString)
	while (index !== -1) {
		count += 1
		index = str.indexOf(searchString, index + 1)
	}
	return count
}

function replaceText(str: string, replace1: string, replace2: string): string {
	return str.replace('{REPLACE_HERE}', `<b>${replace1}</b>`).replace('{REPLACE_HERE}', `<b>${replace2}</b>`)
}

const AddTextModal = ({
	textDetail,
	handleCancel,
	handleOk
}: Props) => {
	const { t } = useTranslation('pages')
	const result = countMatchingStrings(textDetail?.textToReplace)

	const [textToReplace1, setTextToReplace1] = React.useState('')
	const [textToReplace2, setTextToReplace2] = React.useState('')

	const [resultText, setResultText] = React.useState(textDetail?.textToReplace || '')

	useEffect(() => {
		setResultText(replaceText(textDetail?.textToReplace || '', textToReplace1, textToReplace2))
	}, [textToReplace1, textToReplace2, textDetail?.textToReplace])

	useEffect(() => {
		setTextToReplace1('')
		setTextToReplace2('')
	}, [textDetail?.textToReplace])
	return (
		<>
			<Modal
				visible={!!textDetail}
				title={t('pages:Doplň text')}
				onOk={() => handleOk(resultText)}
				onCancel={handleCancel}
				footer={[
					<Button key='back' onClick={handleCancel}>
						{t('pages:Späť')}
					</Button>,
					<Button key='submit' type='primary' onClick={() => handleOk(resultText)}>
						{t('pages:Pridaj')}
					</Button>
				]}
			>
				<div dangerouslySetInnerHTML={{ __html: textDetail?.descriptionWithHtml || '' }} />
				{ Array.from({ length: result }, (_, i) => (
					<Input
						key={i}
						placeholder={t('pages:Zadajte text')}
						style={{ marginBottom: 10 }}
						value={i === 0 ? textToReplace1 : textToReplace2}
						onChange={(e) => {
							if (i === 0) {
								setTextToReplace1(e.target.value)
							} else {
								setTextToReplace2(e.target.value)
							}
						}
						}
					/>
				))
				}
				<div><b>{t('pages:Výsledok')}</b></div>
				<div dangerouslySetInnerHTML={{ __html: resultText || '' }} />
			</Modal>
		</>
	)
}

export default AddTextModal
