// eslint-disable-next-line import/no-cycle
import { IStoreItemActions, IStoreItemPayload, IStoreItemsPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'
import { LOAD_SELECT_STORE_ITEMS, LOAD_STORE_ITEM } from './types'

export const initState = {
	storeItems: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IStoreItemsPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as IStoreItemPayload & ILoadingAndFailure
}

export default (state = initState, action: IStoreItemActions) => {
	switch (action.type) {
		case LOAD_SELECT_STORE_ITEMS.START:
			return {
				...state,
				storeItems: {
					...state.storeItems,
					isFailure: false,
					isLoading: true
				} as IStoreItemsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEMS.DONE:
			return {
				...state,
				storeItems: {
					...initState.storeItems,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IStoreItemsPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_STORE_ITEMS.FAILED:
			return {
				...state,
				storeItems: {
					...initState.storeItems,
					isFailure: true
				} as IStoreItemsPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IStoreItemPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IStoreItemPayload & ILoadingAndFailure
			}
		case LOAD_STORE_ITEM.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IStoreItemPayload & ILoadingAndFailure
			}
			// case CREATE_STORE_ITEMS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_STORE_ITEMS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_STORE_ITEMS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}
			// case UPDATE_STORE_ITEMS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_STORE_ITEMS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_STORE_ITEMS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}

		default:
			return state
	}
}
