import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import notFoundImage from '../assets/images/backgrounds/empty_404.svg'

function NotFoundPage() {
	const { t } = useTranslation('pages')

	return (
		<div className={ 'not-found-page' }>
			<div className={ 'container image-container' }>
				<div className={ 'not-found-image' } style={ { backgroundImage: `url(${notFoundImage})` } } />
				<div className={ 'title' }>{ t('pages:Stránka sa nenašla') }</div>
				<Link className={ 'btn redirect-button' } to={ '/' }>{ t('pages:Prejsť na hlavnú stránku') }</Link>
			</div>
		</div>
	)
}

export default NotFoundPage
