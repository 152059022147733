import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get } from 'lodash'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import TaskForm from './forms/TaskForm'
import {
	createTasks, getTask, ITaskForm, updateTask
} from '../../reducers/tasks/actions'
import { IComputedMatch } from '../../types/interfaces'

type Props = {
    computedMatch: IComputedMatch<{ id: number }>
}

const DetailTask = (props: Props) => {
	const { computedMatch } = props
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state) => get(state, 'tasks.detail'))

	const isLoading = get(detail, 'isLoading')

	const { id } = computedMatch.params

	const initEmptyDetailForm = () => {
		const initValues = {
			id: '',
			name: ''
		}

		dispatch(initialize(FORMS.TASK_FORM, initValues))
	}

	const initDetailForm = (data: ITaskForm) => {
		dispatch(initialize(FORMS.TASK_FORM, data))
	}

	useEffect(() => {
		if (id) {
			dispatch(getTask(id, (data) => {
				const initValues: ITaskForm = {
					id: data.data.id,
					updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',
					name: data.data.name
				}

				initDetailForm(initValues)
			}))
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [computedMatch])

	const handleCreate = (body: ITaskForm) => {
		dispatch(createTasks(body, (taskId) => history.replace(`${t('paths:taskDetail|path')}/${taskId}`)))
	}

	const handleUpdate = (body: ITaskForm) => {
		dispatch(updateTask(id, body, () => {
			dispatch(getTask(id, (data) => {
				const initData: ITaskForm = {
					id: null,
					updatedAt: null,
					name: data.data.name
				}
				initDetailForm(initData)
			}))
		}))
	}

	const handleSubmit = (values: ITaskForm) => {
		if (id) {
			handleUpdate(values)
		} else {
			handleCreate(values)
		}
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<TaskForm
					onSubmit={ handleSubmit }
					isCreate={ !id }
				/>
			</Spin>
		</div>
	)
}

export default DetailTask
