import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	Field, change, reduxForm, getFormValues, InjectedFormProps
} from 'redux-form'
import {
	get
} from 'lodash'
import {
	Button, Col, Form, Row
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORMS, UPLOAD_CATEGORY } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validateDocumentForm'
import { uploadFileByGCP } from '../../../utils/gcpFileUploader'
import UploadDraggerField from '../../../atoms/form/UploadDraggerField'
import { IDocumentForm } from '../../../reducers/documents/actions'

// https://cloud.google.com/storage/docs/access-control/signing-urls-with-helpers#storage-signed-url-object-nodejs

type ComponentProps = {
	isCreate: boolean
}

type Props = InjectedFormProps<IDocumentForm, ComponentProps> & ComponentProps

const DocumentForm = (props: Props) => {
	const { t } = useTranslation('components')

	const {
		handleSubmit, invalid, pristine, isCreate
	} = props

	const dispatch = useDispatch()

	const fieldValues = useSelector((state) => getFormValues(FORMS.DOCUMENT_FORM)(state))

	const [pdfState, setProfilePhoto] = useState({
		isLoading: false,
		imageUrl: get(fieldValues, 'image', null)
	})

	const refreshAfterUpload = (fileUrl: string) => {
		setProfilePhoto({
			isLoading: false,
			imageUrl: fileUrl
		})
	}

	// https://github.com/react-component/upload/blob/00abfeecb1df23aec5b6e240dc8574a6067055cb/docs/examples/customRequest.tsx#L25
	const uploadImage = async ({
		action,
		data,
		file,
		filename,
		headers,
		onError,
		onProgress,
		onSuccess,
		withCredentials
	}: any) => {
		setProfilePhoto({
			...pdfState,
			isLoading: true
		})

		const {
			fileUrl,
			fileId
		} = await uploadFileByGCP(file, UPLOAD_CATEGORY.PDF_DOCUMENT)

		if (fileUrl) {
			dispatch(change(FORMS.DOCUMENT_FORM, 'fileUrl', fileUrl))
			dispatch(change(FORMS.DOCUMENT_FORM, 'fileId', fileId))
			refreshAfterUpload(fileUrl)
			onSuccess(fileUrl, file)
		} else {
			onError()
		}
	}

	return (
		<Form onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<h1>{ t('pages:Pridať dokument') }</h1>
						<Field
							name={ 'name' }
							component={ TextField }
							label={ t('components:Názov dokumentu') }
							size={ 'large' }
							required
						/>
						<Field
							name={ 'fileUrl' }
							imageUrl={ get(fieldValues, 'fileUrl') }
							component={ UploadDraggerField }
							label={ t('components:Pdf document (upload)') }
							customRequest={ uploadImage }
							isLoading={ pdfState.isLoading }
							required
						/>
					</div>
				</Col>
				<Col span={ 6 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start sidebar-content' }>
						{ !isCreate && (
							<Field
								name={ 'updatedAt' }
								component={ TextField }
								disabled
								label={ t('components:Dátum poslednej úpravy') }
							/>
						) }

						<div className={ 'flex direction-col justify-center' }>
							<Button
								icon={ <SaveOutlined /> }
								onClick={ handleSubmit }
								disabled={ pristine || invalid }
								type={ 'primary' }
								style={ { margin: '20px' } }
							>
								{ t('pages:Uložiť') }
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IDocumentForm, ComponentProps>({
	form: FORMS.DOCUMENT_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(DocumentForm)

export default form
