import React from 'react'

// assets

const QRCode = require('qrcode.react')

export interface QrIdentifier {
	name: string,
	data: unknown
}

// type Props = {
// 	jobID: number,
// 	jobName: string,
// } : QrIdentifier

// todo toto skus toto je aj s obrazkom https://github.com/cheprasov/ts-react-qrcode
const QrCode = (props: QrIdentifier) => {
	const { name, data } = props

	const sampleJson = {
		data
	}
	const qrCodeValue = JSON.stringify(sampleJson)
	return (
		<div
			style={{
				backgroundColor: 'black', margin: '0.2rem', width: 270, height: '100%', padding: 20
			}}
		>
			<div className={ 'app-logo app-logo-qr' } />
			<div
				className={'my-2'}
			>
				<div style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					margin: '1.5rem'
				}}>
					<div
						style={{
							display: 'flex',
							backgroundColor: 'white',
							padding: '0.25rem',
							marginLeft: '0.5rem',
							marginRight: '0.5rem'
						}}>
						<QRCode value={qrCodeValue} />
					</div>
				</div>
			</div>
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				marginRight: '0.5rem',
				marginLeft: '0.5rem'
			}}>
				<div style={{
					color: 'white',
					textAlign: 'center',
					fontWeight: 700,
					marginRight: '0.5rem',
					fontSize: '1.125rem',
					lineHeight: '1.75rem'
				}}>
					{name}
				</div>
			</div>
		</div>
	)
}

export default React.memo(QrCode)
