import React from 'react'
import { Select, Form } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'

// assets
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'

const { Item } = Form

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & {
	label?: string,
	placeholder?: string,
	required?: boolean,
	allowClear?: boolean,
	options: any[],
	mode?: 'multiple',
}

const SelectField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		required,
		style,
		className,
		options,
		size,
		disabled,
		meta: {
			error,
			touched
		}
	} = props

	return (
		<Item
			label={<h3>{label}</h3>}
			required={required}
			style={style}
			validateStatus={(error && touched) ? 'error' : undefined}
		>
			<Select
				{...input}
				onBlur={() => {}}
				className={className || 'dropDown'}
				size={size || 'middle'}
				value={input.value}
				options={options}
				placeholder={placeholder}
				disabled={disabled}
				suffixIcon={<ChevronIcon width={20} height={20}/>}
			/>
		</Item>
	)
}

export default React.memo(SelectField)
