import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

type Props = RouteProps & {
	layout: any
	component: any
}

class BaseRoute extends Route<Props> {
	componentDidMount = () => {
		document.title = 'Umyjeme Top'
	}
	render = () => {
		const Layout = this.props.layout
		const Component = this.props.component
		if (Layout) {
			return (<>
				<Layout {...this.props}>
					<Component {...this.props} />
				</Layout>
			</>)
		}
		return (<Component {...this.props} />)
	}
}

export default BaseRoute
