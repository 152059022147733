import React, { useState } from 'react'
import { Field, getFormValues, initialize, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Popover, Row, Tag } from 'antd'
import { PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { FORMS, STORE_ITEM_TRANSACTION_TYPE } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validateStoreItemForm'
import { getStoreItem, IStoreItemForm } from '../../../reducers/storeItems/actions'
import CreateStoreItemTransactionModal, { CreateTransactionProps } from '../components/CreateStoreItemTransactionModal'
import AddressWithStocksTable, { getPriorityForStoreType } from '../components/AddressWithStocksTable'
import { colorHash } from '../StoreItems'
import { STORE_TYPE } from '../../stores/Stores'
import InputNumberField from '../../../atoms/InputNumberField'
import InputField from '../../../atoms/InputField'
import SwitchField from '../../../atoms/SwitchField'

type ComponentProps = {
	isCreate: boolean;
	id: number;
};

type Props = InjectedFormProps<IStoreItemForm, ComponentProps> & ComponentProps;

export interface CreateTransactionPropsExtended extends CreateTransactionProps {
	isShowed: boolean;
}

const StoreItemForm = (props: Props) => {
	const {
		handleSubmit, invalid, pristine, isCreate, id
	} = props

	const dispatch = useDispatch()

	const { t } = useTranslation('components')

	const storeItemForm: IStoreItemForm = useSelector((state) => getFormValues(FORMS.STORE_ITEM_FORM)(state)) as IStoreItemForm

	const [createTransactionModal, setCreateTransactionModal] = useState<CreateTransactionPropsExtended>({
		amount: 1,
		isShowed: false,
		storeItemName: storeItemForm?.name,
		storeItemID: props.id,
		type: STORE_ITEM_TRANSACTION_TYPE.REGULAR,
		jobToID: null
	})

	const initDetailForm = (data: IStoreItemForm) => {
		dispatch(initialize(FORMS.STORE_ITEM_FORM, data))
	}

	const onStoreItemTransactionSuccess = () => {
		dispatch(
			getStoreItem(id, (data) => {
				const initValues: IStoreItemForm = {
					id: data.data.id,
					isLiquid: data.data.isLiquid,
					isReplaceable: data.data.isReplaceable,
					idealStockPercentage: data.data.idealStockPercentage,
					activeJobs: data?.data?.activeJobs,
					updatedAt: moment(data.data.updatedAt).format('D. MMM YYYY HH:mm') || '-',
					allAvailableStock: data.data.allAvailableStock,
					amountOfStockInAddresses: data.data.amountOfStockInAddresses,
					nameSlug: data.data.nameSlug,
					name: data.data.name
				}

				initDetailForm(initValues)
				setCreateTransactionModal({
					...createTransactionModal,
					storeItemID: data.data.id,
					storeItemName: data.data.name,
					isShowed: false
				})
			})
		)
	}

	return (
		<Form layout={'vertical'} onSubmitCapture={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<h1>{t('pages:Pridať skladovú položku')}</h1>
						<Field
							name={'name'}
							component={InputField}
							label={t('components:Názov položky')}
							size={'large'}
							required
						/>
						<Field
							name={'idealStockPercentage'}
							component={InputNumberField}
							label={
								<Popover
									content={
										<div>
											<p>
												<span>- {t('components:ideálna percentuálna hodnota skladových zásob v pomere k počtu vchodov')}</span>
												<span>
													<b> ({storeItemForm?.activeJobs})</b>
												</span>
											</p>
											<p>
												- príklad: ak bude počet vchodov 100 - a do stlpceka <b>{storeItemForm?.name}</b> dám ze to musi byt 120% tak ked klesne počec kusov na sklade pod 120 kusov - tak by sa ten riadok vyčervenil
											</p>
											<p>
												- príklad 2: ak bude počet vchodov 100 - a <b>{storeItemForm?.name}</b> musí byť 20% ... tak to je 20 kusov a keď klesne počet kusov pod 20 riadok sa vyčervení
											</p>
										</div>
									}
								>
									<div>
										<span>{t('components:(%) Ideálna percentuálna hodnota skladových zásob v pomere k počtu vchodov')}</span>
										<span> ({storeItemForm?.activeJobs})</span>
									</div>
								</Popover>
							}
							size={'large'}
							type={'number'}
							min={0}
							precision={0}
							step={1}
							max={1000}
							required
						/>
						<Field
							name={'isLiquid'}
							component={SwitchField}
							label={t('components:Je tekutina')}
							size={'large'}
							required
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && (
							<Field
								name={'updatedAt'}
								component={TextField}
								disabled
								label={t('components:Dátum poslednej úpravy')}
							/>
						)}

						<div className={'flex direction-col justify-center'}>
							<Button
								icon={<SaveOutlined />}
								onClick={handleSubmit}
								disabled={pristine || invalid}
								type={'primary'}
								className={'mt-4'}
							>
								{t('pages:Uložiť')}
							</Button>
						</div>
					</div>
				</Col>
			</Row>

			{createTransactionModal.storeItemID && (
				<Row className={'mt-4'} gutter={16}>
					<Col span={24} className={'grid'}>
						<div className={'flex direction-col justify-start main-content'}>
							<div className={'flex justify-between'}>
								<div className={'flex justify-between'}>
									<h1>
										{t('pages:Celkovy počet kusov na pobočke')}
										<Tag className={'ml-2'} color={colorHash(STORE_TYPE.VIRTUAL_STORE)}>
											{getPriorityForStoreType(STORE_TYPE.VIRTUAL_STORE, t)}
										</Tag>
										<span className={'pr-2'}>{t('pages:+')}</span>
										<Tag color={colorHash(STORE_TYPE.JOB_STORE)}>
											{getPriorityForStoreType(STORE_TYPE.JOB_STORE, t)}
										</Tag>
									</h1>
									<h2 className={'ml-3'}>{storeItemForm?.allAvailableStock}</h2>
								</div>
								<PlusOutlined
									onClick={() => {
										setCreateTransactionModal({
											...createTransactionModal,
											storeItemName: storeItemForm?.name,
											storeItemID: props.id,
											type: STORE_ITEM_TRANSACTION_TYPE.REGULAR,
											isShowed: true
										})
									}}
								/>
							</div>
							<AddressWithStocksTable
								itemID={id}
								amountOfStockInAddresses={storeItemForm?.amountOfStockInAddresses}
							/>
						</div>
					</Col>
				</Row>
			)}

			<CreateStoreItemTransactionModal
				width={500}
				transaction={createTransactionModal}
				isVisible={createTransactionModal.isShowed}
				onCancel={() => {
					setCreateTransactionModal({
						...createTransactionModal,
						isShowed: false
					})
				}}
				onSuccess={onStoreItemTransactionSuccess}
			/>
		</Form>
	)
}

const form = reduxForm<IStoreItemForm, ComponentProps>({
	form: FORMS.STORE_ITEM_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(StoreItemForm)

export default form
