export enum LOAD_SELECT_STORE_ITEM_TRANSACTIONS {
	START= 'LOAD_SELECT_STORE_ITEM_TRANSACTIONS_START',
	DONE= 'LOAD_SELECT_STORE_ITEM_TRANSACTIONS_DONE',
	FAILED= 'LOAD_SELECT_STORE_ITEM_TRANSACTIONS_FAILED'
}

export enum LOAD_STORE_ITEM_TRANSACTION {
	START= 'LOAD_STORE_ITEM_TRANSACTION_START',
	DONE= 'LOAD_STORE_ITEM_TRANSACTION_DONE',
	FAILED= 'LOAD_STORE_ITEM_TRANSACTION_FAILED'
}

export enum CREATE_STORE_ITEM_TRANSACTIONS {
	START= 'CREATE_STORE_ITEM_TRANSACTIONS_START',
	DONE= 'CREATE_STORE_ITEM_TRANSACTIONS_DONE',
	FAILED= 'CREATE_STORE_ITEM_TRANSACTIONS_FAILED'
}

export enum UPDATE_STORE_ITEM_TRANSACTIONS {
	START= 'UPDATE_STORE_ITEM_TRANSACTIONS_START',
	DONE= 'UPDATE_STORE_ITEM_TRANSACTIONS_DONE',
	FAILED= 'UPDATE_STORE_ITEM_TRANSACTIONS_FAILED'
}

export enum DELETE_STORE_ITEM_TRANSACTION {
	START= 'DELETE_STORE_ITEM_TRANSACTION_START',
	DONE= 'DELETE_STORE_ITEM_TRANSACTION_DONE',
	FAILED= 'DELETE_STORE_ITEM_TRANSACTION_FAILED'
}
