export enum LOAD_SELECT_STORE_ITEMS {
	START= 'LOAD_SELECT_STORE_ITEMS_START',
	DONE= 'LOAD_SELECT_STORE_ITEMS_DONE',
	FAILED= 'LOAD_SELECT_STORE_ITEMS_FAILED'
}

export enum LOAD_STORE_ITEM {
	START= 'LOAD_STORE_ITEM_START',
	DONE= 'LOAD_STORE_ITEM_DONE',
	FAILED= 'LOAD_STORE_ITEM_FAILED'
}

export enum CREATE_STORE_ITEMS {
	START= 'CREATE_STORE_ITEMS_START',
	DONE= 'CREATE_STORE_ITEMS_DONE',
	FAILED= 'CREATE_STORE_ITEMS_FAILED'
}

export enum UPDATE_STORE_ITEMS {
	START= 'UPDATE_STORE_ITEMS_START',
	DONE= 'UPDATE_STORE_ITEMS_DONE',
	FAILED= 'UPDATE_STORE_ITEMS_FAILED'
}

export enum DELETE_STORE_ITEM {
	START= 'DELETE_STORE_ITEM_START',
	DONE= 'DELETE_STORE_ITEM_DONE',
	FAILED= 'DELETE_STORE_ITEM_FAILED'
}
