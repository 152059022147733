/* eslint-disable import/no-cycle */
import {
	first, get, isArray, isInteger, isNaN, isNil, mapValues, pick, split, toNumber, chain
} from 'lodash'
import slugify from 'slugify'
import {
	isEmail, isNaturalNonZero, isNotNumeric
} from 'lodash-checkit'
import i18next from 'i18next'
import dayjs from 'dayjs'

import {
	DEFAULT_DATE_WITH_TIME_FORMAT, INVALID_DATE_FORMAT, MSG_TYPE, DEFAULT_DATE_FORMAT, DEFAULT_DATE_WITH_TIME_FORMAT_DD_MM_YYYY, DEFAULT_DATE_WITH_TIME_FORMAT_HH_MM, FREQUENCIES_KEY, FREQUENCIES, FORM
} from './enums'

/**
 * Zakóduj dáta do base64 stringu, pre použitie v url query parametry
 */
// NOTE: https://stackoverflow.com/a/26603875
export const encodeBackDataQuery = (any: any) => {
	let encoded
	if (any) {
		const string = JSON.stringify(any)
		if (string) {
			encoded = btoa(encodeURIComponent(string))
		}
	}
	return encoded
}

export const formFieldID = (form?: FORM | string, name?: string) => {
	let id
	if (form && name) {
		// NOTE: element can't be queried if id contains dots
		const fieldSelector = chain(name)
			.filter((char) => char !== ']')
			.map((char) => (char === '[' || char === '.' ? '-' : char))
			.value()
			.join('')
		id = `${form}-${fieldSelector}`
	}
	return id
}

export const decodeBackDataQuery = (base64?: string | null) => {
	let decoded = null
	try {
		if (base64) {
			const decodedString = decodeURIComponent(atob(base64))
			decoded = JSON.parse(decodedString)
		}
	} catch (e) {
		decoded = null
	}
	return decoded
}

export const toNormalizeQueryParams = (queryParams: object, allowQueryParams: string[]) => {
	const pickQueryParams = pick(queryParams, Object.values(allowQueryParams))

	return mapValues(pickQueryParams, (val) => {
		let normalizeVal
		if (val === undefined || val === null || val === '' || isArray(val)) {
			normalizeVal = val
		} else if (val === 'true' || val === true) {
			normalizeVal = true
		} else if (val === 'false' || val === false) {
			normalizeVal = false
		} else if (get(val, 'length') > 1 && first(val) === '0') {
			normalizeVal = decodeURIComponent(val)
		} else {
			const number = toNumber(val)
			normalizeVal = isNaN(number) ? decodeURIComponent(val) : number
		}
		return normalizeVal
	})
}

/**
 * @param date Date
 * @param placeholder String
 * @param format DEFAULT_DATE_FORMAT
 * @return string
 *
 * Returns formatted date
 */
export function formatDate(date: string | Date | undefined, placeholder = INVALID_DATE_FORMAT, format = DEFAULT_DATE_FORMAT) {
	if (!date) {
		return placeholder
	}
	// ISO 8601
	if (!dayjs(date, undefined, true).isValid()) {
		return INVALID_DATE_FORMAT
	}
	return dayjs(date).format(format)
}

/**
 * Returns formatted date with time
 */
export function formatDateWithTime(date?: any, placeholder = '-') {
	// ISO 8601
	if (!dayjs(date, undefined, true).isValid()) {
		return placeholder
	}
	return dayjs(date).format(DEFAULT_DATE_WITH_TIME_FORMAT)
}

/**
 * Returns name in format 'A. Lincoln'
 */
export function crateNameAbbreviation(fullName: string) {
	const nameArray: string[] = fullName?.split(' ')
	if (nameArray.length === 2) {
		return `${nameArray[0].substr(0, 1)}. ${nameArray[1]}`
	}
	return fullName
}

export function formatDateWithTimeDdMmYyyy(date?: any, placeholder = '-') {
	// ISO 8601
	if (!dayjs(date, undefined, true).isValid()) {
		return placeholder
	}
	return dayjs(date).format(DEFAULT_DATE_WITH_TIME_FORMAT_DD_MM_YYYY)
}

export function formatDateWithTimeHhMM(date?: any, placeholder = '-') {
	// ISO 8601
	if (!dayjs(date, undefined, true).isValid()) {
		return placeholder
	}
	return dayjs(date).format(DEFAULT_DATE_WITH_TIME_FORMAT_HH_MM)
}

export const translateMessageType = (msgType: MSG_TYPE) => {
	switch (msgType) {
		case MSG_TYPE.ERROR:
			return i18next.t('loc:Chyba')
		case MSG_TYPE.WARNING:
			return i18next.t('loc:Upozornenie')
		case MSG_TYPE.SUCCESS:
			return i18next.t('loc:Úspešné')
		case MSG_TYPE.INFO:
			return i18next.t('loc:Info')
		default:
			return ''
	}
}

export const createSlug = (value: string, separator: string = '-', lower: boolean = true) => {
	if (value) {
		return slugify(value, {
			replacement: separator,
			lower
		})
	}
	return ''
}

export const normalizeQueryParams = (queryParams: object) => mapValues(queryParams, (queryParam) => {
	if (queryParam === '' || isNil(queryParam)) {
		return undefined
	}
	return queryParam
})

// Number validators
export const validationNumber = (value: string) => !isNaturalNonZero(value) && i18next.t('loc:Nie je validná číselná hodnota')
export const validationDecimalNumber = (value: number) => isNotNumeric(value) && i18next.t('loc:Nie je validná číselná hodnota')
export const validationIntegerNumber = (value: string|number) => value && !isInteger(Number(value)) && i18next.t('loc:Nie je validná číselná hodnota')

// String validators
export const validationString = (maxLength: number) => (value: string) => get(value, 'length') > maxLength && i18next.t('loc:Max. počet znakov je {{max}}', { max: maxLength })
export const validationStringFixLength = (length: number, optional?: boolean) => (value: string) => {
	if (optional && !get(value, 'length')) {
		return null
	}
	return get(value, 'length') !== length && i18next.t('loc:Počet znakov musí byť {{length}}', { length })
}

export const validationEmail = (value: any) => value && !isEmail(value) && i18next.t('loc:Nesprávny formát emailovej adresy')

// Other validators
export const validationRequired = (value: string) => !value && i18next.t('loc:Toto pole je povinné')

export const normalizeDirectionKeys = (direction: 'ascend' | 'descend' | null | undefined) => (direction === 'descend' ? 'DESC' : 'ASC')
export const normalizeASCDESCKeys = (direction: string) => (direction === 'DESC' ? 'descend' : 'ascend')

export const orderDirectionByTable = (tableDirection?: 'ascend' | 'descend' | null): string => {
	if (tableDirection === 'descend') {
		return 'desc'
	}
	return 'asc'
}

export const orderDirectionForTable = (direction?: 'asc' | 'desc'): 'ascend' | 'descend' => {
	if (direction === 'desc') {
		return 'descend'
	}
	return 'ascend'
}

export const setOrder = (order: string | null | undefined, colName: string) => {
	const [name, direction] = split(order || '', ':')
	let result
	if (name === colName) {
		result = normalizeASCDESCKeys(direction)
	}
	return result as 'descend' | 'ascend' | undefined
}

export const divideArrayFromAnother = (a:any[], b:any[]) => (b.length === 0 ? a : a.filter((x) => b.includes(x.key)))

// @ts-ignore
export const getFrequenciesStorage = () => (localStorage.getItem(FREQUENCIES_KEY) ? JSON.parse(localStorage.getItem(FREQUENCIES_KEY)) : null)

export const setFrequenciesStorage = (frequencies: any) => localStorage.setItem(FREQUENCIES_KEY, JSON.stringify(frequencies))
