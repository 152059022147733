import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { setUserPasswordManually } from '../reducers/user/userActions'

const PasswordChangeModal = ({
	userID,
	setShowModal
}) => {
	const { t } = useTranslation('pages')

	const [password, setPassword] = useState('')
	const dispatch = useDispatch()

	return (
		<Modal
			visible
			title={ t('pages:Zmeniť používateľovi heslo') }
			okText={ t('pages:Zmeniť') }
			cancelText={ t('pages:Zrušiť') }
			onCancel={ () => setShowModal(null) }
			onOk={ () => {
				dispatch(setUserPasswordManually(password, userID))
				setShowModal(null)
			} }
		>
			<Input
				placeholder={ t('components:Nové heslo') }
				value={ password }
				onChange={ (data) => {
					setPassword(data.target.value)
				} }
			/>
		</Modal>
	)
}

PasswordChangeModal.propTypes = {
	userID: PropTypes.number.isRequired,
	setShowModal: PropTypes.func.isRequired,
	submitPasswordChange: PropTypes.func.isRequired
}

export default PasswordChangeModal
