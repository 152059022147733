export enum LOAD_DOCUMENT_CATEGORIES {
	START= 'LOAD_DOCUMENT_CATEGORIES_START',
	DONE= 'LOAD_DOCUMENT_CATEGORIES_DONE',
	FAILED= 'LOAD_DOCUMENT_CATEGORIES_FAILED'
}

export enum LOAD_DOCUMENT_CATEGORY {
	START= 'LOAD_DOCUMENT_CATEGORY_START',
	DONE= 'LOAD_DOCUMENT_CATEGORY_DONE',
	FAILED= 'LOAD_DOCUMENT_CATEGORY_FAILED'
}
