export enum LOAD_DASHBOARD_RATINGS {
	START= 'LOAD_DASHBOARD_RATINGS_START',
	DONE= 'LOAD_DASHBOARD_RATINGS_DONE',
	FAILED= 'LOAD_DASHBOARD_RATINGS_FAILED'
}

export enum LOAD_RATINGS {
	START = 'LOAD_RATINGS_START',
	DONE = 'LOAD_RATINGS_DONE',
	FAILED = 'LOAD_RATINGS_FAILED'
}

export enum POST_RATING_INVISIBLE {
	START = 'POST_RATING_INVISIBLE_START',
	DONE = 'POST_RATING_INVISIBLE_DONE',
	FAILED = 'POST_RATING_INVISIBLE_FAILED'
}
