import {
	Button, Empty, Form, Input, Popconfirm, Table, Tabs, Tag
} from 'antd'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import {
	DeleteOutlined, DownloadOutlined, MenuOutlined, PlusCircleOutlined, QuestionCircleOutlined
} from '@ant-design/icons'
import arrayMove from 'array-move'
import React, {
	useCallback, useEffect, useState
} from 'react'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import {
	NumberParam, StringParam, useQueryParams, withDefault
} from 'use-query-params'
import { debounce, isEmpty, map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { getFormInitialValues, initialize } from 'redux-form'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import {
	deleteDocument, DocumentTableItem, getDocuments, postSwapDocument
} from '../../reducers/documents/actions'
import { RootState } from '../../reducers'
import { CategoryDocumentTableItem, getCategoryDocuments } from '../../reducers/documentCategories/actions'
import AddDocumentCategoryModal from '../../components/modals/AddDocumentCategoryModal'
import EditDocumentModal, { EditDocumentProps } from './components/EditDocumentModal'
import { FORM } from '../../utils/enums'

const PAGE_SIZE = 100

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)

interface IDataSource {
	dataSource : any[]
}

const SortableItem = SortableElement((props: any) => <tr {...props} />)
const SortableContainerElement = SortableContainer((props: any) => <tbody {...props} />)

type Columns = ColumnsType<DocumentTableItem>

const { TabPane } = Tabs

const NO_VALUE = -1

const initialTabs = [
	{
		name: 'Bez kategórie',
		key: NO_VALUE,
		id: 0
	}
] as CategoryDocumentTableItem[]

const DocumentsDraggable = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const dataSourceDocumentCategories = useSelector((state: RootState) => state.documentCategories.documentCategories)

	const [isAddDocumentCategoryModalShowed, setAddDocumentCategoryModalShowed] = useState(false)
	const [state, setState] = useState<IDataSource>(({ dataSource: [] }))
	const [tabState, setTabState] = useState({
		activeKey: initialTabs[0].key,
		panes: initialTabs.concat(dataSourceDocumentCategories?.tableData)
	})
	const [editDocumentModal, setEditDocumentModal] = useState<EditDocumentProps>(({
		isShowed: false,
		id: null,
		name: '',
		categoryName: '',
		categoryID: 0
	}))

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: NumberParam,
		page: withDefault(NumberParam, 1),
		categoryID: withDefault(NumberParam, null),
		orderBy: withDefault(StringParam, 'order'),
		orderDirection: withDefault(StringParam, 'asc')
	})

	const dataSourceDocument = useSelector((state: RootState) => state.documents.documents)
	const isLoadingList = dataSourceDocument.isLoading

	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.DOCUMENTS_FILTER)(state))

	const refreshDocuments = () => {
		dispatch(getDocuments({
			limit: PAGE_SIZE,
			search: query.search,
			page: query.page,
			orderBy: query.orderBy,
			categoryID: query.categoryID,
			orderDirection: query.orderDirection
		}))
	}

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		const { dataSource } = state
		if (oldIndex !== newIndex) {
			const newData = arrayMove(dataSource, oldIndex, newIndex).filter((el) => !!el)
			const newOrderMap = map(newData, (record, index) => ({
				id: record.id,
				order: index
			}))
			dispatch(postSwapDocument(newOrderMap, () => {
				refreshDocuments()
			}))
			setState({ dataSource: newData })
		}
	}

	const DraggableContainer = (props: any) => (
		<SortableContainerElement
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={onSortEnd}
			{...props}
		/>
	)

	const DraggableBodyRow = ({ className, style, ...restProps } : any) => {
		const { dataSource } = state
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = dataSource.findIndex((x) => x.index === restProps['data-row-key'])
		return <SortableItem index={index} {...restProps} />
	}

	useEffect(() => {
		setState({ dataSource: dataSourceDocument.tableData })
	}, [dataSourceDocument.tableData])

	useEffect(() => {
		dispatch(getCategoryDocuments())
	}, [dispatch])

	useEffect(() => {
		refreshDocuments()
	}, [dispatch,
		query.search, query.limit, query.page, query.categoryID, query.orderBy, query.orderDirection])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.DOCUMENTS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	useEffect(() => {
		setTabState({
			...tabState,
			panes: initialTabs.concat(dataSourceDocumentCategories?.tableData)
		})
	}, [dataSourceDocumentCategories?.tableData])

	const columns: Columns = [
		{
			title: i18next.t('pages:Poradie'),
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible',
			render() {
				return <DragHandle />
			}
		},
		{
			title: i18next.t('pages:Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			width: 210,
			align: 'left',
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Kategória'),
			dataIndex: 'category',
			key: 'category',
			width: 40,
			align: 'center',
			render(category) {
				return category ? <Tag>{category}</Tag> : <div/>
			}
		},
		{
			title: i18next.t('pages:Odkaz na stiahnutie'),
			dataIndex: 'path',
			key: 'path',
			width: 210,
			align: 'right',
			render(path) {
				return <Button
					type="primary"
					href={path}
					onClick={(e) => {
						e?.stopPropagation()
					}}
					icon={<DownloadOutlined />} target="_blank" size={'middle'}>
					Download
				</Button>
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 20,
			render(text, record) {
				return (
					<Popconfirm
						title={ i18next.t('pages:Skutočne chcete vymazať záznam?') }
						icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
						cancelText={ i18next.t('pages:Zrušiť') }
						okText={ i18next.t('pages:Vymazať') }
						onConfirm={ (e) => {
							e?.stopPropagation()
							dispatch(deleteDocument(record.id, () => {
								refreshDocuments()
							}))
						} }
						onCancel={ (e) => e?.stopPropagation() }
						okButtonProps={ {
							size: 'small',
							type: 'primary'
						} }
						cancelButtonProps={ {
							size: 'small',
							type: 'ghost'
						} }
					>
						<Button
							icon={ <DeleteOutlined /> }
							type={ 'primary' }
							onClick={ (e) => e.stopPropagation() }
						/>
					</Popconfirm>
				)
			}
		}
	]

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, page: 1, search: searchTerm }), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string,
			FilterValue | null>,
		sorter: SorterResult<DocumentTableItem> | SorterResult<DocumentTableItem>[],
		extra: TableCurrentDataSource<DocumentTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			limit: PAGE_SIZE,
			...query,
			page: pagination.current,
			...order
		})
	}

	const onTabChange = (activeKey: string) => {
		const activeKeyNumber = parseInt(activeKey, 10)

		setTabState({
			...tabState,
			activeKey: parseInt(activeKey, 10)
		})

		if (activeKeyNumber === NO_VALUE) {
			setQuery({
				...query,
				page: 1,
				categoryID: null
			})
		} else {
			setQuery({
				...query,
				page: 1,
				categoryID: parseInt(activeKey, 10)
			})
		}
	}

	const addNewTab = () => {
		setAddDocumentCategoryModalShowed(true)
	}

	const onNewTabAdded = () => {
		dispatch(getCategoryDocuments())
		setAddDocumentCategoryModalShowed(false)
	}

	const onTabEdit = (targetKey: any, action:'add' | 'remove') => {
		if (action === 'add') {
			addNewTab()
		}
	}

	const onDocumentEdited = () => {
		refreshDocuments()
		setEditDocumentModal({ ...editDocumentModal, isShowed: false })
	}

	const { dataSource } = state

	return (
		<div className={ 'page-wrapper' }>
			<div className={ 'flex justify-between' }>
				<Form.Item>
					<Input.Search defaultValue={query.search || ''} onChange={ handleOnChange } style={ { width: 300 } } allowClear />
				</Form.Item>
				<Button
					icon={ <PlusCircleOutlined /> }
					href={ t('paths:documentCreate|path') }
					type={ 'primary' }
				>
					{ t('pages:Pridať dokument') }
				</Button>
			</div>
			<Tabs
				type="editable-card"
				onChange={onTabChange}
				activeKey={`${tabState.activeKey}`}
				onEdit={onTabEdit}
			>
				{tabState.panes.map((pane) => (
					<TabPane tab={pane.name} key={`${pane.key}`} id={`${pane.id}`} closable={true}>
						<Table
							pagination={ {
								pageSize: PAGE_SIZE,
								total: dataSourceDocument?.pagination?.totalCount,
								current: dataSourceDocument?.pagination?.page,
								showSizeChanger: false
							} }
							showSorterTooltip={ false }
							dataSource={dataSource}
							columns={columns}
							rowKey='index'
							loading={ isLoadingList }
							onChange={ handleTableChange }
							components={{
								body: {
									wrapper: DraggableContainer,
									row: DraggableBodyRow
								}
							}}
							onRow={ (record) => ({
								onClick: () => setEditDocumentModal({
									...editDocumentModal,
									categoryID: record?.categoryID,
									categoryName: record?.category,
									name: record?.name,
									id: record?.id,
									isShowed: true
								})
							}) }
							locale={ {
								emptyText: (
									<Empty description={ t('pages:Žiadne dáta') } />
								)
							} }
						/>
					</TabPane>
				))}
			</Tabs>
			<AddDocumentCategoryModal
				width={800}
				isVisible={isAddDocumentCategoryModalShowed}
				title={t('loc:Pridať novú kategóriu')}
				onCancel={() => {
					setAddDocumentCategoryModalShowed(false)
				}}
				cancelText={t('loc:Zrušiť')}
				onSuccess={onNewTabAdded}
				onConfirmBtnTitle={t('loc:Pridať')}
			/>
			<EditDocumentModal
				width={500}
				document={{
					id: editDocumentModal.id,
					name: editDocumentModal.name,
					categoryID: editDocumentModal.categoryID,
					categoryName: editDocumentModal.categoryName,
					isShowed: editDocumentModal.isShowed
				}}
				isVisible={editDocumentModal.isShowed}
				onCancel={() => {
					setEditDocumentModal({
						...editDocumentModal,
						isShowed: false
					})
				}}
				onSuccess={onDocumentEdited}
			/>
		</div>
	)
}

export default DocumentsDraggable
