import React from 'react'
import { Field } from 'redux-form'
import { Col, Row, Tag } from 'antd'
import { useSelector } from 'react-redux'
import DragAndDropField from '../../../atoms/form/DragAndDropField'
import { RootState } from '../../../reducers'
import { FREQUENCY, toSkImportantStatus, translateFrequencyToSk, translateToTableNameFrequency } from '../../../utils/translate'
import { colorHash } from '../../storeItems/StoreItems'

type ComponentProps = {
	title: string,
	frequency: FREQUENCY
}

type Props = ComponentProps

const FrequencyJobsField = (props: Props) => {
	const {
		title,
		frequency
	} = props

	const dataSource = useSelector((state: RootState) => state.tasks.tasks.tableData)

	return <Col span={24} className={'grid mt-4'}>
		<div className={'flex direction-col justify-start main-content'}>
			<Row justify={'space-between'}>
				<h3>{title}</h3>
				<h5>{toSkImportantStatus(frequency)}</h5>
				<Tag color={colorHash(frequency || '')}>{translateFrequencyToSk(frequency)}</Tag>
			</Row>
			<Field
				name={`${translateToTableNameFrequency(frequency)}Tasks`}
				extraData={false}
				options={dataSource}
				handleRefresh=''
				frequency={ frequency }
				component={DragAndDropField}
				required
			/>
		</div>
	</Col>
}

export default FrequencyJobsField
