import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get, map } from 'lodash'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import JobForm from './forms/JobForm'
import { createJobs, getJob, IJobForm, ITrusteeParam, sendTransferJobToPriceOffer, updateJob } from '../../reducers/jobs/actions'
import { IComputedMatch } from '../../types/interfaces'
import { RootState } from '../../reducers'
import DeleteJobModal from './components/DeleteJobModal'

// fake data generator
const getItems = (count: number) => Array.from({ length: count }, (v, k) => k)
	.map((k) => ({
		id: `item-${k}`,
		content: `item ${k}`
	}))

type Props = {
	computedMatch: IComputedMatch<{ id: number }>
	trusteePopup: boolean
}

const DetailJob = (props: Props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state: RootState) => state.jobs.detail)

	const isLoading = detail.isLoading || false

	const { id } = props.computedMatch?.params

	const [removeModalVisible, setRemoveModalVisible] = React.useState(false)

	const initEmptyDetailForm = () => {
		const initValues = {
			userId: '',
			address: '',
			objectTrusteeEmail: '',
			ownerEmail: '',
			tasks: getItems((Math.random() * 10) + 1)
		}

		dispatch(initialize(FORMS.JOB_FORM, initValues))
	}

	const initDetailForm = (data: IJobForm) => {
		const initValues = {
			userID: get(data, 'user.id'),
			address: get(data, 'address'),
			updatedAt: moment(get(data, 'updatedAt')).format('D. MMM YYYY HH:mm') || '-',
			priceOfferId: get(data, 'priceOfferId'),
			note: get(data, 'note'),
			priceForCleaning: get(data, 'priceForCleaning') ? (get(data, 'priceForCleaning') / 100) : 0,
			extras: get(data, 'extras'),
			objectTrusteeEmail: get(data, 'objectTrusteeEmail'),
			ownerEmail: get(data, 'ownerEmail'),
			trustees: map(get(data, 'trustees'), (data) => (data ? JSON.stringify(data) : [])),
			mondayTasks: get(data, 'mondayTasks'),
			tuesdayTasks: get(data, 'tuesdayTasks'),
			wednesdayTasks: get(data, 'wednesdayTasks'),
			thursdayTasks: get(data, 'thursdayTasks'),
			fridayTasks: get(data, 'fridayTasks'),
			saturdayTasks: get(data, 'saturdayTasks'),
			sundayTasks: get(data, 'sundayTasks'),
			monthTasks: get(data, 'monthTasks'),
			quarterYearTasks: get(data, 'quarterYearTasks'),
			halfYearTasks: get(data, 'halfYearTasks'),
			yearTasks: get(data, 'yearTasks'),
			priceOfferTrustee: get(data, 'priceOfferTrustee'),
			inflationPartAccepted: get(data, 'inflationPartAccepted'),
			nameOfHousingAssociation: get(data, 'nameOfHousingAssociation'),
			floors: get(data, 'floors'),
			flats: get(data, 'flats')
		}
		dispatch(initialize(FORMS.JOB_FORM, {
			...initValues
		}))
	}

	const handleSendTransferJobToPriceOffer = (jobId: number) => {
		dispatch(sendTransferJobToPriceOffer(jobId, () => {
			// notification.success({
			// 	message: 'Cenová ponuka bola úspešne prevedená na zákazku'
			// })
			dispatch(getJob(jobId, (data) => {
				initDetailForm(data)
			}))
		}))
	}

	useEffect(() => {
		if (id) {
			dispatch(getJob(id, (data) => {
				// eslint-disable-next-line no-use-before-define
				initDetailForm(data)
			}))
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [props.computedMatch])

	const handleCreate = (body: IJobForm) => {
		dispatch(createJobs(body, (JobId) => history.push(`${t('paths:jobDetail|path')}/${JobId}`)))
	}

	const handleUpdate = (body: IJobForm) => {
		dispatch(updateJob(id, body, () => {
			dispatch(getJob(id, (data) => {
				initDetailForm(data)
			}))
		}))
	}

	const handleRefresh = () => {
		dispatch(getJob(id, (data) => {
			initDetailForm(data)
		}))
	}

	const handleRemove = (detailID: number) => {
		setRemoveModalVisible(true)
	}

	const handleSubmit = (values: IJobForm) => {
		const body = {
			userID: values.userID,
			address: values.address,
			note: values.note,
			objectTrusteeEmail: values.objectTrusteeEmail,
			priceForCleaning: Math.round(values.priceForCleaning ? values.priceForCleaning * 100 : 0),
			trustees: map(values.trustees, (trusteeString : any) => {
				try {
					// @ts-ignore
					const trustee = JSON.parse(trusteeString)
					return {
						userID: trustee.id,
						sendEmail: trustee.sendEmail
					} as ITrusteeParam
				} catch (e) {
					return {
						userID: trusteeString.id,
						sendEmail: trusteeString.sendEmail
					} as ITrusteeParam
				}
			}),
			ownerEmail: values.ownerEmail,
			mondayTasks: values.mondayTasks,
			tuesdayTasks: values.tuesdayTasks,
			wednesdayTasks: values.wednesdayTasks,
			thursdayTasks: values.thursdayTasks,
			fridayTasks: values.fridayTasks,
			saturdayTasks: values.saturdayTasks,
			sundayTasks: values.sundayTasks,
			monthTasks: values.monthTasks,
			quarterYearTasks: values.quarterYearTasks,
			halfYearTasks: values.halfYearTasks,
			yearTasks: values.yearTasks,

			inflationPartAccepted: values.inflationPartAccepted,
			nameOfHousingAssociation: values.nameOfHousingAssociation,
			floors: values.floors,
			flats: values.flats
		} as IJobForm

		if (id) {
			handleUpdate({
				...body
			})
		} else {
			handleCreate({
				...body
			})
		}
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<JobForm
					onSubmit={ handleSubmit }
					onRefresh={ handleRefresh }
					onRemove={ handleRemove }
					isCreate={ !id }
					id={id}
					transferJobToOffer={handleSendTransferJobToPriceOffer}
				/>
			</Spin>
			{id && <DeleteJobModal
				isVisible={removeModalVisible}
				jobID={id}
				onCancel={() => setRemoveModalVisible(false)}
				onSuccess={() => {}}
			/>}
		</div>
	)
}

export default DetailJob
