export enum LOAD_SELECT_STORE_ITEM_ORDERS {
	START= 'LOAD_SELECT_STORE_ITEM_ORDERS_START',
	DONE= 'LOAD_SELECT_STORE_ITEM_ORDERS_DONE',
	FAILED= 'LOAD_SELECT_STORE_ITEM_ORDERS_FAILED'
}

export enum LOAD_STORE_ITEM_ORDER {
	START= 'LOAD_STORE_ITEM_ORDER_START',
	DONE= 'LOAD_STORE_ITEM_ORDER_DONE',
	FAILED= 'LOAD_STORE_ITEM_ORDER_FAILED'
}

export enum LOAD_SHARED_STORES_ORDER {
	START= 'LOAD_SHARED_STORES_START',
	DONE= 'LOAD_SHARED_STORES_DONE',
	FAILED= 'LOAD_SHARED_STORES_FAILED'
}

export enum CREATE_STORE_ITEM_ORDERS {
	START= 'CREATE_STORE_ITEM_ORDERS_START',
	DONE= 'CREATE_STORE_ITEM_ORDERS_DONE',
	FAILED= 'CREATE_STORE_ITEM_ORDERS_FAILED'
}

export enum UPDATE_STORE_ITEM_ORDERS {
	START= 'UPDATE_STORE_ITEM_ORDERS_START',
	DONE= 'UPDATE_STORE_ITEM_ORDERS_DONE',
	FAILED= 'UPDATE_STORE_ITEM_ORDERS_FAILED'
}

export enum DELETE_STORE_ITEM_ORDER {
	START= 'DELETE_STORE_ITEM_ORDER_START',
	DONE= 'DELETE_STORE_ITEM_ORDER_DONE',
	FAILED= 'DELETE_STORE_ITEM_ORDER_FAILED'
}
