export enum FORM_DOCS {
	FORM_DOCS_LOAD_START = 'FORM_DOCS_LOAD_START',
	FORM_DOCS_LOAD_DONE = 'FORM_DOCS_LOAD_DONE',
	FORM_DOCS_LOAD_FAIL = 'FORM_DOCS_LOAD_FAIL'
}

export enum FORM_DOC {
	FORM_DOC_LOAD_START = 'FORM_DOC_LOAD_START',
	FORM_DOC_LOAD_DONE = 'FORM_DOC_LOAD_DONE',
	FORM_DOC_LOAD_FAIL = 'FORM_DOC_LOAD_FAIL'
}

export enum DUPLICATE_FORM_DOC {
	FORM_DOC_LOAD_START = 'FORM_DOC_LOAD_START',
	FORM_DOC_LOAD_DONE = 'FORM_DOC_LOAD_DONE',
	FORM_DOC_LOAD_FAIL = 'FORM_DOC_LOAD_FAIL'
}
