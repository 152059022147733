export enum LOAD_SELECT_STORE_ITEM_REPLACEMENTS {
	START = 'LOAD_SELECT_STORE_ITEM_REPLACEMENTS_START',
	DONE = 'LOAD_SELECT_STORE_ITEM_REPLACEMENTS_DONE',
	FAILED = 'LOAD_SELECT_STORE_ITEM_REPLACEMENTS_FAILED'
}

export enum LOAD_STORE_ITEM_REPLACEMENT {
	START = 'LOAD_STORE_ITEM_REPLACEMENT_START',
	DONE = 'LOAD_STORE_ITEM_REPLACEMENT_DONE',
	FAILED = 'LOAD_STORE_ITEM_REPLACEMENT_FAILED'
}

export enum CREATE_STORE_ITEM_REPLACEMENT {
	START = 'CREATE_STORE_ITEM_REPLACEMENT_START',
	DONE = 'CREATE_STORE_ITEM_REPLACEMENT_DONE',
	FAILED = 'CREATE_STORE_ITEM_REPLACEMENT_FAILED'
}

export enum UPDATE_STORE_ITEM_REPLACEMENT {
	START = 'UPDATE_STORE_ITEM_REPLACEMENT_START',
	DONE = 'UPDATE_STORE_ITEM_REPLACEMENT_DONE',
	FAILED = 'UPDATE_STORE_ITEM_REPLACEMENT_FAILED'
}

export enum DELETE_STORE_ITEM_REPLACEMENT {
	START = 'DELETE_STORE_ITEM_REPLACEMENT_START',
	DONE = 'DELETE_STORE_ITEM_REPLACEMENT_DONE',
	FAILED = 'DELETE_STORE_ITEM_REPLACEMENT_FAILED'
}
