import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

// assets
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { RootState } from '../reducers'
import { getUsers } from '../reducers/usersU/actions'

const { Item } = Form

const { Option } = Select

type Props = {
	onValueChange: (value: ISelectedUsersOption) => void,
	userValues: (number | null)[]
}

export interface ISelectedUsersOption {
	value: ISelectedUserOption[]
}

export interface ISelectedUserOption {
	value: number,
	label: string
}

const SelectUserField = (props: Props) => {
	const { onValueChange, userValues } = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [userSelected, setUserSelected] = useState<any>([])

	function onChange(value:string[], option: any) {
		onValueChange({
			value: option.map((data: any) => ({
				value: data.value,
				label: data?.children
			}))
		})
		const valuesSet = option.map((data:any) => (data?.children))
		setUserSelected(valuesSet)
	}

	useEffect(() => {
		dispatch(getUsers(
			1,
			100
		))
	}, [])

	useEffect(() => {
		setUserSelected(userValues)
	}, [userValues])

	const users = useSelector((state:RootState) => state.users.users.tableData)
		.map((user: any) => {
			const userName = user.lastName ? `${user.lastName} ${user.firstName}` : user.email
			return {
				value: user.id,
				label: userName
			}
		})

	return (
		<Item>
			<Select
				onBlur={() => {}}
				className={'dropDown'}
				size={ 'middle'}
				onChange={onChange}
				mode={'multiple'}
				suffixIcon={<ChevronIcon width={20} height={20}/>}
				showSearch
				allowClear={true}
				value={userSelected}
				// value={['sss', 'sssss'] as any}
				style={{ width: 200 }}
				placeholder="Vyber osobu"
				optionFilterProp="children"
			>
				options={ map(users, (option) => (
					<Option
						key={ option.value }
						value={ option.value }
					>
						{ option.label }
					</Option>
				)) }
			</Select>
		</Item>
	)
}

export default React.memo(SelectUserField)
