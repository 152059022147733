import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Empty, Table, Tabs, Tag } from 'antd'
import i18next from 'i18next'
import { debounce, get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PlusCircleOutlined } from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { useReactToPrint } from 'react-to-print'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { getFormInitialValues, initialize } from 'redux-form'
import { history } from '../../utils/history'
import { RootState } from '../../reducers'
import { FORM } from '../../utils/enums'
import { getStoreItemOrders, StoreItemOrderJob, StoreItemOrderTableItem } from '../../reducers/storeItemOrders/actions'

const { TabPane } = Tabs

const PAGE_SIZE = 20

type Columns = ColumnsType<StoreItemOrderTableItem>

enum STORE_ORDER_TYPE {
	MY = 'MY',
	OPEN = 'OPEN',
	FINISHED = 'FINISHED',
}

const MY_TAB_INDEX = 1
const OPEN_TAB_INDEX = 2
const FINISHED_TAB_INDEX = 3

type TabItem = {
	key: number
	id: number
	name: string
	type: STORE_ORDER_TYPE
}

// Dorobit filtrovanie tabov podlat typu zakazky a tiez vytvorit a otestovat backend ci funguje s parametrom store type

const StoreItemOrders = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const initialTabs = [
		{
			name: i18next.t('pages:Moje objednávky'),
			key: MY_TAB_INDEX,
			id: MY_TAB_INDEX,
			type: STORE_ORDER_TYPE.MY
		},
		{
			name: i18next.t('pages:Nerealizované objednávky'),
			key: OPEN_TAB_INDEX,
			id: OPEN_TAB_INDEX,
			type: STORE_ORDER_TYPE.OPEN
		},
		{
			name: i18next.t('pages:Ukončené objednávky'),
			key: FINISHED_TAB_INDEX,
			id: FINISHED_TAB_INDEX,
			type: STORE_ORDER_TYPE.FINISHED
		}
	] as TabItem[]

	const storeItemOrders = useSelector((state: RootState) => state.storeItemOrders.list)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.JOBS_FILTER)(state))

	const isLoadingList = storeItemOrders.isLoading

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: withDefault(NumberParam, PAGE_SIZE),
		page: withDefault(NumberParam, 1),
		storeType: withDefault(StringParam, STORE_ORDER_TYPE.MY),
		orderBy: withDefault(StringParam, 'id'),
		orderDirection: withDefault(StringParam, null)
	})

	const [tabState, setTabState] = useState({
		activeKey: initialTabs.find((tab) => tab.type === query.storeType)?.key,
		panes: initialTabs
	})

	useEffect(() => {
		dispatch(getStoreItemOrders({
			limit: query.limit,
			page: query.page,
			queryParams: {
				search: query.search,
				storeType: query.storeType,
				orderBy: 'id',
				orderDirection: query.orderDirection
			}
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderBy, query.orderDirection, query.storeType])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.JOBS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const columns: Columns = [
		{
			title: i18next.t('pages:id'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			width: 20,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Objednávka z'),
			dataIndex: 'jobFrom',
			key: 'jobFrom',
			ellipsis: true,
			width: 110,
			render: (value: StoreItemOrderJob) => value.name || '-'
		},
		{
			title: i18next.t('pages:Objednávka do'),
			dataIndex: 'jobTo',
			key: 'jobTo',
			ellipsis: true,
			width: 110,
			render: (value: StoreItemOrderJob) => value.name || '-'
		},
		{
			title: i18next.t('pages:Množstvo'),
			dataIndex: 'amount',
			key: 'amount',
			ellipsis: true,
			width: 110,
			render: (value: StoreItemOrderJob) => value || '-'
		},
		{
			title: i18next.t('pages:Položka'),
			dataIndex: 'storeItemName',
			key: 'storeItemName',
			ellipsis: true,
			width: 110,
			render: (value: StoreItemOrderJob) => value || '-'
		},
		{
			title: i18next.t('pages:Vykonaná objednávka'),
			dataIndex: 'orderExecuted',
			key: 'orderExecuted',
			ellipsis: true,
			width: 110,
			render(value) {
				return value ? <Tag color="green">
					{t('pages:Áno')}
				</Tag>
					: <Tag color="red">
						{t('pages:Nie')}
					</Tag>
			}
		}
	]

	const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<StoreItemOrderTableItem> | SorterResult<StoreItemOrderTableItem>[]) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		// @ts-ignore
		setQuery({
			...query,
			limit: PAGE_SIZE,
			page: pagination.current,
			...order
		})
	}

	const onTabChange = (activeKey: string) => {
		const activeKeyNumber = parseInt(activeKey, 10)

		setTabState({
			...tabState,
			activeKey: parseInt(activeKey, 10)
		})

		if (activeKeyNumber === MY_TAB_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_ORDER_TYPE.MY
			})
		} else if (activeKeyNumber === OPEN_TAB_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_ORDER_TYPE.OPEN
			})
		} else if (activeKeyNumber === FINISHED_TAB_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_ORDER_TYPE.FINISHED
			})
		}
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm, page: 1 }), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	return (
		<>
			<div className={ 'page-wrapper' }>
				<div className={ 'flex justify-end' }>
					{/* <Form.Item> */}
					{/*	<Input.Search onChange={ handleOnChange } style={ { width: 300 } } allowClear /> */}
					{/* </Form.Item> */}
					<div>
						<Button
							icon={<PlusCircleOutlined />}
							href={t('paths:storeOrderCreate|path')}
							type={'primary'}
						>
							{t('pages:Vytvoriť objednávku')}
						</Button>
					</div>
				</div>
				<Tabs
					type="card"
					onChange={onTabChange}
					activeKey={`${tabState.activeKey}`}
				>
					{tabState.panes.map((pane) => (
						<TabPane
							tab={
								(pane.key === OPEN_TAB_INDEX)
									? <Badge count={storeItemOrders.notExecutedOrders}>
										<span className={'mr-4'}>{pane.name}</span>
									</Badge> : pane.name
							}
							key={`${pane.key}`}
							id={`${pane.id}`}
						>
							<Table
								className={ 'general-table' }
								columns={ columns }
								dataSource={ storeItemOrders.tableData }
								onChange={ handleTableChange }
								style={ { marginTop: 0 } }
								showSorterTooltip={ false }
								pagination={ {
									pageSize: PAGE_SIZE,
									total: storeItemOrders.pagination?.totalCount,
									current: storeItemOrders.pagination?.page,
									showSizeChanger: false
								} }
								loading={ isLoadingList }
								onRow={ (record) => ({
									onClick: () => {
										history.push(`${t('paths:storeOrderDetail|path')}/${get(record, 'id')}`)
									}
								}) }
								locale={ {
									emptyText: (
										<Empty description={ t('pages:Žiadne dáta') } />
									)
								} }
								size={ 'small' }
							/>
						</TabPane>
					))}
				</Tabs>
			</div>
		</>
	)
}

StoreItemOrders.propTypes = {}

export default StoreItemOrders
