import React from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import PriceOffers from './PriceOffers'
import { Profile } from '../../reducers/user/userActions'
import { RootState } from '../../reducers'
import StatisticsDashboard from './components/StatisticsDashboard'

const PriceOffersTabs = () => {
	const { t } = useTranslation('pages')

	// Access user profile from the Redux store
	const profile = useSelector((state: RootState) => state.profile.profile.data)

	const hasSuperAdminFeature = (userProfile: Profile | null) => userProfile?.hasSuperAdminFeature || false
	// Check if user has the SuperAdmin feature

	return hasSuperAdminFeature(profile) ? (
		<Tabs defaultActiveKey="1" size={'large'}>
			<Tabs.TabPane tab={t('pages:Zoznam')} key="1">
				<PriceOffers />
			</Tabs.TabPane>
			<Tabs.TabPane tab={t('pages:Štatistiky')} key="2">
				<StatisticsDashboard />
			</Tabs.TabPane>
		</Tabs>
	) : (
		<PriceOffers />
	)
}

export default PriceOffersTabs
