import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { Select } from 'antd'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { useDispatch } from 'react-redux'
import { map } from 'lodash'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import i18next from 'i18next'
// eslint-disable-next-line import/no-cycle
import { getStoreItems, IStoreItemsPayload } from '../../../reducers/storeItems/actions'

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & any & {
	label?: string,
	showLabel?: string,
	placeholder?: string,
	customClass?: string,
	customInputClass?: string,
	customLabelClass?: string,
	required?: boolean,
	allowClear?: boolean
}

const { Option } = Select

const StoreItemSelectField = (props: Props) => {
	const {
		input,
		label,
		placeholder,
		showLabel,
		customClass,
		customLabelClass,
		meta: { touched, error },
		type,
		disabled,
		onInput,
		onKeyUp,
		suffix,
		required,
		min,
		onBlur,
		width,
		multiple,
		...rest
	} = props

	const dispatch: any = useDispatch()

	const { t } = useTranslation('pages')

	const [query, setQuery] = useState({
		search: '',
		limit: 100,
		page: 1
	})

	const [storeItemsList, setStoreItemsList] = useState<IStoreItemsPayload | null>(null)

	useEffect(() => {
		dispatch(getStoreItems(query.page, query.limit, {
			search: query.search
		}, (data: IStoreItemsPayload) => {
			setStoreItemsList(data)
		}))
	}, [dispatch, query.search])

	const checkValue = () => {
		if (input.value) {
			return input.value
		}

		if (multiple) {
			return []
		}

		return input.value
	}

	const onInputChange = (value: any) => {
		if (multiple) {
			input.onChange(value || [])
		} else {
			input.onChange(value || null)
		}
	}

	const onSearch = (value: any) => {
		setQuery(
			{
				...query,
				search: value,
				page: 1
			}
		)
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			<label className={ cx(customLabelClass, { required }) }>{label || t('components:Store Item')}</label>
			<div className={ 'input-field min-w-full' }>
				<Select
					{ ...input }
					value={ checkValue() }
					placeholder={ placeholder }
					type={ type }
					disabled={ disabled }
					className={ 'min-w-full' }
					onInput={ onInput }
					onKeyUp={ onKeyUp }
					required={ required }
					min={ min }
					onBlur={ onBlur }
					suffix={ suffix }
					filterOption={false}
					style={ { width } }
					onSearch={ onSearch }
					onChange={ onInputChange }
					notFoundContent={ <div><PlusOutlined/> { i18next.t('pages:No Store Items Found') } <b style={ { color: 'black' } }></b></div> }
					{ ...rest }
				>
					{storeItemsList?.tableData?.length !== 0
						&& map(storeItemsList?.tableData, (option) => (
							<Option
								key={ option.id || 0 }
								value={ option.id || 0 }
							>
								<div className={'w-full flex justify-between'}>
									<span className={'w-16'}>{ option.name }</span>
								</div>
							</Option>
						))
					}
				</Select>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

export default StoreItemSelectField
