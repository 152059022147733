import i18next from 'i18next'
import { emailRegEx } from '../../../utils/regex'

export default (values: any) => {
	const errors: any = {}

	if (!values.email) {
		errors.email = i18next.t('pages:Toto pole je povinné')
	}

	if (values.email && !emailRegEx.test(values.email)) {
		errors.email = i18next.t('pages:Email nie je platný')
	}

	if (!values.password) {
		errors.password = i18next.t('pages:Toto pole je povinné')
	}

	return errors
}
