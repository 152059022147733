export enum LOAD_SELECT_DOCUMENTS {
	START= 'LOAD_SELECT_DOCUMENTS_START',
	DONE= 'LOAD_SELECT_DOCUMENTS_DONE',
	FAILED= 'LOAD_SELECT_DOCUMENTS_FAILED'
}

export enum LOAD_DOCUMENT {
	START= 'LOAD_DOCUMENT_START',
	DONE= 'LOAD_DOCUMENT_DONE',
	FAILED= 'LOAD_DOCUMENT_FAILED'
}

export enum CREATE_DOCUMENTS {
	START= 'CREATE_DOCUMENTS_START',
	DONE= 'CREATE_DOCUMENTS_DONE',
	FAILED= 'CREATE_DOCUMENTS_FAILED'
}

export enum UPDATE_DOCUMENTS {
	START= 'UPDATE_DOCUMENTS_START',
	DONE= 'UPDATE_DOCUMENTS_DONE',
	FAILED= 'UPDATE_DOCUMENTS_FAILED'
}

export enum DELETE_DOCUMENT {
	START= 'DELETE_DOCUMENT_START',
	DONE= 'DELETE_DOCUMENT_DONE',
	FAILED= 'DELETE_DOCUMENT_FAILED'
}
