export enum LOAD_PROFILE {
	START= 'LOAD_PROFILE_START',
	DONE= 'LOAD_PROFILE_DONE',
	FAILED= 'LOAD_PROFILE_FAILED'
}

export enum SWITCH_PROFILE {
	START= 'SWITCH_PROFILE_START',
	DONE= 'SWITCH_PROFILE_DONE',
	FAILED= 'SWITCH_PROFILE_FAILED'
}

export enum LOGOUT_USER {
	LOGOUT = 'LOGOUT_USER'
}
