import React from 'react'
import { Field, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { Button, Col, Form, Row } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateForm from './validateStoreForm'
import { RootState } from '../../../reducers'
import { IStoreForm, IUserOrganization } from '../../../reducers/stores/actions'
import StoreItems from '../../storeItems/StoreItems'
import InputField from '../../../atoms/InputField'
import TableSwitchField from '../../../atoms/TableSwitchField'
// eslint-disable-next-line import/no-cycle
import TableVirtualStorePickerField from '../../storeItems/components/TableVirtualStorePickerField'

type ComponentProps = {
	onRemove: (id: number) => void,
	onRefresh: () => void,
	modalMode: boolean,
	isCreate: boolean,
	id: number | null
}

type Props = InjectedFormProps<IStoreForm, ComponentProps> & ComponentProps

const StoreForm = ({
	handleSubmit,
	modalMode,
	onRemove,
	invalid,
	pristine,
	isCreate,
	id
}: Props) => {
	const dispatch = useDispatch()

	const storeFormValues: IStoreForm = useSelector((state: RootState) => getFormValues(FORMS.STORE_FORM)(state)) as IStoreForm

	const detail = useSelector((state:RootState) => state.jobs.detail)

	const { t } = useTranslation('components')

	const virtualStore = (storeFormValues?.userOrganizations?.length) > 0

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ modalMode ? 24 : 18 } className={ 'grid' }>
					<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
						{!modalMode && <h3>{ t('pages:Pridať virtuálny sklad') }</h3>}
						<Field
							name={ 'address' }
							component={ InputField }
							label={ t('components:Názov') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
				{
					!modalMode && <Col span={ 6 } className={ 'grid' }>
						<div className={ 'flex direction-col justify-start sidebar-content' }>
							{ !isCreate && (
								<Field
									name={ 'updatedAt' }
									component={ TextField }
									disabled
									label={ t('components:Dátum poslednej úpravy') }
								/>
							) }

							<div className={ 'flex direction-col justify-center' }>
								<Button
									icon={ <SaveOutlined /> }
									onClick={ handleSubmit }
									disabled={ pristine || invalid }
									type={ 'primary' }
									style={ { marginTop: '10px' } }
								>
									{ t('pages:Uložiť') }
								</Button>

								<Button
									icon={ <DeleteOutlined /> }
									style={ { marginTop: '20px' } }
									type={'primary'}
									onClick={ () => {
										if (id) onRemove(id)
									} }
								>
									{virtualStore ? t('components:Odstrániť virtuálny sklad') : t('components:Odstrániť sklad') }
								</Button>
							</div>
						</div>
					</Col>
				}
			</Row>
			{ (id && (storeFormValues?.userOrganizations?.length) > 0) && <Col className={'grid mt-2'} span={ 24 }>
				<div className={'main-content store-container'}>
					<Col>
						<h3 className={'max-w-full h-10'}>{ t('pages:Nastavenie zdieľania skladových položiek medzi organizáciami') }</h3>
						<Field
							name={ 'userOrganizations' }
							component={ TableSwitchField }
							itemChecked={(userOrganization: IUserOrganization) => userOrganization.hasStore}
							onItemChange={(userOrganization: IUserOrganization) => ({
								...userOrganization,
								hasStore: !userOrganization.hasStore
							})}
							itemDisabled={() => false}
							label={ '' }
							size={ 'large' }
							required
						/>
					</Col>
					<Col>
						<h3 className={'max-w-full h-10'}>{ t('pages:Sklad spojený s pobočkou') }</h3>
						<Field
							name={ 'userOrganizations' }
							component={ TableVirtualStorePickerField }
							itemDisabled={(userOrganization: IUserOrganization) => !userOrganization.hasStore}
							label={ '' }
							size={ 'large' }
							required
						/>
					</Col>
					<Col>
						<h3 className={'max-w-full h-10'}>{ t('pages:Organizácia má vlastné skladové položky') }</h3>
						<Field
							name={ 'userOrganizations' }
							component={ TableSwitchField }
							itemChecked={(userOrganization: IUserOrganization) => {
								if (userOrganization.organizationHasItsOwnStoreItems === undefined) { return true } return userOrganization.organizationHasItsOwnStoreItems
							}}
							onItemChange={(userOrganization: IUserOrganization) => ({
								...userOrganization,
								organizationHasItsOwnStoreItems: !userOrganization.organizationHasItsOwnStoreItems
							})}
							itemDisabled={(userOrganization: IUserOrganization) => !userOrganization.hasStore}
							label={ '' }
							size={ 'large' }
							required
						/>
					</Col>
					<Col>
						<h3 className={'max-w-full h-10'}>{ t('pages:Organizácia má vlastné úlohy') }</h3>
						<Field
							name={ 'userOrganizations' }
							component={ TableSwitchField }
							itemChecked={(userOrganization: IUserOrganization) => {
								if (userOrganization.organizationHasItsOwnTasks === undefined) { return true } return userOrganization.organizationHasItsOwnTasks
							}}
							onItemChange={(userOrganization: IUserOrganization) => ({
								...userOrganization,
								organizationHasItsOwnTasks: !userOrganization.organizationHasItsOwnTasks
							})}
							itemDisabled={(userOrganization: IUserOrganization) => !userOrganization.hasStore}
							label={ '' }
							size={ 'large' }
							required
						/>
					</Col>
				</div>
			</Col>}
			{ id && <Row >
				<Col className={'grid  mt-2'}>
					<div className={'main-content'}>
						<h3 className={'ml-2 mt-2'}>{t('pages:Skladové položky')}</h3>
						<StoreItems
							addRemoveItemFeature={true}
							tableData={ null }
							initFilter={
								{ jobID: id, jobToID: null }
							}
							tableMode={true}
						/>
					</div>
				</Col>
			</Row> }
		</Form>
	)
}

const form = reduxForm<IStoreForm, ComponentProps>({
	form: FORMS.STORE_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(StoreForm)

export default form
