import i18next from 'i18next'

export default (values: any) => {
	const errors: any = {}

	if (!values.amount) {
		errors.amount = i18next.t('state:Toto pole je povinné')
	}

	return errors
}
