export enum LOAD_STORE {
	START= 'LOAD_STORE_START',
	DONE= 'LOAD_STORE_DONE',
	FAILED= 'LOAD_STORE_FAILED'
}

export enum CREATE_STORE {
	START= 'CREATE_STORE_START',
	DONE= 'CREATE_STORE_DONE',
	FAILED= 'CREATE_STORE_FAILED'
}

export enum UPDATE_STORE {
	START= 'UPDATE_STORE_START',
	DONE= 'UPDATE_STORE_DONE',
	FAILED= 'UPDATE_STORE_FAILED'
}

export enum REMOVE_STORE {
	START= 'REMOVE_STORE_START',
	DONE= 'REMOVE_STORE_DONE',
	FAILED= 'REMOVE_STORE_FAILED'
}
