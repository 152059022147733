import React from 'react'
import {
	Field, FieldArray, getFormValues, InjectedFormProps, reduxForm
} from 'redux-form'
import {
	Button, Col, Divider, Form, Row
} from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import validateForm from './validateStoreForm'
import { RootState } from '../../../reducers'
// eslint-disable-next-line import/no-cycle
import { IStoreItemOrderForm } from '../../../reducers/storeItemOrders/actions'
import SharedStoreSelectField, { SharedStoreOption } from './SharedStoreSelectField'
import { ItemOrderFields } from './ItemOrderFields'

type ComponentProps = {
	onRemove: (id: number) => void,
	onAcceptOrder: (id: number) => void,
	onRefresh: () => void,
	isCreate: boolean,
	id: number | null
}

type Props = InjectedFormProps<IStoreItemOrderForm, ComponentProps> & ComponentProps

const StoreItemOrderForm = ({
	handleSubmit,
	onRemove,
	invalid,
	pristine,
	isCreate,
	onAcceptOrder,
	id
}: Props) => {
	// const dispatch = useDispatch()

	const storeFormValues: IStoreItemOrderForm = useSelector((state: RootState) => getFormValues(FORMS.STORE_ITEM_ORDER_FORM)(state)) as IStoreItemOrderForm

	// const detail = useSelector((state:RootState) => state.storeItemOrders.detail)

	const { t } = useTranslation('components')

	// const virtualStore = (storeFormValues?.userOrganizations?.length) > 0

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
						{!id && <h3>{ t('pages:Vytvoriť objednávku') }</h3>}
						<Field
							isSupplierCallingTheRequest={storeFormValues?.isSupplierCallingTheRequest}
							supplySharedStore={storeFormValues?.supplySharedStore}
							name={ 'supplyStoreID' }
							component={ SharedStoreSelectField }
							label={ t('components:Objednávka z') }
							size={ 'large' }
							required
						/>

						<FieldArray
							id={id}
							isSupplierCallingTheRequest={storeFormValues?.isSupplierCallingTheRequest}
							name={'orders'}
							onAcceptOrder={onAcceptOrder}
							component={ItemOrderFields}
							jobFromID={storeFormValues?.supplyStoreID}
						/>
					</div>
				</Col>
				{
					<Col span={ 6 } className={ 'grid' }>
						<div className={ 'flex direction-col justify-start sidebar-content' }>
							<div className={ 'flex direction-col justify-center' }>
								{
									!id && <Button
										icon={ <SaveOutlined /> }
										onClick={ handleSubmit }
										disabled={ pristine || invalid }
										type={ 'primary' }
										style={ { marginTop: '10px' } }
									>
										{ t('pages:Uložiť') }
									</Button>
								}

								<Button
									icon={ <DeleteOutlined /> }
									style={ { marginTop: '20px' } }
									type={'primary'}
									onClick={ () => {
										if (id) onRemove(id)
									} }
								>
									{ t('components:Odstrániť objednávku') }
								</Button>
							</div>
						</div>
					</Col>
				}
			</Row>
		</Form>
	)
}

const form = reduxForm<IStoreItemOrderForm, ComponentProps>({
	form: FORMS.STORE_ITEM_ORDER_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(StoreItemOrderForm)

export default form
