import React from 'react'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'

const ConfirmationModal = ({
	item, loading, handleCancel, handleOk
}) => {
	const { t } = useTranslation('pages')
	return (
		<>
			<Modal
				visible={item}
				title={t('pages:Naozaj zmazať')}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={[
					<Button key="back" onClick={handleCancel}>
						{t('pages:Späť')}
					</Button>,
					<Button key="submit" type="primary" loading={loading} onClick={handleOk}>
						{t('pages:Zmazať')}
					</Button>
				]}
			>
				{item.address}
			</Modal>
		</>
	)
}

export default ConfirmationModal
