/* eslint-disable import/no-cycle */
import {
	get
} from 'lodash'
import i18next from 'i18next'

import { ThunkResult } from '../index'
import {
	AUTH_USER, USER
} from './authTypes'
import { IResetStore, RESET_STORE } from '../generalTypes'

// utils
import {
	clearAccessToken, setAccessToken
} from '../../utils/auth'
import { history } from '../../utils/history'

import { getReq, postReq } from '../../utils/request'

export type IUserActions = IResetStore | IGetAuthUserProfileActions | IGetUserActions

// user
interface IGetUserActions {
	type: USER
	payload: IUserPayload
}

export interface IUserPayload {
	data: AuthUser | null
	isLoading: boolean
	isFailure: boolean
}

// auth user
export interface IGetAuthUserProfileActions {
	type: AUTH_USER
	payload: IAuthUserPayload
}

export type AuthUser = {
	id: string
	email: string
	role: string
	fullName: string
}

export interface IAuthUserPayload {
	data: AuthUser | null
}

export const logInUser = (username: string, password: string): ThunkResult<void> => async (dispatch) => {
	try {
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_START })
		const { data } = await postReq('/api/admin/authorization/login', null, {
			username,
			password
		})

		const userData = data?.data?.user
		const authUserData: AuthUser = {
			id: userData.id,
			fullName: userData.fullName,
			role: userData?.role,
			email: userData?.email
		}

		const payload: any = {
			data: authUserData
		}
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_DONE, payload })
		setAccessToken(data?.data?.token)

		history.push(i18next.t('paths:index'))
		return null
	} catch (e) {
		history.push(i18next.t('paths:login|path'))
		dispatch({ type: AUTH_USER.AUTH_USER_LOAD_FAIL })
		// eslint-disable-next-line no-console
		console.log(e)
		return e
	}
}

export const logOutUser = (): ThunkResult<void> => (dispatch) => {
	clearAccessToken()

	dispatch({
		type: RESET_STORE
	})

	history.push(i18next.t('paths:login|path'))
}

export const getUser = (userID: number): ThunkResult<Promise<IUserPayload>> => async (dispatch) => {
	try {
		dispatch({ type: USER.USER_LOAD_START })

		const { data } = await getReq(`/api/v1/users/${userID}`)

		const payload: any = {
			data: get(data, 'user')
		}

		dispatch({ type: USER.USER_LOAD_DONE, payload })
		return payload
	} catch (e) {
		dispatch({ type: USER.USER_LOAD_FAIL })
		return e
	}
}
