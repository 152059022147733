import { Button, Input, Modal } from 'antd'
import React, { FC } from 'react'
import { ModalProps } from 'antd/lib/modal'
import { useTranslation } from 'react-i18next'
import { filter } from 'lodash'
import { CloseOutlined } from '@ant-design/icons'

type Props = ModalProps & {
	visible: boolean,
	initEmail: string[],
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	handleOk: (emails: string[]) => void,
}

const EmailPickerModal: FC<Props> = (props) => {
	const { t } = useTranslation('pages')

	const {
		visible,
		initEmail,
		onCancel,
		handleOk
	} = props

	const [emailList, setEmailList] = React.useState<string[]>([])
	const [email, setEmail] = React.useState<string>('')

	return (
		<Modal
			visible={visible}
			title={t('pages:Odosli cenovú ponuku na emaily')}
			onCancel={onCancel}
			footer={[
				<Button key='back' onClick={onCancel}>
					{t('pages:Zrušiť')}
				</Button>,
				<Button
					key='submit'
					type='primary'
					onClick={() => {
						handleOk(emailList)
					}}
				>
					{t('pages:Odoslať')}
				</Button>
			]}
		>
			{initEmail.concat(emailList)?.map((email, index) => (
				<div className={'mb-4'} key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div key={index}>
						{email}
					</div>
					{initEmail.includes(email) ? null : <CloseOutlined onClick={() => setEmailList(filter(emailList, (e) => e !== email))}/>}
				</div>
			))}
			<div
				style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Input
					className={'mr-5'}
					placeholder="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<Button
					onClick={() => {
						setEmailList([...emailList, email])
						setEmail('')
					}}
				>
					{t('pages:Pridať email')}
				</Button>
			</div>

		</Modal>
	)
}

export default EmailPickerModal
