import React, { FC, useEffect, useState } from 'react'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ModalProps } from 'antd/lib/modal'
import { DatePicker, Table, Timeline, Tooltip } from 'antd'
// eslint-disable-next-line import/no-cycle
import { get } from 'lodash'
// eslint-disable-next-line import/no-cycle
import { StringParam, useQueryParams, withDefault } from 'use-query-params'
// eslint-disable-next-line import/no-cycle
import moment from 'moment'
// eslint-disable-next-line import/no-cycle
import { EditOutlined } from '@ant-design/icons'
// eslint-disable-next-line import/no-cycle
import { getUserJob, IUserJob, IUserJobFinish } from '../../../reducers/usersU/actions'
import { history } from '../../../utils/history'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../reducers'
// eslint-disable-next-line import/no-cycle
import UpdateFinishQrCodeModal from './UpdateFinishQrCodeModal'
import { isWeekFrequency, translateFrequencyToSk } from '../../../utils/translate'

type Props = ModalProps & {
	userID: number | null | undefined,
}

const UserJobs: FC<Props> = (props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const {
		userID
	} = props

	const [isUpdateFinishQrCodeModalShowed, setUpdateFinishQrCodeModalShowed] = useState(false)
	const [finishID, setFinishID] = useState<number | null>(null)

	const userJobsData = useSelector((state: RootState) => state.users.jobs)?.data
	const userJobs = userJobsData?.jobs
	const hasPriceForCleaningFeature = useSelector((state: RootState) => state.profile.profile.data?.hasPriceForCleaningFeature)
	const allTimeWorked = userJobsData?.allTimeWorkedThisMonthFormatted
	const allMoneyEarned: number = userJobsData?.allMoneyEarned ? userJobsData?.allMoneyEarned : 0

	const [query, setQuery] = useQueryParams({
		month: withDefault(StringParam, null)
	})

	useEffect(() => {
		if (userID) {
			dispatch(getUserJob(
				userID,
				{
					month: query.month
				}
			))
		}
	}, [dispatch, query.month, userID])

	// @ts-ignore
	const columns: ColumnsType<IUserJob> = [
		{
			title: i18next.t('pages:Address'),
			dataIndex: 'address',
			key: 'address',
			hide: false,
			render(value: any) {
				return <div className={'h-full'}>
					<b>{value}</b>
				</div>
			}
		},
		{
			title: i18next.t('pages:Odpracované hodiny'),
			dataIndex: 'sumDuration',
			key: 'sumDuration',
			hide: false,
			render(value: any) {
				return <div className={'h-full'}>
					{value} min.
				</div>
			}
		},
		{
			title: i18next.t('pages:Ukončenia'),
			dataIndex: 'finishes',
			align: 'center',
			key: 'finishes',
			hide: false,
			render(value: any) {
				const finishes = value as IUserJobFinish[]
				return finishes?.length > 0 ? <Timeline mode={'left'}>
					{finishes?.map((finish) => <Timeline.Item
						className={'group'}
						key={finish.date}
						label={<span className={'font-semibold'}>{finish.date}</span>}>
						<div>
							{finish?.qrScans?.from} - {finish?.qrScans?.to}
							{finish?.qrScans?.duration ? <span className={'font-semibold text-xs ml-3'}>
								({finish?.qrScans?.duration})
							</span> : <span/>}
							<Tooltip
								placement="topLeft"
								title={translateFrequencyToSk(finish?.frequency)}
							>
								{
									finish?.frequency && <span className={
										isWeekFrequency(finish?.frequency)
											? 'font-semibold text-xs ml-3 text-green-500' : 'font-semibold text-xs ml-3 text-red-400'}>
								({translateFrequencyToSk(finish?.frequency)?.substr(0, 1)?.toUpperCase()})
									</span>
								}
							</Tooltip>
							<Tooltip
								placement="topLeft"
								title={t('pages:Upraviť')}
							>
								<EditOutlined
									className='ml-4 h-full invisible group-hover:visible'
									onClick={(e) => {
										setUpdateFinishQrCodeModalShowed(true)
										setFinishID(finish.id)
										e.stopPropagation()
									}}
								/>
							</Tooltip>
						</div>
					</Timeline.Item>)}
				</Timeline> : <div/>
			}
		},
		{
			title: i18next.t('pages:Cena za upratanie'),
			dataIndex: 'priceForCleaning',
			key: 'priceForCleaning',
			width: 120,
			hide: !hasPriceForCleaningFeature,
			render(value: any) {
				return <div className={'h-full'}>
					{value / 100} €
				</div>
			}
		},
		{
			title: i18next.t('pages:Celkovo'),
			dataIndex: 'priceForCleaningSum',
			key: 'priceForCleaningSum',
			width: 100,
			hide: !hasPriceForCleaningFeature,
			render(value: any) {
				return <div className={'h-full'}>
					{value / 100} €
				</div>
			}
		}
	].filter((item: any) => {
		console.log(`hide value is: ${item?.hide} ${hasPriceForCleaningFeature} `)
		return !item?.hide
	})

	const onMonthDateChange = (date: any, dateString: any) => {
		setQuery({
			...query,
			month: dateString
		})
	}

	const onFinishQrCodeUpdated = () => {
	}

	return (
		userID ? <div className={'page-wrapper hover:bg-violet-400'}>
			<h1>{ t('pages:Pridelené zákazky') }</h1>
			<div className={'flex mt-5'}>
				<div>
					<h4 className={''}>{ t('pages:Vyber mesiac') }</h4>
					<DatePicker defaultValue={moment(moment(), 'YYYY-MM') as any} onChange={onMonthDateChange} picker="month" />
				</div>
				<div className={'ml-6'}>
					<span>Odpracovaných hodín za mesiac: </span> <span className={'font-semibold'}>{allTimeWorked}</span>
				</div>
				{hasPriceForCleaningFeature && <div className={'ml-6'}>
					<span>Výplata: </span> <span className={'font-semibold'}>{allMoneyEarned / 100} €</span>
				</div>}
			</div>
			{(userJobs) ? <Table
				className={'mt-6'}
				rowClassName={'height1'}
				pagination={false}
				dataSource={userJobs}
				columns={columns}
				onRow={ (record) => ({
					onClick: () => history.push(`${t('paths:jobDetail|path')}/${get(record, 'id')}`)
				}) }
			/> : <div/>}
			<UpdateFinishQrCodeModal
				finishID={finishID}
				width={500}
				isVisible={isUpdateFinishQrCodeModalShowed}
				onCancel={() => {
					setUpdateFinishQrCodeModalShowed(false)
				}}
				onSuccess={onFinishQrCodeUpdated}
			/>
		</div> : <div/>
	)
}

UserJobs.propTypes = {}

export default UserJobs
