import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'redux'

// routes middlewares
import { get, map } from 'lodash'
import { notification } from 'antd'

// layouts
import Login from '../pages/login/LoginPage'
import AdminRoute from './AdminRoute'
import Layout from '../layouts/Layout'
import Dashboard from '../pages/dashboard/Dashboard'
import { axios } from '../utils/request'
import firebaseAdmin from '../utils/firebase'
import { clearAccessToken, clearProfile, setAccessToken } from '../utils/auth'
import { history } from '../utils/history'
import DetailUser from '../pages/users/DetailUser'
import Users from '../pages/users/Users'
import Jobs from '../pages/jobs/Jobs'
import DetailJob from '../pages/jobs/DetailJob'
import Tasks from '../pages/tasks/Tasks'
import DetailTask from '../pages/tasks/DetailTask'
import Finishes from '../pages/finishes/Finishes'
import Ratings from '../pages/ratings/Ratings'
import DetailDocument from '../pages/documents/DetailDocument'
import NotFoundPage from '../pages/NotFoundPage'
import BaseRoute from './BaseRoute'
import DocumentsDraggable from '../pages/documents/DocumentsDragable'
import StoreItems from '../pages/storeItems/StoreItems'
import StoreItemDetail from '../pages/storeItems/StoreItemDetail'
import Stores from '../pages/stores/Stores'
import StoreDetail from '../pages/stores/StoreDetail'
import StoreItemOrders from '../pages/storeItemOrders/StoreItemOrders'
import StoreItemOrderDetail from '../pages/storeItemOrders/StoreItemOrderDetail'
import DetailPriceOffer from '../pages/priceOffers/DetailPriceOffer'
import SettingsDetail from '../pages/settings/SettingsDetail'
import SuperAdminDetail from '../pages/superAdmin/SuperAdminDetail'
import StoreItemsReplacements from '../pages/storeItems/StoreItemsReplacements'
import PriceOffersTabs from '../pages/priceOffers/PriceOffersTabs'

type Props = WithTranslation

class Routes extends Component<Props> {
	constructor(props: Props) {
		super(props)
		axios.interceptors.response.use(this.responseSuccessHandler, this.responseErrorHandler)
	}

	responseErrorHandler = async (error: any) => {
		const { t } = this.props
		const messages = get(error, 'response.data.messages')
		const status = get(error, 'response.status')

		const key = 'updatable' // squash more notification, show only last message

		if (status >= 500) {
			notification.error({
				key,
				message: t('pages:Ups, niečo sa pokazilo')
			})
		} else if (status === 401) {
			const token = await firebaseAdmin?.auth()?.currentUser?.getIdToken(true)
			if (token) {
				setAccessToken(token)
				// notification.error({
				// 	key,
				// 	message: t('pages:Ups, skúste opäť')
				// })
				const { config } = error
				config.headers.Authorization = `Bearer ${token}`
				return new Promise((resolve, reject) => {
					axios.request(config).then((response) => {
						resolve(response)
					}).catch((error) => {
						reject(error)
					})
				})
			}
			map(messages, (message) => {
				notification.error({
					key,
					message: get(message, 'message', '')
				})
			})
			clearAccessToken()
			clearProfile()
			history.push(t('paths:login|path'))
		} else if (status === 403) {
			notification.error({
				key,
				message: get(messages, '[0].message', t('components:Spojenie so serverom bolo neúspešné'))
			})
		} else if (status >= 400) {
			if (get(error, 'response.data.messages')) {
				map(messages, (message) => {
					notification.error({
						key,
						message: get(message, 'message', '')
					})
				})
			} else {
				map(messages, (message) => {
					notification.error({
						key,
						message: get(message, 'message', '')
					})
				})
			}
		}

		return Promise.reject(error)
	}

	responseSuccessHandler = (response: any) => {
		if (get(response, 'status') === 200) {
			map(get(response, 'data.messages'), (message) => {
				notification.success({
					message: get(message, 'message', '')
				})
			})
		}
		return Promise.resolve(response)
	}

	render = () => {
		const { t } = this.props
		return (
			<Switch>

				<Route
					exact
					routeName={ t('paths:login|title') }
					routeKey={ t('paths:login|key') }
					path={ t('paths:login|path') }
					component={ Login }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:base|title') }
					routeKey={ t('paths:home|key') }
					path={ t('paths:home|path') }
					component={ Dashboard }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:appSettings|title') }
					routeKey={ t('paths:appSettings|key') }
					path={ t('paths:appSettings|path') }
					component={ SettingsDetail }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:appSuperAdmin|title') }
					routeKey={ t('paths:appSuperAdmin|key') }
					path={ t('paths:appSuperAdmin|path') }
					component={ SuperAdminDetail }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:base|title') }
					routeKey={ t('paths:home|key') }
					path={ t('paths:index') }
					component={ Dashboard }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:userDetailID|title') }
					routeKey={ t('paths:userDetailID|key') }
					path={ t('paths:userDetailID|path') }
					component={ DetailUser }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:userCreate|title') }
					routeKey={ t('paths:userCreate|key') }
					path={ t('paths:userCreate|path') }
					component={ DetailUser }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					{...this.props}
					routeName={ t('paths:users|title') }
					routeKey={ t('paths:users|key') }
					path={ t('paths:users|path') }
					component={ Users }
					layout={ Layout }
				/>

				{/* Jobs */}
				<AdminRoute
					exact
					routeName={ t('paths:jobs|title') }
					routeKey={ t('paths:jobs|key') }
					path={ t('paths:jobs|path') }
					component={ Jobs }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:jobDetailID|title') }
					routeKey={ t('paths:jobDetailID|key') }
					path={ t('paths:jobDetailID|path') }
					component={ DetailJob }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:jobCreate|title') }
					routeKey={ t('paths:jobCreate|key') }
					path={ t('paths:jobCreate|path') }
					component={ DetailJob }
					layout={ Layout }
				/>

				{/* Stores */}
				<AdminRoute
					exact
					routeName={ t('paths:stores|title') }
					routeKey={ t('paths:stores|key') }
					path={ t('paths:stores|path') }
					component={ Stores }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:jobStoreID|title') }
					routeKey={ t('paths:jobStoreID|key') }
					path={ t('paths:jobStoreID|path') }
					component={ StoreDetail }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:jobStoreCreate|title') }
					routeKey={ t('paths:jobStoreCreate|key') }
					path={ t('paths:jobStoreCreate|path') }
					component={ StoreDetail }
					layout={ Layout }
				/>
				{/* Store item orders */}
				<AdminRoute
					exact
					routeName={ t('paths:storeOrders|title') }
					routeKey={ t('paths:storeOrders|key') }
					path={ t('paths:storeOrders|path') }
					component={ StoreItemOrders }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:storeOrderCreate|title') }
					routeKey={ t('paths:storeOrderCreate|key') }
					path={ t('paths:storeOrderCreate|path') }
					component={ StoreItemOrderDetail }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					routeName={ t('paths:storeOrderDetailID|title') }
					routeKey={ t('paths:storeOrderDetailID|key') }
					path={ t('paths:storeOrderDetailID|path') }
					component={ StoreItemOrderDetail }
					layout={ Layout }
				/>

				{/* Tasks */}
				<AdminRoute
					exact
					routeName={ t('paths:tasks|title') }
					routeKey={ t('paths:tasks|key') }
					path={ t('paths:tasks|path') }
					component={ Tasks }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:taskDetailID|title') }
					routeKey={ t('paths:taskDetailID|key') }
					path={ t('paths:taskDetailID|path') }
					component={ DetailTask }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:taskCreate|title') }
					routeKey={ t('paths:taskCreate|key') }
					path={ t('paths:taskCreate|path') }
					component={ DetailTask }
					layout={ Layout }
				/>

				{/* PriceOffers */}
				<AdminRoute
					exact
					routeName={ t('paths:priceOffers|title') }
					routeKey={ t('paths:priceOffers|key') }
					path={ t('paths:priceOffers|path') }
					component={ PriceOffersTabs }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:priceOfferDetailID|title') }
					routeKey={ t('paths:priceOfferDetailID|key') }
					path={ t('paths:priceOfferDetailID|path') }
					component={ DetailPriceOffer }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:priceOfferCreate|title') }
					routeKey={ t('paths:priceOfferCreate|key') }
					path={ t('paths:priceOfferCreate|path') }
					component={ DetailPriceOffer }
					layout={ Layout }
				/>

				{/* Store - Items */}
				<AdminRoute
					exact
					routeName={ t('paths:storeItems|title') }
					routeKey={ t('paths:storeItems|key') }
					path={ t('paths:storeItems|path') }
					component={ StoreItems }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:storeItemsDetailID|title') }
					routeKey={ t('paths:storeItems|key') }
					path={ t('paths:storeItemsDetailID|path') }
					component={ StoreItemDetail }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:storeItemsCreate|title') }
					routeKey={ t('paths:storeItems|key') }
					path={ t('paths:storeItemsCreate|path') }
					component={ StoreItemDetail }
					layout={ Layout }
				/>

				{/* Store - Items Amounts */}
				<AdminRoute
					exact
					routeName={ t('paths:storeItemsAmounts|title') }
					routeKey={ t('paths:storeItemsAmounts|key') }
					path={ t('paths:storeItemsAmounts|path') }
					component={ StoreItemsReplacements }
					layout={ Layout }
				/>

				{/* Finishes */}
				<AdminRoute
					exact
					routeName={ t('paths:finishes|title') }
					routeKey={ t('paths:finishes|key') }
					path={ t('paths:finishes|path') }
					component={ Finishes }
					layout={ Layout }
				/>

				<AdminRoute
					exact
					routeName={ t('paths:ratings|title') }
					routeKey={ t('paths:ratings|key') }
					path={ t('paths:ratings|path') }
					component={ Ratings }
					layout={ Layout }
				/>
				{/* Documents */}
				<AdminRoute
					exact
					routeName={ t('paths:documents|title') }
					routeKey={ t('paths:documents|key') }
					path={ t('paths:documents|path') }
					component={ DocumentsDraggable }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:documentDetailID|title') }
					routeKey={ t('paths:documentDetailID|key') }
					path={ t('paths:documentDetailID|path') }
					component={ DetailDocument }
					layout={ Layout }
				/>
				<AdminRoute
					exact
					routeName={ t('paths:documentCreate|title') }
					routeKey={ t('paths:documentCreate|key') }
					path={ t('paths:documentCreate|path') }
					component={ DetailDocument }
					layout={ Layout }
				/>

				<BaseRoute
					component={ NotFoundPage }
					layout={ Layout }
				/>
			</Switch>
		)
	}
}

export default compose(
	withTranslation()
)(Routes)
