import { Field, FormSection } from 'redux-form'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { WrappedFieldArrayProps } from 'redux-form/lib/FieldArray'
import { Button, Tag } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import InputField from '../../../atoms/InputField'
import { IStoreItemOrder } from '../../../reducers/storeItemOrders/actions'
import StoreItemSelectField from './StoreItemSelectField'
import InputNumberField from '../../../atoms/InputNumberField'

type Props = WrappedFieldArrayProps<IStoreItemOrder> & {
	jobFromID: number | null,
	isSupplierCallingTheRequest: boolean | null,
	onAcceptOrder: (id: number) => void,
	id: number | null,
}

// eslint-disable-next-line import/prefer-default-export
export const ItemOrderFields = (param: Props): any => {
	// const dispatch = useDispatch()
	const { t } = useTranslation('components')
	// const storeFormValues: IStoreItemOrderForm = useSelector((state: RootState) => getFormValues(FORMS.STORE_ITEM_ORDER_FORM)(state)) as IStoreItemOrderForm

	const {
		jobFromID,
		isSupplierCallingTheRequest,
		onAcceptOrder,
		id
	} = param

	// useEffect(() => {
	// 	if (storeFormValues?.orders?.length === 0) {
	// 		dispatch(arrayInsert(FORMS.STORE_ITEM_ORDER_FORM, 'orders', 0, {
	// 			amount: 0,
	// 			comment: '',
	// 			storeItemID: null,
	// 			jobFromID,
	// 			jobToID: null
	// 		} as IStoreItemOrder))
	// 	}
	// }, [storeFormValues])

	return param?.fields?.map((field: any, index: number, fields: any) => {
		const detail = fields.get(index)
		const executed = detail?.orderExecuted
		return (
			<div key={index}>
				<FormSection name={field}>
					{
						id && <div className={'mt-4 mb-4'}>
							<b className={'mr-8'}>{t('components:Objednávka vykonaná  ')}</b>
							{executed
								? <Tag color="green">
									{t('pages:Áno')}
								</Tag>
								: <Tag color="red">
									{t('pages:Nie')}
								</Tag>}
						</div>
					}
					{jobFromID && <Field
						name={'storeItemID'}
						component={StoreItemSelectField}
						filterJobID={jobFromID}
						label={t('components:Položka')}
						size={'large'}
						required
					/>}
					{jobFromID && <Field
						name={'amount'}
						component={ InputNumberField }
						min={0}
						precision={0}
						step={1}
						max={1000}
						label={t('components:Počet kusov')}
						size={'large'}
						required
					/>}
					<Field
						name={ 'comment' }
						component={ InputField }
						label={ t('components:Komentár k objednávke') }
						size={ 'large' }
						maxLength={ 100 }
					/>
					{
						isSupplierCallingTheRequest && <Button
							icon={ <SendOutlined /> }
							style={ { marginTop: '20px' } }
							type={'primary'}
							onClick={ () => {
								if (isSupplierCallingTheRequest && id) onAcceptOrder(id)
							} }
						>
							{ t('components:Realizovať objednávku') }
						</Button>
					}
				</FormSection>
				{index + 1 !== fields.length && <div className={'vertical-divider'} />}
			</div>
		)
	})
}
