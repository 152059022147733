import decode from 'jwt-decode'
import firebaseAdmin from './firebase'

export const ACCESS_TOKEN_KEY = 'access_token'

export const USER_KEY = 'persist:USER'
export const LOGIN_URL = '/login'

export const clearAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY)
export const setAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token)

/**
 * Removes saved profile from session storage
 */
export function clearProfile() {
	localStorage.removeItem(USER_KEY)
}

/**
 * Redirect user into login page
 */
export function login() {
	window.location.replace(LOGIN_URL)
}

/**
 * @return boolean
 *
 * check if token is exist and try to decoded
 */
export function isLoggedIn() {
	try {
		const token = getAccessToken()
		if (!token) {
			return false
		}

		return true
	} catch (error) {
		return false
	}
}

/**
 * @return boolean
 *
 * check if token is exist and try to decoded
 */
export async function isLoggedInAsync() {
	try {
		if (getAccessToken()) {
			return true
		}
		const token = await firebaseAdmin?.auth()?.currentUser?.getIdToken(true)
		if (token) {
			setAccessToken(token)
		}
		// eslint-disable-next-line no-console
		console.log(`is login async: ${token}`)
		console.log('current user')
		console.log(firebaseAdmin.auth().currentUser)
		if (!token) {
			return false
		}

		return true
	} catch (error) {
		console.log('error')
		console.log(error)
		return false
	}
}
