import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import {
	IQueuesPayload, IFormTemplatePayload
} from './formTemplateActions'
// eslint-disable-next-line import/no-cycle
import { ILoadingAndFailure } from '../../types/interfaces'
import {
	FORM_TEMPLATE_QUEUES, FORM_TEMPLATE, FORM_EDIT_CHANGE, FORM_EDITED, RESET_FORM_TEMPLATE_STORE
} from './formTemplateTypes'

export const initState = {
	queue: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IQueuesPayload & ILoadingAndFailure,
	formTemplate: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IFormTemplatePayload & ILoadingAndFailure,
	disable: false,
	formEdited: false
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case FORM_TEMPLATE_QUEUES.FORM_TEMPLATE_QUEUES_LOAD_START:
			return {
				...state,
				queue: {
					...state.queue,
					isFailure: false,
					isLoading: true
				}
			}
		case FORM_TEMPLATE_QUEUES.FORM_TEMPLATE_QUEUES_LOAD_FAIL:
			return {
				...state,
				queue: {
					...initState.queue,
					isFailure: true
				}
			}
		case FORM_TEMPLATE_QUEUES.FORM_TEMPLATE_QUEUES_LOAD_DONE:
			return {
				...state,
				queue: {
					...initState.queue,
					data: action.payload.data
				}
			}
		case FORM_TEMPLATE.FORM_TEMPLATE_LOAD_START:
			return {
				...state,
				formTemplate: {
					...state.formTemplate,
					isFailure: false,
					isLoading: true
				}
			}
		case FORM_TEMPLATE.FORM_TEMPLATE_LOAD_FAIL:
			return {
				...state,
				formTemplate: {
					...initState.formTemplate,
					isFailure: true
				}
			}
		case FORM_TEMPLATE.FORM_TEMPLATE_CHANGE_BACKUP_STATUS:
			return {
				...state,
				formTemplate: {
					...state.formTemplate,
					data: {
						...state.formTemplate.data,
						backup: {
							...state.formTemplate.data?.backup,
							status: action.status
						}
					}
				}
			}
		case FORM_TEMPLATE.FORM_TEMPLATE_LOAD_DONE:
			return {
				...state,
				formTemplate: {
					...initState.formTemplate,
					data: action.payload.data
				}
			}
		case FORM_EDIT_CHANGE:
			return {
				...state,
				disable: action.disabled
			}
		case FORM_EDITED:
			return {
				...state,
				formEdited: action.edited
			}
		case RESET_FORM_TEMPLATE_STORE:
			return initState
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
