import { mapValues, get } from 'lodash'
import { postReq } from './request'
import { UPLOAD_CATEGORY } from './enums'

// eslint-disable-next-line
export function uploadFileByGCP(fileToUpload, uploadCategory = UPLOAD_CATEGORY.PDF_DOCUMENT) {
	// eslint-disable-next-line
	return new Promise(async (resolve, reject) => {
		try {
			const { name, type } = fileToUpload

			const queries = {
				filename: name,
				category: uploadCategory
			}

			const normalizeQueryParams = mapValues(queries, (query) => (query || undefined))
			const fd = new FormData()
			fd.append('file', fileToUpload, name)
			const response = await postReq('api/admin/upload/private', normalizeQueryParams, fd, { 'content-type': type })
			const { data } = response
			const fileUrl = encodeURI(get(data, 'data.fileUrl'))
			const fileId = encodeURI(get(data, 'data.fileId'))
			// return resolve(encodeURI(get(data, 'fileUrl')))
			return resolve({
				fileUrl,
				fileId
			})
		} catch (err) {
			console.log('error uploading')
			return reject(err)
		}
	})
}
