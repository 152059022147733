import React from 'react'
import {
	Field, reduxForm, InjectedFormProps
} from 'redux-form'
import {
	Col, Form, Row
} from 'antd'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateDocumentCategoryForm from './validateDocumentCategoryForm'
import { IDocumentForm } from '../../../reducers/documents/actions'

type ComponentProps = {
	isCreate: boolean
}

type Props = InjectedFormProps<IDocumentForm, ComponentProps> & ComponentProps

const DocumentCategoryForm = (props: Props) => {
	const { t } = useTranslation('components')

	const {
		handleSubmit
	} = props

	return (
		<Form onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 18 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<Field
							name={ 'name' }
							component={ TextField }
							label={ t('components:Názov Kategórie') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IDocumentForm, ComponentProps>({
	form: FORMS.DOCUMENT_CATEGORY_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateDocumentCategoryForm
})(DocumentCategoryForm)

export default form
