import React, {
	FC, useCallback, useEffect, useState
} from 'react'
import { Button, Modal, Select } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { useDispatch, useSelector } from 'react-redux'
// assets
import { useTranslation } from 'react-i18next'
import { StringParam, withDefault } from 'use-query-params'
import { debounce, map } from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import i18next from 'i18next'
import { initialize, submit } from 'redux-form'
import { ReactComponent as CloseIcon } from '../../../assets/icons/BaseIcons/x-icon.svg'
import { ReactComponent as ChevronIcon } from '../../../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { getJobs, JobTableItem } from '../../../reducers/jobs/actions'
import { STORE_TYPE } from '../../stores/Stores'
import { RootState } from '../../../reducers'
import { FORMS } from '../../../utils/enums'
// eslint-disable-next-line import/no-cycle
import { createStore, IStoreForm } from '../../../reducers/stores/actions'
// eslint-disable-next-line import/no-cycle
import StoreModalForm from '../../stores/forms/StoreModalForm'

// eslint-disable-next-line import/no-cycle

interface IStoreName {
	id: number,
	organizationID: number,
	name: string
}

type Props = ModalProps & {
	isVisible: boolean,
	organizationStoreName: string,
	organizationID: number,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: (storeName: IStoreName) => void,
	classNameBtn?: string
}

const CreateVirtualStoreModal: FC<Props> = (props) => {
	const {
		isVisible,
		organizationStoreName,
		organizationID,
		onCancel,
		onSuccess,
		width,
		classNameBtn
	} = props

	const { t } = useTranslation('pages')

	const dispatch: any = useDispatch()

	const initEmptyDetailForm = () => {
		const initValues = {
			userId: '',
			address: '',
			updatedAt: '',
			objectTrusteeEmail: '',
			ownerEmail: '',
			tasks: []
		}

		dispatch(initialize(FORMS.STORE_MODAL_FORM, initValues))
	}

	const stores = useSelector((state: RootState) => state.jobs.jobs)

	const [query, setQuery] = useState({
		search: '',
		limit: 100,
		page: 1,
		orderDirection: withDefault(StringParam, null)
	})

	const [selectedStore, setSelectedStore] = useState({
		storeID: 0,
		storeName: ''
	})

	const [isModalVisible, setIsModalVisible] = useState(false)

	useEffect(() => {
		if (isModalVisible) {
			initEmptyDetailForm()
		}
	}, [isModalVisible])

	useEffect(() => {
		dispatch(getJobs({
			limit: query.limit,
			page: 1,
			queryParams: {
				organizationID,
				search: query.search,
				storeType: STORE_TYPE.VIRTUAL_STORE,
				orderBy: 'address',
				orderDirection: 'asc'
			}
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderDirection, organizationID, isModalVisible])

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({
		...query,
		search: searchTerm,
		page: 1
	}), 300), [query])

	const onSearch = (searchTerm: string) => {
		debounced(searchTerm)
	}

	const handleCreate = (body: IStoreForm) => {
		dispatch(createStore(body, (StoreId) => {
			setIsModalVisible(false)
			onSuccess({
				id: StoreId,
				organizationID,
				name: body.address
			})
		}))
	}

	const handleSubmit = (values: IStoreForm) => {
		const body = {
			userID: values.userID,
			address: values.address,
			userOrganizations: values.userOrganizations,
			organizationID
		} as IStoreForm

		handleCreate({
			...body
		})
	}

	const onInputChange = (value: any) => {
		const resultStore: JobTableItem | undefined = stores.tableData?.find((store) => store.address === value)
		if (resultStore) {
			setSelectedStore({
				storeID: resultStore.id,
				storeName: resultStore.address
			})
		}
	}

	const showModal = () => {
		setIsModalVisible(true)
	}

	return <><Modal
		closeIcon={<CloseIcon width={18} height={18} />}
		title={
			isModalVisible ? `${t('loc:Vytvoriť virtuálny sklad v ')} ${organizationStoreName}`
				: `${t('loc:Vyber virtuálny sklad v organizácii')} ${organizationStoreName}`
		}
		centered
		visible={isVisible}
		width={width}
		onCancel={onCancel}
		footer={[
			<Button
				style={{
					width: 118,
					height: 40
				}}
				key='back'
				type='text'
				onClick={(e) => {
					if (isModalVisible) {
						setIsModalVisible(false)
					} else {
						onCancel(e)
					}
				}}
			>
				{t('loc:Zrušiť')}
			</Button>,
			<Button
				disabled={selectedStore.storeName === '' && !isModalVisible}
				style={{
					width: 118,
					height: 40
				}} className={classNameBtn || ''} key='submit' type='primary' onClick={() => {
					if (isModalVisible) {
						dispatch(submit(FORMS.STORE_MODAL_FORM))
					} else {
						onSuccess({
							organizationID,
							name: selectedStore.storeName,
							id: selectedStore.storeID
						})
					}
				}}
			>
				{isModalVisible ? t('loc:Vytvoriť') : t('loc:Vybrať')}
			</Button>
		]}
	>
		{
			isModalVisible ? <StoreModalForm
				onSubmit={ handleSubmit }
			/> : <div>
				<Select
					onBlur={() => {
					}}
					showSearch={true}
					className={'dropDown w-full'}
					size={'large'}
					value={selectedStore.storeName}
					placeholder={t('loc:Select procedure')}
					disabled={false}
					onSearch={onSearch}
					onChange={onInputChange}
					suffixIcon={<ChevronIcon width={20} height={20} />}
					notFoundContent={<Button
						onClick={showModal}
						style={{
							padding: 0,
							width: '100%'
						}}
						type='text'
					>
						<div><PlusOutlined />
							{i18next.t('pages: Vytvoriť virtuálny sklad')} <b style={{ color: 'black' }}>{organizationStoreName}</b></div>
					</Button>}
				>
					{map(stores.tableData, (store) => <Select.Option
						key={store.key}
						value={store.address}
					>
						{store.address}
					</Select.Option>)}
				</Select>
			</div>
		}
	</Modal>
	</>
}

export default CreateVirtualStoreModal
