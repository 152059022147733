import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, initialize } from 'redux-form'
import { get } from 'lodash'
import { Spin, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined } from '@ant-design/icons'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import StoreForm from './forms/StoreForm'
import {
	createStore, getStore, IStoreForm, removeStore, updateStore
} from '../../reducers/stores/actions'
import { IComputedMatch } from '../../types/interfaces'
import { RootState } from '../../reducers'

// fake data generator
const getItems = (count: number) => Array.from({ length: count }, (v, k) => k)
	.map((k) => ({
		id: `item-${k}`,
		content: `item ${k}`
	}))

type Props = {
	computedMatch: IComputedMatch<{ id: number }>
	trusteePopup: boolean
}

const StoreDetail = (props: Props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	const detail = useSelector((state: RootState) => state.stores.detail)

	const isLoading = detail.isLoading || false

	const { id } = props.computedMatch?.params

	const initEmptyDetailForm = () => {
		const initValues = {
			userId: '',
			address: '',
			updatedAt: '',
			objectTrusteeEmail: '',
			ownerEmail: '',
			tasks: getItems((Math.random() * 10) + 1)
		}

		dispatch(initialize(FORMS.STORE_FORM, initValues))
	}

	const initDetailForm = (data: IStoreForm) => {
		const initValues = {
			userID: get(data, 'user.id'),
			address: get(data, 'address'),
			updatedAt: get(data, 'updatedAt'),
			userOrganizations: get(data, 'userOrganizations')
		}
		dispatch(initialize(FORMS.STORE_FORM, {
			...initValues
		}))
	}

	useEffect(() => {
		if (id) {
			dispatch(getStore(id, (data) => {
				// eslint-disable-next-line no-use-before-define
				initDetailForm(data)
			}))
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [props.computedMatch])

	const handleCreate = (body: IStoreForm) => {
		dispatch(createStore(body, (StoreId) => history.push(`${t('paths:storeDetail|path')}/${StoreId}`)))
	}

	const handleUpdate = (body: IStoreForm) => {
		dispatch(updateStore(id, body, () => {
			dispatch(getStore(id, (data) => {
				initDetailForm(data)
			}))
		}))
	}

	const handleRefresh = () => {
		dispatch(getStore(id, (data) => {
			initDetailForm(data)
		}))
	}

	const handleRemove = (detailID: number) => {
		Modal.confirm({
			title: t('pages:Skutočne si prajete odstrániť virtuálny sklad?'),
			icon: <DeleteOutlined />,
			okText: t('pages:Odstrániť'),
			cancelText: t('pages:Zrušiť'),
			okType: 'danger',
			onOk: async () => {
				await dispatch(removeStore(detailID, (data) => {
					history.push(t('paths:stores|path'))
				}))
			}
		})
	}

	const handleSubmit = (values: IStoreForm) => {
		const body = {
			userID: values.userID,
			address: values.address,
			userOrganizations: values.userOrganizations
		} as IStoreForm

		if (id) {
			handleUpdate({
				...body
			})
		} else {
			handleCreate({
				...body
			})
		}
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<StoreForm
					modalMode={ false }
					onSubmit={ handleSubmit }
					onRefresh={ handleRefresh }
					onRemove={ handleRemove }
					isCreate={ !id }
					id={id}
				/>
			</Spin>
		</div>
	)
}

export default StoreDetail
