import React, { FC } from 'react'
import { ColumnsType } from 'antd/es/table'
import { useTranslation } from 'react-i18next'
import i18next, { TFunction } from 'i18next'
import { ModalProps } from 'antd/lib/modal'
import { notification, Table, Tag } from 'antd'
// eslint-disable-next-line import/extensions
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { history } from '../../../utils/history'
// eslint-disable-next-line import/no-cycle
import { IStoreAddressItem } from '../../../reducers/storeItems/actions'
// eslint-disable-next-line import/no-cycle
import { removeItemsFromDeletedJob } from '../../../reducers/stores/actions'
// eslint-disable-next-line import/no-cycle
import { STORE_TYPE } from '../../stores/Stores'
// eslint-disable-next-line import/no-cycle
import { colorHash } from '../StoreItems'

type Props = ModalProps & {
	itemID: number
	amountOfStockInAddresses: IStoreAddressItem[]
}

const MAIN_STORE_ID = -1

export function getPriorityForStoreType(storeType: STORE_TYPE | null, t: TFunction): string | null {
	if (storeType === null) {
		return null
	}
	switch (storeType) {
		case STORE_TYPE.VIRTUAL_STORE:
			return t('pages:Virtuálny sklad')
		case STORE_TYPE.JOB_STORE:
			return t('pages:Pracovisko')
		case STORE_TYPE.EMPLOYEE_STORE:
			return t('pages:Zamestnanec')
		default:
			return null
	}
}

const AddressWithStocksTable: FC<Props> = (props) => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const {
		itemID,
		amountOfStockInAddresses
	} = props

	const columns: ColumnsType<IStoreAddressItem> = [
		{
			title: i18next.t('pages:Address'),
			dataIndex: 'address',
			key: 'address',
			render(value) {
				return <div className={'h-full'}>
					<b>{value}</b>
				</div>
			}
		},
		{
			title: i18next.t('pages:Typ'),
			dataIndex: 'storeType',
			key: 'storeType',
			render(value) {
				return <div className={'h-full'}>
					{ value && <Tag color={colorHash(value || '')}>{getPriorityForStoreType(value, t)}</Tag> }
				</div>
			}
		},
		{
			title: '',
			align: 'right',
			dataIndex: 'addressDeleted',
			key: 'addressDeleted',
			render(value) {
				return <div>
					{ (value) && <Tag color="red">{i18next.t('pages:Vymazaná adresa')}</Tag>}
				</div>
			}
		},
		{
			title: i18next.t('pages:Množstvo'),
			align: 'right',
			dataIndex: 'amount',
			key: 'amount',
			render(value) {
				return <div>
					{value} kusov
				</div>
			}
		}
	]

	return (
		<Table
			className={'mt-6'}
			rowClassName={'height1'}
			pagination={false}
			dataSource={amountOfStockInAddresses}
			columns={columns}
			onRow={ (record) => ({
				onClick: async () => {
					const id = get(record, 'id')
					const type = get(record, 'storeType')
					if (id !== MAIN_STORE_ID) {
						if (record.addressDeleted) {
							notification.warning({
								message: t('pages:Adresa bola vymazaná')
							})
							if (id) {
								// todo dorob dialog na vymazanie itemov, takzvanych duchov. Tie co existuju nad pobockami, ktore su uz vymazane
								await dispatch(removeItemsFromDeletedJob(id, itemID, (data) => {

								}))
							}
						} else if (type === STORE_TYPE.EMPLOYEE_STORE) {
							const userId = get(record, 'userIdForEmployeeStoreType')
							history.push(`${t('paths:userDetail|path')}/${userId}`)
						} else if (type === STORE_TYPE.VIRTUAL_STORE) {
							history.push(`${t('paths:storeDetail|path')}/${get(record, 'id')}`)
						} else if (type === STORE_TYPE.JOB_STORE) {
							history.push(`${t('paths:storeDetail|path')}/${get(record, 'id')}`)
						}
					}
				}
			}) }
		/>
	)
}

AddressWithStocksTable.propTypes = {}

export default AddressWithStocksTable
