import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { get } from 'lodash'
import { Spin } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { createUser, getUser, IUserCreateParams, IUserUpdateParams, updateUser } from '../../reducers/usersU/actions'
import UserForm, { IUserForm } from './forms/UserForm'
import { FORMS } from '../../utils/enums'
import { history } from '../../utils/history'
import PasswordChangeModal from '../../components/PasswordChangeModal'
import { IComputedMatch } from '../../types/interfaces'
import { RootState } from '../../reducers'

type Props = {
	computedMatch: IComputedMatch<{ id: number }>
	trusteePopup: boolean
}

const DetailUser = (props: Props) => {
	const { t } = useTranslation('pages')
	const { computedMatch, trusteePopup } = props
	const dispatch = useDispatch()

	const [showPasswordChangeModal, setShowPasswordChangeModal] = useState<Boolean | null>(false)

	const detail = useSelector((state: RootState) => state.users.detail)

	const isLoading = get(detail, 'isLoading')

	const { id } = computedMatch?.params || {}

	const initDetailForm = (data: IUserForm) => {
		const initValues = {
			id: get(data, 'id'),
			firstName: get(data, 'firstName'),
			role: get(data, 'role'),
			lastName: get(data, 'lastName'),
			contactEmail: get(data, 'contactEmail'),
			email: get(data, 'email'),
			phone: get(data, 'phone'),
			isConfirmed: get(data, 'isConfirmed') ? t('pages:Áno') : t('pages:Nie'),
			order: get(data, 'order') || 1,
			lastLoginAt: moment(data.lastLoginAt).format('H:mm:ss D. MMM YYYY ')
		}

		dispatch(initialize(FORMS.USER_FORM, initValues))
	}

	const initEmptyDetailForm = () => {
		const initValues = {
			order: 1
		}

		dispatch(initialize(FORMS.USER_FORM, initValues))
	}

	useEffect(() => {
		if (id) {
			dispatch(getUser(id, (data) => {
				// eslint-disable-next-line no-use-before-define
				initDetailForm(data)
			}))
		} else {
			// eslint-disable-next-line no-use-before-define
			initEmptyDetailForm()
		}
	}, [dispatch])

	const handleUpdate = (body: IUserUpdateParams) => {
		dispatch(updateUser(id, body, () => {
			dispatch(getUser(id, (data) => {
				initDetailForm(data)
			}))
		}))
	}

	const handleCreate = (body: IUserCreateParams) => {
		dispatch(createUser(body, (userID) => history.replace(`${t('paths:userDetail|path')}/${userID}`)))
	}

	const handleSubmit = (values: IUserCreateParams | IUserUpdateParams) => {
		if (id) {
			handleUpdate(values)
		} else {
			handleCreate({
				...values
			})
		}
	}

	const submitPasswordChange = () => {
		// dispatch(changeUserPasswordByAdmin(id, { newPassword }, () => {
		// 	setShowPasswordChangeModal(null)
		// }))
		setShowPasswordChangeModal(null)
	}

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ isLoading }>
				<UserForm
					onSubmit={ handleSubmit }
					isCreate={ !id }
					userID={id}
					trusteePopup={ trusteePopup }
					setShowPasswordChangeModal={ setShowPasswordChangeModal }
					onRefreshPage={ () => dispatch(getUser(id, (data) => initDetailForm(data))) }
				/>
				{ showPasswordChangeModal && <PasswordChangeModal userID={ id } submitPasswordChange={ submitPasswordChange } setShowModal={ setShowPasswordChangeModal } /> }
			</Spin>
		</div>
	)
}

export default DetailUser
