import React from 'react'
import { useDispatch } from 'react-redux'
import { Col, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import MyOrganizations from './components/MyOrganizations'

type Props = {
}

const SuperAdminDetail = (props: Props) => {
	const { t } = useTranslation('pages')
	const dispatch = useDispatch()

	return (
		<div className={ 'page-wrapper' }>
			<Spin spinning={ false }>
				<Col className={'grid mt-4'}>
					<div className={'flex direction-col justify-start main-content'}>
						<h3 className={'ml-2'}>{t('pages:Osobné skladové položky')}</h3>
						<MyOrganizations />
					</div>
				</Col>
			</Spin>
		</div>
	)
}

export default SuperAdminDetail
