import React, { useCallback } from 'react'
import { Input, Form } from 'antd'
import cx from 'classnames'
import { WrappedFieldProps } from 'redux-form'
import { InputProps } from 'antd/lib/input'
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel'
import { trimStart } from 'lodash'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { gray } from 'tailwindcss/colors'

const { Item } = Form

type Props = WrappedFieldProps & InputProps & FormItemLabelProps & {
	search?: boolean // NOTE: only for search inputs fields -> renders diffrent styles
	customOnBlur?: (value: string | null) => any
	hideHelp?: boolean
}

const InputPasswordField = (props: Props) => {
	const {
		input,
		size,
		placeholder,
		label,
		required,
		type,
		prefix,
		disabled,
		style,
		search,
		customOnBlur,
		meta: {
			error,
			touched
		},
		hideHelp,
		maxLength
	} = props

	const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		// NOTE: prevent to have "" empty string as empty value
		const val = e.target.value ? e.target.value : null
		input.onChange(trimStart(val as string))
	}, [input])

	const onBlur = useCallback(async (e) => {
		// NOTE: prevent to have "" empty string as empty value
		const val = e.target.value ? e.target.value : null

		// NOTE: wait until redux-form "BLUR" action is finished
		await input.onBlur(val)

		if (customOnBlur) {
			customOnBlur(val)
		}
	}, [input, customOnBlur])

	const onFocus = useCallback(async (e) => {
		// NOTE: prevent to have "" empty string as empty value
		const val = e.target.value ? e.target.value : null
		if (input.onFocus) {
			input.onFocus(val)
		}
	}, [input])

	return (
		<Item
			label={label}
			required={required}
			style={style}
			help={hideHelp ? undefined : (touched && error)}
			validateStatus={(error && touched) ? 'error' : undefined}
		>
			<Input.Password
				{...input}
				className={cx({ search })}
				onChange={onChange}
				onBlur={onBlur}
				size={size || 'middle'}
				onFocus={onFocus}
				value={input.value}
				placeholder={placeholder}
				type={type || 'text'}
				prefix={prefix}
				disabled={disabled}
				maxLength={maxLength}
				iconRender={(visible: any) => (visible ? <EyeTwoTone twoToneColor={gray['300']} /> : <EyeInvisibleOutlined twoToneColor={gray['300']}/>)}
			/>
		</Item>
	)
}

export default React.memo(InputPasswordField)
