import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Empty, Table } from 'antd'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

import { ColumnsType } from 'antd/lib/table'
import { getOrganizationInfoStats, IOrganizationInfoPayload, IOrganizationInfoRecord } from '../../../reducers/statistics/actions'

type Columns = ColumnsType<IOrganizationInfoRecord>

type Props = {
}

export interface CreateTransactionInTableProps {
}

const MyOrganizations = (props: Props = {
	tableData: null, initFilter: null, tableMode: false, addRemoveItemFeature: true
}) => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const [data, setData] = React.useState<IOrganizationInfoPayload | null>()

	useEffect(() => {
		dispatch(getOrganizationInfoStats((data) => {
			setData(data)
		}))
	}, [dispatch])

	const columns: Columns = [
		{
			title: i18next.t('pages:Názov'),
			dataIndex: 'organizationName',
			key: 'organizationName',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Počet zamestnancov'),
			dataIndex: 'workerCount',
			key: 'workerCount',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Mesiac'),
			dataIndex: 'date',
			key: 'date',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Počet uprataní v aktuálnom mesiaci'),
			dataIndex: 'finishCount',
			key: 'finishCount',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		}
	]

	return (
		<div className={'page-wrapper'}>
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={data?.data || []}
				style={{ marginTop: 0 }}
				showSorterTooltip={false}
				pagination={false}
				locale={{
					emptyText: (
						<Empty description={t('pages:Žiadne dáta')} />
					)
				}}
				size={'small'}
			/>
		</div>
	)
}

export default MyOrganizations
