export enum LOAD_SELECT_TASKS {
	START= 'LOAD_SELECT_TASKS_START',
	DONE= 'LOAD_SELECT_TASKS_DONE',
	FAILED= 'LOAD_SELECT_TASKS_FAILED'
}

export enum LOAD_TASK {
	START= 'LOAD_TASK_START',
	DONE= 'LOAD_TASK_DONE',
	FAILED= 'LOAD_TASK_FAILED'
}

export enum CREATE_TASKS {
	START= 'CREATE_TASKS_START',
	DONE= 'CREATE_TASKS_DONE',
	FAILED= 'CREATE_TASKS_FAILED'
}

export enum UPDATE_TASKS {
	START= 'UPDATE_TASKS_START',
	DONE= 'UPDATE_TASKS_DONE',
	FAILED= 'UPDATE_TASKS_FAILED'
}

export enum DELETE_TASK {
	START= 'DELETE_TASK_START',
	DONE= 'DELETE_TASK_DONE',
	FAILED= 'DELETE_TASK_FAILED'
}
