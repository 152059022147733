export enum LOAD_USERS {
	START= 'LOAD_USERS_START',
	DONE= 'LOAD_USERS_DONE',
	FAILED= 'LOAD_USERS_FAILED'
}

export enum LOAD_DETAIL_USER {
	START= 'LOAD_DETAIL_USER_START',
	DONE= 'LOAD_DETAIL_USER_DONE',
	FAILED= 'LOAD_DETAIL_USER_FAILED'
}

export enum LOAD_USER_JOBS {
	START= 'LOAD_USER_JOBS_START',
	DONE= 'LOAD_USER_JOBS_DONE',
	FAILED= 'LOAD_USER_JOBS_FAILED'
}

export enum DELETE_USER {
	START= 'DELETE_USER_START',
	DONE= 'DELETE_USER_DONE',
	FAILED= 'DELETE_USER_FAILED'
}

export enum CREATE_USER {
	START= 'CREATE_USER_START',
	DONE= 'CREATE_USER_DONE',
	FAILED= 'CREATE_USER_FAILED'
}

export enum UPDATE_USER {
	START= 'UPDATE_USER_START',
	DONE= 'UPDATE_USER_DONE',
	FAILED= 'UPDATE_USER_FAILED'
}
