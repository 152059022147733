import { RESET_STORE } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import {
	IUserActions, IUsersPayload, IUserPayload
} from './userActions'
// eslint-disable-next-line import/no-cycle
import { ILoadingAndFailure } from '../../types/interfaces'
import {
	USERS
} from './userTypes'

export const initState = {
	users: {
		originalData: [],
		tableData: [],
		pagination: null,
		usersOptions: [],
		userPermissions: [],
		isLoading: false,
		isFailure: false
	} as IUsersPayload & ILoadingAndFailure,
	user: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IUserPayload & ILoadingAndFailure
}

export default (state = initState, action: IUserActions) => {
	switch (action.type) {
		// Users
		case USERS.USERS_LOAD_START:
			return {
				...state,
				users: {
					...state.users,
					isFailure: false,
					isLoading: true
				}
			}
		case USERS.USERS_LOAD_FAIL:
			return {
				...state,
				users: {
					...initState.users,
					isFailure: true
				}
			}
		case USERS.USERS_LOAD_DONE:
			return {
				...state,
				users: {
					...initState.users,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
