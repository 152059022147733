import React, { useEffect } from 'react'
import {
	Field, reduxForm, InjectedFormProps
} from 'redux-form'
import {
	Col, Form, Row
} from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { FORMS } from '../../../utils/enums'
import TextField from '../../../atoms/form/TextField'
import validateFinishQrCodeForm from './validateDocumentCategoryForm'
// eslint-disable-next-line import/no-cycle
import { getFinishDetail, IFinishForm } from '../../../reducers/finishes/actions'
import TimeField from '../../../atoms/TimeField'
import InputField from '../../../atoms/InputField'

type ComponentProps = {
	finishID: number | null
}

type Props = InjectedFormProps<IFinishForm, ComponentProps> & ComponentProps

const FinishQrCodeForm = (props: Props) => {
	const { t } = useTranslation('components')

	const dispatch: any = useDispatch()

	const {
		finishID,
		handleSubmit
	} = props

	useEffect(() => {
		if (finishID) {
			dispatch(getFinishDetail(
				finishID,
				((data) => {
					console.log(data)
				})
			))
		}
	}, [dispatch, finishID])

	return (
		<Form
			layout={'vertical'}
			onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 24 } className={ 'grid' }>
					<div className={ 'flex direction-col justify-start main-content' }>
						<Field
							name={ 'name' }
							component={ TextField }
							label={ t('components:Ukončenie') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
			</Row>
			<Row gutter={ 24 }>
				<Col className="gutter-row" span={8}>
					<Field
						component={InputField}
						label={t('components:Trvanie')}
						placeholder={t('components:Trvanie')}
						name='durationTime'
						minuteStep={10} />
				</Col>
				<Col className="gutter-row" span={8}>
					<Field
						component={TimeField}
						label={t('components:Začiatok')}
						placeholder={t('components:Začiatok')}
						name='startTime'
						minuteStep={10} />
				</Col>
				<Col className="gutter-row" span={8}>
					<Field
						component={TimeField}
						label={t('components:Koniec')}
						placeholder={t('components:Koniec')}
						name='endTime'
						minuteStep={8} />
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IFinishForm, ComponentProps>({
	form: FORMS.FINISH_QR_CODE_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateFinishQrCodeForm
})(FinishQrCodeForm)

export default form
