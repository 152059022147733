import { Button, Modal, Select } from 'antd'
import React, { FC, useEffect } from 'react'
import { ModalProps } from 'antd/lib/modal'
import { useTranslation } from 'react-i18next'
import { filter } from 'lodash'
import { FREQUENCY, translateFrequencyToSk, translateFrequencyToSkPriceOffer } from '../../../utils/translate'

const { Option } = Select

type Props = ModalProps & {
	visible: boolean,
	filterUsedFrequency: FREQUENCY[],
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	handleOk: (frequency: FREQUENCY) => void,
}

const ConfirmationModal: FC<Props> = (props) => {
	const { t } = useTranslation('pages')

	const {
		visible,
		filterUsedFrequency,
		onCancel,
		handleOk
	} = props

	const possibleFrequencies = filter(Object.values(FREQUENCY), (frequency) => !filterUsedFrequency.includes(frequency)
		&& frequency !== FREQUENCY.WEEK
		&& frequency !== FREQUENCY.SUNDAY
		&& frequency !== FREQUENCY.SATURDAY)

	const [frequency, setFrequency] = React.useState(possibleFrequencies.length > 0 ? possibleFrequencies[0] : FREQUENCY.MONDAY)

	return (
		<Modal
			visible={visible}
			title={t('pages:Pridaj freqenciu pre zazmlúvnené práce')}
			onCancel={onCancel}
			footer={[
				<Button key='back' onClick={onCancel}>
					{t('pages:Zrušiť')}
				</Button>,
				<Button
					key='submit'
					type='primary'
					onClick={() => {
						const frequencyFilterPickedOne = filter(Object.values(possibleFrequencies), (frequencyInList) => frequency !== frequencyInList)
						setFrequency(frequencyFilterPickedOne.length > 0 ? frequencyFilterPickedOne[0] : FREQUENCY.MONDAY)
						handleOk(frequency)
					}}
				>
					{t('pages:Pridať')}
				</Button>
			]}
		>
			<Select
				defaultValue={FREQUENCY.MONDAY}
				style={{ width: '100%' }}
				onChange={
					(value) => {
						setFrequency(value)
					}
				}
				value={frequency}
			>
				{filter(Object.values(FREQUENCY), (frequency) => !filterUsedFrequency.includes(frequency)
					&& frequency !== FREQUENCY.WEEK
					&& frequency !== FREQUENCY.SUNDAY
					&& frequency !== FREQUENCY.SATURDAY).map((frequency) => (
					<Option value={frequency} key={frequency}>
						{t(`components:${translateFrequencyToSkPriceOffer(frequency)}`)}
					</Option>
				))}
			</Select>
		</Modal>
	)
}

export default ConfirmationModal
