import {
	LOAD_TASK, LOAD_SELECT_TASKS
} from './types'
// eslint-disable-next-line import/no-cycle
import { ITaskActions, ITaskPayload, ITasksPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	tasks: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as ITasksPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	} as ITaskPayload & ILoadingAndFailure
}

export default (state = initState, action: ITaskActions) => {
	switch (action.type) {
		case LOAD_SELECT_TASKS.START:
			return {
				...state,
				tasks: {
					...state.tasks,
					isFailure: false,
					isLoading: true
				} as ITasksPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_TASKS.DONE:
			return {
				...state,
				tasks: {
					...initState.tasks,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as ITasksPayload & ILoadingAndFailure
			}
		case LOAD_SELECT_TASKS.FAILED:
			return {
				...state,
				tasks: {
					...initState.tasks,
					isFailure: true
				} as ITasksPayload & ILoadingAndFailure
			}
		case LOAD_TASK.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as ITaskPayload & ILoadingAndFailure
			}
		case LOAD_TASK.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as ITaskPayload & ILoadingAndFailure
			}
		case LOAD_TASK.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as ITaskPayload & ILoadingAndFailure
			}
			// case CREATE_TASKS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_TASKS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case CREATE_TASKS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}
			// case UPDATE_TASKS.START:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: true,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_TASKS.DONE:
			// 	return {
			// 		...initState,
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: false
			// 		}
			// 	}
			// case UPDATE_TASKS.FAILED:
			// 	return {
			// 		...state,
			// 		detail: {
			// 			...state.detail,
			// 			isLoading: false,
			// 			isFailure: true
			// 		}
			// 	}

		default:
			return state
	}
}
