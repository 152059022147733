import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Empty, Form, Popconfirm, Table } from 'antd'
import i18next from 'i18next'
import { debounce, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface'
import { getFormInitialValues, initialize } from 'redux-form'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { createStoreItemReplacement, deleteStoreItemReplacement, getStoreItemReplacements, IStoreItemReplacementForm, StoreItemReplacementTableItem, updateStoreItemReplacement } from '../../reducers/storeItemReplacements/actions'
import { RootState } from '../../reducers'
import { FORM } from '../../utils/enums'
import SelectStoreField from '../../components/SelectStoreField'
import StoreItemReplacementModal from './replacementForm/StoreItemReplacementsModal'
import SelectUserField from '../../components/SelectUserField'
import SelectStoreItemField from '../../components/SelectStoreItemField'

type Columns = ColumnsType<StoreItemReplacementTableItem>

interface IInitFilter {
	jobID: number | null,
}

type Props = {
	tableMode: Boolean,
	tableData: StoreItemReplacementTableItem[] | null | undefined,
	initFilter: IInitFilter | null,
	addRemoveItemFeature: Boolean
}

const StoreItemReplacements = (props: Props = {
	tableData: null, initFilter: null, tableMode: false, addRemoveItemFeature: true
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')
	const { tableData } = props

	const dataSource = useSelector((state: RootState) => state.storeItemReplacements.storeItemReplacements)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.STORE_ITEM_REPLACEMENT_FILTER)(state))

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [modalInitialValues, setModalInitialValues] = useState<IStoreItemReplacementForm | null | undefined>(null)

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: withDefault(NumberParam, 40),
		page: withDefault(NumberParam, 1),
		jobID: withDefault(NumberParam, null),
		employeeID: withDefault(NumberParam, null),
		storeItemID: withDefault(NumberParam, null),
		orderBy: withDefault(StringParam, 'dateOfReplacement'),
		orderDirection: withDefault(StringParam, null)
	})

	useEffect(() => {
		if (props.tableData == null) {
			dispatch(getStoreItemReplacements(query.page, query.limit, {
				search: query.search,
				jobID: query.jobID,
				orderBy: query.orderBy,
				orderDirection: query.orderDirection,
				employeeID: query.employeeID,
				storeItemID: query.storeItemID
			}))
		}
	}, [dispatch, query.search, query.limit, query.page, query.orderBy, query.orderDirection, query.jobID, query.employeeID, query.storeItemID])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null,
				jobID: 320
			}
			dispatch(initialize(FORM.STORE_ITEM_REPLACEMENT_FILTER, initData))
		}
	}, [dispatch, filterInitialValues, query.search])

	const columns: Columns = [
		{
			title: i18next.t('pages:Date of Replacement'),
			dataIndex: 'dateOfReplacement',
			key: 'dateOfReplacement',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Amount'),
			dataIndex: 'amount',
			key: 'amount',
			width: 210,
			sorter: true,
			render(amount, record: StoreItemReplacementTableItem) {
				return <div><span>{record?.isLiquid ? `${amount} %` : `${amount / 100}`}</span></div>
			}
		},
		{
			title: i18next.t('pages:Store Item Name'),
			dataIndex: 'storeItemName',
			key: 'storeItemName',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Job Address'),
			dataIndex: 'jobAddress',
			key: 'jobAddress',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:User Employee Name'),
			dataIndex: 'userEmployeeName',
			key: 'userEmployeeName',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: i18next.t('pages:Admin Name'),
			dataIndex: 'adminName',
			key: 'adminName',
			ellipsis: true,
			sorter: true,
			width: 210,
			render: (value) => value || '-'
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 40,
			render(text, record) {
				return (
					<div className={'flex justify-center'}>
						{
							!props.tableMode ? <Popconfirm
								title={ i18next.t('pages:Are you sure you want to delete this record?') }
								icon={ <QuestionCircleOutlined style={ { color: 'red' } } /> }
								cancelText={ i18next.t('pages:Cancel') }
								okText={ i18next.t('pages:Delete') }
								onConfirm={ (e) => {
									e?.stopPropagation()
									dispatch(deleteStoreItemReplacement(record.id, () => {
										dispatch(getStoreItemReplacements(1, query.limit))
									}))
								} }
								onCancel={ (e) => e?.stopPropagation() }
								okButtonProps={ {
									size: 'small',
									type: 'primary'
								} }
								cancelButtonProps={ {
									size: 'small',
									type: 'ghost'
								} }
							>
								<Button
									icon={ <DeleteOutlined /> }
									type={ 'primary' }
									onClick={ (e) => e.stopPropagation() }
								/>
							</Popconfirm> : <div/>
						}
					</div>
				)
			}
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 40,
			render(text, record) {
				return (
					<div className={'flex justify-center'}>
						{
							<EditOutlined onClick={(e) => {
								setModalInitialValues({
									id: record.id,
									isLiquid: record.isLiquid,
									dateOfReplacement: record.dateOfReplacementRaw,
									amount: record.isLiquid ? record.amount : record.amount / 100,
									jobID: record.jobID,
									storeItemID: record.storeItemID
								})
								setIsModalVisible(true)
								e.stopPropagation()
							}}/>
						}
					</div>
				)
			}
		}
	]

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, FilterValue | null>,
		sorter: SorterResult<StoreItemReplacementTableItem> | SorterResult<StoreItemReplacementTableItem>[],
		extra: TableCurrentDataSource<StoreItemReplacementTableItem>
	) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setQuery({
			...query,
			limit: query.limit,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm, limit: 30 }), 300), [query])
	const changeLimit = useCallback(debounce((limit: number) => setQuery({ ...query, page: 1, limit }), 300), [query])
	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const handleModalCancel = () => {
		setIsModalVisible(false)
		setModalInitialValues(null)
	}

	const handleModalSubmit = (values: IStoreItemReplacementForm) => {
		if (values.id) {
			dispatch(updateStoreItemReplacement(
				values.id,
				{
					...values,
					amount: values.isLiquid ? values.amount : values.amount * 100
				},
				() => {
					dispatch(getStoreItemReplacements(query.page, query.limit, {
						search: query.search,
						jobID: query.jobID,
						orderBy: query.orderBy,
						orderDirection: query.orderDirection
					}))
					setIsModalVisible(false)
				},
				() => {
					setIsModalVisible(false)
				}
			))
		} else {
			dispatch(createStoreItemReplacement(
				{
					...values,
					amount: values.isLiquid ? values.amount : values.amount * 100
				},
				() => {
					dispatch(getStoreItemReplacements(query.page, query.limit, {
						search: query.search,
						jobID: query.jobID,
						orderBy: query.orderBy,
						orderDirection: query.orderDirection
					}))
					setIsModalVisible(false)
				},
				() => {
					setIsModalVisible(false)
				}
			))
		}
		setIsModalVisible(false)
	}

	return (
		<div className={'page-wrapper'}>
			{!props.tableMode
				? <div className={'flex justify-between'}>
					<div>
						<Form.Item>
							<SelectStoreField
								initWithVirtualStore={false}
								onValueChange={(data) => {
									setQuery({
										...query,
										jobID: data.value
									})
								}}
								storeValue={query.jobID || undefined}
							/>
							<SelectUserField
								onValueChange={(data) => {
									setQuery({
										...query,
										employeeID: data.value
									})
								}}
								userValue={query.employeeID || undefined}
							/>
							<div className={'flex justify-start'}>
								<SelectStoreItemField
									onValueChange={(data) => {
										setQuery({
											...query,
											storeItemID: data.value
										})
									}}
									storeItemValue={query.storeItemID || undefined}
								/>
								{(dataSource?.pagination?.amountOfOneItem && dataSource?.pagination?.amountOfOneItem > 0)
									&& <span className={'mb-2 ml-6 font-semibold'}>
										{t('pages:Celkové množstvo položky ')} {dataSource?.pagination?.amountOfOneItem / 100}
									</span>}
							</div>
						</Form.Item>
					</div>
					<div>
						<Button
							className={'ml-4'}
							icon={<PlusCircleOutlined />}
							onClick={() => {
								setModalInitialValues({
									id: null,
									dateOfReplacement: '',
									isLiquid: false,
									amount: 0,
									jobID: null,
									storeItemID: null
								})
								setIsModalVisible(true)
							}}
							type={'primary'}
						>
							{t('pages:Pridať záznam')}
						</Button>
					</div>
				</div> : <div />
			}
			<Table
				className={'general-table'}
				columns={columns}
				dataSource={tableData || dataSource.tableData}
				onChange={handleTableChange}
				style={{ marginTop: 0 }}
				showSorterTooltip={false}
				pagination={props.tableData != null ? false : {
					total: dataSource.pagination?.totalCount,
					current: dataSource.pagination?.page,
					showSizeChanger: true,
					pageSize: query.limit,
					pageSizeOptions: ['40', '100', '1000'],
					onShowSizeChange: (current, size) => {
						changeLimit(size)
					}
				}}
				loading={dataSource.isLoading}
				onRow={(record) => ({
					// onClick: () => history.push(`${t('paths:storeItemReplacementDetail|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: (
						<Empty description={t('pages:No data')} />
					)
				}}
				size={'small'}
			/>

			<StoreItemReplacementModal
				visible={isModalVisible}
				onCancel={handleModalCancel}
				onSubmit={handleModalSubmit}
				initialValues={modalInitialValues}
				isCreate={!modalInitialValues?.id}
				id={modalInitialValues?.id || 0}
			/>
		</div>
	)
}

export default StoreItemReplacements
