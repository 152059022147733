import {
	LOAD_DOCUMENT_CATEGORIES
} from './types'
import { ILoadingAndFailure } from '../../types/interfaces'
// eslint-disable-next-line import/no-cycle
import { IDocumentCategoryActions, IDocumentCategoryPayload } from './actions'

export const initState = {
	documentCategories: {
		originalData: [],
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IDocumentCategoryPayload & ILoadingAndFailure,
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: IDocumentCategoryActions) => {
	switch (action.type) {
		case LOAD_DOCUMENT_CATEGORIES.START:
			return {
				...state,
				documentCategories: {
					...state.documentCategories,
					isFailure: false,
					isLoading: true
				} as IDocumentCategoryPayload & ILoadingAndFailure
			}
		case LOAD_DOCUMENT_CATEGORIES.DONE:
			return {
				...state,
				documentCategories: {
					...initState.documentCategories,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IDocumentCategoryPayload & ILoadingAndFailure
			}
		case LOAD_DOCUMENT_CATEGORIES.FAILED:
			return {
				...state,
				documentCategories: {
					...initState.documentCategories,
					isFailure: true
				} as IDocumentCategoryPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
