import React, {
	useCallback, useEffect, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	Empty,
	Form,
	Input, Modal,
	Table, Tabs
} from 'antd'
import i18next from 'i18next'
import { debounce, get, isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import {
	PlusCircleOutlined, QrcodeOutlined
} from '@ant-design/icons'

import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FilterValue, SorterResult } from 'antd/lib/table/interface'
import { useReactToPrint } from 'react-to-print'
import {
	NumberParam, StringParam, useQueryParams, withDefault
} from 'use-query-params'
import { getFormInitialValues, initialize } from 'redux-form'
// eslint-disable-next-line import/no-cycle
import {
	getJobs, JobTableItem
} from '../../reducers/jobs/actions'
import { history } from '../../utils/history'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../reducers'
import { ComponentToPrint } from '../../components/QrCode/ComponentToPrint'
import { FORM } from '../../utils/enums'

const { TabPane } = Tabs

const PAGE_SIZE = 20

type Columns = ColumnsType<JobTableItem>

export enum STORE_TYPE {
	ALL = 'ALL',
	JOB_STORE = 'JOB_STORE',
	EMPLOYEE_STORE = 'EMPLOYEE_STORE',
	VIRTUAL_STORE = 'VIRTUAL_STORE',
}

const ALL_INDEX = 0
const EMPLOYEE_INDEX = 1
const VIRTUAL_STORE_INDEX = 2
const JOB_STORE_INDEX = 3

type TabItem = {
	key: number
	id: number
	name: string
	type: STORE_TYPE
}

// Dorobit filtrovanie tabov podlat typu zakazky a tiez vytvorit a otestovat backend ci funguje s parametrom store type

const Stores = () => {
	const dispatch = useDispatch()

	const { t } = useTranslation('pages')

	const initialTabs = [
		// TODO odstran problem pri listovani cez vsetky typy, aktualne nevracia vsetky polozky
		// {
		// 	name: i18next.t('pages:Všetky'),
		// 	key: ALL_INDEX,
		// 	id: ALL_INDEX
		// },
		{
			name: i18next.t('pages:Zákazky'),
			key: JOB_STORE_INDEX,
			id: JOB_STORE_INDEX,
			type: STORE_TYPE.JOB_STORE
		},
		{
			name: i18next.t('pages:Položky-zamestnanci'),
			key: EMPLOYEE_INDEX,
			id: EMPLOYEE_INDEX,
			type: STORE_TYPE.EMPLOYEE_STORE
		},
		{
			name: i18next.t('pages:Virtuálne sklady'),
			key: VIRTUAL_STORE_INDEX,
			id: VIRTUAL_STORE_INDEX,
			type: STORE_TYPE.VIRTUAL_STORE
		}
	] as TabItem[]

	const [visibleGenerateQrCodeModal, setVisibleGenerateQrCodeModal] = useState(false)

	const jobs = useSelector((state: RootState) => state.jobs.jobs)
	const filterInitialValues = useSelector((state: RootState) => getFormInitialValues(FORM.JOBS_FILTER)(state))

	const isLoadingList = jobs.isLoading

	const [query, setQuery] = useQueryParams({
		search: StringParam,
		limit: withDefault(NumberParam, PAGE_SIZE),
		page: withDefault(NumberParam, 1),
		storeType: withDefault(StringParam, STORE_TYPE.JOB_STORE),
		orderBy: withDefault(StringParam, 'address'),
		orderDirection: withDefault(StringParam, null)
	})

	const [tabState, setTabState] = useState({
		activeKey: initialTabs.find((tab) => tab.type === query.storeType)?.key,
		panes: initialTabs
	})

	useEffect(() => {
		dispatch(getJobs({
			limit: query.limit,
			page: query.page,
			queryParams: {
				search: query.search,
				storeType: query.storeType,
				orderBy: query.orderBy,
				orderDirection: query.orderDirection
			}
		}))
	}, [dispatch,
		query.search, query.limit, query.page, query.orderBy, query.orderDirection, query.storeType])

	useEffect(() => {
		if (!filterInitialValues || isEmpty(filterInitialValues)) {
			const initData = {
				search: query.search || null
			}
			dispatch(initialize(FORM.JOBS_FILTER, initData))
		}
	}, [
		dispatch, filterInitialValues,
		query.search
	])

	const columns: Columns = [
		{
			title: i18next.t('pages:Názov skladu'),
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			width: 110,
			render: (value) => value || '-'
		}
	]

	const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<JobTableItem> | SorterResult<JobTableItem>[]) => {
		let order = {}
		if ((sorter as SorterResult<any>)?.order) {
			const sorterLocal = (sorter as SorterResult<any>)
			order = {
				orderBy: sorterLocal.field,
				orderDirection: sorterLocal.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		// @ts-ignore
		setQuery({
			...query,
			limit: PAGE_SIZE,
			page: pagination.current,
			...order
		})
	}

	const onTabChange = (activeKey: string) => {
		const activeKeyNumber = parseInt(activeKey, 10)

		setTabState({
			...tabState,
			activeKey: parseInt(activeKey, 10)
		})

		if (activeKeyNumber === ALL_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_TYPE.ALL
			})
		} else if (activeKeyNumber === EMPLOYEE_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_TYPE.EMPLOYEE_STORE
			})
		} else if (activeKeyNumber === VIRTUAL_STORE_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_TYPE.VIRTUAL_STORE
			})
		} else if (activeKeyNumber === JOB_STORE_INDEX) {
			setQuery({
				...query,
				page: 1,
				storeType: STORE_TYPE.JOB_STORE
			})
		}
	}

	const debounced = useCallback(debounce((searchTerm: string) => setQuery({ ...query, search: searchTerm, page: 1 }), 300), [query])

	const handleOnChange = ((e: React.ChangeEvent<HTMLInputElement>) => {
		debounced(e.target.value)
	})

	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	})

	return (
		<>
			<div className={ 'page-wrapper' }>
				<div className={ 'flex justify-between' }>
					<Form.Item>
						<Input.Search onChange={ handleOnChange } style={ { width: 300 } } allowClear />
					</Form.Item>
					<div>
						<Button
							icon={<QrcodeOutlined />}
							className={'mx-4'}
							type={'primary'}
							onClick={() => setVisibleGenerateQrCodeModal(true)}
						>
							{t('pages:Generuj Qr Kody')}
						</Button>
						<Button
							icon={<PlusCircleOutlined />}
							href={t('paths:jobStoreCreate|path')}
							type={'primary'}
						>
							{t('pages:Pridať Virtuálny sklad')}
						</Button>
					</div>
				</div>
				<Tabs
					type="card"
					onChange={onTabChange}
					activeKey={`${tabState.activeKey}`}
				>
					{tabState.panes.map((pane) => (
						<TabPane tab={pane.name} key={`${pane.key}`} id={`${pane.id}`}>
							<Table
								className={ 'general-table' }
								columns={ columns }
								dataSource={ jobs.tableData }
								onChange={ handleTableChange }
								style={ { marginTop: 0 } }
								showSorterTooltip={ false }
								pagination={ {
									pageSize: PAGE_SIZE,
									total: jobs.pagination?.totalCount,
									current: jobs.pagination?.page,
									showSizeChanger: false
								} }
								loading={ isLoadingList }
								onRow={ (record) => ({
									onClick: () => {
										const activeKeyNumber = tabState.activeKey
										console.log(`activeKeyNumber ${activeKeyNumber}`)
										if (activeKeyNumber === ALL_INDEX) {
											history.push(`${t('paths:jobDetail|path')}/${get(record, 'id')}`)
										} else if (activeKeyNumber === EMPLOYEE_INDEX) {
											history.push(`${t('paths:userDetail|path')}/${get(record, 'user.id')}`)
										} else if (activeKeyNumber === VIRTUAL_STORE_INDEX) {
											history.push(`${t('paths:storeDetail|path')}/${get(record, 'id')}`)
										} else if (activeKeyNumber === JOB_STORE_INDEX) {
											history.push(`${t('paths:storeDetail|path')}/${get(record, 'id')}`)
										}
									}
								}) }
								locale={ {
									emptyText: (
										<Empty description={ t('pages:Žiadne dáta') } />
									)
								} }
								size={ 'small' }
							/>
						</TabPane>
					))}
				</Tabs>
			</div>
			<Modal
				title={t('pages:Vytlač qr kódy')}
				centered
				visible={visibleGenerateQrCodeModal}
				onOk={() => {
					setVisibleGenerateQrCodeModal(false)
					if (handlePrint) {
						handlePrint()
					}
				}}
				onCancel={() => setVisibleGenerateQrCodeModal(false)}
				width={1000}
			>
				<Button
					type={'primary'}
					onClick={handlePrint}
				>
					{t('pages:Vytlačiť')}
				</Button>
				<ComponentToPrint ref={componentRef} list={jobs.tableData.map((data) => ({
					name: data.address,
					data: {
						jobID: data.id,
						jobName: data.address
					}
				}))}/>
			</Modal>
		</>
	)
}

Stores.propTypes = {}

export default Stores
