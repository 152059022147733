export enum LOAD_FINISHES_PER_MONTH {
	START= 'LOAD_FINISHES_PER_MONTH_START',
	DONE= 'LOAD_FINISHES_PER_MONTH_DONE',
	FAILED= 'LOAD_FINISHES_PER_MONTH_FAILED'
}

export enum LOAD_PRICE_OFFERS_SENT_PER_MONTH {
	START= 'LOAD_PRICE_OFFERS_SENT_PER_MONTH_START',
	DONE= 'LOAD_PRICE_OFFERS_SENT_PER_MONTH_DONE',
	FAILED= 'LOAD_PRICE_OFFERS_SENT_PER_MONTH_FAILED'
}

export enum LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH {
	START= 'LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH_START',
	DONE= 'LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH_DONE',
	FAILED= 'LOAD_PRICE_OFFERS_COMPLETED_PER_MONTH_FAILED'
}

export enum LOAD_SUCCESS_RATE_PER_PERSON {
	START= 'LOAD_SUCCESS_RATE_PER_PERSON_START',
	DONE= 'LOAD_SUCCESS_RATE_PER_PERSON_DONE',
	FAILED= 'LOAD_SUCCESS_RATE_PER_PERSON_FAILED'
}
