import React, { FC } from 'react'
import { Modal, Button } from 'antd'
import { ModalProps } from 'antd/lib/modal'

import { initialize, submit } from 'redux-form'
import { useDispatch } from 'react-redux'
// assets
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../../assets/icons/BaseIcons/x-icon.svg'
import { FORMS } from '../../../utils/enums'
import EditDocumentForm from '../forms/EditDocumentForm'
import { IEditDocumentForm, updateDocument } from '../../../reducers/documents/actions'
// eslint-disable-next-line import/no-cycle

export interface EditDocumentProps {
	id: number| null
	name: string| null
	categoryName: string| null
	categoryID: number| null
	isShowed: boolean
}

type Props = ModalProps & {
	isVisible: boolean,
	document: EditDocumentProps,
	onCancel: Function | React.MouseEvent<HTMLElement, MouseEvent>,
	onSuccess: Function,
	classNameBtn?: string
}

const EditDocumentModal: FC<Props> = (props) => {
	const {
		isVisible,
		onCancel,
		onSuccess,
		document,
		width,
		classNameBtn
	} = props

	const { t } = useTranslation('pages')

	const dispatch: any = useDispatch()

	dispatch(initialize(FORMS.EDIT_DOCUMENT_FORM, {
		...document,
		id: document.id,
		name: document.name,
		categoryID: document.categoryID
	}))

	const handleEdit = (body: IEditDocumentForm) => {
		dispatch(updateDocument(document.id || 0, body, () => onSuccess()))
	}

	const handleSubmit = (values:IEditDocumentForm) => {
		const body: IEditDocumentForm = {
			id: null,
			name: values.name,
			categoryID: values.categoryID
		}

		handleEdit({
			...body
		})
	}

	return 	<Modal
		closeIcon={<CloseIcon width={18} height={18}/>}
		title={t('loc:Upraviť dokument')}
		centered
		visible={isVisible}
		width={width}
		onCancel={onCancel}
		footer={[
			<Button style={{ width: 118, height: 40 }} key='back' type='text' onClick={onCancel}>
				{t('loc:Zrušiť')}
			</Button>,
			<Button style={{ width: 118, height: 40 }} className={classNameBtn || ''} key='submit' type='primary' onClick={() => dispatch(submit(FORMS.EDIT_DOCUMENT_FORM))}>
				{t('loc:Upraviť')}
			</Button>
		]}
	>
		<div>
			<EditDocumentForm
				onSubmit={ handleSubmit }
				isCreate={ false }
			/>
		</div>
	</Modal>
}

export default EditDocumentModal
