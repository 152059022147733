import React from 'react'
import * as PropTypes from 'prop-types'
import cx from 'classnames'
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Dragger } = Upload

const UploadField = ({
	name,
	label,
	showLabel,
	customClass,
	customLabelClass,
	required,
	meta: {
		touched,
		error
	},
	customRequest
}) => {
	const props = {
		name: 'file',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		onChange(info) {
			const { status } = info.file
			if (status !== 'uploading') {
				console.log(info.file, info.fileList)
			}
			if (status === 'done') {
				// notification.success({
				// 	message: `${ info.file.name } súbor úspešene načítaný.`
				// })
			} else if (status === 'error') {
				// notification.error({
				// 	message: `${ info.file.name } file upload failed.`
				// })
			}
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files)
		}
	}

	return (
		<div className={ cx('input-wrapper', { error: touched && error }, customClass) }>
			{ showLabel && <label className={ cx(customLabelClass, { required }) } htmlFor={ name }>{ label }</label> }
			<div className={ 'input-field' }>
				<Dragger
					{ ...props }
					multiple={ false }
					maxCount={ 1 }
					customRequest={ customRequest }
				>
					<p className="ant-upload-drag-icon">
						<InboxOutlined/>
					</p>
					<p className="ant-upload-text">Click or drag file to this area to upload</p>
					<p className="ant-upload-hint">
							Support for a single or bulk upload.
					</p>
				</Dragger>
			</div>
			<div className={ 'tooltip-error' }>
				{ touched ? error : '' }
			</div>
		</div>
	)
}

UploadField.propTypes = {
	name: PropTypes.string,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.shape({
		touched: PropTypes.bool.isRequired,
		error: PropTypes.string
	}).isRequired,
	input: PropTypes.shape({}).isRequired,
	customClass: PropTypes.string,
	customLabelClass: PropTypes.string,
	imageUrl: PropTypes.string,
	customRequest: PropTypes.func,
	isLoading: PropTypes.bool,
	required: PropTypes.bool
}

UploadField.defaultProps = {
	name: null,
	showLabel: true,
	label: '',
	customClass: null,
	customLabelClass: null,
	imageUrl: null,
	customRequest: null,
	isLoading: false,
	required: false
}

export default UploadField
