// eslint-disable-next-line import/no-cycle
import { getReq } from '../../utils/request'
import { LOAD_DASHBOARD } from './types'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResetStore } from '../generalTypes'

export type IDashboardActions = IResetStore | IGetDashboard

// get collection
interface IGetDashboard {
	type: LOAD_DASHBOARD
	payload: IDashboardPayload
}

export interface IDashboardPayload {
	data: IDashboard | null
	isLoading: boolean
	isFailure: boolean
}

export interface IDashboard {
	users: IDashboardUser[],
	week: {
		number: number,
		date: string
	}
}

export interface IDashboardUser {
	user: IDashboardUserDetail
	yearJobs: IDashboardJob[],
	halfYearJobs: IDashboardJob[],
	quarterYearJobs: IDashboardJob[],
	monthJobs: IDashboardJob[],

	fridayJobs: IDashboardJob[],
	mondayJobs: IDashboardJob[],
	saturdayJobs: IDashboardJob[],
	sundayJobs: IDashboardJob[],
	thursdayJobs: IDashboardJob[],
	tuesdayJobs: IDashboardJob[],
	wednesdayJobs: IDashboardJob[],
}

export interface IDashboardUserDetail {
	id: number,
	firstName: string,
	fullName: string,
	fullNameSlug: string,
}

export interface IDashboardJob {
	address: string,
	hasJobTasks: boolean,
	id: number,
	isJobDone: boolean
}

export const getDashboard = (
	filter: any,
	onSuccess: (data: any) => void,
	onFailure: (error: any) => void
): ThunkResult<Promise<IDashboardPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_DASHBOARD.START
	})
	try {
		const frequencies = filter.map((n: string, index: number) => `filterOutFrequencies[${index}]=${n.toUpperCase()}`).join('&')
		const { data } = await getReq(`/api/admin/dashboard/week?${frequencies}`)

		dispatch({
			type: LOAD_DASHBOARD.DONE,
			payload: {
				data
			}
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_DASHBOARD.FAILED
		})
		onFailure(error)
		return error
	}
}
