export enum LOAD_SETTINGS {
	START= 'LOAD_SETTINGS_START',
	DONE= 'LOAD_SETTINGS_DONE',
	FAILED= 'LOAD_SETTINGS_FAILED'
}

export enum UPDATE_SETTINGS {
	START= 'UPDATE_SETTINGS_START',
	DONE= 'UPDATE_SETTINGS_DONE',
	FAILED= 'UPDATE_SETTINGS_FAILED'
}
