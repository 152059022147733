import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

// assets
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { ReactComponent as ChevronIcon } from '../assets/icons/BaseMiniIcons/chevron-down-3-icon.svg'
import { RootState } from '../reducers'
import { getUsers } from '../reducers/usersU/actions'
import { createSlug } from '../utils/helper'

const { Item } = Form

const { Option } = Select

type Props = {
	onValueChange: (value: ISelectedUserOption) => void,
	userValue: number| undefined
}

export interface ISelectedUserOption {
	value: number,
	label: string
}

const SelectUserField = (props: Props) => {
	const { onValueChange, userValue } = props
	const dispatch = useDispatch()
	const { t } = useTranslation('pages')

	const [userSelected, setUserSelected] = useState<number | undefined>(undefined)

	function onChange(value:number, option: any) {
		onValueChange({
			value: option?.value,
			label: option?.children
		})
		setUserSelected(option?.value)
	}

	useEffect(() => {
		dispatch(getUsers(
			1,
			10000
		))
	}, [])

	useEffect(() => {
		setUserSelected(userValue)
	}, [userValue])

	const users = useSelector((state:RootState) => state.users.users.tableData)
		.map((user: any) => {
			const userName = user.lastName ? `${user.lastName} ${user.firstName}` : user.email
			return {
				value: user.id,
				label: userName
			}
		})

	return (
		<Item>
			<Select
				onBlur={() => {}}
				className={'dropDown'}
				size={ 'middle'}
				onChange={onChange}
				suffixIcon={<ChevronIcon width={20} height={20}/>}
				showSearch
				allowClear={true}
				value={userSelected}
				style={{ width: 200 }}
				placeholder="Vyber osobu"
				optionFilterProp="children"
				filterOption={(input:any, option:any) => createSlug(option?.children).indexOf(createSlug(input)) >= 0}
			>
				options={ map(users, (option) => (
					<Option
						key={ option.value }
						value={ option.value }
					>
						{ option.label }
					</Option>
				)) }
			</Select>
		</Item>
	)
}

export default React.memo(SelectUserField)
