import React from 'react'
import styled from 'styled-components'
import { NavLink, withRouter } from 'react-router-dom'
import { LinkOutlined } from '@ant-design/icons'

const LinkIcon = () => <LinkOutlined style={{ marginLeft: 4 }} />

const TEXT_COLOR = '#363636'
const StyledLink = styled(NavLink)`
	color: ${TEXT_COLOR};
	position: relative;
	overflow: hidden;
	display: inline-block;
	&:before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		transition: transform 0.5s ease;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background: #8c8c8c;
		transform: translateX(-101%);
	}
	&:hover:before {
		transform: translateX(0);
	}
	&:hover {
		color: ${TEXT_COLOR};
	}
`

const I9Link = (props) => (
	<StyledLink {...props}>
		{props.children}
		<LinkIcon />
	</StyledLink>
)

export default withRouter(I9Link)
