import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Col, Form, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { FORMS } from '../../../utils/enums'
import validateForm from './validateStoreForm'
import { IStoreForm } from '../../../reducers/stores/actions'
import InputField from '../../../atoms/InputField'

type ComponentProps = {}

type Props = InjectedFormProps<IStoreForm, ComponentProps> & ComponentProps

const StoreModalForm = ({
	handleSubmit
}: Props) => {
	const { t } = useTranslation('components')

	return (
		<Form layout={'vertical'} onSubmitCapture={ handleSubmit }>
			<Row gutter={ 16 }>
				<Col span={ 24 } className={ 'grid' }>
					<div style={ { height: '100%' } } className={ 'flex direction-col justify-start main-content' }>
						<Field
							name={ 'address' }
							component={ InputField }
							label={ t('components:Názov') }
							size={ 'large' }
							required
						/>
					</div>
				</Col>
			</Row>
		</Form>
	)
}

const form = reduxForm<IStoreForm, ComponentProps>({
	form: FORMS.STORE_MODAL_FORM,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	validate: validateForm
})(StoreModalForm)

export default form
