import React from 'react'
import {
	Card, Dropdown, Divider, Menu
} from 'antd'
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { loadUser, Organization, switchProfile } from '../reducers/user/userActions'
import { history } from '../utils/history'
import rootReducer, { RootState } from '../reducers'
import { logOutUser } from '../reducers/authorization/authActions'
import configureStore from '../utils/configureStore'

const { store } = configureStore(rootReducer)

const ProfileDropdown = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation('components')
	const profile = useSelector((state: RootState) => state.profile.profile.data)

	const handleLogout = () => {
		logOutUser()(store.dispatch, store.getState, undefined)
		history.push(i18next.t('paths:login|path'))
	}

	const handleSwitchAccount = (id: number) => {
		dispatch(switchProfile(id, () => {
			history.push(i18next.t('paths:base|path'))
			window.location.reload()
		}))
	}

	const hasOrganizations = (profile?.availableOrganizations?.length || 0) > 0

	const menu = (
		<Card
			bordered={false}
			style={{
				width: 300,
				padding: 16
			}}
		>
			<Menu>
				<Divider orientation="left">Konto</Divider>
				<Menu.Item>{profile?.fullName}</Menu.Item>
				<Divider orientation="left">Aktuálna organizácia</Divider>
				<Menu.Item><span className={'font-semibold'}>{profile?.currentOrganization?.name}</span></Menu.Item>
				{hasOrganizations && <Divider orientation="left">Účty</Divider>}
				{profile?.availableOrganizations?.map((data: Organization) => <Menu.Item
					key={data.name}
					onClick={() => handleSwitchAccount(data.id)}>{data?.name}
				</Menu.Item>)}
				<Divider />
				<Menu.Item onClick={handleLogout}>
					<LogoutOutlined style={{ marginRight: 5 }} />
					{t('components:Odhlásiť')}
				</Menu.Item>
			</Menu>
		</Card>
	)

	return (
		<Dropdown overlay={menu} trigger={['click']} placement={'bottomRight'} onVisibleChange={(visible) => {
			if (visible) {
				dispatch(loadUser())
			}
		}}>
			<span className={'profile-dropdown-item'} onClick={(e) => e.preventDefault()}>
				<span className={'text-xs font-semibold'}>{profile?.currentOrganization?.name} </span>
				<span className={'mx-4'}>-</span>
				<UserOutlined style={{ marginRight: 5 }} />
				{t('components:Profil')}
				<DownOutlined style={{ marginLeft: 5 }} />
			</span>
		</Dropdown>
	)
}

export default ProfileDropdown
