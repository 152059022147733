import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Row } from 'antd'
import { useDispatch } from 'react-redux'
import PriceOffersSentPerMonth from './PriceOffersSentPerMonth'
import PriceOffersStatusPerMonth from './PriceOffersCompletedPerMonth'

const { RangePicker } = DatePicker

const StatisticsDashboard = () => {
	const [dateRange, setDateRange] = useState<any>([])
	const dispatch = useDispatch()

	useEffect(() => {
		const params = dateRange.length
			? {
				startDate: dateRange[0].startOf('month').toISOString(),
				endDate: dateRange[1].endOf('month').toISOString()
			}
			: {}
	}, [dateRange, dispatch])

	const handleDateRangeChange = (dates: any) => {
		setDateRange(dates)
	}

	return (
		<div>
			<Row justify="space-between" style={{ marginBottom: 16 }}>
				<Col>
					{/* Other filters can go here */}
				</Col>
				<Col>
					<RangePicker picker="month" onChange={handleDateRangeChange} />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<PriceOffersSentPerMonth />
				</Col>
				<Col span={24}>
					<PriceOffersStatusPerMonth startDate={dateRange[0]?.startOf('month').toISOString()} endDate={dateRange[1]?.endOf('month').toISOString()} />
				</Col>
			</Row>
		</div>
	)
}

export default StatisticsDashboard
