import { get, map, mapValues } from 'lodash'
// eslint-disable-next-line import/no-cycle
import {
	deleteReq, getReq, postReq, putReq
} from '../../utils/request'
import {
	CREATE_DOCUMENTS, LOAD_DOCUMENT, LOAD_SELECT_DOCUMENTS, UPDATE_DOCUMENTS, DELETE_DOCUMENT
} from './types'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import {
	ITasksPayload
} from '../tasks/actions'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IResponsePagination } from '../../types/interfaces'
import { formatDateWithTime } from '../../utils/helper'

export type IDocumentActions = IResetStore
	| IGetDocumentsAction

// documents
export interface IGetDocumentsAction {
	type: LOAD_SELECT_DOCUMENTS
	payload: IDocumentsPayload
}

export interface IDocumentsPayload {
	tableData: DocumentTableItem[]
	originalData: DocumentOriginItem[]
	pagination: IResponsePagination | null
}

export type DocumentTableItem = {
	key: number
	id: number
	index: number
	createdAt: string
	name: string
	category: string
	categoryID: number
	path: string
}

export type DocumentOriginItem = {
	id: number
	createdAt: string
	category: string
	categoryID: number
	name: string
	path: string
}

export const getDocuments = (
	params: any
): ThunkResult<Promise<IDocumentsPayload>> => async (dispatch) => {
	let payload = {} as IDocumentsPayload
	try {
		dispatch({ type: LOAD_SELECT_DOCUMENTS.START })

		const queries = {
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq('/api/admin/documents', normalizeQueryParams)

		const originalData: DocumentOriginItem[] = get(data, 'documents', [])
		const tableData: DocumentTableItem[] = map(originalData, (column, index) => ({
			key: column.id,
			id: column.id,
			name: column.name,
			index,
			category: column?.category,
			categoryID: column?.categoryID,
			path: column.path,
			createdAt: formatDateWithTime(column.createdAt)
		}))

		payload = {
			tableData,
			originalData,
			pagination: get(data, 'context')
		}

		dispatch({
			type: LOAD_SELECT_DOCUMENTS.DONE,
			payload
		})
		return data
	} catch (error) {
		dispatch({ type: LOAD_SELECT_DOCUMENTS.FAILED })
		Promise.reject(error)
		return error
	}
}

export interface ISwapDocumentParams {
	id: number
	order: number
}

export const postSwapDocument = (
	params: ISwapDocumentParams[],
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_DOCUMENTS.START
	})
	try {
		await postReq('/api/admin/documents/swap', null, params)
		dispatch({ type: UPDATE_DOCUMENTS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_DOCUMENTS.FAILED })
		onFailure(error)
		return false
	}
}

export interface IDocumentForm {
	id: number| null
	fileId: number
	name: string
}

export const createDocuments = (
	values: IDocumentForm,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: CREATE_DOCUMENTS.START
	})
	try {
		const normalizeQueryParams = mapValues(values, (query) => query || undefined)

		const { data } = await postReq('/api/admin/documents', null, normalizeQueryParams)

		dispatch({ type: CREATE_DOCUMENTS.DONE })
		onSuccess(get(data, 'data.id'))
		return true
	} catch (error) {
		dispatch({ type: CREATE_DOCUMENTS.FAILED })
		onFailure(error)
		return false
	}
}

export interface IEditDocumentForm {
	id: number| null
	name: string| null
	categoryID: number| null
}

export const updateDocument = (
	id: number,
	values: IEditDocumentForm,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: UPDATE_DOCUMENTS.START
	})
	try {
		await putReq(`/api/admin/documents/${id}`, null, values)
		dispatch({ type: UPDATE_DOCUMENTS.DONE })

		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: UPDATE_DOCUMENTS.FAILED })
		onFailure(error)
		return false
	}
}

export const getDocument = (
	id: number,
	onSuccess: (data: any) => void = ((data) => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<ITasksPayload>> => async (dispatch) => {
	dispatch({
		type: LOAD_DOCUMENT.START
	})
	try {
		const { data } = await getReq(`/api/admin/documents/${id}`)

		dispatch({
			type: LOAD_DOCUMENT.DONE,
			payload: data
		})

		onSuccess(data)
		return data
	} catch (error) {
		dispatch({
			type: LOAD_DOCUMENT.FAILED
		})
		onFailure(error)
		return error
	}
}

export const deleteDocument = (
	id: number,
	onSuccess: () => void = (() => {}),
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onFailure: (error: any) => void = ((error) => {})
): ThunkResult<Promise<boolean>> => async (dispatch) => {
	dispatch({
		type: DELETE_DOCUMENT.START
	})
	try {
		await deleteReq(`/api/admin/documents/${id}`)

		dispatch({ type: DELETE_DOCUMENT.DONE })
		onSuccess()
		return true
	} catch (error) {
		dispatch({ type: DELETE_DOCUMENT.FAILED })
		onFailure(error)
		return false
	}
}
