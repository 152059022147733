/* eslint-disable import/no-cycle */
import { RESET_STORE } from '../generalTypes'
import { ILoadingAndFailure } from '../../types/interfaces'
import {
	TEMPLATES, TEMPLATE
} from './templateTypes'
import {
	TemplateActions, ITemplatesPayload, ITemplatePayload
} from './templateActions'

export const initState = {
	templates: {
		originalData: null,
		tableData: [],
		templatesOptions: null,
		pagination: null,
		isLoading: false,
		isFailure: false
	} as ITemplatesPayload & ILoadingAndFailure,
	template: {
		data: null,
		isLoading: false,
		isFailure: false
	} as ITemplatePayload & ILoadingAndFailure
}

export default (state = initState, action: TemplateActions) => {
	switch (action.type) {
		case TEMPLATES.TEMPLATES_LOAD_START:
			return {
				...state,
				templates: {
					...state.templates,
					isFailure: false,
					isLoading: true
				}
			}
		case TEMPLATES.TEMPLATES_LOAD_FAIL:
			return {
				...state,
				templates: {
					...initState.templates,
					isFailure: true
				}
			}
		case TEMPLATES.TEMPLATES_LOAD_DONE:
			return {
				...state,
				templates: {
					...initState.templates,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination,
					templatesOptions: action.payload.templatesOptions
				}
			}
		case TEMPLATE.TEMPLATE_LOAD_START:
			return {
				...state,
				template: {
					...state.template,
					isFailure: false,
					isLoading: true
				}
			}
		case TEMPLATE.TEMPLATE_LOAD_FAIL:
			return {
				...state,
				template: {
					...initState.template,
					isFailure: true
				}
			}
		case TEMPLATE.TEMPLATE_LOAD_DONE:
			return {
				...state,
				template: {
					...initState.template,
					data: action.payload.data
				}
			}
		case RESET_STORE:
			return initState
		default:
			return state
	}
}
