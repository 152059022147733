import i18next from 'i18next'
import { forEach, set } from 'lodash'
import { IStoreItemOrderForm } from '../../../reducers/storeItemOrders/actions'

export default (values: IStoreItemOrderForm) => {
	const errors: any = {}

	if (!values.supplyStoreID) {
		errors.supplyStoreID = i18next.t('state:Toto pole je povinné')
	}

	forEach(values?.orders, (order, index) => {
		if (!order.storeItemID) {
			set(errors, `orders.${index}.storeItemID`, i18next.t('loc:Musíte vybrať položku'))
		}
		if (order?.amount === 0) {
			set(errors, `orders.${index}.amount`, i18next.t('loc:Počet musí byť väčší ako 0'))
		}
	})
	return errors
}
