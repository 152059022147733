import { LOAD_DASHBOARD_RATINGS, LOAD_RATINGS } from './types'
// eslint-disable-next-line import/no-cycle
import { ICollectionActions, IDashboardRatingPayload, IRatingsPayload } from './actions'
import { ILoadingAndFailure } from '../../types/interfaces'

export const initState = {
	ratings: {
		originalData: null,
		tableData: [],
		pagination: null,
		isLoading: false,
		isFailure: false
	} as IRatingsPayload & ILoadingAndFailure,
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	} as IDashboardRatingPayload & ILoadingAndFailure
}

export default (state = initState, action: ICollectionActions) => {
	switch (action.type) {
		case LOAD_DASHBOARD_RATINGS.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				} as IDashboardRatingPayload & ILoadingAndFailure
			}
		case LOAD_DASHBOARD_RATINGS.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload.data,
					isLoading: false,
					isFailure: false
				} as IDashboardRatingPayload & ILoadingAndFailure
			}
		case LOAD_DASHBOARD_RATINGS.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				} as IDashboardRatingPayload & ILoadingAndFailure
			}

		// ratings list
		case LOAD_RATINGS.START:
			return {
				...state,
				ratings: {
					...state.ratings,
					isFailure: false,
					isLoading: true
				} as IRatingsPayload & ILoadingAndFailure
			}
		case LOAD_RATINGS.DONE:
			return {
				...state,
				ratings: {
					...initState.ratings,
					originalData: action.payload.originalData,
					tableData: action.payload.tableData,
					pagination: action.payload.pagination
				} as IRatingsPayload & ILoadingAndFailure
			}
		case LOAD_RATINGS.FAILED:
			return {
				...state,
				ratings: {
					...initState.ratings,
					isFailure: true
				} as IRatingsPayload & ILoadingAndFailure
			}
		default:
			return state
	}
}
