export enum AUTH_USER {
	AUTH_USER_LOAD_START = 'AUTH_USER_LOAD_START',
	AUTH_USER_LOAD_DONE = 'AUTH_USER_LOAD_DONE',
	AUTH_USER_LOAD_FAIL = 'AUTH_USER_LOAD_FAIL'
}

export enum USER {
	USER_LOAD_START = 'USER_LOAD_START',
	USER_LOAD_DONE = 'USER_LOAD_DONE',
	USER_LOAD_FAIL = 'USER_LOAD_FAIL'
}
